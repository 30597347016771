import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  EU1Image,
  EU2Image,
  EU3Image,
  EU4Image,
  EU5Image,
  EU6Image,
  EU7Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const EUROPE_TOUR = "/assets/img/worldTour/wt9.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "08 Nights / 09 Days",
    title: "Little Europe",
    image: EU1Image,
    description:"",
    path: "/eu1",
  },
  {
    id: 2,
    fromToDays: "08 Nights / 09 Days",
    title: "Exotic Swiss",
    image: EU2Image,
    description:"",
    path: "/eu2",
  },
  {
    id: 3,
    fromToDays: "08 Nights / 09 Days",
    title: "France & Spain Combo",
    image: EU3Image,
    description:"",
    path: "/eu3",
  },
  {
    id: 4,
    fromToDays: "10 Nights / 11 Days",
    title: "Central Europe",
    image: EU4Image,
    description:"",
    path: "/eu4",
  },
  {
    id: 5,
    fromToDays: "11 Nights / 12 Days",
    title: "Central Special",
    image: EU5Image,
    description:"",
    path: "/eu5",
  },
  {
    id: 6,
    fromToDays: "12 Nights / 13 Days",
    title: "European Delight",
    image: EU6Image,
    description:"",
    path: "/eu6",
  },
  {
    id: 7,
    fromToDays: "13 Nights / 14 Days",
    title: "Central Delight",
    image: EU7Image,
    description:"",
    path: "/eu7",
  },
];

// const rulesData = [
//   ""
// ];

const Europe = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Europe"}
            subTitle={"Amazing Holidays to Europe"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={EUROPE_TOUR}
            title={"Popular Europe Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Europe;










