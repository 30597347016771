import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { BA2Image } from "../image";

const heroTextData = [
  {
    title: "Kuta Package",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Kuta",
    endCity: "Seminyak",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Transfer",
    details: "Arrival Transfer Ngurah Rai International Airport to Ubud Hotel on Private Basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Half Day Benoa Beach Tour including Banana Boat on private basis",
    details: "Half Day Benoa Beach Tour including Banana Boat on private basis An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula far away from the hustle & bustle. North of Nusa Dua this beach offers a wide range of water sports from Para-Sailing to Banana boat ride & so on. Please check with our representatives for all the water sports information. Many shacks & restaurants lined up along the beach make sure that your tummy is full & you enjoy the most.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Full Day Kintamani Volcano Tour with Ubud Village + Bali Swing on Private Basis",
    details: "Full Day Kintamani Volcano Tour with Ubud Village + Bali Swing on Private Basis A visit to Kintamani Volcano is a rare opportunity to experience one of Bali’s last remaining active volcanos up close. This is one of the most popular and best day trip in Bali. The tour begins with the sights of a vibrant art village. Here you can meet with the people who keep the techniques of the regions handicrafts alive with a stop at Tohpati village, widely known as the centre of handmade Balinese Batik weaving. Next stop is Celuk village famous for producing high-grade gold and silver handicrafts. Continue trip to Mas Village, one of the many artists villages surrounding Ubud, is the best place on the island to find high-quality Balinese woodcarvings, considered some of the best woodcarvings in the world. The tour continues to the Mount & Lake Batur in Kintamani village for some stunning views of the beautiful locales in this region. This tour only cover viewing Mount Batur Volcano from View point only. Your tour ends in one of the most famous coffee plantations in the area. Here you can look & taste many kinds of coffees from Indonesia. ",
  },
  {
    id: "Day 4",
    header: "Full Day Tanah Lot Temple followed by Uluwatu Temple tour on private basis",
    details: "Full Day Tanah Lot Temple followed by Uluwatu Temple tour on private basis Tanah Lot Temple, locally known as Pura Tanah Lot is probably the most popular and photographed temple on the island, located on the coastal side of Beraban countryside, Kediri sub district and Tabanan Regency. Tanah Lot Temple stands for Earth (Tanah) and Sea (Lot), quite appropriate considering its spectacular setting on top of an impressive rock overlooking the Indian Ocean. This cultural icon is among the seven sea temples located in the coast of Bali and has been contained in Balinese mythology for centuries. In the late afternoon proceed to the southern tip of Bali for Uluwatu Sunset tour. Uluwatu Temple, or Pura Luhur Uluwatu, is renowned for its magnificent location, perched on top of a steep cliff approximately 70 metres above the waves. As one of the 6 key temples considered to be Bali's spiritual pillars, Uluwatu also offers a splendid sunset backdrop. It is definitely one of the top places in Bali for witnessing a delightful sunset, with direct views overlooking the beautiful waves of the Indian Ocean.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure Transfer",
    details: "Departure Transfer Kuta Hotel to Ngurah Rai International Airport on Private Basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Meet & Greet assistance at the Airport",
"04 Nights Accommodation on BB basis",
"Return Airport Transfer on Private basis",
"Half Day Benoa Beach Tour including Banana Boat on private basis",
"Full Day Kintamani Volcano Tour with Ubud Village + Bali Swing on Private Basis",
"Full Day Tanah Lot Temple followed by Uluwatu Temple tour on private basis",
"VAT in Indonesia and all other taxes",
];

const exclusionsData = [
"Airfare, Airport Taxes, meals not mentioned, any cost of personal nature, any cost not mentioned in the ‘Cost Includes’ section",
"5% GST on the total invoice & TCS declaration if payment received in India, Remittance charges of USD 35 if payment received",

];

const BA2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={BA2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default BA2;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
