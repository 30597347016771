import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EA4Image } from "../image";

const heroTextData = [
  {
    title: "Uganda Suggested Itinerary",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Entebbe",
    endCity: "Entebbe",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Entebbe International Airport - Fly to Bwindi Impenetrable Forest",
    details: "Received on arrival at Entebbe airport, escorted to the Domestic departure section, assisted with check in for your domestic flight to Bwindi. Depart Entebbe in the afternoon (departure timings can be flexible to suit guests’ convenience). You will be met by the lodge driver/guide on arrival at Bwindi and be driven to the lodge which is an hour‘s journey, driving through villages. Lunch at the lodge, leisure till evening. Dinner and overnight at Silverback Lodge. Optional activity-Pygmy [Batwa] trail experience cost TBA for 2-4 pax",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Gorilla tracking (can take 5-7 hours)",
    details: "At 06:00 Hours have a cheerful cup of coffee in preparation for the most interesting activity in the world. You shall be guided by very experienced English speaking guide. This park is best known for the fascinating gorillas, where the time taken and terrain varies according to the movements of these fantastic apes. The thrill of spending time with and observing these gentle giants is a rare and moving adventure that will leave you with long lasting memories of a truly unique experience. The gorillas are shy and peaceful animals and it is an unforgettable experience to watch and photograph them as they interact with each other. Packed lunch will be provided. Dinner and accommodation at Silverback Lodge.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Bwindi - Queen Elizabeth National Park [Approx. 4 hours’ drive.]",
    details: "After breakfast, leave for Queen Elizabeth National Park via the Ishasha sector famous for its tree climbing lions and multitude of animals. Keep an eye out for game and nice views of the mystical Rwenzori Mountain Range. Arrive at Mweya lodge in time for lunch. After lunch, enjoy a unique boat cruise on the Kazinga Channel and into Lake Edward. This trip passes through high concentrations of Hippos and a wide variety of animals may be seen drinking at the water’s edge. This river cruise offers an excellent platform for photography, bird watching and game viewing. The prolific bird life is colorful and stupendous with well over 600 recorded species having been spotted. Meals and overnight accommodation at Mweya Safari Lodge.",
  },
  {
    id: "Day 4",
    header: "Game drive [3 hours]",
    details: "After an early morning rise, and a cup of your favorite beverage, embark on a morning game drive along Kasenyi track and Queen’s mile in the park and keep a watch out for Lion, Elephant, Cape Buffalo, Warthogs, Leopard, Hyena, Mongoose, Waterbuck, Ugandan Kob, and lots of the many small animals and birds. After the game drive return to the Lodge for breakfast. Time for leisure. Lunch and afternoon game drive. Overnight at Mweya Safari lodge.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
  id: "Day 5",
  header: "Departure",
  details: "Breakfast and checkout/transfer to Airstrip for return flight to Entebbe",
  labelData: ["label1", "label2", "label3", "label4"],
},
];

const inclusionData = [
"Accommodation as indicated in the itinerary",
"Meals as described by B (Buffet Breakfast), L (Lunch), D (Dinner)",
"National parks and reserve entrance fees",
"Airport transfers as indicated",
"Transport in a 7 seater safari minibus with pop up hatch roof for easy photography",
"Game drives as per the itinerary",
"Services of an experienced safari driver guide",
"Complimentary mineral water {one litre per person per day}",
];

const exclusionsData = [
"Personal expenses such traveling insurance, excess baggage fee, communication charges that include, emails, faxes and phone calls beverages including luxury champagne, luxury spirits or meals not indicated in the itinerary",
"Gratuity for Porterage / waiters at hotels, camps and airports",
"Gratuity for your professional driver guide",
"Cost of obtaining passport and visas",
"International Airfare",
];

const EA4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EA4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EA4;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
