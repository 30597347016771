import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { TH2Image } from "../image";

const heroTextData = [
  {
    title: "Phuket – Krab",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Phuket",
    endCity: "Krab",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
{
  id: "Day 1",
  header: "Phuket Airport – Phuket Hotel",
  details: "Pickup At Phuket International To Phuket Hotel By Pvt Transfer Basis Day Leisure By Own Overnight At Hotel ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 2",
  header: "Phuket",
  details: "Breakfast At Hotel Pickup At Hotel Lobby To Phi Phi Island Tour By Big Boat With Lunch National Park Fee Thb 400 Per Person Not Included – Sic Basis Day Leisure By Own Overnight At Hotel",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 3",
  header: "Phuket – Krabi ",
  details: "Breakfast At Hotel Check Out From Hotel Pickup At Hotel Lobby To Krabi Hotel By Pvt Basis Day Leisure By Own Overnight At Hotel",
},
{
  id: "Day 4",
  header: "Krabi ",
  details: "Breakfast At Hotel Pickup At Hotel Lobby To Krabi 4 Island Tour By Long Tail Boat With Lunch Sic Basis National Park Fee Thb 400 Per Person Not Included Evening Leisure By Own Overnight At Hotel",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 5",
  header: "Krabi – Krabi Airport ",
  details: "Breakfast At Hotel Check Out From Hotel Pickup At Hotel Lobby To Krabi Airport ",
  labelData: ["label1", "label2", "label3", "label4"],
},
];

const inclusionData = [
  "Stay in a hygienic 3-star hotel on a double sharing basis",
  "Meals: Breakfast, Lunch/Dinner",
  "Transfers Airport pick up and drop as per your flight timings.",
  "Comfortable transfers for sightseeing as per the itinerary",
  "Guide Experienced driver cum guide",
  "Sightseeing as per the itinerary",
];

// const exclusionsData = [
//   "Alcoholic beverages except described.",
//   "Optional activities.",
//   "Flights",
//   "Visa",
//   "Meals except described.",
//   "Travel insurance",
// ];

const TH2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TH2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TH2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
