import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W31Image } from "../image";

const heroTextData = [
  {
    title: "Amorous Dale",
    duration: "14 Nights / 15  Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "NJP Rly Station / IXB Airport - Kalimpong (80 Kms. / 3 Hrs.)",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Kalimpong (4,091 Ft.). On arrival, check-in to the hotel and rest of the day at leisure. Overnight stay at Kalimpong.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kalimpong Sightseeing - Gangtok (100 Kms. / 7 Hrs.)",
    details:
      "After breakfast enjoy a half day Kalimpong city tour and visit Mangal Dham, Deolo Hill, Golf Course, Durpin Dara Hills and Pine View Nursery (Sunday closed). After that transfer to Gangtok (5,500 Ft.). On arrival check-in to your hotel. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
  {
    id: "Day 3",
    header:
      "Excursion to Tsomgo Lake and Baba Mandir (53 Kms. / 3 Hrs. one-way)",
    details:
      "After breakfast, start for an excursion to Tsomgo Lake (12,400 ft. / 37 Kms. / 02 Hrs. from Gangtok) and further drive to Baba Mandir (13,200 ft. / 16 Kms. / 01 Hrs. from Tsomgo Lake). Evening back to Gangtok. Overnight stay at Gangtok.",
  },
  {
    id: "Day 4",
    header: " Gangtok Full Day Local Sightseeing",
    details:
      "Start for a Full day city tour covering Tashi view point, Ganesh Tok, Hanuman Tok, Flower show, Cottage Industry and Handicraft Centre (Closed on Sunday and 1st and 3rd Saturday of the month), Institute of Tibetology (Closed on Sunday), Dro-dul Chorten, Gonjang Monastery and Banjakhri Falls (maximum 6 hours). Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Gangtok - Lachung (116 Kms. / 6 Hrs.)",
    details:
      "After breakfast check out from hotel with limited luggage and proceed to Lachung (8,738 Ft.) in North Sikkim. On the way, take a break for some beautiful spots that are worth seeing and visiting like Naga Waterfall, The Confluence of Lachen Chu (River) and Lachung Chu (River) at Chungthang and Bhim Nala Waterfall. On arrival at Lachung, check into your hotel. Dinner and Overnight stay at Lachung.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Excursion to Chopta Valley and Gurudongmar Lake (137 Kms. / 6 Hrs.)	",
    details:
      "Early breakfast proceeds to visit Gurudongmar Lake (17,100 ft.) via Chopta valley (13,200 ft.) and Thangu.  Gurudongmar is considered as one of the 2nd highest lake in World, generally covered with snow from December to April or even more, and is known as the Holy / Sacred Lake. It will take approximately 3-4 hours’ drive from Lachen (One way). Afternoon back to hotel have your lunch rest of the day is at leisure for individual activities, Dinner and Overnight stay at Lachen. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: " Lachen - Lachung (47 Kms. / 2 Hrs.)",
    details:
      "After breakfast transfer to Lachung (8,738 Ft.) through the picturesque valley. On arrival check-in to the hotel, have lunch and enjoy the day as you like it. Overnight stay at Lachung.",
  },
  {
    id: "Day 8",
    header: "Excursion to Yumthang Valley (26 Kms. / 1½ Hrs. one-way)",
    details:
      "After breakfast proceed to Yumthang Valley, which is known as Valley of Flowers (12,000 ft.). Yumthang is the summer grazing ground of the yaks and winter playgrounds of yetis. On the way back, visit Hot Spring which is considered to have medicinal properties. Back to the hotel in the afternoon. After lunch free for individual activities, Dinner and Overnight stay at Lachung.",
  },
  {
    id: "Day 9",
    header: "Lachung - Gangtok (116 Kms. / 6 Hrs.",
    details:
      "After breakfast, transfer to Gangtok (5,500 Ft.). On arrival, check-in to the hotel and rest of the day at leisure. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Pelling - Gangtok via Ravangla ",
    details:
      "After breakfast, transfer to Gangtok (5,500 Ft.). Via Ravangla (8,000 Ft.). Visit Buddha Park. On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Gangtok.",
  },
  {
    id: "Day 11",
    header: "Pelling Full Day Sightseeing with Skywalk",
    details:
      "After breakfast, start for a full day sightseeing tour. In first half, cover Darap village, Rimbi waterfalls, Khecheopalri Lake (Wishfulling Lake) and Khangchendzongha waterfalls. In the second half cover the Pemayangtse monastery, Rabdantse ruins (2nd capital of Sikkim), Sang-Ngag Choling (Skywalk), New helipad ground.  Evening free for leisure. Overnight stay at Pelling.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
  {
    id: "Day 12",
    header: "Pelling - Darjeeling (80 Kms. / 4 Hrs.)",
    details:
      "After breakfast, transfer to Pelling (6,300 Ft.). On arrival check-in to hotel and rest of the day at leisure. Overnight stay at Pelling.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
  {
    id: "Day 13",
    header: "Darjeeling Full Day Sightseeing with Tiger Hill",
    details:
      "Early morning (4:00 AM) visit Tiger Hill (Subject to availability of Permit / Token) back to hotel. After breakfast start at 8.30 AM visit Himalayan Mountaineering Institute, P.N. Zoological Park (Thursday closed), Tenzing Rock, Ropeway, Tibetan Refugee self-help Centre (Sunday closed), Tea Garden (outer view), Japanese Temple, Ghoom Monastery, Batasia loop. Evening free for shopping or leisure. Overnight stay at Darjeeling.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
  {
    id: "Day 14",
    header: " Darjeeling - Mirik - Local Sightseeing (49 Kms. / 2½ Hrs.)",
    details:
      "After breakfast, transfer to Mirik (4,902 Ft.), which is a small picturesque hill station with a beautiful lake surrounded by forested hills on all sides with the back drop of Mt Kanchenjunga. Enroute visit Pasupati Market which is situated in Indo-Nepal border (Extra vehicle charges will be applicable for Nepal Border to Pasupati Market on direct payment basis). Further, drive to Mirik runs through some fascinating tea gardens (Estates), Cardamom plantations and pine forests. On arrival check-in to hotel. Afternoon visit Sumendu Lake, which is famous for being one of the most beautiful man-made lakes. You may enjoy horse ride and boating at this point on direct payment basis. Evening back to hotel. Overnight stay at Mirik.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
  {
    id: "Day 15",
    header: " Mirik - NJP Rly Station / IXB Airport (55 Kms. / 2 Hrs.)",
    details:
      "After breakfast Checkout from the hotel and take the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  }, 
];


const inclusionData = [
"Accommodation on twin Sharing Basis. ",
"Meal Plan (as per mentioned above).",
"Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
"All permit fees & hotel taxes (as per itinerary).",
"Rates are valid for INDIAN NATIONALS only.",

];

const exclusionsData = [
"Air Fare / Train fare. ",
"Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
"Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
"Entrance Fees & Guide charges. ",
"Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
"Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
"Room Heater Charges.",
"Insurance. ",
"Anything which is not included in the inclusion",
];

const W31 = () => { 
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W31Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W31;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
