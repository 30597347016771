import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const WEEKEND_GETAWAY_TO_MANALIL_TOUR =
  "/assets/img/domesticTour/himachal/Weekend_getaway_to_Manali.png";

const heroTextData = [
  {
    title: "Weekend getaway to Manali",
    duration: "03 Nights / 04 Day",
    typoOfTrip: "Road Trip",
    startCity: "Shimla",
    endCity: "Shimla",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Shimla City Tour",
    details:
      "On Arrival at Shimla bus stand pick up & transfer to hotel & check in to the Hotel, Afternoon half day city tour visiting “INDIAN INSTITUTE OF ADVANCE STUDIES” At 1983m, this magnificent English renaissance structure was the former Vice regal Lodge. Evening free to stroll in the famous shopping place of Shimla town – “The Mall & THE RIDGE” This large open space in the heart of town presents excellent views of the mountain ranges. Shimla’s landmarks – the neo- gothic structure of Christ Church and the neo – Tudor library building – are worth seeing. Overnight stay at Shimla. (ADVANCE STUDY, SANKAT MOCHAN TEMPLE, VAISHNO DEVI GUFA, HIMALAYAN BIRD PARK)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kufri Sightseeing",
    details:
      "Today morning after breakfast proceed to sightseeing Shimla, visit KUFRI, India's one of the best holiday paradises Kufri in Shimla is beautifully tucked on an altitude of 2,622 meter. The place is a tourist hotspot for its awesome scenic and sports activities. Overnight stay at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Shimla to Delhi by Volvo",
    details:
      "After enjoying your breakfast at the hotel & leave for Delhi by Volvo A/C Bus. (8.30 AM & 11.15 AM).",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Hadimba Temple",
  "Manali Mall Road",
  "Vashisht Temple",
  "Nehru Kund",
  "Van Vihar",
  "Solang Valley",
  "Kullu, Shawl Factory",
  "Pick up and drop from Delhi",
  "All transfer and sightseeing included.",
  "MAP (Breakfast and Dinner)",
];

const exclusionsData = [
  "All personal expenses, optional tours and extra meals.",
  "Surcharges as applicable due to peak season, surcharge dates, trade fair dates.",
  "Camera fees, alcoholic/non-alcoholic beverages and starters.",
  "Vehicle service on leisure days for sightseeing not included in the itinerary.",
  "Mandatory Christmas/New Year Eve dinner cost will be applicable as advised by the hotels.",
  "Any services not mentioned in the inclusions list.",
  "Services of Tour Manager are not included in this package.",
  "Tips and porter-age.",
  "Tourism tax is excluded wherever applicable.",
  "Tips are excluded wherever applicable.",
];

const WeekendgetawaytoManali = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={WEEKEND_GETAWAY_TO_MANALIL_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default WeekendgetawaytoManali;
