import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";

const contactInfoData = [
  {
    icon: <LocationOnIcon fontSize="small" sx={{ color: "#FFC300" }} />,
    info: "Near ICICI Bank, Maruti Mandir Chowk, Talegaon Dabhade, Pune, Maharashtra, India 410506",
  },
  {
    icon: <PhoneIcon fontSize="small" sx={{ color: "#FFC300" }} />,
    info: "+91-9730747576",
  },
  {
    icon: <MailIcon fontSize="small" sx={{ color: "#FFC300" }} />,
    info: "enquiry@radhatours.com",
  },
  {
    icon: <LanguageIcon fontSize="small" sx={{ color: "#FFC300" }} />,
    info: "www.radhatours.com",
  },
];

const ContactInfo = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h4" color={"text.primary"}>
            Contact Info
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              {contactInfoData.map((item) => (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={1}
                    sm={1.5}
                    md={1}
                    // sx={{ display: "flex", alignItems: "center" }}
                  >
                    {item.icon}
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    sm={10.5}
                    md={11}
                    // sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body1" color={"text.primary"}>
                      {item.info}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactInfo;
