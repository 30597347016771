import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR4Image } from "../image";

const heroTextData = [
  {
    title: "Fjord & Glacier Adventure",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bergen",
    endCity: "Bergen",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival in Bergen",
    details: "Arrival in Bergen according to your own itinerary. Bergen is Norway’s second largest city and the world’s only city surrounded by 7 mountains and 7 fjords. Located on the west coast of Norway, Bergen is the perfect starting point for visits to the Norwegian fjords. Sights of interest in Bergen include the Hanseatic Bryggen Harbour, Fløibanen Funicular, Troldhaugen, the Fish and Flower market, Bergen Aquarium and Gamle Bergen (Old Bergen Open Air Museum). Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Cruise on the Sognefjord (Bergen – Sogndal)",
    details: "Breakfast at the hotel. This day is at your own leisure before afternoon departure by express boat in northern direction along the western coastline of Norway. After a few hours the journey continues along the Sognefjord, the world’s longest and deepest fjord, to the fjord village of Sogndal. Accommodation in Sogndal.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Glacier Walk at Nigards Glacier",
    details: "Breakfast at the hotel. Today you will travel with the glacier bus via Gaupne to Jostedalen and the Nigards Glacier. The valley of Jostedalen offers spectacular scenery, mainly shaped by glacial erosion. The Nigards Glacier is one of the most famous glacial outlets of the Jostedal glacier, the largest glacier in Northern Europe. At the Nigards Glacier you will experience a 2-hour guided glacier walk, a short and easy blue ice trip taking you deep into an icy landscape. It is not a hard or physically demanding trip and is therefore suitable for all who wish to experience what glacier walking is really like. After an adventurous day in Jostedal you will arrive back in Sogndal in time for dinner.Accommodation in Sogndal.",
  },
  {
    id: "Day 4",
    header: "Fjord cruise to Flåm (Sogndal – Flåm)",
    details: "Breakfast at the hotel. Morning at leisure before departing by bus to Leikanger where you will board the express boat for a 1-hour cruise to Flåm, Norway’s only fjord village with train connection. Afternoon at leisure to explore Flåm. The area offers great hiking possibilities. We would also recommend an afternoon excursion to Stegasteinen viewpoint, which offers spectacular view of the Aurlandsfjord. Accommodation in Flåm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Flåm Railway - Downhill biking – Fjord Safari (Flåm - Voss)",
    details: "Breakfast at the hotel. Morning departure with the Flåm Railway from Flåm to Myrdal, before heading half way down again to Berekvam station. The railway passes through wild and beautiful mountain scenery with waterfalls and steep mountain sides reaching a height of up to 900 metres. There is no other adhesion railway in the world running on normal tracks that is so steep over a long distance. At Berekvam station you will leave the train, meet your guide and continue the remaining 11 kilometres to Flåm by bike (your luggage will be transported to your hotel in Voss). The downhill bike ride follows a gravel road along the river with spectacular view of the waterfalls, mountain sides and valley. If you like you can even fill your water bottle from the river and streams along the way. In the fjord village of Flåm you will have some time at leisure before you will go on a thrilling Fjord Safari along the narrow Nærøyfjord, which was added to UNESCO’s list of world heritage sites in 2005. The RIB boat will bring you close to shore, giving you the opportunity to have a closer look at the spectacular nature and fauna of the Norwegian fjords. You will also experience the tranquility of an area hard to reach and seldom visited and learn about the history and geography of this amazing place. If you are lucky you might even see some seals. After 1 ½ hour you will arrive in Gudvangen, at the head of the NærøyfjordAfterwards, a bus will be waiting to take you through the Nærøy Valley and attractive farmland to Voss, famous for its annual Extreme Sports Week. Afternoon at leisure. Accommodation in Voss.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Train Voss - Bergen / Optional White Water Rafting",
    details: "Breakfast at the hotel. Today you can choose between catching the morning or afternoon train to Bergen (approximately 1 hour train ride). If spending the day in Voss, we recommend joining the white water rafting including a delicious barbecue lunch. On the 3 ½ hour rafting tour you will get wet, you will have to paddle, you might fall in, the raft could flip over, but you are guaranteed to have the time of your life. Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure from Bergen",
    details: "Breakfast at the hotel. Departure from Bergen according to your own itinerary.",
  },
];

const inclusionData = [
"6 nights in 4* hotel accommodation in a standard room with private shower/WC, including breakfast",
"Boat Bergen – Sogndal",
"Glacier bus Sogndal – Jostedal – Sogndal",
"Bus Sogndal – Leikanger (NB: not available on Saturdays; Earlier departure on Sundays)",
"Boat Leikanger – Flåm",
"Train Flåm – Myrdal – Berekvam",
"Mountain bike rental Berekvam – Flåm",
"Fjord Safari with RIB boat Flåm – Gudvangen",
"Bus Gudvangen – Voss",
"Train Voss – Bergen",
"Entrance to Breheimsenteret Glacier Centre",
"Short Blue Ice Walk – 2 hours on the ice with glacier guide. Glacier gear included.",
"Boat trip out to the glacier",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
  ];


const SR4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR4;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
