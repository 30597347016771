import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { TH1Image, TH2Image, TH3Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const THAILAND_TOUR = "/assets/img/worldTour/wt21.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Pattaya – Bangkok",
    image: TH1Image,
    description: "",
    path: "/th1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Phuket – Krabi",
    image: TH2Image,
    description: "",
    path: "/th2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Bangkok Pattaya Phuket Tour with Phi Phi Island ",
    image: TH3Image,
    description: "",
    path: "/th3",
  },
];

// const rulesData = [
//   ""
// ];

const Thailand = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Thailand"}
            subTitle={"Amazing Holidays to Thailand"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={THAILAND_TOUR}
            title={"Popular Thailand Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Thailand;
