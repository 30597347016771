import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      mobileno: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(30, "Must be 15 characters or less")
        .matches(/^[A-Za-z ]*$/, "Please enter valid name")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      mobileno: Yup.string()
        .min(10, "Must be 10 characters")
        .max(10, "Must be 10 characters ")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        )
        .required("Required"),
      message: Yup.string()
        .max(200, "Must be 200 characters or less")
        .min(2, "Must be 20 characters or less")
        .required("Required"),
    }),
    onSubmit: (values, { resetForm}) => {
      emailjs
        .send(
          "service_t3an0lc",
          "template_3n7qjn6",
          values,
          "kYIrFB7Hp76MR7S1G"
        )
        .then(() => {
          //   console.log('email sent',values);
          
          navigate("/home");
          resetForm({ values: "" });
        });
    },
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Name"
                name="name"
                placeholder="Name"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name ? (
                <span style={{ color: "red" }}>{formik.errors.name}</span>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                placeholder="Email"
                name="email"
                variant="outlined"
                fullWidth
                type={"email"}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <span style={{ color: "red" }}>{formik.errors.email}</span>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mobileno"
                label="Mobile No."
                placeholder="Mobile No."
                name="mobileno"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                // type={"number"}
                fullWidth
                value={formik.values.mobileno}
                onChange={formik.handleChange}
              />
              {formik.touched.mobileno && formik.errors.mobileno ? (
                <span style={{ color: "red" }}>{formik.errors.mobileno}</span>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                label="Message"
                name="message"
                placeholder="Message"
                variant="outlined"
                multiline
                fullWidth
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.touched.message && formik.errors.message ? (
                <span style={{ color: "red" }}>{formik.errors.message}</span>
              ) : null}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  borderRadius: "23px",
                  width: "136px",
                  height: "46px",
                }}
                // onClick={handleClick}
              >
                Submit
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  This is a success message!
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
