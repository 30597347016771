import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC5Image } from "../image";

const heroTextData = [
  {
    title: "Danang - Hoi An - Hue –phong Nha",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "DaNang",
    endCity: "Hue",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Danang - Hoi An (L/-)",
    details:
      "Meet on arrival at Danang International airport and visit Cham Museum, Marble Mountains, and Da Nang Beach before proceeding to Hoi An Ancient Town. Have lunch at a local restaurant - Discover the historic town was a prosperous seaport city during the 16th to 18th centuries. Its sizeable community of foreign merchants created a unique architectural style from Vietnamese, Chinese, and Japanese influences. You will have a walking tour of the Chinese Assembly Hall & Temples, ancient houses & Japanese Covered Bridge. Dinner and overnight in Hoi An. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Hoi An - Bay Mau Coconut forest (L/-)",
    details:
      "Have breakfast at the hotel. Visit Bay Mau Coconut Forest on basket boats - a typical vehicle of the fishing village of Van Lang village. You can witness firsthand the local people casting their nets on the river to catch fish and you can also experience your own casting skills. After that, watch the remarkable basket-shaking performance by Van Lang artist. You can freely visit the space of the eco-tourism area, then gather to participate in fun folk games with prizes: bridge walking, pot breaking, and bag jumping. Have lunch with typical village flavor. Leave Bay Mau Coconut Forest. 2h30 pm. Departure to Hue. Have dinner at a local restaurant in Hue. Overnight in Hue ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Hue- Phong Nha Cave (L/-) ",
    details:
      "Have breakfast at the hotel. In the morning, drive to Phong Nha cave, passing through Hien Luong. Have luch at a local restaurant in Phong Nha. Phong Nha - one of the most beautiful cave of VietNam with magnificent wonders. Then come back to Hue. Have dinner at Hue local restaurant. Overnight at Hue. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Hue city tour (L/-)",
    details:
      "Breakfast at the hotel. Visit Thien Mu Pagoda and Minh Mang tomb. Have lunch at local restaurant followed by a visit to Tu Duc & Khai Dinh tombs. Free Overnight in Hue.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Hue - Departure",
    details:
      "Have breakfast at the hotel. Leisure time until transfer to Phú Bài Airport for departure.",
  },
];

const inclusionData = [
  "All transfers and excursion per tour programs",
  "Good English speaking guide",
  "Sightseeing fees & boat rides as mentioned in itinerary",
  "Meals as specified in program",
];

const exclusionsData = [
  "International airfares",
  "Personal expenese, drinks, ... and the others not mentioned above.",
];

const VC5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC5;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
