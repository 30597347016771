export const KT1Image = "/assets/img/domesticTour/karnataka/KT1.png";
export const KT2Image = "/assets/img/domesticTour/karnataka/KT2.png";
export const KT3Image = "/assets/img/domesticTour/karnataka/KT3.png";
export const KT4Image = "/assets/img/domesticTour/karnataka/KT4.png";
export const KT5Image = "/assets/img/domesticTour/karnataka/KT5.png";
export const KT6Image = "/assets/img/domesticTour/karnataka/KT6.png";
export const KT7Image = "/assets/img/domesticTour/karnataka/KT7.png";
export const KT8Image = "/assets/img/domesticTour/karnataka/KT8.png";
export const KT9Image = "/assets/img/domesticTour/karnataka/KT9.png";
export const KT10Image = "/assets/img/domesticTour/karnataka/KT10.png";
export const KT11Image = "/assets/img/domesticTour/karnataka/KT11.png";
export const KT12Image = "/assets/img/domesticTour/karnataka/KT12.png";
export const KT13Image = "/assets/img/domesticTour/karnataka/KT13.png";
export const KT14Image = "/assets/img/domesticTour/karnataka/KT14.png";
export const KT15Image = "/assets/img/domesticTour/karnataka/KT15.png";