import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { RH2Image } from "../image";

const heroTextData = [
  {
    title: "Royal Lakend",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Udaipur",
    endCity: "Mount Abu",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Udaipur Arrival-Local ",
    details: "Welcome and Arrive Udaipur Airport / Railway Station and meet our local representative who will transfer to the hotel, proceed for Udaipur Sightseeing 'the city of lakes' is admired for its fairy-tale palaces, lakes, temples, and gardens among many more. Visit Sajjangarh (Monsoon Palace), Shilpgram and Bhartiya Lok Kala Mandal. Overnight stay at Udaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Udaipur",
    details: "After Breakfast, Proceed to morning city sightseeing tour. Built in 1559 A.D. by Maharaja Udai Singh, the city has been described as the most romantic spot on the continent of India by Col. James Todd. The places of interest include City Palace which has several carved balconies, arches and decorated pillars, Zenana Mahal, Sahelion-ki-Bari (queen's resort for the friends), Enjoy Boat Ride at Lake Pichola (Own) Visit to Fateh Prakash, Moti Magari, Durbar Hall and Shambhu Niwas. Also visit Jagdish temple. Overnight stay at Udaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Udaipur - Mount Abu ",
    details: "After Breakfast, Check Out from Hotel and Proceed to Mount Abu. Free for Leisure. Overnight Stay at Mount Abu.",
  },
  {
    id: "Day 4",
    header: "Mount Abu",
    details: "After Breakfast, proceed to visit Dilwara Temple, Guru shikar, Anchalgarh, Nakki Lake, Sunset Point, Honeymoon Point. Overnight Stay at Mount Abu.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Mount Abu - Udaipur Drop",
    details: "After Breakfast, check out from Hotel and Proceed to Drop at Abu Road",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
  ];
  
  const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
  ]

const RH2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={RH2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default RH2;


// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
