import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { DU3Image } from "../image";

const heroTextData = [
  {
    title: "Luxurious Dubai",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Dubai",
    endCity: "Dubai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival and Dhow Cruise",
    details: "You will be arriving at the airport and our representative will be there to receive you and will transfer you to the hotel. After arriving, you will check-in and enjoy the rest of the day at leisure. Spend a lovely evening with our Dhow cruise dinner tour in a traditional Dhow that has AC lower deck and an open & airy upper deck, and enjoy the magnificent view of the city, entertainment shows and delicious buffet dinner served on-board while cruising. Special Note: the pickup time can be modified as per the trip schedule by 30 to 60 minutes, depending on your location and traffic conditions. Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Half day city tour and visit Burj Khalifa",
    details: "After a filling breakfast at the hotel, get ready to explore Dubai. Drive through the skyscrapers with the detailed information provided by our knowledgable tour guides about all the significant places that include extravagant shopping malls, world-class architecture, cultural heritage, and visiting the tallest tower in the world- Burj Khalifa. Your city tour will end at the Dubai Frame Photo Stop & Dubai Mall, where the entrance for Burj Khalifa is located. Overnight stay at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Desert Safari and dinner",
    details: "After breakfast, your morning will be at leisure, where you can explore the city on your own. Enjoy the Arabian sands on our desert safari tour that provides you with the ultimate adventurous experience. You will get a chance to indulge in several activities like dune bashing in a 4x4 vehicle, camel riding, belly dancing show, Tanura dance show, a delicious barbeque dinner with veg and non-veg options. Overnight at the hotel.",
  },
  {
    id: "Day 4",
    header: "Abu Dhabi City Tour and Ferrari World",
    details: "You will also be taken for a full day city tour of the capital emirate, Abu Dhabi after your breakfast. You will be able to witness various attractions like Sheikh Zayed Grand Mosque with Entry, Au Dhabi Palace, corniche, and a lot more of this city filled with rich ancient heritage and modern life. After the city tour, we will be heading to the epic Ferrari World to experience the adrenaline rush of several rides and entertainment at this place. You will be back at the hotel in Dubai for the overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Aquaventure Waterpark and Lost Chambers",
    details: "Today will be filled with a whole lot of adventure and fun. You will be taken to Aquaventure Waterpark Zoo with Ticket in Atlantis the Palm to enjoy many rides and water activities. Later, you will be taken to the Lost Chambers where you can explore several species of the vibrant marine world. Entry and transfers to miracle garden and global village three way transfers. Entry and transfers to aya universe Overnight stay will be at the hotel along with dinner.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Dubai Garden Glow",
    details: "Morning at leisure. You will be picked at 5pm for Dubai Garden Glow & Ice Park tour. THE GLOW PARK : A magical walk in the Park, beyond Imagination! The Glow Park has been inspired by wonders of the world (Season 1) and a tribute to nature (Season 2) - has created several experiences for residents and tourists alike. Within the last two seasons, it has earned the title of being a popular destination park that laid emphasis and convey a strong message on ‘energy saving, wildlife preservation and reduce wastage’. Season 3 unveils the ‘Underwater’ theme, where the wonderful installations, light play and music take you on a magical underwater journey. ICE PARK : “Experience the fun and excitement with the Largest Ice Sculpture park in the region – new attraction of this season” Within the warm weather and tropical desert climate, the Ice Park takes one into an extremely contrasting and outrageous ambiance. The chilling Ice Park has been skillfully created using more than 5000 tons of ice, where in approx. 150 talnted artists from all around the world have dedicated worked upon the assembly of the icy sculptures – “Mini Dubai”, “Ice Age”, “Children’s Play Area” and lots more. One cannot be convinced unless having seen and experienced it for real at a temperature that is set at -7 o C to -8 o C. Overnight stay at hotel ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure",
    details: "After breakfast, check out of the hotel and conclude your Dubai 6 night 7-day package on the last day. You will be provided transfer to the Dubai International Airport for your flight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


// const inclusionData = [
//   "03 nights’ accommodation at respective hotel in Kathmandu with breakfast only ",
//   "Airport / Hotel / Airport transfers by an a/c vehicle exclusively",
//   "Sightseeing as per the itinerary by an a/c vehicle exclusively (Guide and entrance fee not included payable directly)",
//   "Applicable taxes",
// ];

// const exclusionsData = [
//   "Services other than specified.",
//   "Entrance fee at the monumental sites during the sightseeing",
//   "Guide during the sightseeing",
//   "Anything not mentioned in inclusions",
//   "Any expenses caused by reasons beyond our control such as flight delays, rescheduling or cancellations, any accidents, medical evacuations, riots, strikes, etc. ",
//   "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above. ",
//   "Any other tax or service charge that could be enforced during this period will be extra & need to borne by you.",
// ];

const DU5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={DU3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default DU5;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
