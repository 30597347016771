import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EA1Image } from "../image";

const heroTextData = [
  {
    title: "Zanzibar",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Zanzibar",
    endCity: "Zanzibar",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Zanzibar",
    details:
      "On arrival at Zanzibar you will be met and transferred to the beach hotel. Rest of the day is spent at leisure to enjoy beach activities offered by the hotel / resort.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kilindi Zanzibar",
    details:
      "Natural Simplicity - A Lover's Paradise 'If you love nature, you will love Kilindi'. Kilindi Zanzibar, comprising of a series of white-domed Pavilion guest rooms all set in 35 acres of lush tropical gardens, is a rarity in the world of luxury boutique hotels. Originally designed for Benny Andersson - one of the members of the 1970's pop group ABBA - Kilindi is a true blend of the very best of diverse cultures to be found in Tanzania and on its adjacent island of Zanzibar. Kilindi achieves the perfect marriage between Scandinavian minimalism and the dramatic architectural overtones of Middle Eastern heritage, whilst encouraging an open, 'back-to-nature' ambiance throughout. In each of the Pavilion Suites, white wooden-slatted shutters embrace wide-arched glassless windows each affording spectacular views over the Indian Ocean. This same openness allows the gentle ocean breeze to waft throughout each of the rooms and carry with it an exceptional soundtrack of Zanzibar's colourful fauna. With sights and sounds gifted by such creatures as the indigenous red colobus monkeys and the ever-present and ever-busy yellow weaver birds, any stay at Kilindi will be a delight for the senses. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Baraza Resort and SPA Zanzibar",
    details:
      "With its Middle Eastern arches, carved white-plaster walls, ornate golden mirrors and sparkling brass lamps, Baraza resembles a modern-day Omani palace. The new 30-suite hotel, on Zanzibar’s south-west coast, is set in 70 hectares planted with multicolored bougainvillea, frangipani and jasmine, overlooking a dazzlingly white, powder-fine beach. There's no shortage of space: each suite (at least 148 square meters) has a private plunge pool and veranda, living room with double daybed curtained with gold silk, four-poster swathed in mosquito net, and a spacious bathroom with a shower and pond-sized bath. Although it's private and romantic enough for honeymoons, it's family-friendly, too, with a big swimming pool, a kids' club packed with games and toys, a water sports center and bicycles for exploring Bwejuu beach. Meals are served indoors and (preferably) out; the food at this all-inclusive resort is plentiful and varied, if not particularly inspiring, and often accompanied by performances from local Swahili musicians. The highlights are the sea - sparkling in almost unreal shades of brilliant blue - and the spa, which could have been built for a princess. The regal interior, with a secluded pool at its center, glitters with mirrors, lamps, mosaics and silk-strewn daybeds and it is staffed by skilled therapists from Bali, Thailand and India. Dinner and overnight at Kilindi / Baraza Resort & SPA (FB)",
  },
  {
    id: "Day 4",
    header: "Depart",
    details:
      "After breakfast, transfer to the airport for your departure flight back home.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation as indicated in the itinerary",
  "Meals as described by B (Buffet Breakfast), L (Lunch), D (Dinner)",
  "National parks and reserve entrance fees",
  "Airport transfers as indicated",
  "Transport in a 7 seater safari minibus with pop up hatch roof for easy photography",
  "Game drives as per the itinerary",
  "Services of an experienced safari driver guide",
  "Complimentary mineral water {one litre per person per day}",
];

const exclusionsData = [
  "Personal expenses such traveling insurance, excess baggage fee, communication charges that include, emails, faxes and phone calls beverages including luxury champagne, luxury spirits or meals not indicated in the itinerary",
  "Gratuity for Porterage / waiters at hotels, camps and airports",
  "Gratuity for your professional driver guide",
  "Cost of obtaining passport and visas",
  "International Airfare",
];

const EA1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EA1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EA1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
