import React from "react";
import {
  CardMedia,
  Grid,
  Typography,
  Card,
  Box,
  Container,
} from "@mui/material";

import PropTypes from "prop-types";

const TourHeroSection = ({ mainTitle, heroImg ,subTitle}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Card
        sx={{ maxWidth: 1920, borderRadius: "8px 8px 8px 8px", height: "484" }}
      >
        <CardMedia
          component="image"
          image={heroImg}
          height="484"
          sx={{ objectFit: "cover" }}
        >
          <Container>
            <Box
              sx={{
                height: { xs: "25vh", sm: "35vh", md: "55vh" },
                alignItems: "center",
                display: "flex",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h3"
                    sx={{
                      textAlign: "center",
                      color: "text.light",
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    {subTitle}
                  </Typography>
                  <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h1"
                    sx={{
                      textAlign: "center",
                      color: "text.light",
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    {mainTitle}
                  </Typography>
                </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </CardMedia>
      </Card>
    </Box>
  );
};

TourHeroSection.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  tourCardContent: PropTypes.object,
};

export default TourHeroSection;
