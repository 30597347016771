import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const HEMACHAL_TOUR = "/assets/img/domesticTour/Himachal.png";

const HILLS_OF_QUEENL_TOUR =
  "/assets/img/domesticTour/himachal/hills_of_queen.png";

const WEEKEND_GETAWAY_TO_MANALIL_TOUR =
  "/assets/img/domesticTour/himachal/Weekend_getaway_to_Manali.png";

const FEEL_HAPPY_AT_HIMACHALL_TOUR =
  "/assets/img/domesticTour/himachal/Feel_Happy_at_Himachal.png";

const HAPPY_PLACE_HIMACHAL_TOUR =
  "/assets/img/domesticTour/himachal/Happy_place_Himachal.png";

const HEAVENLY_HIMACHALL_TOUR =
  "/assets/img/domesticTour/himachal/Heavenly_Himachal.png";

const SPLENDID_HIMACHAL_TOUR =
  "/assets/img/domesticTour/himachal/Splendid_Himachal.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Nights / 03 Days",
    title: "Hills Of Queen",
    image: HILLS_OF_QUEENL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HillsOfQueen",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Weekend getaway to Manali",
    image: WEEKEND_GETAWAY_TO_MANALIL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/WeekendgetawaytoManali",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Feel Happy at Himachal",
    image: FEEL_HAPPY_AT_HIMACHALL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/FeelHappyatHimachal",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Happy place Himachal",
    image: HAPPY_PLACE_HIMACHAL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HappyplaceHimachal",
  },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "Heavenly Himachal",
    image: HEAVENLY_HIMACHALL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HeavenlyHimachal",
  },
  {
    id: 6,
    fromToDays: "09 Nights / 10 Days",
    title: "Splendid Himachal",
    image: SPLENDID_HIMACHAL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/SplendidHimachal",
  },
];

const rulesData = [
  "Pre-Paid cellular connections do not work in Jammu & Kashmir region. Only Jio, Airtel & Bsnl Post-Paid will work here.",
  "BAISERAN is the best place to visit in Pahalgam though it is not a snow-covered area but the place has its own natural beauty. Either you go by walk or by Horse Riding.",
  "Sonamarg: After Sonamarg, there is a Point called Zero point away from Sonamarg, Thajiwas Glacier and activities like Fish Point (Under Water Balloon Ride). There is only option Union Cab, Charges for Union Cab: 3500/-to 5000/- (Negotiable)",
  "Gondola, you will find Snow and other activities related to snow. Tickets Are fixed as per Govt. fare i.e., Rs. 780 for 1st phase & 920 for 2nd phase",
  "For Kashmiri Wazwan Food: - Aadhoos at Lal Chowk Srinagar is the best place and Mutton Kebab at Gee Enn Bakers - Residency Road.",
];

const HimachalPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Himachal Pradesh Tour Packages"}
            subTitle={"Amazing Holidays to Himachal Pradesh"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={HEMACHAL_TOUR}
            title={"Popular Himachal Pradesh Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Himachal"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default HimachalPackages;
