import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { UB1Image } from "../image";

const heroTextData = [
  {
    title: "Holiday in Uzbekistan",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Tashkent",
    endCity: "Tashkent ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Tashkent",
    details:
      "Arrival to Tashkent; the guests will be met by English-speaking guide at the airport. Transfer to hotel and check-in. City tour in Tashkent: “Independence” Square; Subway of Tashkent (the first subway in Central Asia and one of the most beautiful in the world with attractive decorations of each station); Lunch at local Restaurant Amir Temur Square; Palace of International Forums; Tashkent Chiming Clocks; “Broadway Street” with artist and local souvenirs; Opera and Ballet Theatre named after Alisher Navoi (outside only); Dinner at local restaurant Overnight at hotel. Meals: Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Tashkent - Mountains",
    details:
      "Breakfast at hotel. Drive to Chimgan Mountains, which is located in 80 km from Tashkent on the altitude of 2000 m above sea level. Upon arrival the guests walk along Chimgan, enjoy the mesmerizing view of Uzbekistan nature. Being in mountains guests ride on Chair Lift (the length 802 m, the height 1975 m) going up to the top of the mountain (30 minutes), enjoy free time at the mountain peak taking photos and videos for the memory and then again back onto the Chair Lift for coming down the mountains (30 minutes). Lunch at local restaurant Transfer to outstanding “Charwak” Lake (which is formed by joining of 4 biggest rivers. This beautiful lake supplies Tashkent city and its region with water all year round. Some free time at “Charvak” Lake at own activities. Drive back to Tashkent. Dinner at local restaurant Overnight at hotel. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Tashkent - Samarkand - Tashkent",
    details:
      "Breakfast at hotel. Transfer to the railway station. Departure to Samarkand by Bullet Train (departure is at 7:30 and arrival is at 09:40). City tour in Samarkand: Gur Emir Mausoleum – a burial vault of Timurids dynasty (XIV – XV c.c.). Registan square with three madrasahs: Ulugbek Madrasah (XV c.), Sherdor Madrasah (XVII c.) and Tilla Kori Madrasah (XV – XVII c.c.); Lunch at local restaurant Ulughbek Observatory (XV c.c.) Shakhi-Zinda necropolis (X-XV c.c.) Bibi Khanim Mosque (XV c.) – the biggest medieval building in the city. Visiting oriental bazaar “Siab” where guests can do shopping and enjoy the ancient colorful market; Transfer to the railway station. Departure to Tashkent by Bullet Train (departure is at 17:30 and arrival is at 19:40). Dinner at local restaurant Later transfer to hotel. Overnight at hotel. Meals: Breakfast, Lunch and Dinner",
  },
  {
    id: "Day 4",
    header: "Tashkent - Delhi/Mumbai",
    details:
      "Breakfast at hotel and check-out. Continue the city tour in Tashkent: “Memorial of Repression’s Victims” with the traditional ornaments; Tashkent TV Tower (outside only); “Monument of Courage” (dedicated to the 1966 earthquake in Tashkent); Lunch at local restaurant “Minor Mosque“ called as “Oq masjid” (White Mosque) – built by white marble; Khast Imam Complex (15th c), which includes: Barak Khan Madrasah, Tillya-Sheikh Mosque, Imam Kaffal Shashi Mausoleum, Muyi Muborak Madrasah, Khast Imam library which holds one of the oldest Koran (8th c.) belonging to third caliph, Uthman ibn Affan; Kukeldash Madrasah; Transfer to Chorsu bazaar (one of the ancient markets where guests can buy the best quality of dry fruits and souvenirs); Transfer to the Tashkent International Airport. Departure to Delhi/Mumbai according to the schedule. End of the tour. Meals: Breakfast, Lunch",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation in hotel",
  "Full board meals (3 Breakfast, 4 Lunches and 3 Dinners)",
  "Services for private Comfortable A/C vehicle with driver per program",
  "Escort English/Hindi – speaking guides for all sightseeing and excursions from arrival till departure",
  "Panoramic tours to the sights listed",
  "All airport and railway stations transfers;",
  "Full day trip to Chimgan Mountains and Charvak Lake",
  "Chair-lift fees in Chimgan Mountains.",
  "Full day trip to Samarkand by Afrosiyob bullet train in Economy class* (subject to seats availability): Tashkent – Samarkand – Tashkent;",
  "2 bottles of 0.5 L mineral water per day / per person.",
  "Applicable Government tax.",
];

const exclusionsData = [
  "International air tickets ",
  "Visa fees.",
  "Early check-in to hotel and late check-out charges",
  "Charges in hotels for additional services & penalties.",
  "Entrance fees to sites listed (which will be paid at the sight separately on wish)",
  "Tips and gratuities",
  "Video and photo charges",
  "Any personal expenses",
  "Any services not included in the above itinerary.",
  "In case of non-availability of seats in Afrosiyob bullet train on requested dates the trip to Samarkand will be provided by land transport.",
];

const UB1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={UB1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default UB1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
