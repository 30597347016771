import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { TH3Image } from "../image";

const heroTextData = [
  {
    title: "Bangkok Pattaya Phuket Tour with Phi Phi Island",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bangkok",
    endCity: "Phi Phi Island",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival in Bangkok | Welcome to the City Of Angels",
    details: "Upon your arrival at Bangkok airport, you will be greeted by our representative and will be transferred to your pre-booked hotel. After reaching the hotel, proceed with the check-in formalities and spend the rest of the day leisurely. One can stroll around the markets, explore local cafes and witness the extraordinary beauty of the city of angels. One can also capture the beautiful sight of the Grand Palace, adorned with beautiful architecture and intricate details and get to know why even after 150 years, the monument is the spiritual heart of the Thai kingdom. You can also visit Wat Phra Kaew or the Temple of Emerald Buddha, located within the grounds of Grand Palace and enshrines the Emerald Buddha image carefully carved from the single block of jade. Head back to the hotel after exploring this beautiful city. Overnight stay at the hotel in Bangkok. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "A tour to Safari World | Get a thrilling sensation as you witness the wildlife in their natural habitat",
    details: "Wake up with a delicious breakfast and get ready for an adventurous day. The representative will pick you up from the hotel at around 9:00 AM on private transport. Head towards Thailand greatest open zoo and leisure park that offers a great variety of entertainment for everyone. One can indulge in many activities, whether it is an existing safari drive through the scenic African jungle settings and intimate encounter with the friendly dolphins or an exhilarating fast-paced action stunt spectacular. Witness the unbounded beauty of marine animals such as seals, sharks, etc. in the Marine Park. Don't miss out on 7 spectacular performances throughout the day such as elephant shows, dolphin shows, and more! Enjoy watching gunfights, bullwhips and explosives at the Hollywood Cowboy Stunt Show. After an adventurous day at Safari World, head back to the hotel for an overnight stay. Overnight stay in Bangkok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Transfer from Bangkok to Pattaya | A city that never sleeps",
    details: "Wake up with a delicious breakfast and get transferred to Pattaya which is approximately 147 km from Bangkok. On your way to Pattaya, witness the beautiful countryside covered in lush greenery. Rech Pattaya and proceed with the check-in formalities. Spend the rest of the day at leisure. You can stroll around the famous Pattaya street and explore the various nightclubs, markets, local cafes and so much more. Head back to the hotel for an overnight stay. Overnight stay in Pattaya. ",
  },
  {
    id: "Day 4",
    header: "Coral Island Tour | Indulge in adventurous water sports to feel the thrill within",
    details: "Enjoy a wholesome breakfast and get ready for a fascinating Coral Island Tour in a shared speedboat. The representative will pick you up from the hotel in a private transfer and take you to the port where you'll board the speedboat. Hop on a speed boat which will take around 45 minutes to reach Coral Island. One can indulge in many activities as the Island offers a variety of fun water activities to do and find something you've always wanted to try. Visit the most famous beach Tawaen Beach, where there is a small harbour lined with small tourist shops. Choose from snorkelling, sea walker diving, parasailing, jet-skiing, and many more such activities. Stroll around the white sandy beaches and watch the waves rushing over the boulders. After an adventurous day, head back to the hotel for an overnight stay. Overnight stay in Pattaya. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Fly from Pattaya to Phuket | Welcome to the Pearl of Andaman",
    details: "Upon your arrival at Phuket airport, you will be greeted by our representative and will be transferred to your pre-booked hotel. On your way to the hotel, witness the amazing scenery this island has to offer. Reach the hotel and proceed with the check-in formalities and rest for a while. You can spend the rest of the day at leisure and enjoy exploring the city on your own. One can explore the thrilling nightlife that Phuket is famous for. You can also stroll around Bangla Road, with an incredible variety of things to see and do like international standard nightclubs, street performances, games, live music and so much more. You can also hop onto some beachside cafes to enjoy the views of the waves gushing over while having delicious cuisines. Head back to the hotel in Phuket for an overnight stay. Overnight stay in Phuket.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Excursion to Phi Phi Island | Marvel at the Cliffs and Caves, Sandy Beaches and Turquoise Waters",
    details: "Enjoy a delicious breakfast and embark on a trip to Phi Phi Island by shared speed boat. Get picked up from your hotel in a private transfer at around 7:30 AM, meet up with the representative and get excited about the upcoming journey. Transfer to the Phuket Boat Lagoon Marina and prepare yourself to go to the first destination. Head over to Maya Bay which is very famous as it was the filming location for the movie. The Beach starring Leonardo DiCaprio. Next, head towards Loh Samah Bay located at the back of Maya Bay where you can take beautiful photos amidst the limestone cliffs and azure waters. Next, visit Viking Cave which is one of the most notable sites on Koh Phi Phi Leh. Arrive at Monkey Beach surrounded by steep rock formations and make friends with friendly monkeys on the island. Next, arrive at Koh Phi Phi Leh which is the main island of Phi Phi Island and enjoy lunch at the local restaurant while enjoying the breathtaking view. Head back to the hotel in Phuket. Overnight stay in Phuket. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure | Take a bag full of memories to cherish for eternity",
    details: "Wake up with a delicious breakfast and get transferred to Phuket Airport. Take a plethora of happy memories with you. ",
  },
];

const inclusionData = [
  "Stay in a hygienic 3-star hotel on a double sharing basis",
  "Meals: Breakfast, Lunch/Dinner",
  "Transfers Airport pick up and drop as per your flight timings.",
  "Comfortable transfers for sightseeing as per the itinerary",
  "Guide Experienced driver cum guide",
  "Sightseeing as per the itinerary",
];

// const exclusionsData = [
//   "Alcoholic beverages except described.",
//   "Optional activities.",
//   "Flights",
//   "Visa",
//   "Meals except described.",
//   "Travel insurance",
// ];

const TH3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TH3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TH3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
