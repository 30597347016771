import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const MAJESTIC_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Majestic.png";

const heroTextData = [
  {
    title: "Majestic Kashmir",
    duration: "04 Nights / 05 days",
    typoOfTrip: "Road Trip",
    startCity: "Srinagar",
    endCity: "Srinagar",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Srinagar | Srinagar Local Sightseeing",
    details:
      "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Srinagar – Gulmarg – Srinagar",
    details:
      "In the morning drive to Gulmarg, the meadow of flowers. The route from Srinagar to Gulmarg is fascinating. You will be mesmerized by its beauty. Gulmarg is famous all over the world for Skiing and Snowboarding. During the British Raj, Gulmarg hosted the Britishers and Europeans and as such they created a European hill resort and named it Gulmarg. In Gulmarg you would be able to find European surroundings and 18-hole Golf Course and enjoy Gondola Cable Car Ride world’s highest and the Asia’s longest cable car (at your own cost). Enjoy photography, horse riding and shopping at this beautiful snow point and enjoy gondola ride to Kongdoori & Appahrwhat. In the evening return to Srinagar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Srinagar – Pahalgam – Srinagar",
    details:
      "Early in the morning leave for Pahalgam the 'valley of shepherds' which is situated at the confluence of the streams flowing from river Lidder and Sheeshnag Lake. En route visit the Awantipura ruins, an 1100 year old temple dedicated to Lord Vishnu and built by King Awantivarman. On arrival into Pahalgam enjoy the surrounding beauty or walk to Baisaran, which is about 2 km from Pahalgam. A small village set in pine and fir forest is pleasing and offers good view and u will proceed for Aru valley, and chandanwari by car (at your own cost). Aru is small village, 11 kms upstream the Lidder River, is a beautiful locality. The route is picturesque with ample campsites. This village is the starting point for treks to the Kolahoi glacier. The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly wooded forests of pine .Hajan and Beetab valley on the way to chandanwari. In the evening return to Srinagar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Srinagar – Sonmarg – Srinagar",
    details:
      "Today you embark on a full day excursion to the picturesque 'meadow of gold’ Sonamarg. Sonamarg is last stop of Kashmir district. From here starts the territory of ladhakh. Situated at an altitude of 2743m above sea level. The fast flowing Sindh River makes it popular for trout and masher fishing in summer. You can opt for a pony ride to one of the glaciers for some fun in snow and ice (by your own payment). Check in to Houseboat and night halt at hotel in Houseboat at Dal Lake.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure From Srinagar",
    details:
      "Return Home with Happy Memories After breakfast you will be transferred to Srinagar Airport for your onwards journey with Everlasting Memories.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation in rooms as given at hotels in Srinagar and Pahalgam on Double sharing basis.",
  "1 Night stay at Houseboat",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Shikara Ride for 1 Hrs in Dal Lake.",
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera",
  "All Toll Taxes, Parking Charges & Driver Allowances included",
  "Assistance at all arrival and departure point.",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
];

const exclusionsData = [
  "Note for Gulmarg: Cost for Gondola Cable Car, Gulmarg: INR 800/- Per Pax for Level 1 & INR 1000/- Per Pax for Level 2 (Total INR 1800/- for both) you can book on link in advance to avoid last minute issue. https://www.jammukashmircablecar.com/",
  "Any Airfare, Train fare, Overland Journey which is not mentioned in the Itinerary.",
  "Any other item not specified in cost includes.",
  "Transfers from Tang Marg to Gulmarg to Tang Marg by chained vehicle in case of heavy snowfall.",
  "Aru Chandan Wari and Betaab valley Trip at Pahalgam. (It’s done by Union Taxi at Pahalgam and is on direct payment basis.)",
  "Thajwas Glacier Trip at Sonmarg in Summer Season. (It’s done by pony ride and is on direct payment basis.)",
  "Transfers from Gagangir to Sonmarg to Gagangir because our vehicle can’t go beyond Gagangir due to heavy snow in Winter Season. (It’s done by Union Taxi at Gagangir and is on direct payment basis.)",
  "Garden Entrance fees and guide charges, where ever applicable.",
  "Item of personal nature viz, tips, laundry, room service, telephone, alcoholic or non-alcoholic drinks",
  "Beverages, room heaters, boat rides, pony / horse rides, safaris, rafting, skiing, skating, cable car and Union Taxi.",
];

const MajesticKashmir = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={MAJESTIC_KASHMIR_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default MajesticKashmir;
