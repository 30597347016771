import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EA2Image } from "../image";

const heroTextData = [
  {
    title: "Rwanda Suggested Itinerary Gorilla Experience",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Kigali",
    endCity: "Kigali",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Kigali/Rwanda",
    details:
      "Upon arrival at Kigali International Airport, you will be met by our representative and transferred to Kigali Serena Hotel for overnight. – Lunch & dinner on own account. Kigali Serena Hotel (BB)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kigali - Volcanoes National Park",
    details:
      "After breakfast, OPTIONAL Join your driver/guide on a City Tour of Kigali, includes the Memorial Centre and Crafts centers. The history of Rwanda dates back centuries, Kigali has been a part of it for a hundred years. Founded in 1907 as a German settlement, Kigali did not become the capital until Rwanda’s independence in 1962 when it replaced the colonial seat of power in Butare. Since then, the city has seen major economic development resulting in what it is today; a vibrant African city of 1 million with wide boulevards, an international airport, world-class hotels and restaurants, crafts & curio shops as well as a lively, cosmopolitan population. Visit the Kandt Museum, tour old Kigali and the Craft centers, visit the genocide memorial site where you learn about Rwanda’s history pre-colonial to 1994 genocide as well as other genocides in the world. Tour more and see the new developments; the recovery has been incredible, a true testament to humankind’s resilience. Followed by lunch on own account, you will proceed to drive to Ruhengeri at the foothills of the Virungas. You will arrive at Sabyinyo Silverback Lodge in the afternoon in time for dinner and overnight. Sabyinyo Silverback Lodge (FB)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Volcanoes National Park",
    details:
      "After an early breakfast, drive to Volcanoes National Park HQs to be allocated a gorilla tracking group. 0700 hrs: Briefing by your trackers then drive to the trail head for the start of an amazing adventure. Our exhilarating trek, in the footsteps of the rare and critically endangered Mountain Gorillas, the largest living primate on earth, starts in the cultivated foothills of the Virungas with stirring views in all directions. Upon entering the national park, her paths littered with fresh spoor of the mountains’ elusive buffalo and elephant, trekkers will be immersed in the mysterious intimacy of the rainforest, alive with colorful bird calls, butterflies, etc. Through gaps in the canopy, the magnificent Virunga peaks, among the highest in Africa yet easily accessible, are glimpsed, beckoning an ascent. Weaving through overhanging vines, moss-covered hagenia and giant lobelias that thrive in the tropical climate, our experienced trackers, they spent their entire lives in or close to this forest, soon bring us up close with the family. Upon sighting the gorillas, you will stay with them for a magical hour as they go about their daily life, feeding, playing, resting; raising their young. Coming face to face with a giant silverback must truly be one of the greatest wildlife experiences on earth! This afternoon will be spent at leisure enjoying the view of the Virunga Mountains. -Dinner served at the lodge. Sabyinyo Silverback Lodge (FB)",
  },
  {
    id: "Day 4",
    header: "Departure",
    details:
      "After breakfast, drive back to Kigali and be transferred to the airport for your departure flight. (B) - End of Our Services",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation as indicated in the itinerary",
  "Meals as described by B (Buffet Breakfast), L (Lunch), D (Dinner)",
  "National parks and reserve entrance fees",
  "Airport transfers as indicated",
  "Transport in a 7 seater safari minibus with pop up hatch roof for easy photography",
  "Game drives as per the itinerary",
  "Services of an experienced safari driver guide",
  "Complimentary mineral water {one litre per person per day}",
];

const exclusionsData = [
  "Personal expenses such traveling insurance, excess baggage fee, communication charges that include, emails, faxes and phone calls beverages including luxury champagne, luxury spirits or meals not indicated in the itinerary",
  "Gratuity for Porterage / waiters at hotels, camps and airports",
  "Gratuity for your professional driver guide",
  "Cost of obtaining passport and visas",
  "International Airfare",
];

const EA2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EA2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EA2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
