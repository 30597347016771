import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;


export default function SimpleMap(){
  const renderMarkers =(map, maps)=> {
    let marker = new maps.Marker({
      position: { lat: 18.72076610656556, lng: 73.6829513306817 },
      map,
      title: 'Radha Tours'
    });
  }

  const defaultProps = {

    center: {
      lat:  18.72076610656556,
      lng: 73.6829513306817
    },
    zoom: 15
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '60vh', width: '100%',borderRadius:"24px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDu2v0V_38Y3yvHIyTf3W8Mnch8aeX1HYk"}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
}