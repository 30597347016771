import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  MA1Image,
  MA2Image,
  MA3Image,
  MA4Image,
  MA5Image,
  MA6Image,
  MA7Image,
  MA8Image,
  MA9Image,
  MA10Image,
  MA11Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const MALDIVES_TOUR = "/assets/img/worldTour/wt19.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Explore Maldives",
    image: MA1Image,
    description: "",
    path: "/ma1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhigufaru  3 Nights Resert Stay",
    image: MA2Image,
    description: "",
    path: "/ma2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Ari Explore",
    image: MA3Image,
    description: "",
    path: "/ma3",
  },
  {
    id: 4,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhigufaru  3 Nights Resert Stay",
    image: MA4Image,
    description: "",
    path: "/ma4",
  },
  {
    id: 5,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhigufaru  3 Nights Resert Stay",
    image: MA5Image,
    description: "",
    path: "/ma5",
  },
  {
    id: 6,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhiggiri Island 3 Nights Stay",
    image: MA6Image,
    description: "",
    path: "/ma6",
  },
  {
    id: 7,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhiggiri Island 3 Nights Stay",
    image: MA7Image,
    description: "",
    path: "/ma7",
  },
  {
    id: 8,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhiggiri Island 3 Nights Stay",
    image: MA8Image,
    description: "",
    path: "/ma8",
  },
  {
    id: 9,
    fromToDays: "03 Nights / 04 Days",
    title: "Hulhumale 3 Nights Stay",
    image: MA9Image,
    description: "",
    path: "/ma9",
  },
  {
    id: 10,
    fromToDays: "03 Nights / 04 Days",
    title: "Dhiggiri Island 3 Nights Stay",
    image: MA10Image,
    description: "",
    path: "/ma10",
  },
  {
    id: 11,
    fromToDays: "03 Nights / 04 Days",
    title: "Hulhumale 3 Nights Stay",
    image: MA11Image,
    description: "",
    path: "/ma11",
  },
];

// const rulesData = [
//   ""
// ];

const Maldives = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Maldives"}
            subTitle={"Amazing Holidays to Maldives"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={MALDIVES_TOUR}
            title={"Popular Maldives Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Maldives;
