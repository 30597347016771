import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC7Image } from "../image";

const heroTextData = [
  {
    title: "Southern Vietnam",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "DaNang",
    endCity: "Hue",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Danang Arrival - Hue ",
    details: "Arrive inDanang Airport. Meet our guide and driver and transfer to Hue. Upon arrival in Hue, check in hotel. Dinner at Indian Restaurant Overnight at Hotel in Hue City ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "HueCity tour",
    details: "In the morningBoat trip in Perfume river to visit Thien Mu pagoda and King Minh Mang’s tomb. Lunch at Indian Restaurant Afternoon: visit Imperial Citadel, Dong Ba market Dinner at Indian Restaurant Overnight at Hotel in Hue City ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Hue – Hoi An ancient town",
    details: "Breakfast at Hotel and checkout Depart to Hoi An. Stopover to visit Lang Co fishing village. Lunch at Indian Resturant Afternoon take a walking tour of Hoi An to discover the historic town which used to be a prosperous seaport city during the 16th to 18th centuries. Visit Hoi An colorful local market, Tan Ky old house, Fukien Assembly Hall, and the 400-year-old Japanese covered bridge. Transfer for Bamboo boat ride at Cam Thanh water coconut forest. Dinner at Indian Restaurant Overnight at Hotel in Hoi An ",
  },
  {
    id: "Day 4",
    header: "Hoi An – Banahills – Hoi An",
    details: "Breakfast at Hotel Drive to Bana hills (30km from Danang) take Cable Car which gains 5 world scores to Ba Na hill station. First Stop we will visit Linh Ung pagoda, Old wine tunnel, Orchid Garden, Le Jardin D’Amour , Golden Bridge. Lunch on Bana Hills with local food – Buffet Afternoon we will continue the trip to visit Fantasy Park then drive to Hoi An. Dinner at Indian Restaurant Overnight at Hotel in Hoi",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Hoi An - Danang Departure",
    details: "Breakfast at Hotel and checkout (Checkout time at 12:00) Free for shopping Transfer to the airport for the departure flight ",
  },
];

const inclusionData = [
  "English speaking tour guide",
  "Private air-conditioned transportation",
  "Accommodation as mentioned or similar",
  "Boat trips",
  "Meals as mentioned on tour dating: B=Breakfast, L=Lunch, D=Dinner",
  "All entrance fees",
  "Two way airport transfers",
  "Water & tissues",
  
  ];
  
  const exclusionsData = [
  "International/Domestic flights costs",
  "Personal expenses, meals and drinks unless otherwise specified",
  "Travel insurance",
  "Visa fees to Vietnam",
  "Compulsory Tipping of 3$/pax/day",
  "Surcharge for peak season, New year, Holiday… ",
  
  ];

const VC7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC7;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
