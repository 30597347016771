import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const RAJASTHAN_TOUR = "/assets/img/domesticTour/Rajasthan.png";

const HERITAGE_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Heritage_Pink_City.png";

const HERITAGE_LAKE_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Heritage_Lake_City.png";

const HERITAGE_RELIGION_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Heritage_Religion_City.png";

const TAJ_WITH_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Taj_with_Pink_City.png";

const TIGER_ROAR_WITH_HERITAGE_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Tiger_Roar_with_Heritage.png";

const LAKEND_MARWAR_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Lakend_Marwar.png";

const RAJASTHAN_HEAVEN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Rajasthan_Heaven.png";

const DESERT_DUSK_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Desert_Dusk.png";

const RAJASTHAN_WILDLIFE_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Rajasthan_Wildlife.png";

const GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Golden_Triangle.png";

const MARWAR_JOURNEY_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Marwar_Journey.png";

const GLORY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Glory_Rajasthan.png";

const BEST_OF_GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Best_of_Golden_Triangle.png";

const ROYAL_MARWAR_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Royal_Marwar.png";

const SPIRITUAL_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Spiritual_Rajasthan.png";

const PEARL_OF_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Pearl_of_Rajasthan.png";

const RANGEELO_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Rangeelo_Rajasthan.png";

const COLOURFUL_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Colourful_Rajasthan.png";

const A_TASTE_OF_ROYALTY_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/A_Taste_of_Royalty.png";

const ROYAL_JOURNEY_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Royal_Journey.png";

const ROYAL_KINGDOM_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Royal_Kingdom.png";


const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Nights / 03 Days",
    title: "Heritage Pink City",
    image: HERITAGE_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HeritagePinkCity",
  },
  {
    id: 2,
    fromToDays: "02 Nights / 03 Days",
    title: "Heritage Lake City",
    image: HERITAGE_LAKE_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HeritageLakeCity",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Heritage Religion City",
    image: HERITAGE_RELIGION_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/HeritageReligionCity",
  },
  {
    id: 4,
    fromToDays: "03 Nights / 04 Days",
    title: "Taj with Pink City",
    image: TAJ_WITH_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/TajwithPinkCity",
  },
  {
    id: 5,
    fromToDays: "03 Nights / 04 Days",
    title: "Tiger Roar with Heritage",
    image: TIGER_ROAR_WITH_HERITAGE_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/TigerRoarwithHeritage",
  },
  {
    id: 6,
    fromToDays: "03 Nights / 04 Days",
    title: "Lakend Marwar",
    image: LAKEND_MARWAR_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/LakendMarwar",
  },
  {
    id: 7,
    fromToDays: "04 Nights / 05 Days",
    title: "Rajasthan Heaven",
    image: RAJASTHAN_HEAVEN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RajasthanHeaven",
  },
  {
    id: 8,
    fromToDays: "04 Nights / 05 Days",
    title: "Desert Dusk",
    image: DESERT_DUSK_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/DesertDusk",
  },
  {
    id: 9,
    fromToDays: "04 Nights / 05 Days",
    title: "Rajasthan Wildlife",
    image: RAJASTHAN_WILDLIFE_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RajasthanWildlife",
  },
  {
    id: 10,
    fromToDays: "04 Nights / 05 Days",
    title: "Golden Triangle",
    image: GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/GoldenTriangle",
  },
  {
    id: 11,
    fromToDays: "05 Nights / 06 Days",
    title: "Marwar Journey",
    image: MARWAR_JOURNEY_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/MarwarJourney",
  },
  {
    id: 12,
    fromToDays: "05 Nights / 06 Days",
    title: "Glory Rajasthan",
    image: GLORY_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/GloryRajasthan",
  },
  {
    id: 13,
    fromToDays: "05 Nights / 06 Days",
    title: "Best of Golden Triangle",
    image: BEST_OF_GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/BestofGoldenTriangle",
  },
  {
    id: 14,
    fromToDays: "06 Nights / 07 Days",
    title: "Royal Marwar",
    image: ROYAL_MARWAR_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RoyalMarwar",
  },
  {
    id: 15,
    fromToDays: "06 Nights / 07 Days",
    title: "Spiritual Rajasthan",
    image: SPIRITUAL_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/SpiritualRajasthan",
  },
  {
    id: 16,
    fromToDays: "06 Nights / 07 Days",
    title: "Pearl of Rajasthan",
    image: PEARL_OF_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/PearlofRajasthan",
  },
  {
    id: 18,
    fromToDays: "07 Nights / 08 Days",
    title: "Colourful Rajasthan",
    image: COLOURFUL_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/ColourfulRajasthan",
  },
  {
    id: 17,
    fromToDays: "08 Nights / 09 Days",
    title: "Rangeelo Rajasthan",
    image: RANGEELO_RAJASTHAN_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RangeeloRajasthan",
  },
  {
    id: 19,
    fromToDays: "09 Nights / 10 Days",
    title: "A Taste of Royalty",
    image: A_TASTE_OF_ROYALTY_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/ATasteofRoyalty",
  },
  {
    id: 20,
    fromToDays: "09 Nights / 10 Days",
    title: "Royal Journey",
    image: ROYAL_JOURNEY_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RoyalJourney",
  },
  {
    id: 21,
    fromToDays: "09 Nights / 10 Days",
    title: "Royal Kingdom",
    image: ROYAL_KINGDOM_TOUR_RAJASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/RoyalKingdom",
  },
];

// const rulesData = [
//   "Pre-Paid cellular connections do not work in Jammu & Kashmir region. Only Jio, Airtel & Bsnl Post-Paid will work here.",
//   "BAISERAN is the best place to visit in Pahalgam though it is not a snow-covered area but the place has its own natural beauty. Either you go by walk or by Horse Riding.",
//   "Sonamarg: After Sonamarg, there is a Point called Zero point away from Sonamarg, Thajiwas Glacier and activities like Fish Point (Under Water Balloon Ride). There is only option Union Cab, Charges for Union Cab: 3500/-to 5000/- (Negotiable)",
//   "Gondola, you will find Snow and other activities related to snow. Tickets Are fixed as per Govt. fare i.e., Rs. 780 for 1st phase & 920 for 2nd phase",
//   "For Kashmiri Wazwan Food: - Aadhoos at Lal Chowk Srinagar is the best place and Mutton Kebab at Gee Enn Bakers - Residency Road.",
// ];

const RajasthanPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Rajasthan Tour Packages"}
            subTitle={"Amazing Holidays to Rajasthan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={RAJASTHAN_TOUR}
            title={"Popular Rajasthan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Rajasthan"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default RajasthanPackages;
