import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  TN1Image,
  TN2Image,
  TN3Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const TAMILNADU_TOUR = "/assets/img/domesticTour/tamilnadu.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Madurai / Rameswaram / Madurai",
    image: TN1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/tn1",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "Chennai / Mahabalipuram / Kumbakonam / Tanjore / Madurai",
    image: TN2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/tn2",
  },
  {
    id: 3,
    fromToDays: "08 Nights / 09 Days",
    title: "Chennai / Tirupati / Mahabalipuram / Trichy / Kodaikanal / Madurai / Rameswaram / Madurai",
    image: TN3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/tn3",
  },
];

const Tamilnadu = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Tamilnadu"}
            subTitle={"Amazing Holidays to Tamilnadu"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={TAMILNADU_TOUR}
            title={"Popular Tamilnadu Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Assam"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Tamilnadu;
