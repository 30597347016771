import React from "react";
import {
  CardMedia,
  Grid,
  Typography,
  Card,
  Box,
  Container,
  Divider,
} from "@mui/material";

import PropTypes from "prop-types";

const HeroTextDetails = ({heroTextData}) => {
  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      {
        heroTextData?.map((item) =>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              textAlign: "start",
              color: "text.light",
              textShadow: "1px 1px 2px black",
            }}
          >
            {item.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      Duration
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      {item.duration}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: "#ffffff" }}
              />
              <Grid item xs={3}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      Type of Trip
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      {item.typoOfTrip}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: "#ffffff" }}
              />
              <Grid item xs={3}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      Start City
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                     {item.startCity}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: "#ffffff" }}
              />
              <Grid item xs={2}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                      End City
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "start",
                        color: "text.light",
                        textShadow: "1px 1px 2px black",
                      }}
                    >
                    {item.endCity}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
        )
      }
    </Box>
  );
};

HeroTextDetails.propTypes = {
  heroTextData: PropTypes.func,

};

export default HeroTextDetails;
