import React from "react";
import HeroSlider from "./Components/HeroSlider";
import { Container } from "@mui/material";


function HeroSectionStepper() {
  return (
      <Container>
         <HeroSlider />
      </Container> 
  );
}

export default HeroSectionStepper;