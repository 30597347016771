import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U3Image } from "../image";

const heroTextData = [
  {
    title: "Orlando",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Orlando ",
    endCity: "New York  ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Orlando",
    details:
      "Welcome to Orlando. Orlando is the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. The city is said to welcome more than 52 million tourists every year. Transfer to your hotel upon arrival. Later evening transfer for Dinner at Italian Resturaunt where you the Kids can enjoy a wide Variety of UNLIMITED Pizza, Pastas, Breads, Salad, Desserts and Drinks. Overnight at Orlando.( Meals – Dinner )",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Orlando - Kennedy Space Centre - Cocoa Beach – Dinner",
    details:
      "Breakfast at the Hotel proceed to NASA’s John F.Kennedy Center, our nation’s gateway to exploring, discovering and understanding our Universe! An out of this world experience! The Space Center opened in 1962 and was named in honor of President Kennedy after his assassination in 1963. Ever since, it has been a world of fun and excitement for all! The Visitors Complex has everything from real life artifacts to fascinating exhibits to attractions on the history and future of human and robotic spaceflight. A wonderful and unique experience, you will leave feeling inspired and amazed. Your Activties will include m Space Shuttle Atlantis®, Shuttle Launch Experience®, Heroes and Legends featuring the U.S. Astronaut Hall of Fame® presented by Boeing®, Kennedy Space Center Bus Tour including Apollo/Saturn V Center, Journey to Mars: Explorers Wanted, Astronaut Encounter, IMAX® Theater 3D Film, Rocket Launch Viewing (when available). Enjoy a Continental Lunch. Later afternoon you will be taken to cocoa beach where you will spend some time enjoying beach activities. Later evening transfer to Indian restauraunt for Dinner, return back to the Hotel Overnight.Overnight at Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Orlando – Sea World",
    details:
      "Today you can visit the world's largest Marine Park Sea World - Here you shall discover many attractions. We dare you to take a one-of-a kind water coaster thrill ride through the mysterious lost city of Atlantis. Soar through danger on a jet copter ride to the Wild Arctic. At Sea World, touch, feed or get face to face with awesome and amazing wild animals, and get in on all the action that draws you back again and again. Overnight at Orlando.",
  },
  {
    id: "Day 4",
    header: " Orlando – Gator Tour & Animal Reserve",
    details:
      "Today get ready for down-home family fun at “The Alligator Capital of the World®” –  Gatorland. There isn’t a better place to see alligators and crocodiles of all sizes, from babies, also known as grunts, to the 14-foot monsters that call our Breeding Marsh home. We even have the largest collection of extremely rare white leucistic alligators.  But, Gatorland is more than just a gator park!  Our free-flight aviary, petting zoo, one-of-a-kind animal shows, thrilling Screamin’ Gator Zip Line and new Stompin’ Gator Off-Road Adventure ensure your day will be chock-full of fun, smiles and special memories. Later proceed to Nature’s Animal Rescue whose mail goal is to provide the proper medical care and treatment needed for injured and orphaned FL native wildlife. Back to Nature Wildlife Refuge is a nonprofit organization focused on rescuing, raising, rehabilitating, and releasing injured or orphaned Florida native species and providing education about preserving local wildlife and ecosystems. Overnight at Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Orlando – Shopping at Premium Outlet - Magic Kingdom",
    details:
      "Breakfast at the Hotel. Proceed for shopping at premium Outlet in Orlando and then after 1 pm proceed to Magic Kingdom. This covers 107 acres, opened on October 1, 1971. It’s been dubbed The Most Magical Place on Earth! and is home to seven themed lands, and dozens of attractions that appeal to all ages. It is the place where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Visit Magic Kingdom make your childhood fantasies come true as you step into fairytale land of themed rides, shows and parades inspired by Disney’s Stories. Hop on to the happiest land, the Magic Kingdom, where the legend of Mickey Mouse begins. The magic will bring you the classical vehicles Western Carriage, Pirates Treasure Island and Cartoon stars. Overnight in Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Orlando",
    details:
      "After breakfast, transfer to the Orlando Airport fly back home or to the next destination. End of tour",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "  05 Night’s Accommodation with breakfast at Radisson Lake Buena Vista",
  "  Kennedy Space Centre Admission Tickets",
  "  Cocoa Beach with Transfers",
  "  1Day Sea World Orlando Base Ticket With Transfers",
  "  Gator Tour & Animal Reserve Tour with Transfers",
  "  Premium Outlet Mall and Magic Kingdom ( After 1 pm Tickets )",
  "  4 Continental Lunches and 5 Indian Dinners",
  "  Orlando Airport / Hotel transfers on Pvt Baisis",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
