import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const COCHIN_MUNNAR = "/assets/img/domesticTour/kerala/COCHIN_MUNNAR.png";

const heroTextData = [
  {
    title: "Cochin, Munnar, Thekkady, Kumarakom, Kovalam and Trivandrum",
    duration: "09 Nights / 10 Day",
    typoOfTrip: "Road Trip",
    startCity: "Cochin",
    endCity: "Trivandrum",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival at Calicut",
    details:
      "Upon landing in the Calicut Airport our agent will unconditionally invite you and exchange you by street to the lodging. Evening set out on an energizing touring voyage through Kozhikode. Previously known as Calicut, the town was the recent capital of Northern Kerala under the Zamorin rulers. The town, lying on the Malabar Coast of Kerala, was a main centre point for zest and ivory exchange from the pre-medieval occasions. It was here the Portuguese guide Vasco Da Gama arrived in 1498 AD, prompting the revelation of an immediate ocean course to India.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Destination Wayanad",
    details:
      "After a sumptuous breakfast at the hotel, drive to Wayanad. Rest of the day is available to you to appreciate at recreation.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Voyage through Wayanad",
    details:
      "After a reviving breakfast at the inn, continue for a neighbourhood touring voyage through Wayanad. Wayanad, a good country district situated at a 700-2100 m height above MSL, is the place that is known for intriguing flavor estates and flawless woods. The verdant scene and salubrious atmosphere makes Wayanad a standout amongst the most looked for after traveller goals in Kerala.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Destination Coorg",
    details:
      "Drive to Coorg after a filling breakfast at the lodging; on entry registration check into inn. Rest of the day is available to you to appreciate at relaxation.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Voyage through Coorg",
    details:
      "Following breakfast continue for an energizing touring voyage through Coorg. Kodagu or Coorg, known as the ‘Scotland of India’, is the most pleasant station of Karnataka. Situated on the Western Ghats at an elevation of 4000 ft, Kodagu has thick backwoods, espresso and tea estates, orange plantations and rich paddy fields specking its territory. Most of the populace here has a place with the Kodava people group; an old military race with particular culture and conventions. The general population of Kodagu are considered the most wonderful of the general population in India.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Destination Hassan",
    details:
      "After breakfast drive to Hassan and check into the hotel. Rest of the day is available to you to appreciate at recreation.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Across Hassan",
    details:
      "Set out on an entire day touring voyage through the notable attractions close Hassan. The town of Hassan lies at a separation of around 190 km from the capital city of Bangalore. It is in a perfect world found near spots like Belur, Halebid and Shravanbelagola. Not a lot is thought about the historical backdrop of Hassan, then again, actually it was under the standard of the incomparable Hoysala Empire among eleventh and the fourteenth century. Investigate the numerous memorable spots found near the town. Medium-term remain at lodging.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "To the Silicon Valley",
    details:
      "After an early morning breakfast registration from the inn and drive to Bangalore. Evening, continue for a neighborhood touring voyage through Bangalore City. Bangalore, the capital of Karnataka, is prominently known as the city of Gardens. The city is today the Silicon Valley of India separated from being the central regulatory, business and mechanical center point of Karnataka State. It lies at a height of 920 m above MSL. The present day Bangalore City was established by Kempe Gowda, a chieftain under the Vijayanagara Empire, in 1537 A.D. With the help from ruler Achutaraya, he manufactured a mud stronghold here and set up petite towns inside the fortification. Spots of traveller enthusiasm here incorporate the Lalbagh Gardens, Cubban Park, Vidhana Soudha, and Museum, The Govt. Exhibition hall, Visvesvaraya Industrial, Cariappa Memorial Park, and Technological Museum, The Fort, Bull Temple, Tippu’s Palace, Venkatappa Art Gallery.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Departure",
    details:
      "Your tour comes to an end today. After breakfast, our executives will pick you up and drop you to the airport or railway station.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackages =
  () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Main>
          <TourDetails
            heroImg={COCHIN_MUNNAR}
            heroTextData={heroTextData}
            overview={overview}
            intineraryTimelineData={intineraryTimelineData}
            inclusionsData={inclusionData}
            exclusionsData={exclusionsData}
          />
        </Main>
      </Box>
    );
  };

export default CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackages;
