export const typography = {
  fontFamily: ["Lato"].join(","),

  h1: {
    fontWeight: 700,
    fontSize: "34px"
  },
  h2: {
    fontWeight: 700,
    fontSize: "24px",
  },
  h3: {
    fontWeight: 600,
    fontSize: "18px",
  },
  h4: {
    fontWeight: 600,
    fontSize: "16px",
  },
  h5: {
    fontWeight: 500,
    fontSize: "14px",
  },
  h6: {
    fontWeight: 500,
    fontSize: "12px",
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: "14px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "12px",
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400
  },
  body1:{
    lineHeight: "1.334em",
    fontWeight: 400,
    fontSize: "14px",
  },
  body2:{
    letterSpacing: '0em',
    lineHeight:  "1.5em",
    fontWeight: 400,
    fontSize: "12px",
  },
  button: {
    textTransform: 'capitalize'
  },
};
