import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

const topTouristDestinationData = [
  {
    title: "Maldives",
    href: "/maldives-tour",
  },
  {
    title: "Dubai",
    href: "/dubai-tour",
  },
  {
    title: "Bali",
    href: "/bali-tour",
  },
  {
    title: "Thailand",
    href: "/thailand-tour",
  },
  {
    title: "Europe",
    href: "/europe-tour",
  },
  {
    title: "USA",
    href: "/usa-tour",
  },
  {
    title: "Japan",
    href: "/japan-tour",
  },
  {
    title: "Vietnam & Cambodia",
    href: "/vietnam-cambodia-tour",
  },
  {
    title: "Egypt",
    href: "/egypt-tour",
  },
  {
    title: "Canada",
    href: "/canada-tour",
  },
  {
    title: "Israel",
    href: "/israel-tour",
  },
  {
    title: "Bhutan",
    href: "/bhutan-tour",
  },
  {
    title: "Nepal",
    href: "/nepal-tour",
  },
];

const TopTouristDestination = () => {
  return (
    // <Box sx={{ flexGrow: 1 }}>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} sm={12} md={12}>
    //       <Typography variant="h4" color={"text.primary"}>
    //         Top Tourist Destination
    //       </Typography>
    //     </Grid>
    //     {topTouristDestinationData.map((item) => (
    //       <Grid item xs={6} sm={6} md={6}>
    //         <Link href="#" underline="none" color={"text.primary"}>
    //           {item.title}
    //         </Link>
    //       </Grid>
    //     ))}
    //   </Grid>
    // </Box>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" color={"text.primary"}>
                Top Tourist Destination
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={0}>
            {topTouristDestinationData.map((item) => (
              <Grid item xs={6} sm={6} md={6}>
                <Link href={item.href} underline="none" color={"text.primary"}>
                  {item.title}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopTouristDestination;
