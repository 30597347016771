import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { L9Image } from "../image";

const heroTextData = [
  {
    title: "Ladakh Panorama Tour",
    duration: "11 Nights / 12 Days",
    typoOfTrip: "Road Trip",
    startCity: "Leh",
    endCity: "Srinagar",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Chandigarh-Manali ( 350 km).",
    details: "Arrive Chandigarh. On arrival transfer to hotel at Manali.Overnight at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Manali - Sarchu (222 Kms).",
    details: "Early morning pickup from Manali Hotel and head for Sarchu, driving across Rohtang Pass (13050 ft) and passing through Keylong, Jispa and Dracha and then cross the Baralacha Pass (16020 ft) to arrive at Sarchu in the late afternoon. Overnight in fixed camp at Sarchu.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Sarchu - Leh (255 Kms).",
    details: "After early breakfast leave for Leh en-route crossing Nakeela and Lachalung-la Pass (16,617 ft.). Break for lunch at Pang and then drive on towards Leh passing through Skyangchu Thang (a vast plateau stretching for 42 kms) and then crossing Taglang-La (17,585ft) and driving through the upper Indus Valley. Reach Leh by evening.Overnight in hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "In Leh : visit the town and surrounds.",
    details: "Early departure for Pangong Lake (approx. 145 kms, located on the Indo China Border), via Chang-la pass (alt: 17370 ft). After full day of sightseeing around the Lake check-in at fixed tented camp near the lake at Spangmik or drive back for 30 kms to Tangtse and check into Hotel / Guest House for overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Leh - Pangong Lake (150 kms.)",
    details: "Early departure for Pangong Lake (approx. 145 kms, located on the Indo China Border), via Chang-la pass (alt: 17370 ft). After full day of sightseeing around the Lake check-in at fixed tented camp near the lake at Spangmik or drive back for 30 kms to Tangtse and check into Hotel / Guest House for overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Pangong - Hemis / Thiksey-Leh (165 kms)",
    details: "After breakfast, leave Pangong / Tangtse and retrace the Leh route across Chang-la. Upon reaching Karu, drive across the Indus and visit the famous Hemis Monastery, biggest monastery of Ladakh. Later drive on to Leh, enroute visiting the picturesque Thiksey monastery.Overnight in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Leh -Khardongla - Nubra Valley (125 kms)",
    details: "After breakfast leave for Nubra Valley, driving across Khardong-la (alt: 18,380 ft). After descending from the pass, drive on for sightseeing of Sumoor area and Samstaling Gompa. Later check into a fixed camp in the same area or drive across to Deskit / Hundar for overnight in a fixed camp or hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Nubra Valley - Khardongla-Leh (125 kms)",
    details: "In the morning visit / explore Deskit , Hundar and the Sand Dunes where you can enjoy a short camel safari using the double-humped Bactrian camel found here (optional on direct payment). Later drive back to Leh reaching in the late afternoon / evening. Overnight at the hot",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Leh - Alchi / Uleytokpo: (70 kms)",
    details: "After leisurely breakfast drive down the Indus Valley on the first leg of your journey to Kargil & Srinagar. On the way, visit Hall of Fame, Gurudwara Pathar Sahib, Magnetic Point, Sangam (confluence of Indus & Zanskar Rivers) and then the 11th century Alchi Choskor, oldest surviving monastic complex famous for its wall frescoes and architecture - a UNESCO listed heritage site.Overnight in fixed camp / resort at Uleytokpo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Uleytokpo : Lamayuru - Kargil (135 kms) :",
    details: "After breakfast leave for Kargil, enroute visiting the dramatically located Lamayuru monastery and the adjoining Moonland hills. On the way to Kargil you can view the 9-Mtrs. tall rock sculpture of the Maitreya Buddha at Mulbek (7th -8th century AD).Overnight in hotel at Kargil.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Kargil - Sonamarg - Srinagar (205 kms)",
    details: "Early departure for Srinagar driving through Drass (famous as the second coldest inhabited place in the world) and crossing the Zoji-la Pass (11,500-ft. / 3,505 m) to reach hill station of Sonamarg from where itâ€™s smooth driving through the beautiful Kashmir countryside to reach Srinagar.Overnight in hotel at Srinagar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Departure Srinagar",
    details: "Early morning transfer to the airport for taking the flight back home.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"Accommodation on twin Sharing Basis. ",
"Meal Plan (as per mentioned above).",
"Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
"All permit fees & hotel taxes (as per itinerary).",
"Rates are valid for INDIAN NATIONALS only.",

];

const exclusionsData = [
"Air Fare / Train fare. ",
"Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
"Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
"Entrance Fees & Guide charges. ",
"Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
"Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
"Room Heater Charges.",
"Insurance. ",
"Anything which is not included in the inclusion",
];

const L9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={L9Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default L9;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
