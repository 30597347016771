import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC9Image } from "../image";

const heroTextData = [
  {
    title: "Taste Of Vietnam",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Hanoi",
    endCity: "Hanoi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Day",
    details:
      "Welcome to Hanoi.Hanoi, the capital of Vietnam, is known for its centuries-old architecture and a rich culture with Southeast Asian, Chinese and French influences. At its heart is the chaotic Old Quarter, where the narrow streets are roughly arranged by trade. Upon arrival at the airport, you will be transferred to your hotel. Lunch at Indian restaurant Afternoon, visit the Temple of Literature built in 1070 and regarded as the First University in Vietnam, the Hoan Kiem Lake and Ngoc Son temple and take the Cyclo tour at the Old Quarter to experience the exciting local daily life of Hanoians, shopping free around The Old Quarter that have stock of trendy to basic clothing and thousands of small craft and boutique shops offering variety of Vietnamese handicraft products Enjoy the Water Puppet Show- A form of folk arts originating in the north of Vietnam, in which wood-puppets play their roles according to the direction of puppeteers and singers of Cheo (a kind of traditional theater in Vietnam) sing songs to tell the story in words Dinner at Indian restaurant ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Hanoi City – Halong Bay Cruise",
    details:
      "Breakfast at Hotel and Check-out Transfer to Ha Long Bay in Quang Ninh Province (around 160KM). Upon arrival in Halong Bay, boarding the Cruise for exploring wonderful Bay of Halong Lunch at boat while cruising around the Bay Afternoon: explore hundreds of beautiful karst formations arising from green emerald water, explore Cave, swimming and enjoy Sunset on the Cruise (Program might change due to the weather and the management) Dinner on the Cruise. After dinner is fishing time for everyone who wishes to join Overnight on the Cruise ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Halong Bay – Hanoi",
    details:
      "Breakfast at Boat Cruise Continue to discover the Halong bay then check-out Lunch at local Restaurant Transfer from Halong Bay to Hanoi. Enjoy the Water Puppet Show- A form of folk arts originating in the north of Vietnam, in which wood-puppets play their roles according to the direction of puppeteers and singers of Cheo (a kind of traditional theater in Vietnam) sing songs to tell the story in words Dinner at Local Restaurant Overnight in Hanoi ",
  },
  {
    id: "Day 4",
    header: "Hanoi - Ho Chi Minh City – Cu Chi tunnels",
    details:
      "This morning, transfer for flight to Ho Chi Minh City, upon arrival, transfer for Lunch at Indian restaurant. After lunch, drive to visit to Cu Chi Tunnels (70 km northwest of Ho Chi Minh city), an amazing complex of underground tunnels used during the Vietnam war. Drive back to Ho Chi Minh City for Lunch at Indian Restaurant. Afternoon we will visit the Reunification Palace, War Remnants Museum. Photo stop at Notre Dame Cathedral, General Post office. Dinner at Indian restaurant Overnight in Ho Chi Minh City ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Mekong Delta",
    details:
      "Breakfast at Hotel Drive to My Tho, a prosperous town of 170,000 inhabitants of the Mekong Delta. It is noted for its exuberant orchards and immense rice fields. Enjoy boat rides on the Mekong River and along the lush canopy of water coconuts. Lunch at local restaurant Visit an orchard on an island and taste some exotic fruits, green tea. Rowing boat along the canals and walk around the traditional villages; immerse yourself in nature when listening Southern traditional music Transfer back to Ho Chi Minh City. Dinner at IndianRestaurant ",
  },
  {
    id: "Day 6",
    header: "Ho Chi Minh City – Departure ",
    details:
      "Breakfast at hotel. Free for shopping. Transfer to airport for departure. ",
  },
];

const inclusionData = [
  "English speaking tour guide",
  "Private air-conditioned transportation",
  "Accommodation as mentioned or similar",
  "Boat trips",
  "Meals as mentioned on tour dating: B=Breakfast, L=Lunch, D=Dinner",
  "All entrance fees",
  "Two way airport transfers",
  "Water & tissues",
];

const exclusionsData = [
  "International/Domestic flights costs",
  "Personal expenses, meals and drinks unless otherwise specified",
  "Travel insurance",
  "Visa fees to Vietnam",
  "Compulsory Tipping of 3$/pax/day",
  "Surcharge for peak season, New year, Holiday… ",
];

const VC9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC9Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC9;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
