import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

const topIndiaTouristDestinationData = [
  {
    title: "Rajasthan",
    href: "/rajasthan-tour",
  },
  {
    title: "Kerala",
    href: "/kerala-tour",
  },
  {
    title: "Kashmir",
    href: "/kashmir-tour",
  },
  {
    title: "Himachal Pradesh",
    href: "/himachal-tour",
  },
  {
    title: " Leh Ladakh",
    href: "/lehladakh-tour",
  },
  {
    title: "Odisha",
    href: "/odisha-tour",
  },
  {
    title: "Gujarat",
    href: "/gujarat-tour",
  },
  {
    title: "Uttar Pradesh",
    href: "/uttar-pradesh-tour",
  }, 
];

const TopIndiaTouristDestination = () => {
  return (
    // <Box sx={{ flexGrow: 1 }}>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} sm={12} md={12}>
    //       <Typography variant="h4" color={"text.primary"}>
    //         Top Tourist Destination
    //       </Typography>
    //     </Grid>
    //     {topTouristDestinationData.map((item) => (
    //       <Grid item xs={6} sm={6} md={6}>
    //         <Link href="#" underline="none" color={"text.primary"}>
    //           {item.title}
    //         </Link>
    //       </Grid>
    //     ))}
    //   </Grid>
    // </Box>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" color={"text.primary"}>
              Top India Tourist Destination
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={0}>
            {topIndiaTouristDestinationData.map((item) => (
              <Grid item xs={6} sm={6} md={6}>
                <Link href={item.href} underline="none" color={"text.primary"}>
                  {item.title}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopIndiaTouristDestination;
