import React from "react";

import {
  Grid,
  Typography,
  IconButton,
  CardContent,
  Container,
} from "@mui/material";

import { SM1, SM2, SM3, SM4, SM5 } from "./icons";

const Copyright = () => {
  return (
    <CardContent sx={{ bgcolor: "primary.dark", maxHeight: 100 }}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6" color={"text.light"}>
              © Copyrights 2023 - 2024. Radha Tours. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton
                  href="https://www.facebook.com/profile.php?id=100094029339568"
                  target="_blank"
                >
                  <img src={SM1} width={24} height={24} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://www.instagram.com/radhatours/"
                  target="_blank"
                >
                  <img src={SM2} width={24} height={24} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton href="https://wa.me/+919730747576" target="_blank">
                  <img src={SM3} width={24} height={24} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href="https://in.pinterest.com/radhatoursonly/"
                  target="_blank"
                >
                  <img src={SM4} width={24} height={24} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton href="https://t.me/RADHATOURS" target="_blank">
                  <img src={SM5} width={24} height={24} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </CardContent>
  );
};

export default Copyright;
