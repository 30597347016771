import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KT10Image } from "../image";

const heroTextData = [
  {
    title:  "Mangalore / Udupi / Murudeshwara / Jof Falls / Karwar Dandeli / Hubli",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Mangalore",
    endCity: "Hubli",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Mangalore + Drive to Udupi (60 Kms / 1 – 2 Hrs)",
    details: "On arrival at Mangalore, meet our chauffeur waiting for you and proceed towards Udupi. Arrive Udupi in the Noon and check into the hotel. Later enjoy sightseeing of Udupi temples, Malpe beach & Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Drive Udupi – Murudeshwara (100 Kms / 2 – 3 Hrs)",
    details: "After breakfast, check out of the Hotel and later dive to Murudeshwara. Arrive Murudeshwara in the noon and check into the hotel. Later enjoy sightseeing of Murudeshwara visiting the famous Murudeshwara temple Sultan Masjid, Jamai Masjid, beaches & Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day Excursion to Jog Falls",
    details: "After breakfast, enjoy a day trip to Jog. Return to the hotel later in the evening. Overnight in the hotel.",
  },
  {
    id: "Day 4",
    header: "Drive Murudeshwara – Karwar (120 Kms / 2 – 3 Hrs)",
    details: "After breakfast, check out of the Hotel and later drive to Karwar. Arrive Karwar in the noon and check into the hotel. Later enjoy sightseeing of Karwar visiting Beaches &Temples. Evening free for relaxation. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Drive Karwar – Dandeli (180 Kms / 3 – 4 Hrs)",
    details: "After breakfast, enjoy the activities in the resort. Evening free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Dandeli",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Hubli rail station / airport in time for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Drive Dandeli – Hubli Drop (110 Kms / 2 – 3 Hrs)",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Hubli rail station / airport in time for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const KT10 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KT10Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KT10;
