import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { CR1Image } from "../image";

const heroTextData = [
  {
    title: "Best of Croatia FIT Tour",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Zagreb",
    endCity: "Dubrovnik ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Zagreb airport arrival",
    details: "Arrive in Zagreb and meet and greet your driver at the airport and proceed towards Zagreb city center. Check in at the hotel. Optional: Zagreb sightseeing tour with local guide (Duration approx. 3 hrs) Zagreb grew up on two neighbouring hills: Kaptol - the centre of spiritual and religious life of north-western Croatia and Gradec - the commercial centre of skilful tradesmen and craftsmen. Visit the Upper Town with its Gothic Cathedral, the beautiful and ornate St. Catherine’s church or the massive St. Mark’s church with its famous colour-tiled roof. Continue to the medieval Stone Gate and “Dolac”, the open-air market which are a must for all visitors. Overnight at the hotel in Zagreb area. Optional: Transfer to Ljubljana.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Visit Ljubljana and Bled Lake with local guide (full day trip)",
    details: "Ljubljana is often called a European jewel or described as a small blend of Prague and Amsterdam. It is a 'town with a soul', as visitors often comment. This vibrant old town is concentrated along the Ljubljanica River, and the castle on a hill still looks like it is protecting the romantic city below. On this tour you will stroll along medieval streets, admire an interesting blend of different architectural styles, 'see' and 'be seen' on several famous promenades along the river, discover some of the Ljubljana’s hidden monuments, relax in the city’s greenery, and hear stories and fairytales that connect the present and the past. Transfer further to Bled Lake. Bled Lake guided tour Bled is famous for the Lake Bled, which makes it a major tourist attraction. With its surroundings and natural beauties it represents one of the nicest alpine holiday spots which is known by the mild climate and thermal lake water. Beauty reflection of the mountains in the lake, sun, peace and fresh air, make incredible feelings in visitors at every time of the year. The lake surrounds Bled Island, the only natural island in Slovenia. The island has several buildings, the main one being the Pilgrimage Church of the Assumption of Mary. Return transfer to Zagreb. Overnight at the hotel in Zagreb area.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Visit of the Plitvice National Park with local guide (duration; approx. 3 hrs)",
    details: "Plitvice National Park is one of the most beautiful and best preserved national parks in the country. This is why the entire lakes and surrounding area were declared a national park in 1949 and are listed on the UNESCO`s list of World Heritage Sites because of its exceptional beauty. it is a valley situated between high forested mountains in which lie, one below another, a string of sixteen beautiful lakes of crystal blue and green waters. Fed by small streams and brooks, they spill into each other in 92 foaming cascades and thundering waterfalls. The lakes themselves cover about 2 square kilometres and the entire national park covers 200 square kilometres. The Plitvice lakes` scenery will simply delight you regardless whether you are walking the paths and wooden bridges, taking a boat ride or just taking photographs. Transfer from Plitvice to Split (duration approx. 3 hrs) Overnight at the hotel in Split area",
  },
  {
    id: "Day 4",
    header: "Split and Trogir sightseeing tour with local guide",
    details: "The Split city tour will take you along the Old City to visit main sights: the old town of Split is built on and around the spectacular Roman Palace erected by the Roman Emperor Diocletian (born in Salona – today’s Solin) in the 4th century. The walking tour starts with a visit to the cellars of the Palace that are still very well preserved. The cellars offer a good view at how the life in the Palace was organized. The Cathedral St. Duje is a complex of a church, formed from an Imperial Roman mausoleum, with a bell tower; strictly the church is dedicated to the Virgin Mary, and the bell tower to Saint Duje. Together they form the Cathedral of St. Duje. Transfer to Trogir. Trogir guided tour Get a real insider's look at Trogir on this guided walking tour of this UNESCO-protected coastal town near Split. Stroll the streets of Trogir with a professional guide learning the backstory about significant landmarks and the history of this ancient town on the Dalmatian Coast. Trogir’s best sight is the Cathedral of St Lawrence (Katedrala sv. Lovrijenac) on which building work started in 1213 on a site where a previous cathedral once stood; the main part of the cathedral was completed in 1250. The cathedral’s bell tower was built between the 14th and 16th centuries, and can be climbed to see fantastic views from the top. A must see within the cathedral is the Chapel of St John, built in 1468, and which is considered the best Renaissance sight in Dalmatia.Part of the city walls, built between the 13th and 14th centuries, are visible today on the southern side of the city. In the middle of the city wall is the city gate, which was built in 1593. Return transfer to Split. Overnight at the hotel in Split area. Optional:Transfer to Zadar",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Zadar city sightseeing tour with local guide",
    details: "Although it's the largest metropolis in northern Dalmatia, Zadar is perhaps not as well known as other Croatian cities. Despite this fact, Zadar offers a wealth of attractions, and it's an exceptionally easy city to explore on foot, thanks in part to its many fine marble pedestrian-friendly streets. In addition to its historic old town center, Zadar boasts splendid medieval churches and Roman ruins, including the famous Church of St. Donat built on the site of the former ancient Roman forum. The Promenade, which runs down Obala Kralja Petra Kresimira IV, is also perfect for a gentle stroll as you soak up the surroundings, installed along Zadar's seafront in 2005 and now one of Croatia's most popular art installations, the fun Sea Organ is well worth a visit. In essence a giant musical instrument that's played by the action of the waves. Return transfer to Split. Overnight at the hotel in Split area. Optional:Dubrovnik",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Kravica Waterfalls and Mostar excursion (full day trip)",
    details: "Kravica Waterfalls are some amazing waterfalls near the small town of Kravice. The falls are currently not a well known attraction amongst tourists, meaning they are peaceful and not yet highly commercialised, although there is a small but useful boardwalk taking visitors closer to the falls. Onward transfer to Mostar. Mostar guided tour Mostar is one of the most frequented attractions in Bosnia, thanks to its outstanding natural beauty, architecture, and, of course, its famous UNESCO World Heritage Site, the Stari Most. It is full of quintessential Ottoman architecture, with plenty of traditional restaurants, market stalls, mosques and other historic buildings. It is also surrounded by stunning landscape waiting to be explored. Return transfer to Dubrovnik. Overnight at the hotel in Dubrovnik area.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Dubrovnik sightseeing tour with local guide",
    details: "Dubrovnik never fails to awe the visitor; its streets paved with white limestone, its walls crowning the city, and its medieval sandstone-colored houses oozing with history.«.... those who seek paradise on Earth should come to Dubrovnik» (George Bernard Shaw) The walking portion includes visits to the Dominican Monastery with its fine collection of medieval paintings. Our guide will lead you through the narrow streets of the Old Town introducing you to the history and culture of this magnificent city. Other buildings will be viewed along the way. Overnight at the hotel in Dubrovnik area.",
  },
  {
    id: "Day 8",
    header: "Back to Dubrovnik airport",
    details: "Private transfer from the hotel to the Dubrovnik airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"02 nights accommodation with breakfast at Hotel Panorama or similar in Zagreb.",
"03 nights accommodation with breakfast at Hotel Marvie or similar in Split.",
"02 nights accommodation with breakfast at Hotel Lero or similar in Dubrovnik.",
"Intercity transfers according to program.",
];

// const exclusionsData = [
//   "Alcoholic beverages except described.",
//   "Optional activities.",
//   "Flights",
//   "Visa",
//   "Meals except described.",
//   "Travel insurance",
// ];

const CR1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={CR1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CR1;


// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
