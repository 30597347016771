import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U1Image } from "../image";

const heroTextData = [
  {
    title: "East Coast",
    duration: "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Orlando ",
    endCity: "New York  ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Orlando",
    details:
      "Welcome to Orlando. Orlando is the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. The city is said to welcome more than 52 million tourists every year. Transfer to your hotel upon arrival. Later evening transfer for Dinner at Italian Restaurant where you the Kids can enjoy a wide Variety of UNLIMITED Pizza, Pastas , Breads , Salad , Desserts and Drinks. Overnight at Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Orlando - Kennedy Space Centre- Cocoa Beach – Dinner",
    details:
      "Breakfast at the Hotel proceed to NASA’s John F. Kennedy Center, our nation’s gateway to exploring, discovering and understanding our Universe! An out of this world experience! The Space Center opened in 1962 and was named in honor of President Kennedy after his assassination in 1963. Ever since, it has been a world of fun and excitement for all! The Visitors Complex has everything from real life artifacts to fascinating exhibits to attractions on the history and future of human and robotic spaceflight. A wonderful and unique experience, you will leave feeling inspired and amazed. Your activities will include m Space Shuttle Atlantis®, Shuttle Launch Experience®, Heroes and Legends featuring the U.S. Astronaut Hall of Fame® presented by Boeing®, Kennedy Space Center Bus Tour including Apollo/Saturn V Center, Journey to Mars: Explorers Wanted, Astronaut Encounter, IMAX® Theater 3D Film, Rocket Launch Viewing (when available). Enjoy a Continental Lunch . Later afternoon you will be taken to cocoa beach where you will spend some time enjoying beach activities. Later evening transfer to Indian resturant for Dinner, return back to the Hotel Overnight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Orlando – Gator Tour & Animal Reserve",
    details:
      "Today get ready for down-home family fun at “The Alligator Capital of the World®” –  Gatorland. There isn’t a better place to see alligators and crocodiles of all sizes, from babies, also known as grunts, to the 14-foot monsters that call our Breeding Marsh home. We even have the largest collection of extremely rare white leucistic alligators.  But, Gatorland is more than just a gator park!  Our free-flight aviary, petting zoo, one-of-a-kind animal shows, thrilling Screamin’ Gator Zip Line and new Stompin’ Gator Off-Road Adventure ensure your day will be chock-full of fun, smiles and special memories. Later proceed to Nature’s Animal Rescue whose mail goal is to provide the proper medical care and treatment needed for injured and orphaned FL native wildlife. Back to Nature Wildlife Refuge is a nonprofit organization focused on rescuing, raising, rehabilitating, and releasing injured or orphaned Florida native species and providing education about preserving local wildlife and ecosystems. Overnight at Orlando.",
  },
  {
    id: "Day 4",
    header: "Orlando – Shopping at Premium Outlet - Magic Kingdom",
    details:
      "Breakfast at the Hotel. Proceed for shopping at premium Outlet in Orlando and then after 1 pm proceed to Magic Kingdom. This covers 107 acres, opened on October 1, 1971. It’s been dubbed The Most Magical Place on Earth! and is home to seven themed lands, and dozens of attractions that appeal to all ages. It is the place where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Visit Magic Kingdom make your childhood fantasies come true as you step into fairytale land of themed rides, shows and parades inspired by Disney’s Stories. Hop on to the happiest land, the Magic Kingdom, where the legend of Mickey Mouse begins. The magic will bring you the classical vehicles Western Carriage, Pirates` Treasure Island and Cartoon stars. Overnight in Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Orlando – Niagara – Illumination Tour",
    details:
      "Check out from hotel and we transfer you to Orlando airport for flight to Niagara. On arrival check in to hotel. Evening proceed for Niagara Falls illumination which is a must for any visitor. Hundreds of LED lights are housed in three locations across the Niagara Gorge in Niagara Falls, Ontario. Together, these lights work to create a breathtaking view not to be missed. Overnight at Niagara Falls.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header:
      "Niagara – Maid of the Mist – Hershey Chocolate Factory- Washington DC",
    details:
      "Check out from hotel and proceed for Maid of Mist Boat ride, when you set sail on the Maid of the Mist, you’re not just getting iconic views. You’re also taking the ride of your lifetime. Later we will travel to Harrisburg enroute Hershey Chocolate World, here discover all things chocolatey and fun at Hershey's Chocolate World Attraction. Embark on a journey to learn and see how chocolate is made, share and savor delicious Hershey's-inspired drinks and treats, shop for one-of-a-kind souvenirs and gifts, and have a chocolate-inspired adventure to remember. After the Hershey Chocolate Factory drive towards DC. Indian Dinner at Washington Dc Overnight at Washington DC.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Washington Dc City Tour - Philadelphia Franklin Museum – New York",
    details:
      "Breakfast at the Hotel. Depart to Washington DC the Capital of the United States of America. Visit the most famous sights in Washington DC are the stars of this tour of the US capital. Tour the Capitol Building and visit the White House’s south lawn and visitor centre. Spend time at the Smithsonian Museums, before heading to the World War II and Dr Martin Luther King, Jr. memorials in addition to much more in between. Throughout the trip you see much of DC's finest monuments and structures including: the FBI Building, Federal Triangle, Ellipse, Tidal Basin, National Archives, Washington Monument, House and Senate Office Building, National Gallery of Art, and Grant Memorial. Overnight at Washington DC.",
  },
  {
    id: "Day 8",
    header:
      "New York City Tour with Statue of Liberty- One World Observatory and Wall Street Tour",
    details:
      "Breakfast at hotel and then proceed for New York City tour with entrance to Statue of Liberty Ferry Ride( No Getting Down at Ellis Island ). Continental Lunch at Statue of Liberty. Proceed for City Tour Highlights of the tour:, Rockfeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, Wall Street. Later Visit the One World Spend Time at the M & m Store and Times Square Later evening transfer for Dinner. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Depart New York",
    details:
      "After breakfast, transfer to the New York Airport fly back home or to the next destination. End of tour",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "5Nights Accommodation at Orlando at Raddisson Lake Buena Vista",
  "01 Night Accommodation in Niagara at the Holiday Inn Grand Island Or similar",
  "1 Night Accomodation at Quality Inn River Front",
  "01 Night Accommodation In Washington at Comfort Inn or similar",
  "02 Nights Accommodation in New York at Best Western Ramsey or similar",
  "Kennedy Space Centre Admisson Tickets & Cocoa Beach",
  "1Day Sea World Orlando Base Ticket With Transfers",
  "Gator Tour & Animal Reserve Tour with Transfers",
  "Premium Outlet Mall and Magic Kingdom ( After 1 pm Tickets )",
  "7 Continental Lunches",
  "7 Indian Dinners and 01 Italian Dinner with Transfers",
  "Orlando Airport to Hotel and Hotel to Airport",
  "Niagara Illumination Tour",
  "Maid of the Mist Boat Ride",
  "Hershey Chocolate Factory Tour",
  "Washington DC City Tour with Smithsonian Museum on seat in coach basis",
  "Franklin Museum Visit in Philadelphia",
  "Point to Point Transfers in Orlando",
  "Buffalo Airport to Hotel in Niagara",
  "Niagara Falls Illumination",
  "Niagara – Hershey – Washington Dc – New York intercity",
  "New York sightseeings",
  "New York Hotel to Airport",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U1;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
