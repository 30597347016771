import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const KASHMIR_TOUR = "/assets/img/domesticTour/kashmir.png";

const VALLEY_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Valley.png";
const SPLENDID_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Splendid.png";
const AMAZING_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Amazing.png";
const EXOTIC_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Exotic.png";
const COLORFUL_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Colorful.png";
const MAJESTIC_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Majestic.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Valley of Kashmir",
    image: VALLEY_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/VelleyOfKashmir",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Majestic Kashmir",
    image: MAJESTIC_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/MajesticKashmir",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "Splendid Kashmir",
    image: SPLENDID_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/SplendidKashmir",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Amazing Kashmir",
    image: AMAZING_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/AmazingKashmir",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Exotic Kashmir",
    image: EXOTIC_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/ExoticKashmir",
  },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "Colorful Kashmir",
    image: COLORFUL_KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/ColorfulKashmir",
  },
];

const rulesData = [
  "Pre-Paid cellular connections do not work in Jammu & Kashmir region. Only Jio, Airtel & Bsnl Post-Paid will work here.",
  "BAISERAN is the best place to visit in Pahalgam though it is not a snow-covered area but the place has its own natural beauty. Either you go by walk or by Horse Riding.",
  "Sonamarg: After Sonamarg, there is a Point called Zero point away from Sonamarg, Thajiwas Glacier and activities like Fish Point (Under Water Balloon Ride). There is only option Union Cab, Charges for Union Cab: 3500/-to 5000/- (Negotiable)",
  "Gondola, you will find Snow and other activities related to snow. Tickets Are fixed as per Govt. fare i.e., Rs. 780 for 1st phase & 920 for 2nd phase",
  "For Kashmiri Wazwan Food: - Aadhoos at Lal Chowk Srinagar is the best place and Mutton Kebab at Gee Enn Bakers - Residency Road.",
];

const KasmirPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Kashmir Tour Packages"}
            subTitle={"Amazing Holidays to Kashmir"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={KASHMIR_TOUR}
            title={"Popular Kashmir Holiday Packages"}
            tourCardContent={tourCardContent}
            ruleTitle={"Jamu & Kashmir Rules"}
            rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default KasmirPackages;
