import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const THEKKADY =
"/assets/img/domesticTour/kerala/thekkady.png";

const heroTextData = [
  {
    title: " Cochin, Alleppey And Kovalam",
    duration: "05 Nights / 06 Day",
    typoOfTrip: "Road Trip",
    startCity: "Cochin ",
    endCity: "Kovalam",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival at Cochin",
    details:
      "Your tour across the scenic Kerala begins at Cochin. Once you arrive at the airport or railway station, our executives will come and take you to your pre-booked hotel. Once you reach the hotel, freshen up and sip a cup of coffee or tea. You can then head out to the nearby destinations for shopping.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Towards the hills, Munnar",
    details:
      "After a sumptuous break, you head out for sightseeing across the city of Cochin. You will be taken through the remnants at Fort Kochi. You will also be taken to Marine drive and the famous museums here. Once you are done sightseeing in Cochin, our executives will drive you to Munnar. The temperature drops down as you near Munnar. Once you arrive here, head inside your cozy hotel room and rest for the night.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Across the scenic hills in Munnar",
    details:
      "One of the principle attractions of Munnar Neelakurinji (Strobilanthes kunthiana) is a bush that used to develop richly in the shola prairies of Western Ghats in India. The Nilgiris, which truly implies the blue mountains, got its name from the purplish blue blooms of Neelakurinji that blooms gregariously once in 12 years. Furthermore, the other fascination in Munnar is The Nilgiri Tahr. The Nilgiri Tahrs are stocky goats. Besides these, in Munnar you will also get the chance to trail through the lush green tea plantations. You can then head back to the hotel for the night",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Towards the wild in Thekkady ",
    details:
      "After a sumptuous breakfast in the hotel, you will be heading to the wild destination in Kerala, Thekkady. At Thekkady, you will be mainly spending time the Periyar Nationa Reserve which is one of the most popular destination. Here, you will get the chance to partake in boating and spot different forms of wildlife. On this day you will be spending the night in a homestay close to the national reserve.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "To the backwater destination, Alleppey",
    details:
      "After breakfast in the wild, today you head to beautiful Alleppey. Once you arrive at Alleppey, you can directly head to the houseboat, where you will be staying for the night. The houseboats are the main attractions of Alleppey, you get the chance to cruise through the backwaters as you leisurely rest in the houseboat.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Back to Cochin ",
    details:
      "your tour comes to an end. After some great breakfast in the houseboat, you checkout and head back to Cochin. Our executives will then drop you to the airport or railway station. With umpteen memories, we part our ways.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const  CochinMunnarThekkadyandAlleppeyTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={THEKKADY}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CochinMunnarThekkadyandAlleppeyTourPackages;
