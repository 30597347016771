import React from "react";
import TourHeroSection from "./TourHeroSection";
import { Grid, Box } from "@mui/material";
import TourCard from "./TourCard";

import PropTypes from "prop-types";

const TourPackages = ({
  title,
  details,
  heroImg,
  mainTitle,
  tourCardContent,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TourHeroSection mainTitle={mainTitle} title={title} details={details} heroImg={heroImg} />
        </Grid>
        <Grid item xs={12}>
          <TourCard  title={title}  tourCardContent={tourCardContent} />
        </Grid>
      </Grid>
    </Box>
  );
};

TourPackages.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  heroImg: PropTypes.string,
  mainTitle: PropTypes.string,
  tourCardContent: PropTypes.object,
};

export default TourPackages;
