import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR8Image } from "../image";

const heroTextData = [
  {
    title:
      "The Vibrant Nordic Capitals of Four Countries and the Norwegian Fjords Combination",
    duration: "11 Nights / 12 Days",
    typoOfTrip: "Road Trip",
    startCity: "Helsinki",
    endCity: "Bergen",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Welcome to Helsinki- The capital of Nordics",
    details:
      "Arrival at the Vantaa Airport and get a Private transfer to your hotel. Enjoy your afternoon at leisure to explore the city’s central squares, charming shops, and the latest Finnish trends. Visit Senate Square, the heart of Helsinki. Designed by famous architect Carl Ludvig Engel, it is home to the city’s most iconic building, the Helsinki Cathedral. Continue your exploration on foot as you make your way through the historic streets of Tori Quarters, where the old city meets the new. We recommend buying the Helsinki Card, which grants free entry to major city attractions and museums as well as unlimited travel on public transportation. We celebrate your arrival and included in your package is a dinner at a local restaurant in downtown Helsinki. Share your memories, photos and happiness as you begin the Finnish experience and fantasy holiday to the Nordics. Enjoy your stay at a centrally located hotel in Helsinki area.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:
      "Helsinki – The city of Carl Ludvig Engel. Afternoon departure via Cruise to the Stockholm",
    details:
      "After your Finnish breakfast, enjoy the city and its offerings. You need to head to a designated point located in the city center from where the 1 hour 45 minutes Panorama Sightseeing Bus tour of the city begins. The Tour Showcasing Helsinki's key attractions, providing a good overview of the city's past and present, passing through the beautiful historical centre, discover its neo-classical buildings, see the iconic Uspenski Cathedral, Sibelius Monument, Rock Church, City Hall, Parliament House, Finlandia Hall and the Opera House, among many other top Helsinki attractions. After lunch, around 1500 head to the Pier from where you will board your cruise to Stockholm departing at 17.30. Onboard you will find a choice of entertainment, shops, casino and bars. Dinner on board. Accommodation in outside cabin (seaside) with shower/WC. Enjoy the buzzing Night club onboard and sleep well on this famous and historic route from Helsinki to Stockholm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:
      "Arrival Stockholm- The city built on 14 Islands. Enjoy the Sky View Visit",
    details:
      "Wake up to the feeling of being in Sweden, the country that has consistently been rated to have one of the best living standards in the world. Enjoy your breakfast with the view of the maritime scenery as the ship navigates through Sweden’s Archipelago. Stockholm, the capital of Sweden, is built on 14 islands connected by 57 bridges on Lake Mälaren, which flows into the Baltic Sea past an archipelago of over 24,000 rocky islands and islets. Besides the sleek designs and world-class nightclubs, this famously sophisticated city is also remarkable for its classic architecture, royal heritage, abundant green spaces and many waterways. To make your transfer smooth, we will book a local taxi from the pier to your centrally located hotel in Stockholm. To see the best of Stockholm, we recommend purchasing a My Stockholm Pass, which gives you free admission to 75 experiences throughout the city, plus free sightseeing tours, discounts at a number of restaurants and shops and much more. We'd suggest you make time to visit the Vasa Ship Museum and City Hall's famous Blue Hall, site of the annual Nobel Prize banquet. Visit the SkyView, (You package includes the ticket) a world-class attraction that takes you to the top of the world’s largest spherical building, Ericsson Globe, a Stockholm landmark. From the apex 130 meters (425 feet) above sea level, you´ll get a fantastic view encompassing all of Stockholm. The two SkyView gondolas departs every 10 minutes, and the entire visit takes about 20 minutes. Restaurant, café and souvenir shop are adjacent to SkyView. Celebrate your arrival in Sweden by enjoying a local meal at downtown Stockholm ( included in your package). They say that Stockholm knows how to party and they party hard. Enjoy the night life of this buzzing capital. Spend the night in Stockholm.",
  },
  {
    id: "Day 4",
    header: "Stockholm Sightseeing",
    details:
      "Wake up to the buzz of this vibrant capital of Sweden. Enjoy your Scandinavian breakfast and head to a scheduled point in city center for your 75-minutes Panoramic Sweden sightseeing. An exclusive non-stop sightseeing tour through all of the parts of the city, passing famous places in Stockholm like the City Hall, Royal Djurgården Island and the Old Town. After the tour take a walk to the city’s charming Old Town with its narrow alleys and well-preserved medieval houses or a guided tour at the Viking mounds in nearby Uppsala. In the evening, take the opportunity to visit Absolut Ice bar where the temperature is -5 degree all year round and everything is made of ice, even your glass! Enjoy the night life and spend the night in Stockholm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header:
      "A memorable train Journey from Vibrant Stockholm to the city of ‘Hygge’, Copenhagen",
    details:
      "Enjoy your breakfast and head to Stockholm central, the busy train station of Stockholm from where you board the train to København H, that is Copenhagen central station. This beautiful train journey crosses through the southern Sweden over to Denmark in a little under 5 hours and 30 minutes. Once you arrive at the capital city of the Happiest country on earth, walk from the station to your hotel or take a short taxi transfer. Copenhagen is the ‘home of hygge’, the concept that is taking the world by storm. By Hygge, the Danes (and Scandinavians) mean the idea of giving one another or to yourself the comfort and coziness through the energy offered around. An example would be sitting by the fire with your partner and romancing the nature when it's snowing, or enjoying a good read with candle lights around you, Or listening to music and cuddling under a blanket or just reading a newspaper and sipping coffee in a cafeteria. Now you know why Denmark is one of the Happiest countries on earth. In the afternoon or evening we recommend you visit Tivoli garden which is an oasis with over four million visitors a year. It has wonderful gardens, amusements, restaurants and rides. You can also visit the Copenhagen Ice bar. To celebrate your arrival in Copenhagen, your package includes a dinner at a local restaurant. Enjoy the nightlife of Copenhagen and have a good night sleep at your centrally located hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Happy Copenhagen and the amazing night cruise to Oslo",
    details:
      "Wake up to this happy feeling of being in the capital of the happiest country on earth. Enjoy your delicious Danish breakfast and head to a scheduled point for your 2hours 30 minutes Panorama Copenhagen sightseeing (Offered only during May – 15 Sep. During other period the famous hop on hop off tour is offered). Enjoy the guided tour of the city. The bus will take you to many of the famous landmarks including: Tivoli, Amalienborg Palace, the Royal Danish Opera with its splendid panoramic view, the Danish Parliament Christiansborg, Islands Brygge and of course the Little Mermaid. Also Enjoy a 30 minutes stop at Amalienborg Castle, experiencing the changing of the Royal guards and photo stops at the Little Mermaid and the Royal Danish Opera. The perfect way to get to know Danish culture while seeing the highlight of Wonderful Copenhagen. After the tour it’s time to head to the DFDS Seaways' terminal for your overnight cruise to Oslo, departing at 16.30 hrs. The boarding starts at 14:30. Enjoy a delicious Scandinavian buffet dinner on board. Take part in the dancing and entertainment on board or just relax and watch the sunset from the deck. Spend the night in an outside cabin with private facilities.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Oslo - the capital of the most peaceful countries on earth",
    details:
      "Wake up to the beautiful surroundings of Oslo fjord. Enjoy your buffet breakfast while you sail slowly to Oslo and arrive around 09.30am. Arrival in the Capital city of one of most Peaceful countries on earth -Oslo. Our representative will meet and greet you and you will get a smooth transfer from the Pier to your centrally located hotel. The Nobel Peace Prize host city has maintained a very good balance between urbanization and eco friendliness. Oslo has been judged as Europe’s green capital city and perhaps the only capital city that has the magnificent Fjord on one side and forested ridges on the other. The city has excellent locations and offers unique photo opportunities. Enjoy the city at your pace. Welcome Dinner at a famous Local restaurant in Oslo (included). After Dinner, enjoy the night life. Oslo has many famous Bars and Night clubs. (Oslo has a cocktail bar that has been ranked among the world’s top 50 best cocktail bars)",
  },
  {
    id: "Day 8",
    header: "Sightseeing Oslo",
    details:
      "Wake up peacefully with the feeling of being in the capital of one of the most peaceful countries on earth. After your Scandinavian breakfast head to a scheduled point for a comprehensive 3-hour panoramic city tour of Oslo. On the tour discover and learn about the city’s history and culture from your knowledgeable guides, admire the main attractions in the city center and spectacular views from Holmenkollen ski jump, and experience a fascinating insight into the art of the most prolific Norwegian sculptor, Gustav Vigeland, as you walk through the unique Sculpture Park comprising more than 200 sculptures in bronze and granite. In the afternoon, you can choose from many of the restaurants located in the city, or can visit the trendy Bogstadveien located in the Majorstuen area for some shopping. Oslo is a beautiful city that overlooks idyllic bays and islands with small summer houses. Enjoy your stay in Oslo and sleep well in your centrally located hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Scenic Bergen Train to the Fjord Village -Flåm",
    details:
      "After your ‘Scandinavian’ Breakfast, you will head to Oslo S, the central station, to board your train to Bergen departing around 08.11 am. Now starts your Journey to the magnificent fjords. The Bergen Railway ride is considered as one of the world’s most scenic train rides. The Journey takes you through Europe’s highest mountain plateaus, Spectacular scenery and varied nature. Upon arrival in Myrdal, you disembark and change trains to the legendary Flåm Railway. The Flåm Railway offers spectacular panoramic views to some of the wildest and most magnificent nature in the Norwegian fjord landscape. Spend the night in Flåm area (In case your accommodation is not available at Flåm due to non-availability, then your accommodation will be booked at the Mountain Village of Vatnahalsen) (We recommend that you pack an overnight bag for your stop in Flåm as we can book luggage transfer from Oslo-Bergen. You will receive your luggage on arrival in Bergen.) Dinner at Mountain Hotel. Accommodation at Flåm /Vatnahalsen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header:
      "Flåm-Gudvangen-Voss-Bergen, it’s the Journey that matters not destination – Travel through Boat-Bus-Train",
    details:
      "After enjoying most of your morning in this mountain paradise, your journey continues from Flåm with a Fjord cruise on the magnificent Aurlandsfjord and on the UNESCO's World Heritage Listed Nærøyfjord. (Alternate mode of transport will be arranged If the weather condition is not suitable for the Fjord cruise) After your fjord cruise you arrive in the small village of Gudvangen, nestled in the innermost part of Nærøyfjorden. From here the journey continues with a bus trip through to the adventure capital, Voss, where you continue the tour by train on the Bergen Railway to Bergen. If you have booked for baggage transfer from Oslo-Bergen, then you will collect your luggage at the station. Take a taxi or walk to your hotel located in the center of this Vibrant city. Overnight in Bergen.",
  },
  {
    id: "Day 11",
    header: "Discover Bergen- Gateway to fjords",
    details:
      "After your ‘Viking Breakfast, enjoy the offerings of the city at your pace. Bergen is the ‘Viking’ City of Norway. The city has many beautiful and historic sites. You will see how the city has preserved the charm of a small town. The recommended attractions that you can visit are at walkable distances. Don’t forget to get a good pair of walking shoes. Included in your package is a ride on the funicular to Mt. Fløien for panoramic views of the city and fjords way below. The attractions you can visit are: Bryggen Wharf- a UNESCO World Heritage site Edvard Grieg’s home Troldhaugen Old Bergen Open Air Museum Vibrant fish market Fragrance full flower market Today being the last day of your wonderful tour. We celebrate your end of holidays but a new relationship with the Nordics by including a farewell dinner at a local restaurant. Enjoy your dinner and sleep well in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Departure day. End of a Journey but beginning of a relationship",
    details:
      "As they say, that the first hello and the last goodbye is the most difficult part. Today is your last day of this trip. Transfer (included) from hotel the Airport for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "9 Nights Quality accommodation in Standard Rooms (As Scandinavia does not have any star standards, Quality can be 3-4 star European Standards)",
  "2 Nights in outside cabins with private facilities during cruise from Helsinki-Stockholm and Copenhagen-Oslo (Upgrade possible. You will receive options from us on other room/Upgrade categories)",
  "Eight Dinners (1 in Helsinki, 1 during Cruise from Hel-Stk, 1 in Stk, 1 in Cph, 1 during DFDS Cruise, 1 in Osl, 1 in Flåm/Mountain Village, 1 in Bergen)",
  "Daily breakfasts",
  "Fjord Cruise through Aurlandsfjord (alternative transport incase weather is not suitable)",
  "Seat in Coach Sightseeing in Copenhagen (Approx 150 min during May till 15 Sep ; Alternatively Hop and hop off tickets, no exact minutes but this is as per your leisure)",
  "Seat in Coach Sightseeing in Oslo (Approx 180 mins)",
  "Tickets of cable car to Mt. Fløien in Bergen.",
  "All private airport transfers",
  "Taxi Transfer from Pier in Stockholm to Hotel on Day 3",
  "Taxi Transfer from DFDS pier in Oslo to hotel on Day 7",
  "All Taxes and Service charge",
  "24/7 helpline",
  "A big smile and life time memories 😊",
  "Bus from Gudvangen-Voss",
  "Train from Voss-Bergen",
  "Seat in Coach Sightseeing in Helsinki (Approx 105 mins)",
  "Seat in Coach Sightseeing in Stockholm (Approx 75 Mins)",
  "Tickets to Ericsson Globe Skyview in Stockholm",
  "Train from Stockholm-Copenhagen",
  "Train from Oslo-Myrdal",
  "Train from Myrdal-Flåm",
];

const exclusionsData = [
"International Airfare",
"Domestic Airfare",
"Local Travel costs (unless specified here)",
"Entrance fees to attractions (unless otherwise stated)",
"Meals and beverages unless those specified here",
"Activities unless specified here",
"Sightseeing/Local Transfers (unless specified)",
"Porter Service",
"Personal Travel Insurance",
"Last minute surprises",
];

const SR8 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR8Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR8;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
