import React from "react";
import { Box, CardMedia, ContaiDUr, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  DU1Image,
  DU2Image,
  DU3Image,
  DU4Image,
  DU5Image,
  DU6Image
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const DUBAI_TOUR = "/assets/img/worldTour/wt23.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Dubai Weekend",
    image: DU2Image,
    description: "",
    path: "/du1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Shopping Fest",
    image: DU1Image,
    description: "",
    path: "/du2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Dubai Special",
    image: DU6Image,
    description: "",
    path: "/du4",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Explore Dubai",
    image: DU5Image,
    description: "",
    path: "/du3",
  },
  {
    id: 5,
    fromToDays: "06 Nights / 07 Days",
    title: "Laxurious Dubai",
    image: DU3Image,
    description: "",
    path: "/du5",
  },
];


// const rulesData = [
//   ""
// ];

const Dubai = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Dubai"}
            subTitle={"Amazing Holidays to Dubai"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the DUeds and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the DUeds and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={DUBAI_TOUR}
            title={"Popular Dubai Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Dubai;
