import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  UB1Image,
  UB2Image,
  UB3Image,
  UB4Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const UZBEKISTAN_TOUR = "/assets/img/worldTour/wt6.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Holiday in Uzbekistan",
    image: UB1Image,
    description:"",
    path: "/ub1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Holiday in Uzbekistan",
    image: UB2Image,
    description:"",
    path: "/ub2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Holiday in Uzbekistan",
    image: UB3Image,
    description:"",
    path: "/ub3",
  },
  {
    id: 4,
    fromToDays: "08 Nights / 09 Days",
    title: "Holiday in Uzbekistan",
    image: UB4Image,
    description:"",
    path: "/ub4",
  },
];

// const rulesData = [
//   ""
// ];

const Uzbekistan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Uzbekistan"}
            subTitle={"Amazing Holidays to Uzbekistan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={UZBEKISTAN_TOUR}
            title={"Popular Uzbekistan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Uzbekistan;










