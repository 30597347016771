export const U1Image = "/assets/img/worldTour/Usa/U1.png";
export const U2Image = "/assets/img/worldTour/Usa/U2.png";
export const U3Image = "/assets/img/worldTour/Usa/U3.png";
export const U4Image = "/assets/img/worldTour/Usa/U4.png";
export const U5Image = "/assets/img/worldTour/Usa/U5.png";
export const U6Image = "/assets/img/worldTour/Usa/U6.png";
export const U7Image = "/assets/img/worldTour/Usa/U7.png";
export const U8Image = "/assets/img/worldTour/Usa/U8.png";
export const U9Image = "/assets/img/worldTour/Usa/U9.png";
export const U10Image = "/assets/img/worldTour/Usa/U10.png";
export const U11Image = "/assets/img/worldTour/Usa/U11.png";
export const U12Image = "/assets/img/worldTour/Usa/U12.png";
export const U13Image = "/assets/img/worldTour/Usa/U13.png";
export const U14Image = "/assets/img/worldTour/Usa/U14.png";
export const U15Image = "/assets/img/worldTour/Usa/U15.png";