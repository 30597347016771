import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const KERALA_TOUR = "/assets/img/domesticTour/Kerala.png";

const COCHIN = "/assets/img/domesticTour/kerala/cochin.png";

const MUNNAR = "/assets/img/domesticTour/kerala/munnar.png";

const THEKKADY = "/assets/img/domesticTour/kerala/thekkady.png";

const KUMARAKOM = "/assets/img/domesticTour/kerala/kumarakom.png";

const KOVALAM = "/assets/img/domesticTour/kerala/kovalam.png";

const KANYAKUMARI = "/assets/img/domesticTour/kerala/KANYAKUMARI.png";

const TRIVANDRUM = "/assets/img/domesticTour/kerala/TRIVANDRUM.png";

const MUNNAR_THEKKADY = "/assets/img/domesticTour/kerala/MUNNAR_THEKKADY.png";

const KOVALAM_TRIVANDRUM = "/assets/img/domesticTour/kerala/KOVALAM_TRIVANDRUM.png";

const  CALICUT_WAYANAD = "/assets/img/domesticTour/kerala/CALICUT_WAYANAD.png";

const  COCHIN_MUNNAR = "/assets/img/domesticTour/kerala/COCHIN_MUNNAR.png";

const  BANGALORE = "/assets/img/domesticTour/kerala/BANGALORE.png";

const  TRIVANDRUM_ALLEPPEY = "/assets/img/domesticTour/kerala/TRIVANDRUM_ALLEPPEY.png";


const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Cochin, Alleppey And Kovalam",
    image: COCHIN,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinAlleppeyAndKovalamTourPackages",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Cochin, Munnar And Alleppey",
    image: MUNNAR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinMunnarAndAlleppeyTourPackages",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Cochin, Munnar, Thekkady and Alleppey",
    image: THEKKADY,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinMunnarThekkadyandAlleppeyTourPackages",
  },
  {
    id: 4,
    fromToDays: "05 Nights / 06 Days",
    title: "Munnar, Thekkady, Kumarakom and Alleppey",
    image: KUMARAKOM,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/MunnarThekkadyKumarakomandAlleppeyTourPackages",
  },
  {
    id: 5,
    fromToDays: "05 Nights / 06 Days",
    title: "Kovalam, Kanyakumari, Rameshwaram and Madurai",
    image: KOVALAM,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/KovalamKanyakumariRameshwaramandMadurai",
  },
  {
    id: 6,
    fromToDays: "07 Nights / 8 Days",
    title: "Cochin, Munnar, Thekkady, Kovalam and Kanyakumari",
    image: KANYAKUMARI,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinMunnarThekkadyKovalamandKanyakumariTourPackages",
  },
  {
    id: 7,
    fromToDays: "07 Nights / 8 Days",
    title: "Cochin, Munnar, Thekkady, Kumarakom, Kovalam and Trivandrum",
    image: TRIVANDRUM,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinMunnarThekkadyKumarakomKovalamandTrivandrumTourPackages",
  },
  {
    id: 8,
    fromToDays: "07 Nights / 8 Days",
    title: " Munnar, Thekkady, Kumarakom, Alleppey, Kovalam and Trivandrum",
    image: MUNNAR_THEKKADY,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackages",
  },
  {
    id: 9,
    fromToDays: "08 Nights / 9 Days",
    title: "Kovalam-Trivandrum-Alleppey-Kumarakom-Periyar-Munnar-Cochin",
    image: KOVALAM_TRIVANDRUM,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackages",
  },
  {
    id: 10,
    fromToDays: "08 Nights / 9 Days",
    title: " Calicut Wayanad Coorg Hassan and Bangalore",
    image: CALICUT_WAYANAD,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CalicutWayanadCoorgHassanandBangaloreTourPackages",
  },
  {
    id: 11,
    fromToDays: "09 Nights / 10 Days",
    title: "Cochin Munnar Thekkady Madurai Rameswaram Kanyakumari Kovalam Trivandrum",
    image: COCHIN_MUNNAR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackages",
  },
  {
    id: 12,
    fromToDays: "09 Nights / 10 Days",
    title: "Bangalore Mysore Coorg Ooty Kodaikanal Madurai",
    image: BANGALORE,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackages",
  },
  {
    id: 13,
    fromToDays: "09 Nights / 10 Days",
    title: "Trivandrum Alleppey Thekkady Kodaikanal Ooty Mysore Bangalore",
    image: TRIVANDRUM_ALLEPPEY,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/TrivandrumAlleppeyThekkadyKodaikanalOotyMysoreBangalore",
  },
];

// const rulesData = [
//   "Pre-Paid cellular connections do not work in Jammu & Kashmir region. Only Jio, Airtel & Bsnl Post-Paid will work here.",
//   "BAISERAN is the best place to visit in Pahalgam though it is not a snow-covered area but the place has its own natural beauty. Either you go by walk or by Horse Riding.",
//   "Sonamarg: After Sonamarg, there is a Point called Zero point away from Sonamarg, Thajiwas Glacier and activities like Fish Point (Under Water Balloon Ride). There is only option Union Cab, Charges for Union Cab: 3500/-to 5000/- (Negotiable)",
//   "Gondola, you will find Snow and other activities related to snow. Tickets Are fixed as per Govt. fare i.e., Rs. 780 for 1st phase & 920 for 2nd phase",
//   "For Kashmiri Wazwan Food: - Aadhoos at Lal Chowk Srinagar is the best place and Mutton Kebab at Gee Enn Bakers - Residency Road.",
// ];

const KeralaPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Kerala Packages Tour Packages"}
            subTitle={"Amazing Holidays to Kerala"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={KERALA_TOUR}
            title={"Popular Kerala Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Kerala"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default KeralaPackages;

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "",
    details:"",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];
