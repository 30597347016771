import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const MUNNAR = "/assets/img/domesticTour/kerala/munnar.png";

const heroTextData = [
  {
    title: " Cochin, Alleppey And Kovalam",
    duration: "04 Nights / 05 Day",
    typoOfTrip: "Road Trip",
    startCity: "Cochin ",
    endCity: "Kovalam",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival at Cochin and all day tour",
    details:
      "Our executive will be waiting for you at the airport. You can check in and freshen up at the hotel before you start the city tour. Start the trip visiting what makes Cochin truly unique. Venture into the Fort Kochi, Mattancherry side of the city, famous for its many Dutch, Portuguese and British palaces that have now been converted into museums. Also visit the Jew Street and the Paradesi Synagogue. Take a walk along the Fort Kochi Beach and watch the fishermen operate the Chinese Fishing Nets.You can now return to the city limits where you can visit the Mangalavanam Bird Sanctuary. It has a beautiful variety of flora and fauna. Next, head to the Kerala Folklore museum where you can learn more about the culture and heritage of God’s Own Country. The Santa Cruz Cathedral Basilica and St Francis Church too, are must-visits, popular for their architecture as well as a serene and spiritual ambience.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Arrival at Munnar and all day tour ",
    details:
      "From Cochin, you will be driven to Munnar next day morning. South India’s popular hill station has a very different experience in store for you. Check in to a hotel and freshen up before you start your adventures in Munnar. After a delicious breakfast, head to Mattuppetty Dam and watch a stunning view of Munnar from there. You can also visit the nearby dairy farm. After that, head to Attukad Waterfalls and Lakkom Waterfalls and enjoy the scenery there. Next, visit the Kundala Lake and spend some peaceful time there. You can also visit the Tata Tea Plantation to watch how the tea leaves are plucked, processed and packed. Complete the experience by taking a walk through the green carpet that the plantation is.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "All day tour at Munnar",
    details:
      "The second day in the hill station is reserved for more fun and adventure. Put your trekking shoes on and head out for any of the misty hills in Munnar, like Meesapulimala, Echo point or Top Station. We assure you that you’ll have a great time trekking watching Munnar’s enchantment unfold its magic in front of you. The view from atop is simply stunning and worth all your effort climbing the hills. You can also visit the Eravikulam National Park and watch the thriving wildlife there.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Arrival at Alleppey and all day tour",
    details:
      "After all the green you have enjoyed for the past two days, now it is time for some blues as well. As you reach Alleppey, you will see backwaters, lakes and beaches aplenty, making for a quaint charm that is hard to resist. Start by taking a houseboat ride in the backwaters of Kuttanad or Vembanad Lake. While you are at it, make sure you also take the tasty Kerala cuisine. Up next, you can head to the beaches in Alleppey for some peaceful-yet-fun time. If you have time, you can also drop by at the temples and churches of Alleppey that are also unique.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure",
    details:
      "While you wrap the trip and leave, you will realize that memories you have created are there to stay with you for a long time and probably going to urge you to make a trip again.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const CochinMunnarAndAlleppeyTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={MUNNAR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CochinMunnarAndAlleppeyTourPackages;
