import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const KOVALAM =
 "/assets/img/domesticTour/kerala/kovalam.png";

const heroTextData = [
  {
    title: "Kovalam Kanyakumari Rameshwaram and Madurai",
    duration: "05 Nights / 06 Day",
    typoOfTrip: "Road Trip",
    startCity: "Trivandrum",
    endCity: "Madurai",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Kovalam",
    details:
     "Once you arrive at Trivandrum, our executives will pick you up from the airport or railway station and drive you to Kovalam, the beach destination favoured by many. Kovalam is a universally prestigious shoreline with three neighboring bow shorelines. It has been a most loved frequent of voyagers since the 1930s. A monstrous rough projection on the shoreline has made a delightful sound of quiet waters perfect for ocean washing.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Along the beaches in Kovalam",
    details:
      "The relaxation alternatives at this shoreline are bounty and various. Sunbathing, ayurvedic spa, and sailboat cruising are some of them. The tropical sun acts so quick that one can see the swoon redden of coppery tan on the skin in merely minutes. Life on the shoreline starts late in the day and continues well into the night. The shoreline complex incorporates a string of spending houses, Ayurvedic wellbeing resorts, tradition offices, shopping zones, pools, Yoga and Ayurvedic knead focuses.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "To the southernmost tip, Kanyakumari",
    details:
     "Kanyakumari, the southernmost tip of India, isn’t only a gathering purpose of three water bodies yet additionally an amalgamation of various societies, religions and thoughts created over hundreds of years. The appeal of this beguiling town in Tamil Nadu draws in crowds of vacationers all the all year. Once you arrive here, spend the day resting by the beach watching the beautiful sunset.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Destination Rameshwaram",
    details:
   "Rameshwaram is situated in the ocean district of “Bay of Mannar Biosphere Reserves” and is generally little as far as territory. Standing solidly amidst India and Sri Lanka with its spotless ocean breezes, less swarmed sanctuaries and holy lakes, this well known “Island City” has been the focal point of fascination for explorers over the world. One of the Char Dhams of India and an eager place for Hindu fans, Rameshwaram is one place that has a lot to be explored. And with our tour package you will get the chance to explore the best of this place.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: " To the temple town of Madurai (3 hour, 35 minutes/ 172.5 km)",
    details:
   "Madurai, the second biggest city in Tamil Nadu and one of the state’s best goals, is over 3,500 years of age and has remained a noteworthy place for Tamil culture and learning. The city is regularly alluded to as the “Athens of the East” on account of its comparable structural style, including numerous rear ways. We will take you to the different temples across the city and then you can head back to the hotel for the night.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure",
    details:
  "On the 6th day, your tour comes to an end. Our executive will drop you to the airport or railway station. With memories umpteen you head back home.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const   KovalamKanyakumariRameshwaramandMadurai = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KOVALAM}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KovalamKanyakumariRameshwaramandMadurai;



















