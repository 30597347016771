import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  EA1Image,
  EA2Image,
  EA3Image,
  EA4Image,
  EA5Image,
  EA6Image,
  EA7Image,
  EA8Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const EASTAFRICA_TOUR = "/assets/img/worldTour/wt13.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Zanzibar",
    image: EA1Image,
    description:"",
    path: "/ea1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Rwanda Gorilla Experience",
    image: EA2Image,
    description:"",
    path: "/ea2",
  },
  {
    id: 3,
    fromToDays: "04 Nights / 05 Days",
    title: "Intro to Kenya",
    image: EA3Image,
    description:"",
    path: "/ea3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Uganda Suggested Itineraryi",
    image: EA4Image,
    description:"",
    path: "/ea4",
  },
  {
    id: 5,
    fromToDays: "05 Nights / 06 Days",
    title: "Kenya at Leisure",
    image: EA5Image,
    description:"",
    path: "/ea5",
  },
  {
    id: 6,
    fromToDays: "05 Nights / 06 Days",
    title: "Kenya Safari",
    image: EA6Image,
    description:"",
    path: "/ea6",
  },
  {
    id: 7,
    fromToDays: "05 Nights / 06 Days",
    title: "Tanzania",
    image: EA7Image,
    description:"",
    path: "/ea7",
  },
  {
    id: 8,
    fromToDays: "11 Nights / 12 Days",
    title: "Kenya and Tanzania",
    image: EA8Image,
    description:"",
    path: "/ea8",
  },
];

// const rulesData = [
//   ""
// ];

const EastAfrica = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"EastAfrica"}
            subTitle={"Amazing Holidays to EastA frica"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={EASTAFRICA_TOUR}
            title={"Popular East Africa Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default EastAfrica;










