import {React} from 'react';
import { Route, Routes } from "react-router-dom";

import viewsRoutes from './views/routes';


const routes = () => {
    return (
        <Routes>
        {
          viewsRoutes.map((item,i) => (
            <Route key={i} path={item.path}  element = {item.element} />
          ))
        }
      </Routes>
    );
  };

  
export default routes;