import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  E1Image,
  E2Image,
  E3Image,
  E4Image,
  E5Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const EGYPT_TOUR = "/assets/img/worldTour/wt3.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Cairo",
    image: E1Image,
    description:"",
    path: "/e1",
  },
  {
    id: 2,
    fromToDays: "06 Nights / 07 Days",
    title: "Cairo + Nile Cruise",
    image: E2Image,
    description:"",
    path: "/e2",
  },
  {
    id: 3,
    fromToDays: "08 Nights / 09 Days",
    title: "Cairo - Nile Cruise (Aswan and Luxor)",
    image: E3Image,
    description:"",
    path: "/e3",
  },
  {
    id: 4,
    fromToDays: "05 Nights / 06 Days",
    title: "Egypt Ancient Treasures",
    image: E4Image,
    description:"",
    path: "/e4",
  },
  {
    id: 5,
    fromToDays: "11 Nights / 12 Days",
    title: "Egypt Secret Pharaohs",
    image: E5Image,
    description:"",
    path: "/e5",
  },
];

// const rulesData = [
//   ""
// ];

const Egypt = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Egypt"}
            subTitle={"Amazing Holidays to Egypt"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={EGYPT_TOUR}
            title={"Popular Egypt Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Egypt;










