import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Main from "../../Components/layouts/Main";
import HeroSection from "./HeroSection";
import ServiceSection from "./ServiceSection";

const AIR_TICKETING = "/assets/img/services/AirTicketing.png";
const RAILWAY_TICKETING = "/assets/img/services/RaliwayTicketing.png";
const HOTELRESERVATION_TICKETING = "/assets/img/services/HotelReservation.png";
const FOREIGNEXCHANGE_TICKETING = "/assets/img/services/ForeignExchange.png";
const CARHIRE_TICKETING = "/assets/img/services/CarHire.png";
const VISA_TICKETING = "/assets/img/services/visa.png";

const serviceCardContentData = [
  {
    serviceTitle: "Air Ticketing",
    image: AIR_TICKETING,
    description:
      "Flying for business work or for a vacation? Our team of expert professionals will surely suggest and book the right flight.",
  },
  {
    serviceTitle: "Railway Ticketing",
    image: RAILWAY_TICKETING,
    description:
      "We are reputed for providing hassle free railway ticket booking services so you can only focus on your journey ahead.",
  },
  {
    serviceTitle: "Hotel Reservation",
    image: HOTELRESERVATION_TICKETING,
    description:
      "We are engaged in providing excellent hotel reservation facilities for a wide range of hotels so you can enjoy the stay.",
  },
  {
    serviceTitle: "Foreign Exchange",
    image: FOREIGNEXCHANGE_TICKETING,
    description:
      "Stuck somewhere with a different country currency. No need to worry, we are a one stop shop for all your forex needs.",
  },
  {
    serviceTitle: "Car Hire For Outstation",
    image: CARHIRE_TICKETING,
    description:
      "We have a large fleet of cars to give on hire for outstation which are luxurious as well as economical for our clients.",
  },
  {
    serviceTitle: "Visa",
    image: VISA_TICKETING,
    description:
      "You will need a valid passport that is valid for at least six months after your planned date of departure.",
  },
];

const Services = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeroSection />
          </Grid>
          <Grid item xs={12}>
            <Container>
              <ServiceSection
                // title={"Our Services"}
                serviceCardContent={serviceCardContentData}
              />
            </Container>
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
};

export default Services;
