import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EU1Image } from "../image";

const heroTextData = [
  {
    title: "Little Europe",
    duration: "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Paris",
    endCity: "Zurich",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Paris",
    details:
      "On arrival in Paris, 'The City of Light', transfer on your own to your hotel. Rest of the day is free at leisure to explore the city on your own. Overnight at your hotel in Paris. Paris, has a timeless familiarity for first-time and frequent visitors, with instantly recognisable architectural icons, along with exquisite cuisine, chic boutiques and priceless artistic treasures. Paris is confident that it’s the world’s most beautiful, fashionable and artistic capital. Visit just once and it’s hard to disagree. Paris can be seen as the most interesting city of Europe and probably even as one of the most amazing city’s worldwide. People from all over the world travel to Paris to discover and experience this fairy-like city. Paris is the city of love, inspiration, art and fashion. The night scene, the Eiffel tower and the warm atmosphere will make you feel directly at home. Paris has a lot of interesting architecture and museums to offer, and is also a Walhalla for shopaholics. A city as Paris is one that everyone should visit and experience.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Paris – City Tour + River Seine Cruise + Eiffel / Montparnasse",
    details:
      "After breakfast at the hotel, depart for a Half day city tour of Paris. This excursion will give you the opportunity to discover Paris, its history and great buildings with a tour of the city followed by a one hour cruise along the Seine river. Enjoy a nice tour of Paris to see the top monuments of Paris: Champs Elysées, Arc de Triumph, Notre Dame de Paris Cathedral, Louvre Museum, Saint Germain des Prés, Invalides... Later enjoy the view from the glass fitted terrace and gangways all round the boat which is perfectly designed to discover and admire the rich architecture of the city. Later you will visit either Eiffel Tower or Montparnasse Tower (Any one); to admire the superb panoramic views over Paris. Overnight at your hotel in Paris.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Paris – Day Free",
    details:
      "Have breakfast at the hotel. Rest of the day is free at leisure to explore the city and opt from various optional activities like visiting Euro Disney, Palace of Versailles or Louvre Museum, etc. Overnight at your hotel in Paris.",
  },
  {
    id: "Day 4",
    header: "Paris – Interlaken",
    details:
      "Buffet breakfast at the hotel. Check out of your hotel and transfer to the station on your own to board your super fast TGV to Basel. At Basel, board your connecting train to Interlaken, the gateway to the Top of Europe. Interlaken is known for its remarkable location between the two beautiful lakes of Thun and Brienz. Day at leisure to explore Interlaken’s Hoheweg- an inviting 700 meter long boulevard with hotels, cosy cafes, fashion boutiques, watch and jewellery stores. Overnight is at your hotel in Interlaken. Interlaken – A premier holiday destination, situated in the heart of the Bernese Oberland, is the ideal starting point for any number of excursions. The town is famous for its delightful location and the sites of natural beauty that surround it. It’s not unusual for a town to be on a river, lake or an ocean. But for it to sit between two lakes may well be unique. Interlaken - between Lake Thun and Lake Brienz - can boast such a privileged position. Straddling the glittering Lakes Thun and Brienz and dazzled by the pearly whites of Eiger, Mönch and Jungfrau, the scenery here is mind-blowing. Particularly, some say, if you’re abseiling waterfalls, thrashing white water or gliding soundlessly above 4000m peaks, its Interlaken at its best!! Once Interlaken made the Victorians swoon with mountain vistas from the chandelier-lit confines of grand hotels; today it makes daredevils scream with adrenalin-loaded adventures.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Interlaken – Mt. Jungfraujoch",
    details:
      "Buffet breakfast at the hotel. Depart on an excursion from Interlaken to Jungfraujoch- Top of Europe. This memorable train trip from Interlaken Ost takes you via Lauterbrunnen and Wengen to the Kleine Scheidegg, at the foot of the famous Eiger North Wall. Then ascend by the Jungfrau Railway to the magnificent mountain world of eternal ice and snow, Jungfraujoch-at 3454mts, the highest railway station in Europe. Visit the Ice Palace, where you see a beautiful display of sculptures made of ice and Sphinx Terraces offering breathtaking views of the Aletsch Glacier, the longest glacier in the Alps. Overnight is at your hotel in Interlaken.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Interlaken – Lucerne",
    details:
      "Buffet breakfast at the hotel. Check out and transfer to the station on your own to board your train to Lucerne. On arrival, day at leisure to explore the city on your own. Visit the Chapel Bridge, The Lion Monument, Swiss Transport Museum, the IMAX Theatre, Old City Squares or take a boat trip on Lake Lucerne. Overnight is at your hotel in Lucerne. Lucerne, the gateway to central Switzerland, sited on Lake Lucerne, is embedded within an impressive mountainous panorama. Thanks to its attractions, its souvenir and watch shops, the beautiful lakeside setting and the nearby excursion mountains of the Rigi, Pilatus and Stanserhorn, the town is a destination for many travel groups and individuals on their journey through central Switzerland.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Lucerne – Mt. Titlis",
    details:
      "Buffet breakfast at the hotel. A memorable experience awaits you as you depart on an excursion to the glacier paradise of Mount Titlis. Your journey today begins with a train ride from Lucerne station to Engelberg station. Walk to the Titlis base point and enjoy 3 different cable car rides up to the beautiful summit of Mt. Titlis. Ride up the world’s first ever 360 degree revolving ROTAIR aerial gondola to the Mt. Titlis Glacier Paradise at 10,000 ft. for unlimited attractions. You have free time to play and enjoy in the snow. Visit the Ice Flyer, an enclosed sky lift that travels few meters over the Titlis glacier. Overnight is at your hotel in Lucerne.",
  },
  {
    id: "Day 8",
    header: "Lucerne – Zurich",
    details:
      "Buffet breakfast at the hotel. Check out of your hotel and transfer on your own to the station to board your train to Zurich. In the afternoon enjoy city tour of Zurich. You will visit the business and financial centre, Bahnhofstrasse, the most important museums, a section of the Old Town, Fraumunster Church with its famous Chagall windows, historical Limmatquai, the University district, residential areas, beautiful hillside villas on the Zurichberg, as well as recreation areas and views of the Alps from Zurich's vantage points. Overnight is at your hotel in Zurich. Zurich is the largest city of the Swiss Confederation (Switzerland) by land area and population. It is the financial centre of Switzerland and houses the stock exchange and the headquarters of a large number of national and international companies, and also home of FIFA's headquarter. As a «metropolis of experiences» by the water, with a magnificent view of the snowcapped Alps on the horizon, Zurich offers a unique mixture of attractions – over 50 museums and more than 100 art galleries, international fashion labels and Zurich designs, and the most flamboyant and lively nightlife in Switzerland. Recreational activities range from a visit to the riverside and lakeside bathing areas in the very heart of the city, to a spectacular hike on the Uetliberg mountain.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Depart Zurich",
    details:
      "Breakfast at the hotel. Check – out transfer to the airport on your own for your flight to onwards destination or back to India with memories which will last a life time. * The above itinerary is tentative and subjected to change without prior notice",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on Bed & Breakfast Basis: (Daily breakfast except on the day of arrival)",
  "Paris City Tour and Cruise by Coach",
  "Entry Tickets to Eiffel Tower / Montparnasse Tower, without transfers (Any one. Eiffel Tower tickets are subject to availability. Incase of Non – Availability; Montparnasse Tower will be included",
  "Mt. Jungfraujoch (In Conjunction with Swiss Pass)",
  "Mt. Titlis (In Conjunction with Swiss Pass)",
  "The Zurich TROLLEY Experience - Zurich City Tour",
  "Swiss Travel Pass (Regular) for 8 days in Second Class",
  "Second Class train ticket: Paris to Basel",
];

const exclusionsData = [
  "Airfare, Airport Taxes, Porterage",
  "Visas & Overseas Travel Insurance",
  "Meals other than mentioned in the itinerary",
  "High Season, Trade Fair & Special Event surcharge",
  "Anything not specifically mentioned in the Price Includes section.",
];

const EU1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EU1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EU1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
