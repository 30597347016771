import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O2Image} from "../image";

const heroTextData = [
  {
    title: "Odisha Golden Triangle-i",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Bhubaneswar ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:  "Excursion trip to Chilika(Satapada)&Konark",
    details:
      "Post breakfast excursion trip to Satapada (One end of Chilika Lagoon) (55km/1.25hrs one way). Enjoy the motor boat ride in the largest brakish water lagoon of Asia and explore Sea mouth, Rajhans Island (drive pass), rare Irrawady dolphins, local birds etc. Later proceed to Konark (100km/2hrs). Enjoy the marine drive through Chandrabhaga beach and Ramchandi temple. On arrival visit the Sun temple & interpretation centre at Konark (the UNESCO World heritage monument). You may witness the light & sound show at Konark(subject to weather condition and close on Monday). Back to Puri. O/N- Puri. Note: 1. Konark Sun temple remain open from 08.00 to 18.00hrs. Light & sound show time: 18.30 to19.15hrs (Winter) & 19.00 to 19.40hrs (summer) and operate subject to weather condition and minimum 25-pax. The show remain close on every Monday.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Puri-Bhubaneswar (70 km/1.5 hrs)",
    details:
      "After breakfast proceed to Bhubaneswar. En-route visit through Dhauli Buddhist shanti stupa. On arrival visit the ancient temples like Lingaraj-the biggest temple in Bhubaneswar, Mukteswar, Rajarani (no deity inside the temple) & 64 Yogini temple (Yogini sculpture having 64 different hair style & one among four Yogini temple in India). Later visit to Khandagiri & Udaigiri Jain caves and Jain temple, Tribal Museum OR Kala Bhoomi-a craft museum (both are close on Monday & govt. holidays). Rest of the day you may go to the local market for shopping. O/N-Bhubaneswar. Note:1.Lingaraj temple remain open from 05.00 to 21.00hrs and close for 01hr between 11.00 to 13.00hrs. Devotee can go in to the garbhagriha (sanctum sanctorum) between 06.00 to 08.00hrs & 13.00 to 16.00hrs. 2. Non-Hindu are not allowed to Lingaraj temple. They can see from the specific platform. 3. Be aware of Monkeys at Khandagiri & Udaigiri. Keep your wallet & bag carefully. Udaigiri remain open from 08.00hrs to 17.00hrs. ",
      
  },
  {
    id: "Day 4",
    header: " Departure from Bhubaneswar ",
    details:
      "Post breakfast, if time permits visit to Khandagiri & Udayagiri Jain Caves, Tribal Museum & Kala Bhoomi-a craft museum (both close on Monday & govt. holidays). In-time transfer to airport/railway station for onwards journey. Tour End. Note: Be aware of Monkeys at Khandagiri & Udaigiri. Keep your wallet & bags carefully. Udaigiriremain open from 08.00hrs to 17.00hrs.",
      
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O2;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

