import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  T1Image,
  T2Image,
  T3Image,
  T4Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const TURKEY_TOUR = "/assets/img/worldTour/wt12.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "05 Nights / 06 Days",
    title: "Turkey 5 Nights with 3 Nights in Istanbul + 2 Nights in Cappadocia",
    image: T1Image,
    description:"",
    path: "/t1",
  },
  {
    id: 2,
    fromToDays: "06 Nights / 07 Days",
    title: "Turkey 6 nights with 2N Istanbul + 2N Cappadocia + 2N Kusadasi",
    image: T2Image,
    description:"",
    path: "/t2",
  },
  {
    id: 3,
    fromToDays: "07 Nights / 08 Days",
    title: "Turkey 7 nights with 3N Istanbul + 2N Cappadocia + 2N Antalya",
    image: T3Image,
    description:"",
    path: "/t3",
  },
  {
    id: 4,
    fromToDays: "09 Nights / 10 Days",
    title: "Turkey 9 nights with 3N Istanbul + 3N Cappadocia + 3N Kusadasi",
    image: T4Image,
    description:"",
    path: "/t4",
  },
];

// const rulesData = [
//   ""
// ];

const Turkey = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Turkey"}
            subTitle={"Amazing Holidays to Turkey"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={TURKEY_TOUR}
            title={"Popular Turkey Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Turkey;










