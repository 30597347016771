import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  G1Image,
  G2Image,
  G3Image,
  G4Image,
  G5Image,
} from "./image";


const GUJARAT__TOUR = "/assets/img/domesticTour/Gujarat.png";

const tourCardContent = [
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Dwarka & Somnath Tour",
    image: G4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/g4",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "Divine Shakti Trails",
    image: G2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/g2",
  },
  // {
  //   id: 8,
  //   fromToDays: "02 Nights / 03 Days",
  //   title: "Statue Of Unity – Option 1",
  //   image: G8Image,
  //   description:
  //     "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
  //   path: "/g7",
  // },
  // {
  //   id: 10,
  //   fromToDays: "02 Nights / 03 Days",
  //   title: "Statue Of Unity – Option 3",
  //   image: G10Image,
  //   description:
  //     "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
  //   path: "/g9",
  // },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "Panch Dwarka Tour",
    image: G5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/g5",
  },
  {
    id: 3,
    fromToDays: "07 Nights / 08 Days",
    title: "Temple Tour of Gujarat",
    image: G3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/g3",
  },
  {
    id: 1,
    fromToDays: "010 Nights / 11 Days",
    title: "Highlights of Gujarat",
    image: G1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/g1",
  },
];

// const rulesData = [
//   "Check-In time is 1400 hrs & Check-out time 1200 NOON at all hotels.",
//   "Sasangir National Park will be closed from 15th June to 15th October every year.",
//   "100% bookings for Sasangir safaris are done online through the website www. https://girlion.gujarat.gov.in/ . We would not be able to help if the safari is not booked in advance. Guests’ ID proof is required for safari bookings.",
//   "In the event on any amendment in local Government taxes, or any fuel hike, we reserve the right to adjust the tour price accordingly. ",
//   "Above rates are Nett non commissionable. ",
//   "Kindly note, the names of hotels mentioned above only indicate that our rates have been based on usage of these hotels. It is not to be construed that accommodation is confirmed at these hotels until and unless we convey the confirmations to you on receipt of your acceptance. However, in the event of any of the above-mentioned hotels not becoming available at the time of initiating the reservations, we shall book alternate accommodation at a similar or next best available hotel and shall pass on the difference of rates (supplement/reduction whatever applicable) to you.",
//   "Also, kindly note – Do not treat quotation mail as confirmation of booking, as we are not holding any booking at the time of quotation, price and services can be changed at the time of confirmation. Confirmation of bookings is only based on written communications.",
//   "The above rates are not valid during Janmashtami, Diwali, Christmas, New Year and Holi period",  
// ];

const GujaratYatra = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Gujarat"}
            subTitle={"Amazing Holidays to Gujarat"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={GUJARAT__TOUR}
            title={"Popular Gujarat Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Gujarat"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default GujaratYatra;










