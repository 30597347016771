import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AG3Image } from "../image";

const heroTextData = [
  {
    title: "Baku Tour",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Baku",
    endCity: "Baku",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival to Baku",
    details:
      "Arrival to Baku Meeting at airport and transfer Lunch in Indian restaurant Check-in to hotel Baku Boulevard Walk (entrances: Little Venice, Baku Ferris Wheel) Baku Ferris Wheel, also known as the Baku Eye. The wheel is 60 meters (200 ft) high and has 30 cabins, each is holding eight people. It makes a complete turn in 30 to 40 minutes. Boat trip in Venice: Little Venice in Baku Boulevard is a small town built in 1960. Mountain Park and Fountain Square Tour Dinner in Indian restaurant Transfer to Hotel or desired place of drop Overnight in the hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Absheron peninsula",
    details:
      "Breakfast in the hotel We leave for a tour of the Absheron peninsula. Visit of Indian Fire Temple Visit of Burning Mountain Lunch in Indian restaurant Baku Old City Tour (IcheriSheher), the largest capital in the Transcaucasian region. Transfer to Hotel. Free time Dinner in Indian Restaurant Transfer to Hotel or desired place of drop",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Gobustan Rock Arts Museum Tour",
    details:
      "Breakfast in the Hotel Start of Gobustan Rock Arts Museum Tour Located west of the settlement of Gobustan, about 40 miles (64 km) southwest of the centre of Baku on the west bank of the Caspian Sea. In 2007 Gobustan was declared a UNESCO World Heritage Site considered to be of outstanding universal value for the quality and density of its rock art engravings, for the substantial evidence the collection of rock art images presents for hunting, fauna, flora and lifestyles in pre-historic times and for the cultural continuity between prehistoric and medieval times that the site reflects. Lunch in Indian restaurant Shopping tour to Ganjlik Mall or Souvenirs Transfer to Hotel. Free time Dinner in Indian Restaurant Transfer to Hotel or desired place of drop.",
  },
  {
    id: "Day 4",
    header: "Arrival to Gabala.",
    details:
      "Transfer to Gabala (3 hrs drive from Baku) Arrival to Gabala. Cable Car Ride Lunch in Indian restaurant Visit to Nokhur Lake and Gabalaland Return to Baku Dinner in Indian Restaurant Transfer to Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Heydar Aliyev Center",
    details:
      "Breakfast in the hotel Heydar Aliyev Center. Vintage Car Collection Lunch in Indian restaurant Transfer to Hotel. Free time Dinner in Bollywood Club Transfer to Hotel or desired place of drop",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure",
    details:
      "Free time until transfer Check-out from Hotel Transfer to Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"5 nights accommodation with daily breakfast",
"Transfers and transport on Private basis",
"Guide service",
"Entrance Fees (Indian Fire Temple, Burning Mountain, Gobustan Rock Art Museum)",
"Meals in Indian restaurants (2+1 starter & main course menu pattern)- 6 meals",
"Water for all days (2 x 0.5 lt. bottled water per person)",
];

const exclusionsData = [
  ""
];

const AG3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AG3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AG3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
