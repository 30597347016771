import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const  TRIVANDRUM_ALLEPPEY = "/assets/img/domesticTour/kerala/TRIVANDRUM_ALLEPPEY.png";

const heroTextData = [
  {
    title: "Trivandrum Alleppey Thekkady Kodaikanal Ooty Mysore Bangalore",
    duration: "09 Nights / 10 Day",
    typoOfTrip: "Road Trip",
    startCity: "Trivandrum",
    endCity: "Bangalore",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Across the Capital in Trivandrum",
    details:"Upon landing in the Trivandrum Airport our delegate will warmly invite you and take you to Kovalam; on entry register into lodging. Kovalam is one of the prime shoreline goals in India. Appreciate the night viewing an astounding nightfall or swimming at the beautiful Kovalam Beach.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Across Trivandrum",
    details:"Continue for the touring voyage through Trivandrum after breakfast-Thiruvananthapuram, recently called Trivandrum, is an antiquated city with custom going back to 1000 BC. This capital city of Kerala is a wonderful ocean side city based on seven slopes. It is situated on the west shoreline of India spreading over a sum of 2,192 sq.km. Touring visit is far reaching with the visits to Padmanabhaswami Temple, Napier Museum, Srichitra Art Gallery and Zoo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Destination Alleppey",
    details:"After breakfast and drive to Alleppey; on landing register into a houseboat (registration time 1.00 pm). Alleppey, known as the ‘Venice of the East’, is a locale of monstrous normal magnificence, with a tremendous system of lakes, tidal ponds, channels and freshwater streams confounding its landscape. The locale, flanking the Arabian Sea in the west, likewise appreciates a one of a kind spot in the oceanic history of Kerala. Today, this backwater goal is acclaimed for its pontoon races, shorelines, marine items and coir industry. An exceptional vacation spot in Alleppey is the “Kuttanad” locale – known as the rice bowl of Kerala. Kuttanad is a place that is known for rich paddy fields and is one of only a handful couple of spots on the planet where cultivating is done underneath ocean level. Appreciate an entire day journey along the grand backwaters of Vembanad Lake.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Destination Thekkad",
    details:"After an on-board breakfast, drive to Thekkady. Evening, continue for a nearby touring voyage through Thekkady. The Periyar Wildlife Sanctuary, spread more than 777 sq. km. counting 360 sq. km of thick evergreen timberland tract, is a standout amongst the most captivating natural life holds in Kerala. Pronounced a Tiger Reserve in 1978, the haven is noted for its geomorphology, wealth of natural life and proliferating picturesque magnificence. Discretionary exercises including – Boating at Periyar Lake, Bamboo Rafting, Spice Plantation visit, Trekking and Elephant Ride, can be organized at an additional expense.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Destination Kodaikanal",
    details:"Withdraw for Kodaikanal, after a filling breakfast at the lodging; on entry registrater into inn. Evening you will be taken for a touring voyage through Kodaikanal. Kodaikanal, at a separation of 120 km from Madurai, lies on the southern hill of the Palani Hills. The town is based on the star molded Kodai Lake spread over a zone of sixty sections of land. Kodaikanal has thickly lush slants, well spread out walk ways through beautiful scenes, exquisite cascades and soak rough drops. Spots of traveler intrigue incorporate the Lake, Bryant Park, Coaker’s Walk, Solar Physical Observatory, Kurunji Andavar Temple, Pillar Rocks, Green Valley View, and Shembaganur Natural History Museum.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Destination Ooty",
    details:"After a reviving breakfast drive to Ooty; on entry register into inn. Rest of the day is available to you to appreciate at relaxation.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Across Oot",
    details:"After breakfast continue for a neighbourhood touring voyage through Ooty. The lovely hill station of Ooty is situated on the Blue Mountains (Nilgiris) at an elevation of 2240 m above MSL. Ooty, known as the ‘Ruler of Hill Stations’, was at one time a most loved summer retreat for the Govt. of Madras. The scene here is specked with tremendous territories of tea gardens, thick backwoods, rambling meadows and grandiose mountains. Spots of visitor enthusiasm here incorporate the Botanical Gardens, Centenary Rose Park, Rose Garden, Lake, Government Museum, Deer Park and Doddabetta.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Destination Mysore",
    details:"Following breakfast, drive to Mysore; on landing registrater into inn. Evening, set out on a touring voyage through Mysore. Mysore, a quiet and tranquil city, lies at a separation of 139 km from Bangalore. The city was the royal capital of the past Mysore kingdom administered by the Wodeyar administration. Spots of traveler intrigue incorporate the stupendous Chamarajendra Art Gallery, Mysore Zoo Gardens, Mysore Palace, St. Philomena’s Church, Temples, Brindavan Gardens, Chamundi Hills, and Bylakuppa. Medium-term at the inn.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Destination Bangalore",
    details:"Withdraw by street to Bangalore, after a rich breakfast at the lodging; on landing register into inn. Evening, continue for a nearby touring voyage through Bangalore City. Bangalore, the capital of Karnataka, is referred to famously as the city of Gardens. The city is today the Silicon Valley of India separated from being the main managerial, business and modern center point of Karnataka State. It lies at a height of 920 m above MSL. The present day Bangalore City was established by Kempe Gowda, a chieftain under the Vijayanagara Empire, in 1537 A.D. With the help from ruler Achutaraya, he fabricated a mud post here and built up petite towns inside the fortification. Spots of traveler enthusiasm here incorporate the Lalbagh Gardens, Cubban Park and Museum, The Govt. Exhibition hall, Vidhana Soudha, Visvesvaraya Industrial and Technological Museum, Tippu’s Palace, The Fort, Bull Temple, Cariappa Memorial Park, Venkatappa Art Gallery.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Departure",
    details:"Your tour comes to an end today. After breakfast, our executives will pick you up and drop you to the airport or railway station.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];



const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const TrivandrumAlleppeyhekkadyKodaikanalOotyMysoreBangaloreTourPackages =
  () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Main>
          <TourDetails
            heroImg={TRIVANDRUM_ALLEPPEY}
            heroTextData={heroTextData}
            overview={overview}
            intineraryTimelineData={intineraryTimelineData}
            inclusionsData={inclusionData}
            exclusionsData={exclusionsData}
          />
        </Main>
      </Box>
    );
  };

export default TrivandrumAlleppeyhekkadyKodaikanalOotyMysoreBangaloreTourPackages;
