import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U9Image } from "../image";

const heroTextData = [
  {
    title: "Splendid USA",
    duration: "19 Nights / 20 Days",
    typoOfTrip: "Road Trip",
    startCity: "New York",
    endCity: "San Francisco",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive in New York",
    details: "Welcome to the Big Apple, the City That Never Sleeps. On arrival you will be transferred to the Hotel. The day is free at leisure to explore the city on your own. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "New York City Tour",
    details: "Breakfast at hotel and proceed for New York City tour with entrance to Empire State Building & Statue of Liberty Ferry Ride on Seat in Coach Basis. Highlights of the tour: Statue of Liberty express One hour, Non-stop Cruise (Ferry Ticket Only), Empire State Building’s 86th floor Observatory (Admission Included), Rockefeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, etc. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "New York - Washington",
    details: "Check out from hotel and we drop you to coach station for onward journey to Washington DC, capital of the United States of America and a Guide to the Historic Neighborhoods and Monuments. Evening proceed for Night tour of Washington on Seat in coach basis. When the sun goes down, Washington turns into a different city, and Old Town Trolley Tours knows just the right way to show off the Capitol City at night! See the Capitol Building, the White House, the Jefferson Memorial and the Washington Monument. The trolley will also make stops at the Franklin D. Roosevelt Memorial, the Lincoln Memorial, the Vietnam Veteran's Memorial, the Korean War Veteran's Memorial and the Iwo Jima Memorial. Enjoy of the most emblematic monuments of the city, crossing them in the light of the moon. ",
  },
  {
    id: "Day 4",
    header: "Washington - Buffalo – USA side Niagara Falls",
    details: "Take a flight to buffalo and upon arrival we transfer you to Niagara where the world famous falls is located. It is one of the natural wonders of the World. Rest of the day is Free at leisure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "USA side Niagara Falls",
    details: "On your own proceed to experience the famous boat ride “Maid of the mist” which takes us close to the thundering falls and around the American and Horseshoe Falls (seasonal). Overnight stay at Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Buffalo - Atlanta",
    details: "Fly to Atlanta. As the New Capital of the South, with fine dining, museums, theater, attractions and world-class shopping, Atlanta has something for everyone. Spend some time in shopping in one of the numerous malls or head to downtown for an evening out.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Atlanta City Tour",
    details: "Enjoy today’s city tour and turn your sights to landmarks that tell the story of Atlanta’s history and growth into one of the South’s most progressive cities. You will visit Wold of Coca Cola – a tribute to the famous drink, take a CNN News Studio Tour, visit the worlds largest Acquarium.",
  },
  {
    id: "Day 8",
    header: "Atlanta - Orlando",
    details: "Today we depart to Orlando by flight. Orlando is the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. This city is said to welcome more than 52 million tourists every year. Upon arrival transfer to your hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Orlando – Walt Disney- Magic Kingdom",
    details: "Breakfast at the Hotel. Proceed to Magic Kingdom. This covers 107 acres, opened on October 1, 1971. It’s been dubbed The Most Magical Place on Earth! and is home to seven themed lands, and dozens of attractions that appeal to all ages. It is the place where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Visit Magic Kingdom make your childhood fantasies come true as you step into fairytale land of themed rides, shows and parades inspired by Disney’s Stories. Hop on to the happiest land, the Magic Kingdom, where the legend of Mickey Mouse begins. The magic will bring you the classical vehicles Western Carriage, Pirates` Treasure Island and Cartoon stars. Overnight in Orlando",
  },
  {
    id: "Day 10",
    header: "Orlando – Epcot Center",
    details: "Begin your day at the Epcot center where you can touch the future and travel the world with an amazing array of attractions and live performances. This is an area of 260 acres devoted to past achievements and the future of technology. World showcase offers a kind of permanent World Fair, with pavilions devoted to eleven countries.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Orlando - Sea World",
    details: "Today you will visit the world's largest Marine Park Sea World - Here you shall discover many attractions. We dare you to take a one-of-a kind water coaster thrill ride through the mysterious lost city of Atlantis. Soar through danger on a jet copter ride to the Wild Arctic. At Sea World, touch, feed or get face to face with awesome and amazing wild animals, and get in on all the action that draws you back again and again!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Orlando - Chicago",
    details: "Today we fly to Chicago, often called The Windy City and The City of Broad Shoulders. Today the city's attractions bring 44.2 million visitors annually. Chicago is the largest city by population in the state of Illinois and the American Midwest. It is a dominant center of finance, industry and culture in the region. The city, for much of its history, has been known informally as America's Second City. Upon arrival, transfer to the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 13",
    header: "Chicago City Tour",
    details: "After Breakfast, we proceed for the city tour that includes Sears Tower, Millennium Park, Whacker South, Lake Shore Drive, Navy Pier, Magnificent Mile, Downtown, Art Institute, River North and much more.",
  },
  {
    id: "Day 14",
    header: "Chicago – Las Vegas",
    details: "Depart in the morning for your flight to Las Vegas. On arrival, transfer to your hotel. The Night Tour takes you all the way along the Strip to the famous Las Vegas welcome sign, before heading back to Down Town where you'll have the change to enjoy the Fremont Street Experience .As you travel past Las Vegas' illuminated landmarks, The experienced guides on tour will tell the story of Las Vegas and will highlight the city's most impressive views. The Night Tour lasts approximately 3 hours including time at Fremont Street. Return back to the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 15",
    header: "Grand Canyon (Optional)",
    details: "Day is free to explore to take an optional tour to Grand Canyon. There are multiple options to explore the Canyon by Bus, Helicopter, and Airplane and can add Sky Walk & Boat ride. Evening you can even enjoy a Helicopter Ride over dazzling night time Vegas, visit any of musical shows. Overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 16",
    header: "Las Vegas – Los Angeles",
    details: "Enjoy breakfast at the hotel and then we drop you to coach station for onward journey to Los Angeles. Upon arrival get transferred to Hotel. Evening proceed for Los Angeles city tour driving you through the “Entertainment capital of the world”, Hollywood, Hollywood Walk of Fame, Beverly Hills, Grumman Chinese Theater, Sunset strip, Rodeo Drive for the world’ most expensive shopping and China town for some good bargains.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 17",
    header: "Los Angeles- Universal Studios",
    details: "Join us today for a tour of Universal Studios which has always been known for its innovative rides and attractions, and is designed to let visitors experience the movies like never before. A trip to Universal is like a trip into the heart of Hollywood. With films and TV shows always in production, one never knows who they might see wandering the sidewalks of the park.",
  },
  {
    id: "Day 18",
    header: "Los Angeles - San Francisco",
    details: "Fly to San Francisco, which is one of the few North American cities to achieve the legendary status of such places as Paris or London. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 19",
    header: "San Francisco City Tour",
    details: "Today we proceed on a sightseeing tour of San Francisco. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
  },
  {
    id: "Day 20",
    header: "Departure from San Francisco",
    details: "After breakfast, check out from hotel and we transfer you to airport for onward journey. End of tour and services.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];



const inclusionData = [
"19 Night’s Accommodation with breakfast as per mentioned or similar( No Breakfast In Vegas )",
"All Airport – Hotel – Airport Transfers on Seat in Coach in Orlando and Niagara on Private",
"City Tour’s : New York, Washington DC, Atlanta, Chicago, San Francisco, Las Vegas & Los Angeles( Hop on Hop off in Los Angeles and Vegas Big bus )",
"New York to Washington Coach , Orlando to Miami Coach , Las Vegas to Los Angeles Coach",
"Private transfer from New York Hotel to Bus Station , Washington Bus Station to Hotel , Miami Bus Station to Hotel , Las Vegas Hotel to Bus Station and Los Angeles Bus Station to Hotel",
"Entrance Tickets with Transfers : Statue of Liberty, Empire State Building, Maid of the Mist, Sea World, Epcot Center, Magic Kingdom.( In Orlando Transfers to Sightseeing via Hotel Shuttle )",
"All Taxes",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U9Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U9;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
