import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KT6Image } from "../image";

const heroTextData = [
  {
    title:  "Bangalore / Ooty / Mysore / Bangalore",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bangalore",
    endCity: "Bangalore",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Bangalore + Drive to Ooty (310 Kms / 7 – 8 Hrs)",
    details: "On arrival at Bangalore, meet our chauffeur waiting for you and proceed towards Ooty. Arrive Ooty in the evening and check into the hotel. Later enjoy sightseeing of Ooty visiting Botanical garden, market place. Evening free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day Excursion to Coonoor",
    details: "After breakfast, enjoy a day trip to Coonoor. In Coonoor enjoy visit to Sim’s park, Lamb’s rock and Dolphin’s nose. Return to the hotel later in the evening. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Ooty – Mysore (170 Kms / 3 – 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Mysore. Arrive Mysore in the noon and check into the hotel. Later proceed for sightseeing of Mysore visiting Maharaja’s Palace. Later in the evening proceed to KRS Dam and Brindavan Gardens. One can also enjoy Sound & Light show in the Gardens. Evening free at leisure. Overnight in the hotel.",
  },
  {
    id: "Day 4",
    header: "Drive Mysore – Bangalore (140 Kms / 3 – 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Bangalore. Arrive Bangalore in the noon and check into the hotel. Later proceed for sightseeing of Bangalore City visiting Vidhan Soudha, Lalbagh Botanical garden, Tipu Sultan’s palace and Bull temple – one of the oldest temple built in 16th century. Rest of the time free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Depart Bangalore",
    details: "After breakfast, time free until noon. Later check out of the hotel and proceed to the airport in time for your flight to your onward destination.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const KT6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KT6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KT6;



