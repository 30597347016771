import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const RAJASTHAN_HEAVEN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Rajasthan_Heaven.png";

const heroTextData = [
  {
    title: "Rajasthan Heaven",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Udaipur ",
    endCity: "Udaipur ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Udaipur Arrival-Local",
    details:
      "Welcome and Arrive Udaipur Airport / Railway Station and meet our local representative who will transfer to the hotel, proceed for Udaipur Sightseeing 'the city of lakes' is admired for its fairy-tale palaces, lakes, temples, and gardens among many more. Visit Sajjangarh (Monsoon Palace), Shilpgram and Bhatiya Lok Kala Mandal. Overnight stay at Udaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Udaipur",
    details:
      "Breakfast at hotel. Morning city sightseeing tour. Built in 1559 A.D. by Maharaja Udai Singh, the city has been described as the most romantic spot on the continent of India by Col. James Todd. The places of interest include City Palace which has several carved balconies, arches and decorated pillars, Zenana Mahal, Sahelion-ki-Bari (queen's resort for the friends), Enjoy Boat Ride at Lake Pichola (Own) Visit to Fateh Prakash, Moti Magari, Durbar Hall and Shambhu Niwas. Also visit Jagdish temple. Overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Udaipur - Mount Abu ",
    details:
      "After Breakfast, Check out from hotel. Proceed to visit Dilwara Temple, Guru shikar, Anchalgarh, Nakki Lake, Sunset Point, and Honeymoon Point. Overnight Stay at Mount Abu.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Udaipur Drop",
    details:
      "After Breakfast, check out from Hotel and Proceed to Drop at Udaipur Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Udaipur Drop",
    details:
      "After Breakfast, check out from Hotel and Proceed to Drop at Udaipur Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
];

const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
];

const RajasthanHeaven = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={RAJASTHAN_HEAVEN_TOUR_RAJASTHAN_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default RajasthanHeaven;
