import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR7Image } from "../image";

const heroTextData = [
  {
    title: "Scandinavian Fjords, Villages and Capital Tour",
    duration: "10 Nights / 11 Days",
    typoOfTrip: "Road Trip",
    startCity: "Copenhagen",
    endCity: "Stockholm",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Happy Copenhagen",
    details: " Meet and greet and private hotel transfer (Please note all group members will be picked up at one time and not individual transfers will be offered). Dinner Included Overnight Radisson Blu Scandinavia Copenhagen",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Copenhagen Canal Tour, city sightseeing and beautiful trip to Aarhus",
    details: "Breakfast. 60 Mins Copenhagen canal Tour 3 Hours Guided tour of Copenhagen Drive to Aarhus via Roskilde. Lunch on own. Photo stop at Roskilde. Dinner included. Overnight Radisson Blu Scandinavia Aarhus",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Scenic Drive to Hirtshall via Ålborg. Overnight onboard Fjordline-Scenic journey from Hirthsall to Bergen via Stavanger",
    details: "Breakfast. Scenic drive to Hirtshall via Ålborg. Lunch stop at Ålborg. Lunch on own. Dinner and overnight on board Fjord line cruise.",
  },
  {
    id: "Day 4",
    header: "Scenic cruise and afternoon Arrival to the gateway of Fjords, Bergen (Norway).",
    details: "Breakfast Scenic cruise journey and arrival Bergen in the afternoon approx. 12:30 Lunch on own. After lunch enjoy Guided tour of Bergen. Dinner included. Overnight Scandic Kokstad Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Hardanger Fjord Excursion day.",
    details: "Breakfast Full day excursion to the Hardanger region and scenic Hardangerfjord area. Lunch on own Dinner included Overnight Scandic Kokstad",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "cenic town of Voss and most beautiful Flåm train",
    details: "Breakfast Scenic transfer to Voss. Photo Stops enroute. Enjoy Lunch on own in Voss. Evening drive to the Fjord village of Flåm. Enjoy Flåm train ride. Dinner included Overnight Scandic Voss.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Scenic Fjord Cruise and scenic drive to Oslo.",
    details: "Breakfast Scenic transfer to Viking village of Gudvangen. Enjoy the scenic Fjord cruise Lunch on own Scenic drive to Oslo. Dinner included Overnight Radisson Blu Alna Oslo",
  },
  {
    id: "Day 8",
    header: "Oslo Sightseeing",
    details: "Breakfast. Enjoy Oslo Sightseeing with a local guide. Lunch on own. Dinner included Overnight Radisson Blu Alna Oslo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Scenic drive to Stockholm via Örebro",
    details: "Breakfast. Enjoy scenic drive to Stockholm. Lunch stop at Örebro (Lunch on own). Also enjoy Photo ops at Örebro castle. Continue drive to stylish Stockholm. Dinner included Overnight Quality Hotel Globe Stockholm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Stylish Stockholm guided tour",
    details: "Breakfast. Enjoy Stockholm city tour with a local guide. Lunch on own. Dinner included Overnight Quality Hotel Globe Stockholm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Departure",
    details: "Breakfast. Airport Transfers (All group members will be transferred at same time. No individual transfers). Departure with fond memories.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"09 Nights Hotel Accommodation",
"01 Night overnight in Cruise",
"Daily breakfasts",
"10 x Dinners (Mix of Indian, Italian, Chinese, Local).",
"Comfortable 35-Seater Coach from Day 1 – Day 11",
"3 Hours Guided city tour of Copenhagen, Bergen, Oslo and Stockholm",
"Full day Hardanger fjord excursion tour stay at Bergen",
"Stegåstein view point",
"Copenhagen canal tour- 60 Mins",
"Scenic Fløyenbane/Funicular cable car experience to top of Bergen",
"Most scenic Fjord Cruise through Nærøyfjord (The Norway in a Nutshell Fjord Cruise)",
"Popular Flåm train ride (The Norway in a Nutshell train)",
"Vasa museum in Stockholm",
"Orientation tour/ Photo Stops at scenic locations like Roskilde, Ålborg, Örebro, Gudvangen.",
"Local Tour leader",
];

const exclusionsData = [
"Meals and Beverages other than those mentioned in the itinerary.",
"All extra activities and admission fees",
"Porterage services",
"Coach service for 10 Hrs. The tour and route will be planned accordingly",
"Personal Travel Insurance",
"Air tickets",
"Tips to driver (Normal standard Eur 2 per person per day).",
];

const SR7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR7;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
