import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR3Image } from "../image";

const heroTextData = [
  {
    title: "Fjords & Pulpit Rock Adventure",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bergen",
    endCity: "Stavanger",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Bergen, the Gateway to the Fjords",
    details: "Arrival in Bergen according to your own itinerary. Bergen is Norway’s second largest city and the world’s only city surrounded by 7 mountains and 7 fjords. Bergen is with good reason called the Gateway to the Fjords due to its close proximity to the Norwegian fjords. This makes the city the perfect starting point for excursion and activities in the fjord region. Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Sognefjord Cruise to Flåm",
    details: "Breakfast at the hotel. From Bergen you will depart on a cruise across the world’s longest fjord, the Sognefjord, to the fjord village of Flåm. Afternoon at leisure to explore Flåm. The area offers great hiking possibilities. We would also recommend an afternoon excursion to Stegasteinen viewpoint, which offers spectacular view of the Aurlandsfjord. Accommodation in Flåm.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Flåm Railway - Downhill biking - Fjord Safari",
    details: "Breakfast at the hotel. Morning departure with the Flåm Railway from Flåm to Myrdal, before heading half way down again to Berekvam station. The railway passes through wild and beautiful mountain scenery with waterfalls and steep mountain sides reaching a height of up to 900 metres. There is no other adhesion railway in the world running on normal tracks that is so steep over a long distance. At Berekvam station you will leave the train, meet your guide and continue the remaining 11 kilometres to Flåm by bike (your luggage will be transported to your hotel in Voss). The downhill bike ride follows a gravel road along the river with spectacular view of the waterfalls, mountain sides and valley. If you like you can even fill your water bottle from the river and streams along the way. In the fjord village of Flåm you will have some time at leisure before you will go on a thrilling Fjord Safari along the narrow Nærøyfjord, which was added to UNESCO’s list of world heritage sites in 2005. The RIB boat will bring you close to shore, giving you the opportunity to have a closer look at the spectacular nature and fauna of the Norwegian fjords. You will also experience the tranquility of an area hard to reach and seldom visited and learn about the history and geography of this amazing place. If you are lucky you might even see some seals. After 1 ½ hour you will arrive in Gudvangen, at the head of the Nærøyfjord. If you like, you can add a 4-hour kayaking tour on the fjord, please see add-ons for more information. Afterwards, a bus will be waiting to take you through the Nærøy Valley and attractive farmland to Voss, famous for its annual Extreme Sports Week. Afternoon at leisure. Accommodation in Vo",
  },
  {
    id: "Day 4",
    header: "Travel from Voss via Bergen to Stavanger",
    details: "Breakfast at the hotel. Morning train from Voss to Bergen. Continue by a 5 hours bus trip along the western coastline, arriving Stavanger around 5 pm. Stavanger, Norway’s fourth largest city, has a fine collection of gabled houses, cobblestone streets, winding alleys and busy markets. One of Stavanger’s highlights is the Old Town (Gamle Stavanger), with well-preserved early 18th-century whitewashed wooden houses. Evening at own leisure. We recommend a visit to the Norwegian Petroleum Museum. Accommodation in Stavanger.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Lysefjord & Hike to the Pulpit Rock",
    details: "Breakfast at the hotel. This morning you will depart Stavanger for a full day, self-guided excursion to the Pulpit Rock, or Preikestolen as it is called in Norwegian. The excursion will start from Stavanger with a 2-hour cruise to the Lysefjord. At Oanes you will continue by bus to the Pulpit Rock cabin, which is the starting point for the trail leading up to the Pulpit Rock. The Pulpit Rock is the most famous tourist attraction in the Stavanger region and towers 604 meters above the Lysefjord . The approximately 25 x 25 meters flat mountain plateau was most likely formed by melting frost 10,000 years ago and today you can enjoy spectacular views of the Lysefjord and surrounding mountains from the plateau. The scenic walk to the Pulpit Rock has a total distance of about 6 kilometres and runs along a marked trail. The terrain is hilly with an altitude difference of 350 meters and along the way you can find swimming and picnic areas. The hike takes approximately 4-6 hours back and forth and requires an average fitness level. After the hike you will continue to bus to Tau, from where you will return to Stavanger by ferry. Accommodation in Stavanger.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure from Stavanger",
    details: "Breakfast at the hotel. Departure from Stavanger according to your own itinerary.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"5 nights in 4* hotel accommodation in a standard room with private shower/WC, including breakfast",
"Cruise from Bergen – Flåm",
"Flåm Railway, Flåm – Myrdal – Berekvam",
"Mountain bike rental Berekvam – Flåm",
"Fjord Safari with RIB boat Flåm – Gudvangen",
"Bus Gudvangen – Voss",
"Tour guide from Berekvam to Voss (guiding during Fjord Safari and bus ride from Gudvangen to Voss only).",
"Luggage transport Flåm – Voss",
"Train Voss – Bergen",
"Bus Bergen – Stavanger",
"2-hour cruise on the Lysefjord, Stavanger – Oanes (not available May 17)",
"Bus Oanes – the Pulpit Rock Cabin (startingpoint for hike to the Pulpit Rock)",
"Bus from the Pulpit Rock cabin to Tau",
"Ferry crossing Tau – Stavanger",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
  ];

const SR3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
