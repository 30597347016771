import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  MP1Image,
  MP2Image,
  MP3Image,
  MP4Image,
  MP5Image,
  MP6Image,
  MP7Image,
  MP8Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const MADHYAPRADESH_TOUR = "/assets/img/domesticTour/madhyapradesh.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Night / 03 Days",
    title: "Divine Mahakal Tour - Madhya Pradesh",
    image: MP1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Exotic Pachmarhi Tour",
    image: MP2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Heritage Tour of Madhya Pradesh",
    image: MP3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp3",
  },
  {
    id: 4,
    fromToDays: "03 Nights/ 04 Days",
    title: "Jyotirlingas Tour of Madhya Pradesh",
    image: MP5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "Experience Wildlife of Madhya Pradesh",
    image: MP4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp5",
  },
  {
    id: 6,
    fromToDays: "04 Nights / 05 Days",
    title: "Jyotirlingas Tour with Mandu Heritage",
    image: MP6Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp6",
  },
  {
    id: 7,
    fromToDays: "04 Nights / 05 Days",
    title: "Pachmarhi Hills with Lake City",
    image: MP7Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp7",
  },
  {
    id: 8,
    fromToDays: "04 Nights / 05 Days",
    title: "Pachmarhi Hills with Satpura Forest",
    image: MP8Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/mp8",
  },
];

const MadhyaPradesh = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Madhya Pradesh"}
            subTitle={"Amazing Holidays to Madhya Pradesh"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={MADHYAPRADESH_TOUR}
            title={"Popular Madhya Pradesh Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Assam"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default MadhyaPradesh;
