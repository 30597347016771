import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W4Image } from "../image";

const heroTextData = [
  {
    title: "Heaven On Hills",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "NJP Rly Station / IXB Airport - Kalimpong (80 Kms. / 3 Hrs.)",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Darjeeling (6,950 Ft.). On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Darjeeling",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kalimpong Sightseeing - Darjeeling (79 Kms. / 6½ Hrs.)",
    details:
      "After breakfast enjoy a half day Kalimpong city tour and visit Mangal Dham, Deolo Hill, Golf Course, Durpin Dara Hills and Pine View Nursery (Sunday closed). Afternoon transfer to Darjeeling (6,950 Ft.). On arrival check-in to the hotel. Overnight stay at Darjeeling",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: " Darjeeling Full Day Sightseeing with Tiger Hill",
    details:
      "Early morning (4:00 AM) visit Tiger Hill (Subject to availability of Permit / Token) and then back to hotel. After breakfast, starting at 8.30 AM, visit Himalayan Mountaineering Institute, P.N. Zoological Park (Thursday closed), Tenzing Rock, Ropeway, Tibetan Refugee self-help Centre (Sunday closed), Tea Garden (outer view), Japanese Temple. Evening free for shopping or leisure. Overnight stay at Darjeeling."
  },
  {
    id: "Day 4",
    header: "Darjeeling - NJP Rly Station / IXB Airport (75 Kms. / 3 Hrs.)",
    details:
      "After breakfast Checkout from hotel and take, the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on twin Sharing Basis. ",
  "Meal Plan (as per mentioned above).",
  "Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
  "All permit fees & hotel taxes (as per itinerary).",
  "Rates are valid for INDIAN NATIONALS only.",
  
  ];
  
  const exclusionsData = [
  "Air Fare / Train fare. ",
  "Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
  "Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
  "Entrance Fees & Guide charges. ",
  "Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
  "Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
  "Room Heater Charges.",
  "Insurance. ",
  "Anything which is not included in the inclusion",
  ];
const W4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W4;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
