import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { T2Image } from "../image";

const heroTextData = [
  {
    title: "Turkey 6 nights with 2N Istanbul + 2N Cappadocia + 2N Kusadasi",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Istanbul",
    endCity: "Kusadasi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Istanbul",
    details: "Arrival and transfer to the hotel. Overnight at the hotel in Istanbul.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Istanbul",
    details: "After the breakfast, we begin our tour from the Sultanahmet district, the heart of old Istanbul at Hagia Sophia. Built by Emperor Justinian in the 6th century AD this church is one of the marvels of world architecture. Its massive dome dominates the skyline of old Istanbul. It is famed for its mosaics, including glittering portraits of emperors and empresses. Next we visit the Blue Mosque, which owes its name to the exquisite tiles adorning its interior. Dating from the 17th century, it is the only imperial Mosque with six minarets. The Hippodrome, the stadium of ancient Byzantium, held 100.000 spectators and featured objects from all corners of the empire. After lunch break we continue our tour at Topkapi Palace which, from the 15th to 19th century, was the principle residence of the Ottoman Sultans. Final visit will be to the Grand Bazaar which was the commercial heart of the old city and its 4.000 shops are full of treasures – including carpets and kilims, silks, jewelery, ceramics, icons and leather goods. Overnight at the hotel in Istanbul. Remark: Hagia Sophia is usually closed in winter period & open in summer periods, it can be closed in all periods so kindly check at the time of booking), Topkapi Palace is closed on Tuesdays, Blue Mosque is closed on Friday due to Friday prayers, Grand Bazaar is closed on Sundays",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Fly from Istanbul to Cappadocia",
    details: "After the breakfast, transfer to the airport for the flight to Kayseri/Nevsehir. Arrival and transfer to the hotel.Dinner & overnight at the hotel in Cappadocia.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Cappadocia",
    details: "After the breakfast, our first real encounter with Cappadocia almost Martian landscape is in the Dervent valley. We visit Pasabag’s “fairy chimneys”, where the voice of the wind harmonizes with songs of the fairies. From here we pause at Avanos center of terracotta art which is a popular destination because of its attractive old town with cobbled streets, and views over the river and is its production of earthenware pottery, it is also the most economic activity in the town. The ceramic trade in this district and its countless pottery factories date right back to the Hittites, and the ceramic clay from the red silt of the Kızılırmak has always been used. A visit to ceramic & tile center to see how the ceramics made by hand. After lunch we visit the famous Goreme Open Air Museum to see the best examples of Byzantine art in Cappadocia. People of Goreme, at the heart of the Cappadocia Region, realized that these soft rocks could be easily carved out to form houses, churches, monasteries. These Christian sanctuaries contain many examples of Byzantine art from the post-iconoclastic period. These frescoes are a unique artistic achievement from this period. You visit a carpet cooperative, where you'll see how exquisite Turkish carpets are hand-woven. We complete the tour with a panoramic view of the valleys of Cappadocia.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Fly from Cappadocia - Kusadasi",
    details: "After the breakfast, transfer to the airport for the flight to Kusadasi. Arrival and transfer to the hotel in Kusadasi. Dinner and overnight at the hotel in Kusadasi.",
  },
  {
    id: "Day 5",
    header: "Kusadasi",
    details: "After the breakfast, first we will visit Virgin Mary House. This place is discovered by Lazarist priests after a German nun described the house and the mountain as the last residence of Virgin Mary after she had some visions about the place. Today this place is a Muslim and Catholic, being cared by Lazarist Fathers. Lazarist Fathers still celebrating the mass every day. Then we will drive to Ephesus. Ephesus was one of the most important harbor cities in antique world and also it is mentioned in the book of Revelations as one of the seven churches. Also Celsus Library and theater in Ephesus are the other highlights. In Ephesus we will see, Celcus Library, Temple of Hadrian, the fountain of Trajan and the Theater. We will have lunch in a local Turkish restaurant. After lunch we will visit Isa Bey Mosque which is built in 1375 by Anatolian Seljuks. The last stop of the tour will be the Artemis Temple, one of the seven wonders of the ancient world, standing today next to Ephesus antique city. Dinner and overnight at the hotel in Kusadasi.",
  },
  {
    id: "Day 7",
    header: "Departure (Fly from Cappadocia – Istanbul)",
    details: "After the breakfast, transfer to the airport for the flight to Istanbul to take the international flight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"2 nights in Istanbul with daily breakfast",
"2 nights in Cappadocia with daily breafkast & dinner",
"2 nights in Kusadasi with daily breafkast & dinner",
"Return transfers in Istanbul (IST airport), Cappadocia and Kusadasi on SIC basis",
"Full Day Istanbul Old City Tour with local lunch on SIC basis",
"Half Day Bosphorus Cruise on SIC basis",
"Full Day Cappadocia North Tour with local lunch on SIC basis (Full day South tour can be selected instead)",
"Full Day Ephesus & Virgin Mary Tour with local lunch on SIC basis (Full day Pamukkale & Hierapolis Tour can be selected instead)",
"Entrance fees",
"English Speaking Guide during tours",
];

const exclusionsData = [
"Domestic flight from Istanbul – Cappadocia – Kusadasi – Istanbul"
];

const T2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={T2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default T2;

// const intineraryTimelineData = [
  // {
  //   id: "Day 1",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 2",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 3",
  //   header: "",
  //   details: "",
  // },
  // {
  //   id: "Day 4",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
// ];
