import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KT2Image } from "../image";

const heroTextData = [
  {
    title:  "Hubli / Hospet / Hampi / Badami / Hubli",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Hubli",
    endCity: "Hubli",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Hubli + Drive to Hospet (150 Kms / 3 – 4 Hrs)",
    details: "On arrival at Hubli, meet our chauffeur waiting for you and proceed towards Hospet. Arrive Hospet in the Noon and check into the hotel. Later enjoy sightseeing of Hospet visiting Anegundi - The early capital of Vijayanagara, Anjanadri Hill – The birth place of Lord Hanuman and also other sites related to Hindu mythology Rest of the day free at leisure. Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day Excursion to Hampi",
    details: "After breakfast, enjoy sightseeing of full day sight seeing of Hampi, Tungabhadra Dam with beautiful Gardens, Musical Fountains, Fish Aquarium, Japanese Type Gardens, etc Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Hospet – Badami (140 Kms / 3 – 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Badami. Arrive Badami in the noon and check into the hotel. Later enjoy sightseeing of Badami visiting Badami, Aihole, Pattadakal. Overnight in the hotel",
  },
  {
    id: "Day 4",
    header: "Drive Badami – Hubli Drop (110 Kms / 2 – 3 Hrs)",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Hubli rail station / airport in time for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const KT2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KT2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KT2;


