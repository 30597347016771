import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { L7Image } from "../image";

const heroTextData = [
  {
    title: "Moon Valley & Pangong Lake",
    duration: "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Leh",
    endCity: "Leh",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Leh",
    details: "Arrival Leh by morning flight and transfer to the hotel.Full day of complete rest for acclimatization to the high altitude of Leh (11,500 ft / 35 00 mtrs).Overnight at hotel in Leh.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Leh local sightseeing.",
    details: "After leisurely breakfast, visit Shey Palace, Thiksey Monastery, Hemis Monastery and Stok Palace Museum.Overnight in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Leh-Lamayuru-Uleytoko.",
    details: "In the morning drive down the Indus Valley on a tour of the Lamayuru (approx. 130 kms.) circuit, famous for the dramatically located Lamayuru monastery and the adjoining 'Moonland' features. After sightseeing and lunch here, retrace the Leh route till Uleytokpo (60 kms.) and check into a fixed camp / resort for overnight stay.",
  },
  {
    id: "Day 4",
    header: "Uleytoko- Leh.",
    details: "After breakfast drive towards Leh, enroute visiting the 11th century Alchi Choskor, oldest surviving monastic heritage of Ladakh- a UNESCO listed heritage site. Thereafter, visit Sangam (confluence of Indus & Zanskar Rivers), Magnetic Point, Gurudwara Pathar Sahib, Kali Mata (Temple of Guardians in Spituk monastery) & Hall of Fame.Overnight in hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Leh - Pangong Lake - Leh",
    details: "Full day excursion to Pangong Lake (approx. 300 kms round trip, located on the Indo-China Border), enroute crossing the Chang-la pass (alt: 17370 ft.). After sightseeing around the lake, drive back to Leh, reaching in the late afternoon / evening.Overnight in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Leh",
    details: "Free day at Leh for rest. In the afternoon visit Shanti Stupa, Sankar Gompa, Leh Palace & the markets.Overnight in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Leh - Nubra Valley",
    details: "After breakfast leave for Nubra Valley, driving across Khardong-la (alt: 18,380 ft). After descending from the pass, drive on for sightseeing of Sumoor area and Samstaling Gompa. Later check into a fixed camp in the same area or drive across to Deskit / Hundar for overnight in a fixed camp or hotel.",
  },
  {
    id: "Day 8",
    header: "Nubra Valley - Leh",
    details: "In the morning visit / explore Deskit , Hundar and the Sand Dunes where you can enjoy a short camel safari using the double-humped Bactrian camel found here (optional on direct payment). Later drive back to Leh reaching in the late afternoon / evening.Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Departure Leh",
    details: "Early morning transfer to the airport for taking the flight back home.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodations on double/twin sharing basis as per package Selected.",
  "Meal Plan based on MAP (Room + Breakfast +Dinner) included in the package cost.",
  "All transport Services as per the itinerary by Non-A/C Crytsa Innova/Innova/ xylo / Ertiga. The same car shall do the complete itinerary (Maximum 6 persons including kids) are possible in one vehicle, for more than 6 please ask for Tempo Traveller).",
  "Meeting and Assistance on Arrival and Dept by our representative",
  "An experienced driver would work as a basic guide (You can hire a dedicated guide as well)",
  "Parking, toll, fuel, driver allowances.",
  "Inner Line Permits/Wild Life Fees. Except 11year old below",
  "01 Oxygen Cylinders will be available in the cars during Nubra Valley and Pangong Tour only. If you require Oxygen other than these days, ",
];

const exclusionsData = [
  "Any kind of Medical & Travel insurance.",
  "Expenses of personal nature such as Tips, Laundry, Telephone, Table Drinks & portage et",
  "Extra nights in hotel due to ill health, flight cancellation or any other reason",
  "Service of vehicle is not included on leisure days & after finishing sightseeing tour",
  "Adventure activity like Rafting, Camel Safari, Mountain Biking, Motor Biking etc",
  "Monastery entrance fees, camera fee, and monument guide service",
];

const L7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={L7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default L7;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
