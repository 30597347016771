import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";

import PropTypes from "prop-types";

const TourCard = ({ title, tourCardContent }) => {
  
  return (
    <Box>
      <Typography variant="h2" color={"text.secondary"}>
        {title}
      </Typography>
      <br />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} rowGap={2}>
          {tourCardContent?.map((item) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card sx={{ width: 345}}>
                <CardActionArea href={item.path}>
                  <CardMedia
                    height={"185"}
                    component="img"
                    image={item.image}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 155,
                      left: 10,
                    }}
                  >
                    <Typography
                      variant="h3"
                      color={"text.secondary"}
                      sx={{
                        color: "text.light",
                        textShadow: "1px 1px 1px black",
                      }}
                    >
                      {item.totalPackges} Packges
                    </Typography>
                  </Box>
                </CardActionArea>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <CardContent>
                      <Typography variant="h3" color={"text.secondary"}>
                        {item.title}
                      </Typography>
                      {/* <Typography variant="caption" color={"text.secondary"}>
                    <br />
                    {item.description}
                  </Typography> */}
                    </CardContent>
                  </Grid>
                  <Grid item  xs={6}>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button variant="contained" href={item.path} sx={{height:30}}>
                        View More
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

TourCard.propTypes = {
  title: PropTypes.string,
  tourCardContent: PropTypes.func,
};

export default TourCard;
