import { React } from "react";
import { Toolbar, IconButton, Grid, Divider,Button } from "@mui/material";
import PropTypes from "prop-types";

import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { Navigate, useNavigate } from "react-router-dom";

export const SM1 = "/assets/icons/callIcon.png";
export const SM2 = "/assets/icons/enquireNow.png";
export const SM3 = "/assets/icons/Wapp.png";

const BottomBar = () => {
  const navigate = useNavigate();

  const CallIcon = () => {
    return (
      <img src={SM1} width={24} height={24} />
    )
  };

  const EnquireIcon = () => {
    return (
      <img src={SM2} width={24} height={24} />
    )
  };
  const WhatsAppIcon = () => {
    return (
      <img src={SM3} width={24} height={24} />
    )
  };
  return (
    <Toolbar style={{padding:"0px"}}>
      <Grid container>
        <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            startIcon={<CallIcon />}
            size="medium"
            href="tel:+91-9730747576"
          >
            Call Now
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: "#dedede" }}
        />
        <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            startIcon={<EnquireIcon />}
            size="medium"
            href="/contact-us"
          >
            Enquire Now
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: "#dedede" }}
        />
        <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
            <Button
            variant="text"
            startIcon={<WhatsAppIcon />}
            size="medium"
            href="https://wa.me/+919730747576"
            target="_blank"
          >
            WhatsApp
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default BottomBar;
