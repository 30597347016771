import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AG5Image } from "../image";

const heroTextData = [
  {
    title: "The best of Georgia",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Georgia",
    endCity: "Georgia",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival / Transfer to the hotel / Check in",
    details: "Airport Pick up: Arrival Transfer to the hotel Check in",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Tbilisi and Mtskheta Full day Tour",
    details: "Because of its location on the crossroads between Europe and Asia, and its proximity to the lucrative Silk Road, throughout history Tbilisi was a point of contention between various global powers. The city's location to this day ensures its position as an important transit route for various energy and trade projects. Tbilisi's diverse history is reflected in its architecture, which is a mix of medieval, neoclassical, Beaux Arts, Art Nouveau, Stalinist and the Modern structures. Tour Program Pick up : 9:00 am Tour in Tbilisi Old town Metekhi church Narikala fortress Meidani square Mtatsminda park (funicular ride) Jvari Monastery Svetitskhoveli monastery Jvari Panorama Night Stay in Tbilisi",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Ananuri / Gudauri / Tbilisi",
    details: "Kazbegi National Park is located on the northern slopes of the mighty Caucasus range, and its protected area covers a total of over 8,700 hectares. At 5,047 metres above sea level, Kazbegi Mountain (or Mkinvartsveri) is the third highest mountain in Georgia, and is surrounded by myths and religious tradition. The ski/summer resort Gudauri is located in Mtskheta-Mtianeti (Georgia). For skiing and snowboarding, there are 32.5 km of slopes available. Tour Program Pick up 9 am Breakfast at the hotel Ananuri fortress Jinvali water reservoir Gudauri Panorama Snow activities in Gudauri Transfer for dinner Night stay in Tbilisi",
  },
  {
    id: "Day 4",
    header: "Bodbe / Signagi / Kvareli / tour",
    details: "In this country of wine-lovers, everyone agrees that the very best wines come from the fertile easternmost part of Georgia, called by Dumas “the garden province of Kakheti.” In some ways, little has changed since his time – horse-drawn carts are a frequent sight on the quiet country roads, the fields are dotted with hayricks, the rivers wind untrammeled through green pastures and the sweet grapes are still harvested by hand. Tour Program Pick up : 9:00 am Bodbe monastery Sighnaghi city (city of love) Kvarelis city with wine tasting possibilities Drop off: 6 pm",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure day / Transfer to Airport",
    details: "Airport Drop off: Check out Transfer to airport Departure",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Private car",
"English speaking Guide",
"Airport transfers",
"1 bottle of water per day",
"Breakfast",
"1 free sim card with internet",
"Tour pick up and drop off from your hotel",
];

const exclusionsData = [
"Entrance fees",
"Traveling insurance",
"Lunch",
"Dinner",
"Flight tickets",
"Any service which is not mentioned into the itinerary",
];

const AG5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AG5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AG5;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
