import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  U1Image,
  U2Image,
  U3Image,
  U4Image,
  U5Image,
  U6Image,
  U7Image,
  U8Image,
  U9Image,
  U10Image,
  U11Image,
  U12Image,
  U13Image,
  U14Image,
  U15Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const USA_TOUR = "/assets/img/worldTour/wt1.png";

const tourCardContent = [
  {
    id: 10,
    fromToDays: "04 Nights / 05 Days",
    title: "Eastern Wonders",
    image: U10Image,
    description:"",
    path: "/u10",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Orlando",
    image: U3Image,
    description:"",
    path: "/u3",
  },
  {
    id: 8,
    fromToDays: "06 Nights / 07 Days",
    title: "Eastern Elegance",
    image: U8Image,
    description:"",
    path: "/u8",
  },
  {
    id: 11,
    fromToDays: "06 Nights / 07 Days",
    title: "Wonders of the West",
    image: U11Image,
    description:"",
    path: "/u11",
  },
  {
    id: 6,
    fromToDays: "07 Nights / 08 Days",
    title: "Western Fiesta",
    image: U6Image,
    description:"",
    path: "/u6",
  },
  {
    id: 1,
    fromToDays: "East Coast",
    title: "08 Nights / 09 Days",
    image: U1Image,
    description:"",
    path: "/u1",
  },
  {
    id: 12,
    fromToDays: "09 Nights / 10 Days",
    title: "Splendors of the East",
    image: U12Image,
    description:"",
    path: "/u12",
  },
  {
    id: 2,
    fromToDays:"10 Nights / 11 Days",
    title: "East Coast",
    image: U2Image,
    description:"",
    path: "/u2",
  },
  {
    id: 5,
    fromToDays: "10 Nights / 11 Days",
    title: "West Coast USA with Hawaii",
    image: U5Image,
    description:"",
    path: "/u5",
  },
  {
    id: 7,
    fromToDays: "11 Nights / 12 Days",
    title: "Best of East Coast",
    image: U7Image,
    description:"",
    path: "/u7",
  },
  {
    id: 13,
    fromToDays: "11 Nights / 12 Days",
    title: "Wonderful USA",
    image: U13Image,
    description:"",
    path: "/u13",
  },
  {
    id: 14,
    fromToDays: "15 Nights / 16 Days",
    title: "Exotic USA",
    image: U14Image,
    description:"",
    path: "/u14",
  },
  {
    id: 4,
    fromToDays: "16 Nights / 17 Days",
    title: "Grand USA",
    image: U4Image,
    description:"",
    path: "/u4",
  },
  {
    id: 15,
    fromToDays: "17 Nights / 18 Days",
    title: "USA Grandeur",
    image: U15Image,
    description:"",
    path: "/u15",
  },
  {
    id: 9,
    fromToDays: "19 Nights / 20 Days",
    title: "Splendid USA",
    image: U9Image,
    description:"",
    path: "/u9",
  },
];

// const rulesData = [
//   ""
// ];

const Odisha = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"USA"}
            subTitle={"Amazing Holidays to USA"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={USA_TOUR}
            title={"Popular USA Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"USA"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Odisha;










