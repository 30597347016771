import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { MA1Image } from "../image";

const heroTextData = [
  {
    title: "Explore Maldives",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Maldives",
    endCity: "Maldives",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Day 1",
    details: "Airport Greetings Airport Transfer Enjoy Beautiful Sunset Dinner ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day 2",
    details: "Breakfast Sandbank Trip Guided Snorkelling Dolphin Cruise Picnic Lunch on Sandbank Canoe Ride Beach Photography Dinner ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day 3",
    details: "Breakfast FREE & Easy Dinner",
  },
  {
    id: "Day 4",
    header: "Day 4",
    details: "Breakfast Transfer to Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"3 Nights Hotel Stay",
"Daily Breakfast & Dinner",
"Return Airport Transfer",
"Tours & Sightseeing",
"FREE WiFi",

];

const exclusionsData = [
  "Flight Fare",
  "Lunch",
  "Dinner",
  ];

const MA1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={MA1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default MA1;

// {
//   id: "Day 1",
//   header: "Day 1",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "Day 2",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "Day 3",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "Day 4",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },


// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
