import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const  MUNNAR_THEKKADY = "/assets/img/domesticTour/kerala/MUNNAR_THEKKADY.png";

const heroTextData = [
  {
    title: "Cochin, Munnar, Thekkady, Kumarakom, Kovalam and Trivandrum",
    duration: "07 Nights / 08 Day",
    typoOfTrip: "Road Trip",
    startCity: "Munnar",
    endCity:"Munnar"
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Munnar",
    details:
      "Once you arrive in Cochin, our executives will pick you up and drop you to Munnar. Munnar is 4 hours away from Cochin. Once you arrive at Munnar, check in to the pre-booked hotel and spend the rest of the day at leisure. In transit appreciate a few cascades and common beautiful spots.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Across the nooks and corners in Munnar",
    details:
      "After breakfast set out for touring of Munnar. Significant touring attractions are the Kundale tea estate, The High Range Club, Eravikulam National Park, Mattuppetty, Top Station, Devikulam, Rajamalai, Marayoor, Chithirapuram, Pallivasal and so on.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Destination Thekkady",
    details:
      "After a sumptuous breakfast at the hotel, you will be heading to Thekkady today. Thekkady is viewed as a paradise for regular flavors, for example, cardamom, dark pepper, cinnamon and clove. The primary fascination in Thekkady is drifting on the lake and the Mullai Periyar Dam. The Forest Department offers numerous projects including Periyar Tiger Trail, Nature walk, Bamboo Rafting, and so forth.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Destination Kumarakom",
    details:
      "Continue to Kumarakom and register with the lodging. Kumarakom, the ruler of Vembanad, is a ‘resort’ goal. Kumarakom, today is a labyrinth of current retreats and legacy homes-cum-inns overflowing the island’s bank. Kumarakom Bird Sanctuary is an unquestionable requirement visit with its various wonderful rivulets and conduits. The picturesque Vembanad Lake encompassed by mangrove backwoods and paddy fields is a standout amongst the best places As Kumarakom is a famous backwater goal in Kerala, a houseboat journey is a must. A watercraft journey on the delightful Vembanad Lake is a standout amongst the most ideal approaches to appreciate birdwatching in the recreation center.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header:
      "Destination Alleppey",
    details:
      "After breakfast drive to Alleppey. Set out on a captivating backwater voyage in customarily enlivened houseboats. Check in time is 01:00 PM and journey begins with lunch. While cruising in these coasting extravagance estates, let your eyes explore the influencing coconut forests.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "To the beach destination, Kovalam",
    details:
      "After breakfast look drive to Kovalam. Go through the day touring in Kovalam, which is a shoreline town on the Arabian Sea in Kerala. Kovalam has three sickle formed shorelines isolated by rough outcroppings. The shallow waters extending for many meters are perfect for swimming.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Across the nooks and corners in Trivandrum",
    details:
      "Continue for the touring voyage through Trivandrum after breakfast – an old city with a convention going back to 1000 BC. This capital city of Kerala is an excellent shoreline city based on seven slopes. It is situated on the west shore of India spreading over an absolute territory of 2,192 sq.km. You will be taken for sightseeing to destinations such as Padmanabhaswami Temple, Napier Museum, Srichitra Art Gallery, and Zoo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Departure",
    details:
      "The tour comes to an end today. After a delicious breakfast at the hotel, our executives will pick you up and drop you to the airport or railway station.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];
const  MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={MUNNAR_THEKKADY}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackages;

