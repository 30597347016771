import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { TN2Image } from "../image";

const heroTextData = [
  {
    title: "Chennai / Mahabalipuram / Kumbakonam / Tanjore / Madurai",
    duration:  "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Chennai",
    endCity: "Madurai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Chennai",
    details: "Arrive at Chennai airport or station and you are transferred to the hotel. Later enjoy the city tour of Chennai, which, will include Kalpeshwara Temple, Crocodile Bank, Ramakrishna Temple, and Queens Lands. Evening back to hotel. Overnight stay at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Drive Chennai – Mahabalipuram (60 Kms / 1-2 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Mahabalipuram. Later visit Mahabalipuram the shore Temple, Panch Rath, Varah Caves, Arjuna's Penance and Krishna's Butter Ball. Overnight at Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Mahabalipuram – Kumbakonam (230 Kms / 5 - 6 Hrs)",
    details: "After breakfast check-out from hotel and proceed for the Kumbakonam. Arrive Kumbakonam and check into the hotel. Later visit Airavatesvara Temple and Adi Kumbeswarar Temple. Rest of the day free at leisure. Evening free for relaxation. Overnight in the hotel.",
  },
  {
    id: "Day 4",
    header: "Drive Kumbakonam – Tanjore (40 Kms / 1 – 1 ½ Hrs)",
    details: "After breakfast, check out of the hotel and drive to Thanjavur. Arrive Tanjore and check into the hotel. Later visit and enjoy the sightseeing of Brihadeeswarar temple, Thanjavur Palace and Thanjavur art gallery. Overnight stay at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Drive Tanjore – Madurai (190 Kms / 3 - 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Madurai. While in Madurai visit the famous Sree Meenakshi Amman temple and Alagarkoyil temple. Evening free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Depart Madurai",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Madurai rail station / airport in time for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const TN2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TN2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TN2;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
