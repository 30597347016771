import { React } from "react";
import { Box, Grid, Card, CardMedia, Container } from "@mui/material";
import HeroContent from "./HeroContent";

const SERVICES_RADHA_TOUR_BG = "/assets/img/servicesbg.png";


const HeroSection = () => {
  return (
   <Container>
     <Box sx={{ flexGrow: 1 }}>
      <Card sx={{ maxWidth: 1920, borderRadius: "8px 8px 8px 8px" }}>
        <CardMedia
          component="image"
          image={SERVICES_RADHA_TOUR_BG}
          height="484"
          sx={{ objectFit: "cover" }}
        >
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <HeroContent />
              </Grid>
            </Grid>
        </CardMedia>
      </Card>
    </Box>
   </Container>
  );
};

export default HeroSection;
