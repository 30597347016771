export const light = {
  mode: "light",
  common: {
    black: "#000000",
  },
  primary: {
    light: "#4d4d4d",
    main: "#212121",
    dark: "#171717",
  },
  secondary: {
    light: "#ffcf33",
    main: "#FFC300",
    dark: "#b28800",
  },
  text: {
    primary: "#212121",
    secondary: "#4d4d4d",
    tertiary:"#FFC300",
    dark: "#171717",
    hint: "#f5f5f5",
    light:"#ffffff",
    footerLink:"#f5f5f5"

  },
  background: {
    paper: "#ffffff",
    default: "#ffffff",
  },
};

export const dark = {
  mode: "dark",
  common: {
    black: "#000000",
  },
  primary: {
    main: "#FFD646",
    light: "#FFD646",
    dark: "#FFD646",
    contrastText: "#ffffff",
  },
  primary: {
    light: "#FFD646",
    main: "#FFD646",
    dark: "#FFD646",
  },
  secondary: {
    light: "#FFD646",
    main: "#FFD646",
    dark: "#FFD646",
  },
  text: {
    primary: "#000000",
    secondary: "#000000",
    dark: "#000000",
    hint: "#000000",
  },
  background: {
    paper: "#ffffff",
    default: "#ffffff",
  },
};
