import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AG7Image } from "../image";

const heroTextData = [
  {
    title: "Azerbaijan",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Baku",
    endCity: "Baku",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival to Baku",
    details: "After the checking at the passport control and customs, you will meet with your guide and will transferred to Baku city. Check-in at the hotel. Overnight in Baku.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Baku city tour (6 hours)",
    details: "Breakfast at the hotel. Full sightseeing tour in Baku:Panoramic view of the city and Baku bay from Mountain Park Visit to the medieval part of the Old City (XI-XIX cc)Fortifications (information about Old Baku); Baku Khans’ House; Museum of Archaeology and Etnography (entrance); Caravanserai complex - Bukhara and Multani (XIV-XV cc); old bath; “GyzGalasi” (Maiden Tower) - the oldest construction of Baku under UNESCO protection (entrance); art shops and bench of handicraft; old mosques (XI-XIX cc); narrow small streets; Baku Museum of Miniature Books; Shirvanshakhs' Palace (XIV-XV cc., entrance). Acquaintance with the architecture of the city (XIX – XX century): Walking along Fountain Square, Monument of NizamiGanjavi, Nizami Street, Seaside Boulevard.Returning to the hotel. Overnight in Baku.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Absheron peninsula (5 hours)",
    details: "Breakfast At The Hotel. Sightseeing Excursion On Absheron Peninsula:absheron Peninsula, Extension Of The Caucasus Mountains, Juts 35 Miles Into The Caspian Sea, The Largest Inland Sea In The World, Covering 386,400 Sq. Km. (149,200 Sq. Miles). Wide Plain Contains Ravines And Salt Lakes, And Its Some Parts Are Frequently Flooded With Tides. Vineyard Plantations Are Located Here, But The Region Is Particularly Marked For Its Oil Deposits. You Will Informed About The Story Of Oil Development In Azerbaijan. The Guide Will Tell You About Different Methods Of Oil Extraction, Both Ancient And Modern. You Will Introduced “james Bond Oil Field”. Yanardaq” State Natural Preserve “yanardaq” State Natural Preserve Flames Shoot Into The Air 3 M From Sandstone Layer At The Base Of 9,1 M Wide Scarp Below Hillside Of Yanar Dag (Burning Mountain). Natural Flames Burns Since Ancient Times And Reported By Historical Writers Such As Marco Polo. Atashgah Fire -worshippers’ Temple The Temple Is Located Within Baku In Surakhany Village (9 Miles From Baku). The Historical Roots Of The Monument Go Back To The Days When Azerbaijan State Was Only Taking Shape And Establishing Itself, And Zoroastrianism, In Which The Main Role In Rituals Belonged To Fire, Was A Dominant Religion In The Country. Gala” State Historical-ethnographic Preserve. Gala Village Is The Ancient Settlement, Has The History Of Five Thousand Years Old. Barrows, Fire Traces And Tombs Found Here During Archeological Excavations Prove This Fact Once Again. There Are 215 Architectural And Archeological Monuments, 5 Mosques, 3 Bathhouses, 4 Water Houses, Houses, Agricultural Buildings, Sepulchers, Tombs, Barrows, Remnants Of Castle, Etc. Visiting Haydaraliyev Center. Returning To Hotel. Overnight In Baku",
  },
  {
    id: "Day 4",
    header: "Baku – Shamakhi –Sheki",
    details: "Breakfast at the hotel. Check - out. Passage to the rout Maraza – Shamakhi - Lahij (195km.).On the way visiting “Diri Baba” mausoleum in Maraza settlement. Photo stops on in the most picturesque places in the mountains. Continue trip to Gabala city and on the way to Gabala visit to small step falls 7Gozel (“7 beauties”) in Ruskyan settlement, panoramic view of Nohur mountain lake in NohurgishlagGabala. Arrival to Sheki city. Check in at the hotel. Sightseeing program in Sheki(excursion and museums): - Survey of fortifications, caravanserai in the Old city - Visiting History Museum (entrance) - Visiting “Sheki Khan's palace”-XVIII c. under UNESCO protection (entrance) - Survey of the exposition of the National Applied Arts museum in the old round Christian temple (IX-XVI c.) and acquaintance with works of handicraftsmen on manufacturing stained-glass windows - “shebaka” (network) in their workshops. Overnight in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Sheki – Baku",
    details: "Breakfast at the hotel. Check - out. Visiting Albanian Church in Kish village Passage to the rout Sheki – Baku. Visiting to Gobustan National Historical - Artist Preserve (65 km. away from Baku). Gobustan(Land of Gobu) is famous for its archaeological monuments. Travel 30 miles southwest of Baku along the Caspian shore to the volcanic desert where rocks are covered with carvings, standing amidst the scattered stones and mountains. There are more than 6,000 rock carvings left by the artists of the Paleolithic period. Acquaintance with archaeological exposition of the museum, lifestyle of ancient people of “Stone Age” epoch (XVIII-VIII mln. B.C). Survey of Gobustan petro glyphs. Under UNESCO Protection Visiting Bibi Heybat Mosque. The existing structure, built in the 1990s, is a recreation of the mosque with the same name built in the 13th century by ShirvanshahFarrukhzad II Ibn Ahsitan II, which was completely destroyed by the Bolsheviks in 1936 and was restored later in the end of the 20th century. The Bibi-Heybat Mosque includes the shrine of UkeymaKhanum (a descendant of the Prophet Muhammad), and today is the spiritual center for the Muslims of the region and one of the major monuments of Islamic architecture in Azerbaijan. You will informed about the story of oil development in Azerbaijan. The guide will tell you about different methods of oil extraction, both ancient and modern. Arrival to Baku. Check in at the hotel. Overnight in Baku",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Baku",
    details: "Breakfast at the hotel, check – out. Transfer to the airport. Departure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Private car",
"English speaking Guide",
"Airport transfers",
"1 bottle of water per day",
"Breakfast",
"1 free sim card with internet",
"Tour pick up and drop off from your hotel",
];

const exclusionsData = [
"Entrance fees",
"Traveling insurance",
"Lunch",
"Dinner",
"Flight tickets",
"Any service which is not mentioned into the itinerary",
];

const AG7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AG7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AG7;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
