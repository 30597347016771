import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U11Image } from "../image";

const heroTextData = [
  {
    title: "Wonders of the West",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "New York",
    endCity: "New York",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive in San Francisco",
    details:
      "Upon arrival San Francisco airport we transfer you to hotel. San Francisco is one of the few North American cities to achieve the legendary status of such places as Paris or London. The city's well-known hills offer stunning views of the Pacific Ocean and San Francisco Bay, and feature a wide array of shops, restaurants, and cosmopolitan nightlife. In addition to its diversity and charm, San Francisco is a gateway to Silicon Valley, America's premier high-technology center.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "San Francisco City Tour",
    details:
      "Today we proceed on a sightseeing tour of San Francisco. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Las Vegas",
    details:
      "Depart in the morning for your flight to Las Vegas. On arrival, transfer to your hotel. The Night Tour takes you all the way along the Strip to the famous Las Vegas welcome sign, before heading back to Down Town where you'll have the change to enjoy the Fremont Street Experience .As you travel past Las Vegas' illuminated landmarks, The experienced guides on tour will tell the story of Las Vegas and will highlight the city's most impressive views. The Night Tour lasts approximately 3 hours including time at Fremont Street. Return back to the Hotel.",
  },
  {
    id: "Day 4",
    header: "Grand Canyon (Optional)",
    details:
      "Day is free to explore to take an optional tour to Grand Canyon. There are multiple options to explore the Canyon by Bus, Helicopter, and Airplane and can add Sky Walk & Boat ride. Evening you can even enjoy a Helicopter Ride over dazzling night time Vegas, visit any of musical shows. Overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Las Vegas – Los Angeles",
    details:
      "Enjoy breakfast at the hotel and then we drop you to coach station for onward journey to Los Angeles. Upon arrival get transferred to Hotel. Evening proceed for Los Angeles city tour driving you through the “Entertainment capital of the world”, Hollywood, Hollywood Walk of Fame, Beverly Hills, Grumman Chinese Theater, Sunset strip, Rodeo Drive for the world’ most expensive shopping and China town for some good bargains.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Los Angeles-Universal Studios",
    details:
      "Join us today for a tour of Universal Studios which has always been known for its innovative rides and attractions, and is designed to let visitors experience the movies like never before. A trip to Universal is like a trip into the heart of Hollywood. With films and TV shows always in production, one never knows who they might see wandering the sidewalks of the park.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure from Los Angeles",
    details:
      "After breakfast, check out from hotel and we transfer you to airport for onward journey. End of tour and services",
  },
];

const inclusionData = [
"06 Night’s Accommodation with breakfast as per mentioned or similar( No breakfast in Vegas)",
"All Airport – Hotel – Airport Transfers on Seat in Coach in San Francisco Los Angeles and Las Vegas",
"Las Vegas to Los Angeles Intercity Bus on Seat in coach basis",
"Las Vegas Bus Station to Hotel and Los Angeles Bus Station to Hotel on Private basis",
"City Tour of San Francisco with Bay Cruise on Seat in Coach",
"LAS Vegas Open Top 3 Hrs. Night Tour",
"Los Angeles Hop on and Hop of Tour 24 Hrs. Pass",
"Universal Studios Transfers on Seat in Coach basis",
"Entrance Tickets with Transfers :Universal Studios",
"All Taxes",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U11 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U11Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U11;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
