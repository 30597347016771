import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AR1Image } from "../image";

const heroTextData = [
  {
    title: "Beauty of Armenia",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Zvartnots",
    endCity: "Zvartnots",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival in Zvartnots",
    details:
      "Arrival in Zvartnots International Airport. - Transfer Airport-hotel. Check-in at the hotel and Rest. - Leisure time to enjoy the evening on your own. - Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Yerevan City Tour",
    details:
      "Breakfast at the hotel. - Meet your tour guide and start city tour around Yerevan. - Sightseeing of Republic Square, Opera House, Swan Lake. - Visit Cascade Complex, an open-air modern art museum with famous exhibits. - Climbing the huge stair you will get to Victory Park from where it opens a unique view of the city. - Proceed to Tsitsernakaberd Memorial dedicated to the victims of Genocide. - Visit Blue Mosque, the only fully preserved Muslim Monument in Yerevan. - Visit Yerevan Food and Fruit Market complete with fresh vegetables and dried fruits, alleys of Armenian bread lavash and local sweets. - Leisure time to enjoy the evening on your own. - Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:
      "Megeryan Carpets / Khor Virap / Noravank Monastery /Areni Cave/ Yerevan",
    details:
      "Breakfast at the hotel - Visit Megerian Carpet showroom to get a full insight into ancient Armenian traditions of rug weaving - Departure to the cradle of civilization Khor Virap Monastery (4-17th cc.) which is the closest point with the border to the Turkey and from where you are getting closer to the Biblical Mount Ararat via Ararat valley where the Armenian wine yards are located. - Continue to Noravank monastery - great religious and cultural center of 13th c. It is situated in a narrow gorge, which is known for its tall, sheer, brick-red cliffs, directly across from the monastery. Visit the famous Arreni Cave where the worlds oldest leather shoe was discovered. - Return to the capital. Optional: En route visit “Tushpa” winery for degustation and tour in the family owned estate (5 USD P/P for degustation) - Overnight at the hotel in Yerevan",
  },
  {
    id: "Day 4",
    header: "Yerevan / Garni / Geghard / Lake Sevan / Yerevan",
    details:
      "Breakfast at the hotel - Departure to Geghard Monastery, which is hewn out from the cave and included in the UNESCO World Heritage. - Proceed to Garni Temple from 1st c., the only preserved pagan temple in the region. Optional:Jeeping at Azat River gorge to the natural formation Symphony of Stones (10 USD P/P UNESCO). - Proceed to Lake Sevan, the “pearl of Armenia” and the world’s 2nd highest mountainous lake. - Sightseeing on the peninsula. Visit Sevanavank Monastery and stunning view of the lake. Optional: seasonal activity: Sailboat trip on the lake (up to 5 pax) Boat trip on the lake for an hour (min. 10 pax). - Drive back to Yerevan. - Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure",
    details:
      " Breakfast at the hotel. - Check out by 12:00 Noon. - Transfer to the airport. Departure home",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation.",
  "4 x overnights at the hotel in Yerevan.",
  "Airport / hotel / airport transfers.",
  "Transportation as mentioned in the program in comfortable A/C vehicle.",
  "English speaking professional guide throughout the tour.",
  "All activities as per program with entrance fees.",
  "Catering BB: breakfast at the hotel.",
  "1 bottle of mineral water per day/per person.",
];

const exclusionsData = [
  "Alcoholic beverages except described.",
  "Optional activities.",
  "Flights",
  "Visa",
  "Meals except described.",
  "Travel insurance",
];

const AR1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AR1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AR1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
