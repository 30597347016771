import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { G8Image } from "../image";

const heroTextData = [
  {
    title: "Statue Of Unity – Option 1",
    duration: "02 Nights / 03 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhuj ",
    endCity: "Bhuj ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "By Air / Train – Arrive Ahmedabad Followed By Road – 200 Kms / 04 Hrs – Ahmedabad To Kevadia Or By Air / Train – Arrive Vadodara Followed By Road – 90 Kms / 02 Hrs – Vadodara To Kevadia",
    details:
      "Arrive Ahmedabad / Vadodara and drive to Kevadia, arrive and transfer to your Tent City. DAY 1 12:00 pm Welcome & Check-in. 12:30 pm to 02:30 pm Lunch at Dining Area. 02:30 pm to 04:00 pm Visit to Sardar Sarovar Dam View and Selfie Point, Valley of Flower On SIC (Seat-in-Coach) Basis. 04:00 pm to 06:00 pm Visit to The Statue of Unity on SIC (Seat-in-Coach) Basis (Viewing Gallery Ticket is not Included in the package and same Will Be subject to availability.) 06:00 pm to 06:45 pm Light Refreshment. 06:45 pm to 08:00 pm The Statute of Unity Laser Show on SIC (Seat-in-Coach) Basis (Subject to show / ticket availability). 08:00 pm to 08:30 pm Illumination Tour around The Statue of Unity. 08:00 pm to 10:00 pm Dinner at Dining Area. Overnight in Tented Accommodation. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "In Kevadia",
    details:
      "DAY 2 06:00 am to 06:30 am Morning Tea. 06:30 am to 07:30 am Guided Khalwani Sunrise Walking Tour (Optional – To be notified At the time of check-in) 07:30 am to 09:30 am Breakfast at Dining Area. 09:30 am to 12:30 pm Eco Tourism Tour on SIC Basis (The tour includes places such as Vishwa Van, Khalwani Eco-Tourism Campsite, Aarogya Van, Ekta Nursery, Cactus Garden, Butterfly Garden etc.) 12:30 pm to 02:30 pm Lunch at Dining Area. 02:30 pm to 04:30 pm Enjoy In House Activities 04:30 pm to 05.30 pm Hi Tea at Dining Area. 06:00 pm to 08:00 pm Visit to Unity Glow Garden. It is a unique theme park which has, been, developed with a glimmering array of installations, figures, and optical illusions. 08:00 pm to 08:30 pm Return transfers to Tent City. 08:30 pm to 10:30 pm Dinner at Dining Area. Overnight in Tented Accommodation. ",
  },
  {
    id: "Day 3",
    header: "By Road – 90 Kms / 02 Hrs – Kevadia To Vadodara Or By Road – 200 Kms / 04 Hrs – Kevadia To Ahmedabad Followed By Air / Train – Depart Vadodara / Ahmedabad",
    details:
      "DAY 3 06:00 am to 06:30 am Morning Tea. 07:30 am to 09:30 am Breakfast at Dining Area. 09:30 am Check-out from Tent City with Happy memories. Later drive to Vadodara / Ahmedabad and in time transfer to Vadodara / Ahmedabad Airport Railway Station to board your flight train for onward destination.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "2 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
  "Meal Plan – As Mentioned above.",
  "All currently applicable taxes.",
  "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
  "Road taxes, parking fee, fuel charges, interstate taxes.",
];

const exclusionsData = [
  "Monument Entrance Fee.",
  "Services of English-Speaking Local Guide.",
  "Game Drive at Sasangir National Park or any other national park.",
  "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
  "International or Domestic airfare / Train Fare.",
  "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
];
const G8 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={G8Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default G8;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
