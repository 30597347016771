import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W11Image } from "../image";

const heroTextData = [
  {
    title: "Rendezvous Sikkim Part",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "NJP Rly Station / IXB Airport - Gangtok (128 Kms. / 5 Hrs)",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Gangtok (5,500 Ft.). On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Excursion to Tsomgo Lake and Baba Mandir (53 Kms. / 3 Hrs. one-way)",
    details:
      "After breakfast, start for an excursion to Tsomgo Lake (12,400 ft. / 37 Kms. / 02 Hrs. from Gangtok) and further drive to Baba Mandir (13,200 ft. / 16 Kms. / 01 Hrs. from Tsomgo Lake). Evening back to Gangtok. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:"Gangtok - Lachen (120 Kms. / 6 Hrs.)",
    details:
      "After breakfast check out from hotel with limited luggage and proceed to Lachen (8,980 Ft.) in North Sikkim. On the way, take a break for some beautiful spots that are worth seeing and visiting like Naga Waterfall, The Confluence of Lachen Chu (River) and Lachung Chu (River) at Chungthang and Bhim Nala Waterfall. On arrival at Lachen, check into your hotel. Dinner and Overnight stay at Lachen.",
  }, 
  {
    id: "Day 4",
    header:   " Lachen - Chopta Valley - Gurudongmar Lake - Lachung (185 Kms. / 10 Hrs.)",
    details:
      "After Early breakfast proceed to Gurudongmar Lake (17,100 Ft.) enroute visit Thangu Valley (12,795 Ft.) and Chopta valley (13,200 Ft.). Gurudongmar Lake is the second highest lake in World and it will take approx 3-4 hours’ drive from Lachen (One way). The Lake generally is covered with snow from December to April or even more and is known as the Holy / Sacred Lake. Afternoon back to hotel for lunch at Lachen and check out for a 2 hrs. Drive to Lachung (8,738 Ft.). Evening arrival and check-in to the hotel. Dinner and Overnight stay at Lachung.",
  },
  {
    id: "Day 5",
    header: "Excursion to Yumthang Valley (26 Kms. / 1½ Hrs. one-way)",
    details:
      "After breakfast proceed to Yumthang Valley, which is known as Valley of Flowers (12,000 ft.). Yumthang is the summer grazing ground of the yaks and winter playgrounds of yetis. On the way back, visit Hot Spring which is considered to have medicinal properties. Back to the hotel in the afternoon. After lunch free for individual activities, Dinner and Overnight stay at Lachung.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: " Lachung - Gangtok (116 Kms. / 6 Hrs.)",
    details:
      "After breakfast, transfer to Gangtok (5,500 Ft.). On arrival, check-in to the hotel and rest of the day at leisure. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Gangtok - NJP Rly Station / IXB Airport (128 Kms. / 5 Hrs.)",
    details:
      "After breakfast Checkout from hotel and take, the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on twin Sharing Basis. ",
  "Meal Plan (as per mentioned above).",
  "Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
  "All permit fees & hotel taxes (as per itinerary).",
  "Rates are valid for INDIAN NATIONALS only.",
  
  ];
  
  const exclusionsData = [
  "Air Fare / Train fare. ",
  "Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
  "Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
  "Entrance Fees & Guide charges. ",
  "Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
  "Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
  "Room Heater Charges.",
  "Insurance. ",
  "Anything which is not included in the inclusion",
  ];
const W11 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W11Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W11;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
