import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  C1Image,
  C2Image,
  C3Image,
  C4Image,
  C5Image,
  C6Image,
  C7Image
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const CANADA_TOUR = "/assets/img/worldTour/wt7.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Toronto Niagara",
    image: C1Image,
    description:"",
    path: "/c1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Nothern Light Experience",
    image: C2Image,
    description:"",
    path: "/c2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Enchanting Eastern Canada",
    image: C3Image,
    description:"",
    path: "/c3",
  },
  {
    id: 4,
    fromToDays: "09 Nights / 10 Days",
    title: "Best of Canadian Rockies",
    image: C4Image,
    description:"",
    path: "/c4",
  },
  {
    id: 5,
    fromToDays: "09 Nights / 10 Days",
    title: "Canadian Rockies Self Drive Tour",
    image: C5Image,
    description:"",
    path: "/c5",
  },
  {
    id: 6,
    fromToDays: "09 Nights / 10 Days",
    title: "Canadian Rockies Van Tour",
    image: C6Image,
    description:"",
    path: "/c6",
  },
  {
    id: 7,
    fromToDays: "10 Nights / 11 Days",
    title: "Classic Canadian Rockies",
    image: C7Image,
    description:"",
    path: "/c7",
  },
];

// const rulesData = [
//   ""
// ];

const Canada = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Canada"}
            subTitle={"Amazing Holidays to Canada"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={CANADA_TOUR}
            title={"Popular Canada Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Canada;










