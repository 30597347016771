import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const InclusionExclusion = ({ inclusionsData, exclusionsData }) => {
  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);

  const inclusions = () => {
    setShowFirst(!showFirst);
    setShowSecond(false);
  };

  const exclusions = () => {
    setShowSecond(!showSecond);
    setShowFirst(false);
  };

  return (
    <Container>
      <Grid>
        <Card>
          <CardContent>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={2} md={1.5}>
                  <Button
                    variant={showFirst ? "contained" : "outlined"}
                    onClick={inclusions}
                    fullWidth
                  >
                    Inclusions
                  </Button>
                </Grid>
                <Grid item xs={4} sm={2} md={1.5}>
                  <Button
                    variant={showSecond ? "contained" : "outlined"}
                    onClick={exclusions}
                    fullWidth
                  >
                    Exclusions
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardContent>
            {showFirst && (
              <Box sx={{ flexGrow: 1 }}>
                {inclusionsData?.map((item) => (
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      sm={0.5}
                      md={0.3}
                      // sx={{ display: "flex", alignItems: "center" }}
                    >
                      <CheckIcon fontSize="small" sx={{ color: "#00E676" }} />
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sm={11.5}
                      md={11.7}
                      // sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="caption" color={"text.secondary"}>
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
            {showSecond && (
              <Box sx={{ flexGrow: 1 }}>
                {exclusionsData?.map((item) => (
                  <Grid container>
                    <Grid item xs={1} sm={0.5} md={0.3}>
                      <CloseIcon fontSize="small" sx={{ color: "#F44336" }} />
                    </Grid>
                    <Grid item xs={11} sm={11.5} md={11.7}>
                      <Typography variant="caption" color={"text.secondary"}>
                        {item}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

InclusionExclusion.propTypes = {
  inclusionsData: PropTypes.func,
  exclusionsData: PropTypes.func,
};

export default InclusionExclusion;
