import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";

import PropTypes from "prop-types";

const ServiceSection = ({ title, serviceCardContent }) => {
  return (
    <Box>
      <Typography variant="h2" color={"text.secondary"} textAlign={"center"}>
        {title}
      </Typography>
      <br />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} rowGap={2}>
          {serviceCardContent?.map((item) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card sx={{ width: 345, borderRadius: "24px 0px 24px 0px", }}>
                <CardActionArea href={item.path}>
                    <CardMedia
                      height={"185"}
                      component="img"
                      image={item.image}
                      sx={{boxShadow: "4px 4px #00000025 inset" }}
                    />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 140,
                      left: 10,
                    }}
                  >
                    <Typography
                      variant="h3"
                      color={"text.secondary"}
                      sx={{
                        color: "text.light",
                        textShadow: "1px 1px 1px black",
                      }}
                    >
                      {item.serviceTitle}
                    </Typography>
                  </Box>
                </CardActionArea>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <CardContent>
                      {/* <Typography variant="h3" color={"text.secondary"}>
                        {item.title}
                      </Typography> */}
                      <Typography variant="caption" color={"text.secondary"} textAlign={"start"}>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Grid>
                  {/* <Grid item>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button variant="contained" href={item.path} sx={{height:30}}>
                        View More
                      </Button>
                    </CardActions>
                  </Grid> */}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

ServiceSection.propTypes = {
  title: PropTypes.string,
  serviceCardContent: PropTypes.func,
};

export default ServiceSection;
