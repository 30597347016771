import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { E5Image } from "../image";

const heroTextData = [
  {
    title: "Egypt Secret of Pharaohs Culture segment (Nile Cruise).",
    duration: "12 Nights / 13 Days",
    typoOfTrip: "Road Trip",
    startCity: "Cairo",
    endCity: "Cairo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival to Cairo international airport",
    details: " - Arrival from Cairo airport, meet and assist at the APT through our Airport guide. - Meet and assist at hotel. - Dinner At the hotel. - Overnight at Cairo Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Luxor - Valley of the Kings",
    details: "- Early Breakfast (Takeaway box) - Transfers to Luxor by Air - Visit Hatshepsut temple - Valley of the Kings - Shopping - Lunch at Nile View restaurant - Check in Luxor Hotel - Dinner and overnight at the Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Karnak Temple - Luxor Temple",
    details: "- Balloon Trip (optional) - Early Breakfast at Luxor Hotel - Visit Karnak Temple & afternoon Luxor Temple - Check In Nile Cruise. - Dinner and overnight at the nile cruise",
  },
  {
    id: "Day 4",
    header: "Esna Temple & Edfu temple",
    details: "- Breakfast at the nile cruise - Visit Esna Temple - Sound and light show at Edfu temple - Dinner and overnight at the nile Cruise",
    labelData: ["label1", "label2", "label3", "label4"],
  },  {
    id: "Day 5",
    header: "Kom Ombo Temple",
    details: "- Breakfast at the nile Cruise - Visit Kom Ombo Temple - Nubian Village (including camel ride, boat transfer, Nubian tea) - Dinner at the nile Cruise and overnight",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Aswan",
    details: "- Early Breakfast (Takeaway Box) - Transfer and Visit Abu Simble temple 2 - Lunch at Aswan - Visit High Dam - Unfinished Oblesique - Dinner at the nile cruise and overnight ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Transfer to Cairo Airport",
    details: "Breakfast at the nile cruise and Check out - Transfer to Cairo by Air (price excluded) - Arrival to Cairo Airport and transfer to Egyptian Museum - Lunch at Egyptian Restaurant - City tour at El-Moazz Street with Tea at Om Kalthom Café - Dinner at restaurant - Check in Cairo Hotel - Overnight at Cairo Hotel",
  },
  {
    id: "Day 8",
    header: "Pharaonic Egypt Tour",
    details: "- Breakfast at the Hotel - Full day Pharaonic Egypt Tour * - Arrival back to the hotel for refreshments (Optional Sound and light show) - Dinner at Chinese Restaurant - Overnight at the hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },  {
    id: "Day 9",
    header: "Transfer to Alexandria",
    details: "- Early Breakfast (Takeaway Box). - Transfer to Alexandria with our professional Egyptologists (English Language). - Visit Qaytbai Citadel , afternoon Bombay column Alexandria Library from Outside. - Afternoon Lunch (Sea Food Lunch). - Visit El Montazah Palace. - Transfers Back to Cairo Hotel. - Omar Oasis rest* for 30 min. - Dinner Oriental Restaurant. - Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Full day Old Cairo Tour",
    details: "- Breakfast at the hotel. - Full day Old Cairo Tour *(09:30 pm Old Cairo area (Coptic & Islamic) + 12:30 Citadel of Saladin + 13:30 Lunch at Mahony Restaurant + 16:30 City tour at Khan El-Khalily) - Dinner at Nile Cruise. - Overnight at the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Transfers to St. Catherine",
    details: "- Breakfast at the hotel and check out. - Transfers to St. Catherine. - Check in at St. Catherin Hotel. - Dinner at the Hotel. - Free Time till at night - at the Hotel.",
  },
  {
    id: "Day 12",
    header: "St. Catherin Monastery",
    details: "- Early Rise & Hike up to Moses Mountain to see the Sun rise. - Breakfast at the hotel after Mountain Hike and visit St. Catherin Monastery. - Lunch at Wadi El-Raha Vally. - Transfers Back To Cairo. - Check in at Cairo Hotel. - Dinner at the Hotel and overnight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 13",
    header: "Cairo Airport for Departure",
    details: "- Breakfast at the Hotel and Check out. - Transfer to Cairo Airport for Departure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"Accommodation as per program",
"Transfers and sightseeing by A/C coach",
"All Excursions and Tours with English speaking guide except optional tours.",
"One bottle of Mineral Water per person per day during the tour",
];

const exclusionsData = [
"Tips",
"Domestic Flights and International flights",
"Entry Visa will cost 45.00 USD each per each passport. Any Extras",
];

const E5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={E5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default E5;

// const intineraryTimelineData = [
  // {
  //   id: "Day 1",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 2",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 3",
  //   header: "",
  //   details: "",
  // },
  // {
  //   id: "Day 4",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
// ];
