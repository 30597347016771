import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U8Image } from "../image";

const heroTextData = [
  {
    title: "Eastern Elegance",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "New York",
    endCity: "Niagara",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive New York",
    details: "Welcome to the Big Apple, the City That Never Sleeps. On arrival you will be transferred to the Hotel. The day is free at leisure to explore the city on your own. Overnight stay in New York",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "New York City Tour",
    details: "Breakfast at hotel and proceed for New York City tour with entrance to Empire State Building & Statue of Liberty Ferry Ride on Seat in Coach basis.Highlights of the tour: Statue of Liberty Express One hour, Non-stop Cruise (Ferry Ticket Only), Empire State Building’s 86th floor Observatory (Admission Included), Rockfeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, etc. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "New York - Washington",
    details: "Breakfast at the Hotel. Depart to Washington DC the Capital of the United States of America and a Guide to the Historic Neighbourhoods and Monuments. Other than the federal government, tourism is DC`s biggest industry. The city attracts almost twenty million visitors each year. Arrive Washington, transfer to the Hotel. The day is free at leisure. Overnight in Washington.",
  },
  {
    id: "Day 4",
    header: "Washington City Tour",
    details: "After breakfast proceed to Union Station for Washington DC city tour on Seat in Coach basis.The most famous sights in Washington DC are the stars of this tour of the US capital. Tour the Capitol Building and visit the White House’s south lawn and visitor centre. Spend time at the Smithsonian Museums, before heading to the World War II and Dr Martin Luther King, Jr. memorials in addition to much more in between.Throughout the trip you see much of DC's finest monuments and structures including: the FBI Building, Federal Triangle, Ellipse, Tidal Basin, National Archives, Washington Monument, House and Senate Office Building, National Gallery of Art, and Grant Memorial. Enjoy lunch at your leisure at one of the stops.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Washington - Buffalo - USA Side Niagara Falls",
    details: "Check out from hotel and we transfer you to airport for flight to Buffalo. On arrival we transfer to Niagara the town of the world famous falls, which forms one of the natural wonders of the World. Transfer to Niagara Falls. The evening is free at leisure. Overnight stay in Niagara Falls.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Niagara Falls Discovery Pass",
    details: "What else can be a better way than Niagara Discovery Pass to explore the Niagara Falls.Discovery Pass allows admission to Maid of the Mist, Cave of the Winds, Niagara Adventure Theater, Gorge Discovery Centre & Aquarium of Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure from Niagara - Buffalo",
    details: "After breakfast, transfer to the Buffalo Airport fly back home or to the next destination.",
  },
];



const inclusionData = [
" 06 Night’s Accommodation with breakfast as per mentioned or similar",
" Guided City tour of New York with entrance to Empire State Building & Statue of Liberty ferry Ride only on Seat in Coach basis",
" Guided Washington city tour on Seat in Coach basis",
" Niagara Discovery Pass",
" New York Airport / Hotel transfers on Seat in Coach basis",
" New York Hotel / Coach Station Transfers on Private basis",
" One way Coach Ticket New York to Washington",
" Washington Coach Station / Hotel transfers on Private basis",
" Washington Hotel / Airport transfers on Seat in Coach Basis",
" Buffalo Airport / Niagara Hotel round trip transfers on Private basis",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U8 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U8Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U8;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
