import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { J5Image } from "../image";

const heroTextData = [
  {
    title: "Japan Golden Route 10N/11D",
    duration: "10 Nights / 11 Days",
    typoOfTrip: "Road Trip",
    startCity: "Narita",
    endCity: "Tokyo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Narita",
    details: "Arrival at Narita - Welcome at airport - Procced to Tokyo City - Sightseeing of Asakusa Sensoji & Nakamise Shopping Arcade - Lunch at Local restaurant (Own) - Sightseeing of Tokyo Sky Tree - Sightseeing of Ginza area - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Tokyo",
    details: "Breakfast at Hotel - Tranfer to Shinjuku station - Proceed to Hakoneyumoto - Enjoy Lake Ashi Cruise & Roapway - Proceed to Shinjuku - On arrival transfer to Hotel - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Tokyo",
    details: "Breakfast at Hotel - Tranfer to Tokyo Station - Proceed to Kyoto - On arrival - Sightseeing of Kiyomizu Temple - Sightseeing of Gion Area - Transfer to Hotel - Overnight at Hotel",
  },
  {
    id: "Day 4",
    header: "Kyoto",
    details: "Breakfast at Hotel - Hotel Departure - Sightseeing of Kamigamo jinjya, Kinkaku-Ji, Ryoan-ji Temple - Ninna-ji Temple, Kitano Tenmangu - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Kyoto",
    details: "Breakfast at Hotel - Hotel Departure - Sightseeing of Toji, Inari-taisya - Sightseeing of Nishiki Bazaar, Sanjusangendo, Nijo Castle, Heian-Jingu - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Kyoto",
    details: "Breakfast at Hotel - Hotel Departure - Sightseeing of Byodoinhoodo - Transfer to Nara - Sightseeing of Todai-ji, Kasuga taisya - Transfer to Osaka - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Osaka",
    details: " Breakfast at Hotel - Enjoy Unibersal Studios - Transfer to Hotel - Overnight at Hotel",
  },
  {
    id: "Day 8",
    header: "Osaka",
    details: "Breakfast at Hotel - Hotel Departure - Sightseeing of Osaka castle & Cruise - Sightseeing of Umeda Sky Building - Sightseeing of Namba, Shinsaibashi - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Osaka",
    details: "Breakfast at Hotel - Transfer to Shinosaka - Proceed to Tokyo - Sightseeing of Shibuya, Harajuku, Meiji-jingu - Sightseeing of Akihabara - Transfer to Hotel - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Tokyo",
    details: "Breakfast at Hotel - Enjoy Tokyo Disney land - Transfer to Hotel - Overnight at Hotel",
  },
  {
    id: "Day 11",
    header: "Tokyo",
    details: "Breakfast at Hotel - Drive to International airport - Flight to Delhi",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [""];

// const exclusionsData = [""];

const J5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={J5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default J5;

// const intineraryTimelineData = [
  // {
  //   id: "Day 1",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 2",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 3",
  //   header: "",
  //   details: "",
  // },
  // {
  //   id: "Day 4",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
// ];
