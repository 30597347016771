import React from "react";
import { CardMedia, Grid, Typography, Card, Box } from "@mui/material";

import PropTypes from "prop-types";

const TourHeroSection = ({ mainTitle, details, heroImg }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Box pt={4}>
          <Typography
            variant="h1"
            color={"text.tertiary"}
            sx={{ display: "inline" }}
          >
            {mainTitle}
          </Typography>
          <Typography
            variant="h1"
            color={"text.secondary"}
            sx={{ display: "inline", ml: 1 }}
          >
            Tour Packages
          </Typography>
          <Typography
            color={"text.secondary"}
            variant="body1"
            textAlign={"justify"}
          >
            <br />
            {details}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: "195px 0px 195px 195px",
            display: { xs: "none", sm: "flex", md: "flex" },
            maxWidth: "415",
          }}
          elevation={3}
        >
          <CardMedia
            component="img"
            image={heroImg}
            height="315"
            // alt={alt}
            loading="lazy"
            sx={{ objectFit: "cover" ,}}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

TourHeroSection.propTypes = {
  mainTitle: PropTypes.string,
  tourCardContent: PropTypes.object,
};

export default TourHeroSection;
