import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { RH1Image } from "../image";

const heroTextData = [
  {
    title: "Lovely Heritage Pink City",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jaipur",
    endCity: "Udaipur",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Jaipur Arrival",
    details: "Welcome and Arrive Jaipur Airport / Railway Station and meet our local representative who will transfer to the hotel, proceed to Amber fort, built in 16th century, enjoy an Elephant ride (By Own) at Amber, the Observatory Visit the Sheesh Mahal or the Hall of Victory glittering with mirrors. Photo stop at Jal mahal. Drive past the Hawa Mahal. Overnight at hotel in Jaipur. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jaipur",
    details: "After Breakfast, proceed to tour of Jaipur. Jaipur - the capital of Rajasthan was given a colour coat of pink a century ago in honour of a visiting Prince and ever since, it has retained this colour. Built by Maharaja Jai Singh, the notable astronomer, this city is 260 years old. Visit Maharaja's City Palace, Jantar Mantar, Albert Hall, Birla Temple. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jaipur - Udaipur",
    details: "After Breakfast & Check out from hotel. Depart for Udaipur. Arrive at Udaipur and transfer to the hotel. Udaipur, 'the city of lakes' is admired for its fairy-tale palaces, lakes, temples, and gardens among many more. Visit Sajjangarh (Monsoon Palace), Shilpgram and Bhartiya Lok Kala Mandal. Overnight stay at Udaipur.",
  },
  {
    id: "Day 4",
    header: "Udaipur",
    details: "After Breakfast, Proceed to Morning city sightseeing tour. Built in 1559 A.D. by Maharaja Udai Singh, the city has been described as the most romantic spot on the continent of India by Col. James Todd. The places of interest include City Palace which has several carved balconies, arches and decorated pillars, Zenana Mahal, Sahelion-ki-Bari (queen's resort for the friends), Enjoy Boat Ride at Lake Pichola (Own) Visit to Fateh Prakash, Moti Magari, Durbar Hall and Shambhu Niwas. Also visit Jagdish temple. Overnight stay. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Udaipur Drop",
    details: "After Breakfast, check out from Hotel and Proceed to Drop at Udaipur Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
  ];
  
  const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
  ]

const RH1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={RH1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default RH1;


// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
