import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U13Image } from "../image";

const heroTextData = [
  {
    title: "Wonderful USA",
    duration: "11 Nights / 12 Days",
    typoOfTrip: "road trip",
    startCity: "New York",
    endCity: "San Francisco",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive in New York",
    details: "Welcome to the Big Apple, the City That Never Sleeps. On arrival you will be transferred to the Hotel. The day is free at leisure to explore the city on your own. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "New York City Tour",
    details: "Breakfast at hotel and proceed for New York City tour with entrance to Empire State Building & Statue of Liberty Ferry Ride on Seat in Coach basis. Highlights of the tour: Statue of Liberty Express One hour, Non-stop Cruise (Ferry Ticket Only), Empire State Building’s 86th floor Observatory (Admission Included), Rockfeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, etc. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "New York - Washington",
    details: "Check out from hotel and we drop you to coach station for onward journey to Washington DC, capital of the United States of America and a Guide to the Historic Neighborhoods and Monuments. Evening proceed for Night tour of Washington on Seat in coach basis. When the sun goes down, Washington turns into a different city, and Old Town Trolley Tours knows just the right way to show off the Capitol City at night! See the Capitol Building, the White House, the Jefferson Memorial and the Washington Monument. The trolley will also make stops at the Franklin D. Roosevelt Memorial, the Lincoln Memorial, the Vietnam Veteran's Memorial, the Korean War Veteran's Memorial and the Iwo Jima Memorial. Enjoy of the most emblematic monuments of the city, crossing them in the light of the moon. ",
  },
  {
    id: "Day 4",
    header: "Washington - Buffalo – USA side Niagara Falls",
    details: "Take a flight to buffalo and upon arrival we transfer you to Niagara where the world famous falls is located. It is one of the natural wonders of the World. Rest of the day is Free at leisure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "USA side Niagara Falls",
    details: "On your own proceed to experience the famous boat ride “Maid of the mist” which takes us close to the thundering falls and around the American and Horseshoe Falls (seasonal). Overnight stay at Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Las Vegas",
    details: "Depart in the morning for your flight to Las Vegas. On arrival, transfer to your hotel. The Night Tour takes you all the way along the Strip to the famous Las Vegas welcome sign, before heading back to Down Town where you'll have the change to enjoy the Fremont Street Experience .As you travel past Las Vegas' illuminated landmarks, The experienced guides on tour will tell the story of Las Vegas and will highlight the city's most impressive views. The Night Tour lasts approximately 3 hours including time at Fremont Street. Return back to the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: " Grand Canyon (Optional)",
    details: "Day is free to explore to take an optional tour to Grand Canyon. There are multiple options to explore the Canyon by Bus, Helicopter, Aeroplane and can add Sky Walk & Boat ride. Evening you can even enjoy a Helicopter Ride over dazzling night time Vegas, visit any of musical shows. Overnight stay.",
  },
  {
    id: "Day 8",
    header: "Las Vegas- Los Angeles",
    details: "Enjoy breakfast at the hotel and then we drop you to coach station for onward journey to Los Angeles. Upon arrival get transferred to Hotel. Evening proceed for Los Angeles city tour driving you through the “Entertainment capital of the world”, Hollywood, Hollywood Walk of Fame, Beverly Hills, Grumman Chinese Theater, Sunset strip, Rodeo Drive for the world’ most expensive shopping and China town for some good bargains.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Los Angeles - Universal Studios",
    details: "Join us today for a tour of Universal Studios which has always been known for its innovative rides and attractions, and is designed to let visitors experience the movies like never before. A trip to Universal is like a trip into the heart of Hollywood. With films and TV shows always in production, one never knows who they might see wandering the sidewalks of the park.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Los Angeles - Disney Land",
    details: "Enjoy a day trip to Disneyland where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Los Angeles - San Francisco- Panoramic San Francisco by Night Tour",
    details: "Fly to San Francisco, which is one of the few North American cities to achieve the legendary status of such places as Paris or London. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
  },
  {
    id: "Day 12",
    header: "Departure from San Francisco",
    details: "After breakfast, check out from hotel and we transfer you to airport for onward journey. End of tour and services",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"11 Night’s Accommodation with breakfast as per mentioned or similar( No Breakfast in Vegas)",
"All Airport – Hotel – Airport Transfers ON Seat in Coach basis",
"New York – Washington Bus",
"New York Hotel to Bus Station and Washington Bus Station to Hotel on Private",
"Las Vegas to Los Angeles Bus",
"Las Vegas Hotel to Bus Station and LOS Angeles Bus Station to Hotel on Seat in Coach basis",
"New York City Tour on Seat in Coach",
"Washington by Night Tour on Seat in coach",
"Las Vegas by Night on Open Top bus on Seat in coach",
"Los Angeles Hop on and Hop off 24 Hrs",
"San Francisco by Night Panoramic Tour on Seat in coach",
"Entrance Tickets with Transfers : Statue of Liberty, Empire State Building, Maid of the Mist, Universal Studios, Disneyland ( All Tours on Se)" 
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U13 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U13Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U13;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];


