import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { C1Image } from "../image";

const heroTextData = [
  {
    title: "Toronto Niagara",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Toronto",
    endCity: "Toronto",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Toronto – Niagara - Niagara Falls by night",
    details:
      "Arrive into Toronto’s Pearson International airport. After clearing the immigration procedures proceed towards Niagara on SIC coach/Van. Arrive in Niagara and check in to your hotel. Evening is free at leisure. You may enjoy Illumination show of Niagara Falls by Night (On your Own).Overnight at the hotel. brightness_3Night Stay: Niagara Falls local_diningMeals: Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Full day walking tour of Niagara Falls – Toronto",
    details:
      "After Breakfast, Check out of the hotel and proceed for Full day walking tour of Niagara Falls. Enjoy the Horn blower Niagara Cruise. Also visit The Niagara’s Fury – a 3D movie experience which takes you through the ancient story of Niagara Falls. Later take the Journey behind the falls. Enjoy the White Water Walk - A walk like no other, along a boardwalk at the very edge of one of the world's wildest stretch of whitewater! Later on, proceed to Toronto on SIC basic and check in to your hotel in Toronto. Overnight at the hotel. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Toronto – City Tour of Toronto – CN Tower",
    details:
      "After breakfast, proceed for the City tour of Toronto on Hop on Hop off basis. Visit the world famous CN Tower. Take a ride to the Observatory Deck atop the majestic and monumental CN tower. Later on visit Harbor front, Dundas square, Casa Loma, Queen’s park, Chinatown, Roger’s Center etc. Evening is free at leisure to explorer downtown Toronto. Return back to you hotel and relax. Overnight in the hotel. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
  },
  {
    id: "Day 4",
    header: "Toronto Airport",
    details:
      "After breakfast, check out of the hotel and transfer to the airport on SIC basis to board your flight towards your next destination. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Accommodation in above mentioned Hotel or Similar category.",
"Daily Hotel Breakfast.",
"Full day Walking tour of Niagara Falls Covering visit to Journey behind the fall, Horn blower Cruise, Niagara’s Fury, White Water Walk",
"City tour of Toronto on SIC basis",
"Admission to CN Tower",
"Toronto Airport to Niagara Falls One way Airport Drop off on SIC",
"Niagara hotel to Toronto hotel on SIC basis",
"Toronto hotel to Toronto airport on SIC basis",
];

const exclusionsData = [
  "Airfare, Visa, any kind of personal expenses.",
];

const C1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={C1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default C1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
