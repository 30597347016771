import React from "react";
import { Box, Grid,Container } from "@mui/material";

import PropTypes from "prop-types";

import HeroTourDetailSection from "./HeroTourDetailSection";
import InclusionIcons from "./InclusionIcons";
import OverviewSection from "./OverviewSection";
import Itinerary from "./Itinerary";
import InclusionExclusion from "./InclusionExclusion";

const TourDetails = ({ heroImg,heroTextData,overview,intineraryTimelineData ,inclusionsData,exclusionsData}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Container>
          <HeroTourDetailSection 
            heroImg={heroImg}
            heroTextData={heroTextData}
          />
        </Container>
        </Grid>
        <Grid item xs={12}>
          <InclusionIcons />
        </Grid>
        {/* <Grid item xs={12}>
          <OverviewSection 
            overview={overview}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Itinerary intineraryTimelineData={intineraryTimelineData} />
        </Grid>
        <Grid item xs={12}>
          <InclusionExclusion
            inclusionsData={inclusionsData}
            exclusionsData={exclusionsData}
            
          />
        </Grid>
      </Grid>
    </Box>
  );
};

TourDetails.propTypes = {
  heroImg: PropTypes.string,
  heroTextData: PropTypes.func,
  overview: PropTypes.string,
  intineraryTimelineData: PropTypes.func,
  inclusionsData: PropTypes.func,
  exclusionsData: PropTypes.func,
};

export default TourDetails;
