import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EA6Image } from "../image";

const heroTextData = [
  {
    title: "Kenya Safari",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Nairobi",
    endCity: "Masai Mara",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Nairobi",
    details:
      "Arrive at Jomo Kenyatta International Airport Nairobi; after you disembark off the flight and are assisted through immigration and custom procedures, our team will meet you and transfer you to your hotel. Overnight at a Nairobi hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Nairobi - Tree Hotel (200 KM – 02.30 HRS)",
    details:
      "After breakfast, leave Nairobi in the morning to Nyeri, a small but fast growing town in the heart of the coffee growing and dairy farming area in the so-called former White Highlands of Kenya. After a sumptuous lunch at the base hotel, the tour leaves for a tree hotel where the evening is spent watching succession of animals that emerge from the forest and descend on floodlit water hole and salt lick, scuffling and jostling for a good vantage position. At night if you want to remain awake and watch the show-the Elephants may engage in a show of strength with each other but even they would be reluctant to challenge the rhino. The herbivores keep their distance from these two competitors. In the case of Mountain Lodge you arrive directly at the lodge for lunch. Meals and overnight at a Tree hotel. (BLD) You are required to pack an overnight bag when you transfer to the Ark for the night.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Tree Hotel – Fairmont Mount Kenya Safari Club (80 KM – 01.30 HRS)",
    details:
      "After breakfast a short drive brings you to the Fairmont Mount Kenya Safari Club, which is situated at the foot of Mount Kenya (17,058 feet). You also get a chance to stop at the Equator. With magnificent views of majestic Mount Kenya, the Fairmont Mount Kenya Safari Club Resort's 120 luxuriously appointed hotel rooms, set in over 100 acres of landscaped gardens, offer a unique blend of comfort, relaxation and adventure. Originally the retreat of movie star and Club founder, William Holden, the Club's illustrious former members have included Winston Churchill and Bing Crosby. There are many recreational activities available: horseback riding, golf, croquet, a bowling green, table tennis, swimming, a beauty salon, an animal orphanage and much more. After lunch, enjoy the activities at the hotel. Meals and overnight at the Fairmont Mount Kenya Safari Club. (BLD)",
  },
  {
    id: "Day 4",
    header:
      "Nakuru/Naivasha – Masai Mara (300 KM – 05.30 HRS/ 230 KM – 05.30 HRS)",
    details:
      "After breakfast, drive through the dramatic Great Rift Valley to the Masai Mara National Reserve. This enormous reserve is actually part of the vast Serengeti plains famously known for its spectacular great wildebeest’s migration and is home of the Big Five: Lion, Elephant, Leopard, Buffalo and Rhino. Lunch at your Lodge/ camp and relax before departing for an afternoon game drive. The Mara Game Reserve – one of the greatest wildernesses of the world. Large mammals are varied, and easy to see. Residents among the Parks are: Masai Giraffe, Buffalo, Eland and thousands of plain game including Impala, Zebra, Topi, both Thomson's and grants Gazelles. Meals and overnight at a lodge/ camp in Mara. (BLD)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Masai Mara",
    details:
      "After breakfast, proceed on a morning game-viewing drive to search for the myriad species of game that make this reserve their home. If you are lucky, you can witness the kill in action, moment by moment, right in front of your unbelieving eyes! After a sumptuous lunch at your lodge, proceed for another exciting game drive, where you can expect to see the famous 'Big 5'. Meals and overnight at a lodge/camp in Mara. (BLD)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Masai Mara – Nairobi (270 KM – 05.00 HRS)",
    details:
      "After breakfast checkout and drive back to Nairobi arriving in time for lunch. Lunch on own. Later transfer to Jomo Kenyatta International Airport for your onward flight. (B) END OF SERVICE",
  },
];

const inclusionData = [
  "Accommodation as indicated in the itinerary",
  "Meals as described by B (Buffet Breakfast), L (Lunch), D (Dinner)",
  "National parks and reserve entrance fees",
  "Airport transfers as indicated",
  "Transport in a 7 seater safari minibus with pop up hatch roof for easy photography",
  "Game drives as per the itinerary",
  "Services of an experienced safari driver guide",
  "Complimentary mineral water {one litre per person per day}",
];

const exclusionsData = [
  "Personal expenses such traveling insurance, excess baggage fee, communication charges that include, emails, faxes and phone calls beverages including luxury champagne, luxury spirits or meals not indicated in the itinerary",
  "Gratuity for Porterage / waiters at hotels, camps and airports",
  "Gratuity for your professional driver guide",
  "Cost of obtaining passport and visas",
  "International Airfare",
];

const EA6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EA6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EA6;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
