import React from "react";

import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const holidayThemeData = [
  {
    title: "India Tours",
    href: "/domestic-tour",
  },
  {
    title: "World Tours",
    href: "/world-tour",
  },
  {
    title: "Yatra Tour",
    href: "/yatra-tour",
  },
  {
    title: "Student special Tour",
    href: "/student-tour",
  },
  {
    title: "Honeymoon Tours",
    href: "/honeymoon-tour",
  },
  {
    title: "Popular Tours",
    href: "/popular-tour",
  },
];

const MenuButton = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" ,py:"0px "}}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Tour Package" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {holidayThemeData.map((item) => (
              <ListItemButton sx={{ pl: 4 }} href={item.href}>
                <ListItemText primary={item.title} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </Box>
  );
};

export default MenuButton;
