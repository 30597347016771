export const NE1Image = "/assets/img/worldTour/Nepal/NE1.png";
export const NE2Image = "/assets/img/worldTour/Nepal/NE2.png";
export const NE3Image = "/assets/img/worldTour/Nepal/NE3.png";
export const NE4Image = "/assets/img/worldTour/Nepal/NE4.png";
export const NE5Image = "/assets/img/worldTour/Nepal/NE5.png";
export const NE6Image = "/assets/img/worldTour/Nepal/NE6.png";
export const NE7Image = "/assets/img/worldTour/Nepal/NE7.png";
export const NE8Image = "/assets/img/worldTour/Nepal/NE8.png";
export const NE9Image = "/assets/img/worldTour/Nepal/NE9.png";
export const NE10Image = "/assets/img/worldTour/Nepal/NE10.png";
export const NE11Image = "/assets/img/worldTour/Nepal/NE11.png";
