import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { DU1Image } from "../image";

const heroTextData = [
  {
    title: "Shopping Fest",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Dubai",
    endCity: "Dubai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Dubai Airport Transfer to Hotel",
    details:
      "Dubai is the city of wonder, it is the city of shimmer, and it is definitely the city of everything extravagant! On your arrival at the Dubai International Airport, we will transfer you to your city-centre hotel. Check into your room and get some rest. In the evening set sail for an unforgettable night out in Dubai on a relaxed cruise along the stylish Dubai Marina. Retrace Dubai’s past and experience its captivating culture as you sail aboard a traditional Arabian wooden dhow. Float past some of the region’s iconic attractions and get a whole new perspective on Dubai’s unrivalled modern sights. Entertainment will be arranged onboard and you will be cruising over the Dubai’s Marina while enjoying the savory dishes and a complimentary drink. The stunning view, sumptuous meals and the live entertainment will give you a dazzling experience. After an eventful evening, we will transfer you back to your hotel for an overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Dubai City Tour with Burj Khalifa",
    details:
      "Enjoy a delicious breakfast at the hotel Today, we take you for a half day city tour of Dubai giving you a glimpse of important landmarks and buildings of the emirate. You will be picked up from your hotel by a bus where an expert English-speaking guide awaits you and welcomes you onboard. You get to see two different sides of Dubai, the historical one and the modern one. Beginning with the Dubai Museum, located in the Al Fahidi Fort (built in the 18th century), you’ll get to experience Dubai before it discovered oil and became the power city it is today. This is followed by a drive along the Dubai Creek, which is a historical area and still holds remnants of the past. While the historical part of the city is quite interesting, your Dubai city tour would not be complete if you do not see its modern side. So now get ready to see some of the emirate’s futuristic skyscrapers and awe-inspiring architectures! We’ll drive you past Sheik Zayed Road, allowing you to catch a glimpse of Dubai’s modern icons like Mall of the Emirates, Burj Khalifa, Dubai Mall and Burj Al Arab along the way. In the evening, head to Burj Khalifa. Enjoy the spectacular views of Dubai from the world's tallest building. Take a look at the marvelous building from the inside by joining the Burj Khalifa Tour with us. During the tour, you are shown a multimedia presentation about the history of Dubai as well as the story of the construction of the building. Once you have gathered information about this tall structure, you are taken to the 125th observatory deck (At the Top) on the fastest elevator. Here you get a chance to have a 360-degree panoramic view of the entire city of Dubai. This tour package is perfect for those who want to view the entire city of Dubai in a short period of time. Return to your hotel for an overnight stay. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Desert Safari with BBQ Dinner and Tanoura Show",
    details:
      "Enjoy a delicious breakfast at the hotel. Later in the afternoon head out to get up close and personal with the deserts of Dubai. A thrilling experience amidst the large expanse of the Dubai deserts and its glistening dunes, deem your visit to Dubai incomplete if you don't take the Desert Safari. A swanky land cruiser picks you up from the hotel and transports you to the desert. Once there, you are first taken for an adrenaline rushing dune bashing experience where you climb on top of tall dunes in high speed in your 4 X 4 vehicle. Admire the desert sunset views as you stop along the way to spend more time amid the sands. Do not miss the chance to take some breathtaking pictures of the desert in your camera. Soft drinks are served on the house. Next, you get to watch the world famous Tanoura dance which leaves you spellbound. Belly dancers are up next to give you a truly Arabic experience. Once the recreation is over, feast yourself on a fulfilling dinner under the beautiful star-studded sky and call it a night. At an additional cost, you can enjoy various other options available in Desert safari experience like camel rides, henna tattooing, sand boarding, Falcon photography, Quad biking etc. (Shisha smoking facility is available at the camps, Quad biking and alcohol are available on prior request and surcharge) Overnight stay at your hotel.",
  },
  {
    id: "Day 4",
    header:
      "Full Day Shopping Tour (Outlet Mall, Deira City Centre, Gold Souk, Abra Ride, Meena Bazaar)",
    details:
      "Enjoy a delightful breakfast at the hotel and you will be picked up from hotel on sharing basis and proceed to Outlet Mall. After spending time in Outlet mall then the next stop is Deira City Centre, City Centre Deira encompasses over 123,028 square meters of retail space and features over 371 retail stores and services. Next stop is Gold Souk, located in Deira, Gold Souk shops are among the most visited places in Dubai, selling jewellery, gemstones and precious metals. Board the Abra ride and from Deira to Bur Dubai. Shop at Meena Bazaar, you’ll find hundreds of merchants selling fabric on reels, ready-made traditional garments, shoes, jewellery and bags. Among the bustling alleys, you can discover some of Dubai’s oldest gold and textile businesses. Visit Meena Jewellers, one of the most popular gold shops in the area, known for its legacy and craft. Later return back to your hotel for an overnight stay. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure to Dubai Airport",
    details:
      "After breakfast, we check-out from our hotel & transfer to the airport to catch a flight back home. We return home with bags full of souvenirs and happy memories..",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  ,
];

// const inclusionData = [
//   "03 nights’ accommodation at respective hotel in Kathmandu with breakfast only ",
//   "Airport / Hotel / Airport transfers by an a/c vehicle exclusively",
//   "Sightseeing as per the itinerary by an a/c vehicle exclusively (Guide and entrance fee not included payable directly)",
//   "Applicable taxes",
// ];

// const exclusionsData = [
//   "Services other than specified.",
//   "Entrance fee at the monumental sites during the sightseeing",
//   "Guide during the sightseeing",
//   "Anything not mentioned in inclusions",
//   "Any expenses caused by reasons beyond our control such as flight delays, rescheduling or cancellations, any accidents, medical evacuations, riots, strikes, etc. ",
//   "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above. ",
//   "Any other tax or service charge that could be enforced during this period will be extra & need to borne by you.",
// ];

const DU2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={DU1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default DU2;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
