import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const KANYAKUMARI = "/assets/img/domesticTour/kerala/KANYAKUMARI.png";

const heroTextData = [
  {
    title: "Cochin, Munnar, Thekkady, Kovalam and Kanyakumari",
    duration: "07 Nights / 08 Day",
    typoOfTrip: "Road Trip",
    startCity: "Cochin",
    endCity: "Kanyakumari",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival at Cochin",
    details:
      "Once you arrive at Cochin airport or railway station, our executives will pick you up and drop you to the hotel. Spend the rest of the evening at leisure across the nearby markets.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Across the nooks and corners of Cochin and drive to Munnar",
    details:
      "After the morning meal, go for a touring in and around Cochin which incorporates The Chinese angling nets, Dutch Palace, Portuguese engineering the Jewish Synagogue, and St. Francis Church. After the touring, drive to Munnar. On landing, check into the hotel in Munnar and spend the evening at leisure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "across Munnar",
    details:
      "After the morning meal, continue for touring of Munnar which incorporates Mattupetti Dam, Rajamala, Kundala dam Eravikulam National Park, Echo Point and more. Once sightseeing is over, retire for the day in your comfortable, cozy room in Munnar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Into the wild at Thekkady",
    details:
      "After breakfast at the retreat, executives will drive you to Thekkady. On landing in Thekkady, register with the lodging at Thekkady. Spend the rest of the day as you wish touring across the little paths here.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Boating and more at Periyar National Reserve in Thekkady and then drive to Kumarako",
    details:
      "Early morning today you will be spending time boating in the lake amidst the thick greenery. You will then be taken to Kumarakom. On landing register with the lodging in Kumarakom. Evening tour in and around Kumarakom.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Destination Kovalam",
    details:
      "After breakfast, drive to Kovalam. Enroute visit of Srichitra Art Gallery, Napier Museum and Sri Padmanabha Swami Temple. On landing in Kovalam register with the inn in Kovalam. Spend the rest of the night at leisure in Kovalam.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "To Kanyakumari (2 h 28 min, 90.0 km)",
    details:
      "After breakfast at the lodging, drive to Kanyakumari for touring. Drive back to Kovalam in the night and relax the night.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Departure",
    details:
      "After a sumptuous breakfast at the hotel, our executives will drop you back to the airport or railway station. Your tour comes to an end today.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const CochinMunnarThekkadyKovalamandKanyakumariTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KANYAKUMARI}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CochinMunnarThekkadyKovalamandKanyakumariTourPackages;

