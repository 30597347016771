import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { JO8Image } from "../image";

const heroTextData = [
  {
    title: "Regular in The Eyes of The Holy Land & Jordan",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "Ben Gurion",
    endCity: "Amman",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Airport Ben Gurion - Jerusalem 110 KM",
    details:
      "Transfer from Ben Gurion Airport to the hotel in Jerusalem for overnight in Jerusalem hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jerusalem - Sightseeing of the new city (B, D) 100 KM",
    details:
      "Breakfast at the hotel then departure for a panoramic view of Jerusalem from the top of Mount of Olives. On to the Garden of Gethsemane and Church of the Agony. Continue to the Israel Museum’s Shrine of the book with the famous Dead Sea scrolls, then a visit to the beautifully renovated model of Herodian city of Jerusalem, dating back to the time of the second temple. Proceed to Yad Vashem (the memorial to the six million Jews who were killed in the Holocaust). Afternoon drive to the picturesque village of Ein Karem (The Birth place of John the Baptist).",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jerusalem - Old City - Bethlehem - Jerusalem (B, D) 100 KM",
    details:
      "Breakfast at the hotel. Enter through one of the Gates to the walled old city to visit the renewed jewish quarter, the Western Wall, the Temple area and the colorful bazaars. Walk along the Via Dolorosa and the Stations of the Cross, ending at the church of the Holy Sepulchre. Drive to Mount Zion and visit the tomb of King David, the Room of the Last Supper and Dormition Abbey. In the afternoon continue to Bethlehem to visit the Church of the Nativity and a view of the Shepherd’s Fields, back to Jerusalem for dinner and overnight at the hotel.",
  },
  {
    id: "Day 4",
    header: "Jerusalem - Beit Shean - Nazareth - Galilee (B, D) 300 KM",
    details:
      "Breakfast at the hotel. Leave Jerusalem and drive to north via Jericho, the oldest city in the world. Travel through the Jordan Valley to Beit Shean. Visit the Excavations of This beautifully preserved Roman City. Proceed to Nazareth to visit the Church of the Annunciation and the Carpentry workshop of Joseph. On to Safed, city of the Kabala, for a walk through the mystic alleys and the artists’ colony. Dinner and overnight at Galilee hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Galilee - Golan Heights - Sea of Galilee (B, D) 200 KM",
    details:
      "Breakfast at the hotel. Drive to Golan Heights. Visit the former Syrian fortifications and the Druze villages. Travel via Banias, source of the Jordan River to the shores of the Sea of Galilee and Capernaum. Visit the ruins of the ancient synagogue and Peter’s house. visit Tabgha, where the miracle of the fish and bread took place and on the Mount of Beatitudes. Continue to Tiberius for a short tour of the old center of Jewish Learning. Return to the Galilee hotel for dinner & overnight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Galilee - Sheikh Hussein - Jerash - Amman Panoramic (B, D) 165 KM",
    details:
      "Breakfast at the hotel. Depart to Sheikh Hussein Bridge to arrive to Jordan. Transfer to visit Jerash, one of the most complete and best preserved of the Roman Empire. A walk-through time visiting its streets, theaters, temples and other places like the Great Colonnade, the Arc of Triumph, the Oval Plaza, as well as the Temples of Zeus and Artemis. Continue to Amman for a panoramic visit. Check in hotel dinner and overnight in Amman hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Amman - Madaba – Mount Nebo – Dead Sea - Petra (B, D) 320 KM",
    details:
      "Breakfast at the hotel. Departure to Madaba the City of Mosaics where you will visit the church of St. George which houses the map of the Holy Land made ​​in 571 D. C. Continue to Mount Nebo, known as the tomb of Moses, from whose summit a magnificent view of the Jordan Valley. Then transfer to the Dead Sea which sits 400 meters below sea level. The Dead Sea is one of the most incredible places in the world. It is known for the Biblical story of the destruction of Sodom and Gomorrah, which was turned into salt. The Dead Sea speaks for itself; there is no living fish! It is by far one of Jordan's most impressive and spectacular places to visit. Its calm shining water glowing from its scorching sun known for the concentration of salt and minerals, which makes it a therapeutic as well as a unique swimming experience not to be missed! Free time at the Dead Sea Tourist Beach Rest house, for a short swim before departure to Petra. Dinner and overnight at the hotel",
  },
  {
    id: "Day 8",
    header: "Petra (B, D) 15 KM",
    details:
      "After breakfast, visit the Nabatean Red Rose City of Petra, which is one of the Seven Wonders of the World. A city carved in stone more than 2000 years ago to dominate the trade routes of ancient Arabia. First, you will walk the “siq”, a narrow path of 1.2 kilometers, which is the main entrance to the city. At the end of the “siq”, you will see the treasury Khazneh - which was believed to hold countless treasures. The first view of the Khazneh is breath-taking. Other monuments you will visit are the Theatre, the Byzantine Church, and the Royal Tombs. Dinner and overnight at Petra hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Petra - Wadi Rum - Amman (B, D) 420 KM",
    details:
      "After breakfast depart to Wadi Rum, called the valley of moon where the movie Lawrence of Arabia was filmed for a classic 2 hours visit of Wadi Rum in a 4x4 local jeeps. Wadi Rum is a uniquely shaped massive mountain that rose amongst the rosy red sand of the desert with towering cliffs of various brownish, reddish, and golden colors. Return to Amman for dinner and overnight in Amman hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Amman - Amman Airport (B) 40 KM",
    details:
      "Breakfast and transfer to Amman International Airport to coincide with the departure flight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Meet and Assist with Our Representative",
  "Transfers Airport - Hotel - Airport",
  "Leisure Transportation according number of participants with 1 luggage and 1 hand bag per person",
  "Accommodations in double or twin rooms with single supplement on selected hotel options: 2 nights Amman and 2 nights Petra",
  "Meals as per itinerary (B = Breakfast, D = Dinner)",
  "Classic visits mentioned with entrance fees included in the tour itinerary and Jeep 4x4 (classic tour) in Wadi Rum",
  "English / French Speaking Local Guide for visits",
  "Visa Exemption Service",
];

const exclusionsData = [
  "Visa and Exit Fees. Visa fees: 40 JOD approximately 60 USD per person and exit fees thru Airport 30 JOD approximately 45 USD per person included in the air ticket. Exit fees by any bridge 10 JOD approximately 15 USD per person. Consult for visa exemption services and free group collective visa entry.",
  "Lunches.",
  "All kinds of drinks and water.",
  "All kinds of tips and portages.",
  "Personal expenses.",
  "Travel Insurance.",
  "Any Service not mentioned in the program",
];

const JO8 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={JO8Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default JO8;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
