import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC4Image } from "../image";

const heroTextData = [
  {
    title: "Danang - Hoi An- My Son - Hue",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "DaNang",
    endCity: "Hue",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Danang - Hoi An (L/-)",
    details:
      "Meet on arrival at Danang International airport and visit Cham Museum, Marble Mountains, and Da Nang Beach before proceeding to Hoi An Ancient Town. Have lunch at a local restaurant - Discover the historic town was a prosperous seaport city during the 16th to 18th centuries. Its sizeable community of foreign merchants created a unique architectural style from Vietnamese, Chinese, and Japanese influences. You will have a walking tour of the Chinese Assembly Hall & Temples, ancient houses & Japanese Covered Bridge. Dinner and overnight in Hoi An. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Hoi An - My Son - Hoi An (L/-)",
    details:
      "Have breakfast at the hotel. Begin the day with an excursion to My Son Holy Land of the former Cham Kingdom (the ruins of the former Cham Capital in the 4th century). Have lunch at a local restaurant. This afternoon you will visit Thanh Ha Pottery village. At every turn, you will see the remarkable tenacity of the local people as they work at their craft that has passed through generations. Courtyards are lined with hand-cut bricks, drying in the accommodating sun. Village elders turn the potting wheels while their children shape the vessels.. Have dinner at a local restaurant and Overnight in Hoi An. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Hoi An - Danang - Hue (L/-)",
    details:
      "Breakfast at the hotel. In the morning, drive to Hue via Hai Van Pass (Pass of the Ocean & Clouds), pass through many pretty red-tiled villages with fruit orchards, and enjoy spectacular views of the sky and sea. Upon arrival at the hotel for check-in. Have lunch at a local restaurant. P.m.: Visit Imperial Citadel, Imperial Museum and Dong Ba market. Have dinner at local restaurant. Overnight in Hue. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Hue city tour (L/-)",
    details:
      "Breakfast at the hotel. Visit Thien Mu Pagoda and Minh Mang tomb. Have lunch at local restaurant followed by a visit to Tu Duc & Khai Dinh tombs. Free Overnight in Hue.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Hue - Departure",
    details:
      "Have breakfast at the hotel. Leisure time until transfer to Phú Bài Airport for departure.",
  },
];

const inclusionData = [
  "All transfers and excursion per tour programs",
  "Good English speaking guide",
  "Sightseeing fees & boat rides as mentioned in itinerary",
  "Meals as specified in program",
];

const exclusionsData = [
  "International airfares",
  "Personal expenese, drinks, ... and the others not mentioned above.",
];

const VC4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC4;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
