import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { J4Image } from "../image";

const heroTextData = [
  {
    title: "Japan Golden Route 9N/10D",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "Tokyo",
    endCity: "Osaka",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Dep. Narita - AIR Mini Coach",
    details: "Welcome at Airport - Drive to Tokyo City - Sightseeing of Ueno Park - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "In. Tokyo - Mini Coach",
    details: "Breakfast at Hotel - Sightseeing of Tokyo City - Asakusa Sensoji & Nakamise Shopping Arcade, Sky Tree, Sumida River Cruise, Chidorigafuchi, Shinjuku Gyoen Garden - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "In. Tokyo - Mini Coach",
    details: "Breakfast at Hotel - Sightseeing of Tokyo City - Imperial Palace, Shibuya Crossing, takeshita Street at Harajuku Ginza - Overnight at Hotel",
  },
  {
    id: "Day 4",
    header: "In. Tokyo - Mini Coach",
    details: "Breakfast at Hotel - Drive to Kawaguchiko - En route Sightseeing of Oshinohakkai - On arrival Hotel Check-in - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "In. Kawaguchiko - Mini Coach",
    details: "Breakfast at Hotel - Drive to Ashinoko - On arrival Sightseeing of Lake Ashi Cruise, Komagatake Ropeway, Fuji Safari Park - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "In. Hakone - Mini Coach",
    details: "Breakfast at Hotel - Sightseeing of Kowakien Yunessun - Drive to Ito - Sightseeing of Ito Granpal Park - Back to Hakone - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "In. Hakone - Mini Coach",
    details: "Breakfast at Hotel - Drive to Odawara Railway station - Proceed to Osaka by Bullet Train - On arrival sightseeing of Osaka city, Umeda Sky building, Osaka Castle Sakuranomiya Park, Shinsaibashi & Dotonbori - arrival at Hotel",
  },
  {
    id: "Day 8",
    header: "In. Osaka - Mini Coach",
    details: "Breakfast at Hotel - Drive to Kyoto - Sightseeing of Kyoto, Sagano Romantic Train, Kinkakuji Philosophers Path, Fushimi Inari Taisha Shrine - Back to Osaka - Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "In. Osaka - Mini Coach",
    details: "Breakfast at Hotel - Drive to Nara - Sightseeing of Nara, Todaiji Temple, Nara Park, Koriyama Castle Ruinss - Back to Osaka - Overnight at Hotel",
  },
  {
    id: "Day 10",
    header: "In. Osaka - Mini Coach AIR",
    details: "Breakfast at Hotel - Drive to International Airport - Flight to India (Own Arrangement)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [""];

// const exclusionsData = [""];

const J4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={J4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default J4;

// const intineraryTimelineData = [
  // {
  //   id: "Day 1",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 2",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 3",
  //   header: "",
  //   details: "",
  // },
  // {
  //   id: "Day 4",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
// ];
