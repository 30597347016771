import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { UB3Image } from "../image";

const heroTextData = [
  {
    title: "Holiday in Uzbekistan",
    duration: "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Tashkent",
    endCity: "Tashkent ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Tashkent",
    details:
      "Arrival to Tashkent; the guests will be met by English-speaking guide at the airport. Transfer to hotel and check-in. City tour in Tashkent: “Independence” Square; Subway of Tashkent (the first subway in Central Asia and one of the most beautiful in the world with attractive decorations of each station); Lunch at local Restaurant Amir Temur Square; Palace of International Forums; Tashkent Chiming Clocks; “Broadway Street” with artist and local souvenirs; Opera and Ballet Theatre named after Alisher Navoi (outside only); Dinner at local restaurant Overnight at hotel. Meals: Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Tashkent",
    details:
      "Breakfast at the hotel. City tour of Tashkent: “Lal Bahadur Shastri” Memorial and Statue; German Kirche; Roman Catholic Church; Museum of Applied Arts; Lunch at local restaurant “Memorial of Repression’s Victims” with the traditional ornaments; Tashkent TV Tower (outside only); “Minor Mosque“ called as “Oq masjid” (White Mosque) – built by white marble; “Monument of Courage” (dedicated to the 1966 earthquake in Tashkent); Transfer for shopping to “Mega Planet” Mall. Dinner at local restaurant Overnight at hotel. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Tashkent - Urgench/Khiva",
    details:
      "Breakfast at hotel and check-out. Transfer to Tashkent Domestic Airport for Tashkent-Urgench flight (departure 07:25 and arrival at 09:00). Upon arrival transfer to Khiva. Full day city tour in Ichan Kala, inner town of old Khiva: Visit: Ichon-Qala Gates & Wall; Mohammed Amin Khan Medressa – 1850; Kalta Minor minaret; Kukhna Ark; Mohamed Rakhim Khan Medressa; Sayid Alauddin Mausoleum & Music Museum; Juma Mosque & around. Mosque is interesting for the 218 wooden columns supporting its roof. The few finely decorated columns are from 10th century mosque. Arabhana Medressa - 17th c.; Lunch at Local restaurant Dost Alyam Medressa; Abdulla Khan Medressa – 1855; Aq Mosque – 1657; Anusha Khan baths; Alloquli Khan Medressa – 1835; Kutlimurodinok Medressa – 1809; Caravansarai, Tosh-Khouly Palace – 1832; Islom-Huja Medressa – 1910; Pakhlavan Makhmud Mausoleum – 1701; Sherghozi Khan Medressa – 1326; Dishon-Qala – 1906; Transfer to the hotel for check-in & rest. Dinner at local restaurant. Overnight at hotel in Khiva. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Khiva - Bukhara",
    details:
      "Early buffet breakfast at the hotel and check-out. Transfer to Railway Station to depart to Bukhara (departure at 08:30 and arrival at 14:44). Upon arrival to Bukhara transfer to hotel and check-in and free time fresh-up. Half-day city tour in Bukhara. Visit The Samanids mausoleum (IX-Xc.), Chashma Ayyub complex (XIVc.), Bolo Hauz mosque (1712y.). Then visit Ark Citadel (Early Residence of Bukhara Emirs); Lunch at Local restaurant Complex Poi Kalon (Beneath the Great): Kalon Minaret (12th c.), Kalon Mosque (12th c.) and Miri Arab Madrassah (16th c.); Hammam Bozori Kord; Lyabi-Hauz ensemble: Khanaka and Madrassah of Nadir Divan-Beghi (1620), Kukeldash Madrassah (Kulba-Kukeldash) (1568-69). Dinner at Nadir Devon Begi Madrasah with Exclusive Folklore Music and dance show. Overnight at hotel in Bukhara. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Bukhara - Samarkand",
    details:
      "Buffet breakfast at the hotel. Bukhara city tour: Magoki-Attari Mosque (10-16th cc.); Toki-Zargaron, Toki-Sarrafon, Toki-Tilpak-Furushon - shopping mall (15th-16thcc.), Ulugbeg Madrassah (15th c.); Lunch at Local restaurant Abdulaziz-Khan Medressa (17th c.); Visit Chor-Minor Madrasah (1807). Transfer to the Mausoleum of Bakhouddin Naqshbandi (XVI); Visit Sitorai Mokhi-Khosa (XIX-XX) – summer residence of Bukhara’s last emir; Transfer to the railway station. Departure to Samarkand by Bullet Train at 15:50 and arrival at 17:05. Upon arrival to Samarkand transfer to hotel and check-in. Dinner at local restaurant Overnight at hotel in Samarkand. Meals: Breakfast, Lunch and Dinner",
  },
  {
    id: "Day 6",
    header: "Samarkand",
    details:
      "Buffet breakfast at hotel and check-out. Excursion in Samarkand: Visiting memorial complex of Imam Al-Bukhari; Gur Emir Mausoleum (Tamerlane’sTomb) (14-15th cc.); Ruhabad Mausoleum (House ofSpirit) (14th c.); Excavations of ancient city Afrosiab (Scientists refer to the beginning of Samarkand VIII - VII centuries BC. It was at this time on the northern edge of modern Samarkand there is an ancient settlement Afrasiab); Lunch at Local restaurant Registan Square (A sandy place): Ulugbeg Medressa (15th c.), Sher-Dor Medressa (Having tigers) (17th c.), Tillya-Kari Medressa (Coated by gold) (17th c.). Bibi Khanim Mosque (14th c.)– the biggest medieval building in the city; VIsit Silk Carpet factory. Dinner at local restaurant Overnight at hotel in Samarkand. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Samarkand - Tashkent",
    details:
      "Buffet breakfast at hotel and check-out. Continue a city tour in Samarkand: Khodja Daniyar Mausoleum; Afrasiab Museum; Hazret-Hyzr Mosque (19th c.); Lunch at Local restaurant The architectural complex Shakhi-Zinda (Alive king) (11-19th cc.); Ulugbek Observatory (15th c.); Visiting oriental bazaar - Siab where guests can do shopping and enjoy the ancient colorful market. Transfer to the Railway Station for departing to Tashkent by bullet train Afrasiyab at 17:30 and arrival at 19:40. Upon arrival Transfer to the hotel, check-in for rest. Dinner at local restaurant Overnight at hotel in Tashkent. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Chimgan Mountains and Charwak Lake",
    details:
      "Breakfast at hotel. Drive to Chimgan Mountains, which is located in 80 km from Tashkent on the altitude of 2000 m above sea level Upon arrival the guests walk along Chimgan, enjoy the mesmerizing view of Uzbekistan nature. Being in mountains guests ride on Chair Lift (the length 802 m, the height 1975 m) going up to the top of the mountain (30 minutes), enjoy free time at the mountain peak taking photos and videos for the memory and then again back onto the Chair Lift for coming down the mountains (30 minutes). Lunch at Local restaurant Transfer to outstanding “Charwak” Lake (which is formed by joining of 4 biggest rivers. This beautiful lake supplies Tashkent city and its region with water all year round. Some free time at “Charvak” Lake at own activities. Drive back to Tashkent; Dinner at local restaurant Overnight at hotel in Tashkent. Meals: Breakfast, Lunch and Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Tashkent - Delhi/Mumbai",
    details:
      "Breakfast at hotel and check-out. Continue the city tour in Tashkent: “Memorial of Repression’s Victims” with the traditional ornaments; Tashkent TV Tower (outside only); “Monument of Courage” (dedicated to the 1966 earthquake in Tashkent); Lunch at local restaurant “Minor Mosque“ called as “Oq masjid” (White Mosque) – built by white marble; Khast Imam Complex (15th c), which includes: Barak Khan Madrasah, Tillya-Sheikh Mosque, Imam Kaffal Shashi Mausoleum, Muyi Muborak Madrasah, Khast Imam library which holds one of the oldest Koran (8th c.) belonging to third caliph, Uthman ibn Affan; Kukeldash Madrasah; Transfer to Chorsu bazaar (one of the ancient markets where guests can buy the best quality of dry fruits and souvenirs); Transfer to the Tashkent International Airport. Departure to Delhi/Mumbai according to the schedule. End of the tour. Meals: Breakfast, Lunch",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation in hotel",
  "Full board meals (3 Breakfast, 4 Lunches and 3 Dinners)",
  "Services for private Comfortable A/C vehicle with driver per program",
  "Escort English/Hindi – speaking guides for all sightseeing and excursions from arrival till departure",
  "Panoramic tours to the sights listed",
  "All airport and railway stations transfers;",
  "Full day trip to Chimgan Mountains and Charvak Lake",
  "Chair-lift fees in Chimgan Mountains.",
  "Full day trip to Samarkand by Afrosiyob bullet train in Economy class* (subject to seats availability): Tashkent – Samarkand – Tashkent;",
  "2 bottles of 0.5 L mineral water per day / per person.",
  "Applicable Government tax.",
];

const exclusionsData = [
  "International air tickets ",
  "Visa fees.",
  "Early check-in to hotel and late check-out charges",
  "Charges in hotels for additional services & penalties.",
  "Entrance fees to sites listed (which will be paid at the sight separately on wish)",
  "Tips and gratuities",
  "Video and photo charges",
  "Any personal expenses",
  "Any services not included in the above itinerary.",
  "In case of non-availability of seats in Afrosiyob bullet train on requested dates the trip to Samarkand will be provided by land transport.",
];

const UB3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={UB3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default UB3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
