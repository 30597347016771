import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U4Image } from "../image";

const heroTextData = [
  {
    title: "Grand USA",
    duration: "16 Nights / 17 Days",
    typoOfTrip: "Road Trip",
    startCity: "New York",
    endCity: "New York",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive New York",
    details:
      "Welcome to the Big Apple, the City That Never Sleeps —New York is a city of superlatives: America’s biggest; it’s most exciting; its business and cultural capitals; the nation’s trendsetter. For more than a century, it has been one of the world’s major centres of commerce and finance. On arrival at New York Airport you will be met & transferred to the Hotel. Check-in at the hotel. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "New York City Tour",
    details:
      "Breakfast at hotel and proceed for New York City tour with entrance to Empire State Building & Statue of Liberty Ferry Ride on Seat in Coach basis. Highlights of the tour: Statue of Liberty Express One hour, Non-stop Cruise (Ferry Ticket Only), Empire State Building’s 86th floor Observatory (Admission Included), Rockfeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, etc. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "New York - Washington",
    details:
      "Check out from hotel and we transfer you to New York coach station for onward journey to Washington DC. On arrival check in to hotel. Evening proceed for Night tour of Washington on Seat in coach basis. When the sun goes down, Washington turns into a different city, and Old Town Trolley Tours knows just the right way to show off the Capitol City at night! See the Capitol Building, the White House, the Jefferson Memorial and the Washington Monument. The trolley will also make stops at the Franklin D. Roosevelt Memorial, the Lincoln Memorial, the Vietnam Veteran's Memorial, the Korean War Veteran's Memorial and the Iwo Jima Memorial. Enjoy of the most emblematic monuments of the city, crossing them in the light of the moon.",
  },
  {
    id: "Day 4",
    header: "Washington - Buffalo (Niagara Falls)",
    details:
      "Check out from hotel and we transfer you to airport for flight to Buffalo. On arrival we transfer to Niagara the town of the world famous falls, which forms one of the natural wonders of the World. Transfer to Niagara Falls. The evening is free at leisure. Overnight stay in Niagara Falls.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Niagara Falls Discovery Pass",
    details:
      "What else can be a better way than Niagara Discovery Pass to explore the Niagara Falls. Discovery Pass allows admission to Maid of the Mist, Cave of the Winds, Niagara Adventure Theater, Gorge Discovery Centre & Aquarium of Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Niagara - Orlando",
    details:
      "Breakfast at the Hotel. Transfer to the airport for your flight to Orlando. Arrive Orlando, the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. This city is said to welcome more than 52 million tourists every year. On arrival transfer to the Hotel. The day is free at leisure. Overnight in Orlando.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Orlando - Walt Disney Magic Kingdom",
    details:
      "Breakfast at the Hotel. Proceed to Magic Kingdom. This covers 107 acres, opened on October 1, 1971. It’s been dubbed The Most Magical Place on Earth! and is home to seven themed lands, and dozens of attractions that appeal to all ages. It is the place where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Visit Magic Kingdom make your childhood fantasies come true as you step into fairytale land of themed rides, shows and parades inspired by Disney’s Stories. Hop on to the happiest land, the Magic Kingdom, where the legend of Mickey Mouse begins. The magic will bring you the classical vehicles Western Carriage, Pirates` Treasure Island and Cartoon stars. Overnight in Orlando",
  },
  {
    id: "Day 8",
    header: " Orlando - Universal’s Island of Adventure",
    details:
      "Today you proceed to have an experience of a lifetime at Universal’s Island of Adventure. At Universal’s Islands of Adventure® the most cutting edge rides, shows, and interactive attractions ever created challenge all your senses. You can step into the magic and excitement of The Wizarding World of Harry Potter™; swing high above the streets in The Amazing Adventures of Spider-Man®, now in high-def 3-D; splash through the comical water rides of Toon Lagoon®; face dinosaurs as you visit earth’s primordial past in Jurassic Park®; encounter epic adventures in The Lost Continent®; and enter the colourful worlds of children’s author Dr. Seuss at Seuss Landing™. Live the adventure℠ of a lifetime at Islands of Adventure®…it’s the stuff legends are made of.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Orlando – Universal Studios Florida",
    details:
      "Jump into the Action of the Movies. Go beyond the screen, behind the scenes, and jump right into your favourite scenes from your favourite movies and TV shows at Universal Studios Florida®. At this real, working production studio you’ll find an amazing array of rides, shows, movie sets and attractions that make YOU the star. Join the ranks of the minions on the heartwarming and hilarious 3-D ride Despicable Me Minion Mayhem; experience Shrek 4-D; the Hollywood Rip Ride Rockit® multi-sensory coaster; Revenge of the Mummy®; The Simpsons Ride™; MEN IN BLACK™ Alien Attack™; and the ground-breaking ride experience, TRANSFORMERS™: The Ride-3D.",
  },
  {
    id: "Day 10",
    header: "Orlando - Las Vegas",
    details:
      "Check out from hotel and get transferred to Orlando Airport for onward flight to Las Vegas. On arrival you will be met by your driver at the Baggage claim area holding a signboard in your name and get transferred to the Hotel. The day is free at leisure. Overnight in Las Vegas.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Las Vegas Open Top Big Bus Las Vegas by Night Tour",
    details:
      "The day is free at leisure. In the evening enjoy the Las Vegas by night experience, join the Night Tour and enjoy spectacular views of the entire Strip and Down Town in all their neon glory. The Night Tour takes you all the way along the Strip to the famous Las Vegas welcome sign, before heading back to Down Town where you'll have the change to enjoy the Fremont Street Experience .As you travel past Las Vegas' illuminated landmarks, The experienced guides on tour will tell the story of Las Vegas and will highlight the city's most impressive views. You can start the tour at either Circus Circus at 19:00 or at the Excalibur at 19:30. The Night Tour lasts approximately 3 hours including time at Fremont Street. Return back to the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Las Vegas Helicopter City lights Tour",
    details:
      "This evening enjoy the Helicopter ride over the Vegas strip. Helicopter Flight over the Las Vegas Strip Enjoy the “Entertainment Capital of the World” from the best view possible – the sky. Soar high above the world-famous Las Vegas Strip as you witness iconic landmarks such as the Bellagio, Caesars Palace, Stratosphere Tower, MGM Grand, Eiffel Tower, Luxor, historic downtown and more. This 12-15 minute flight takes you high above both the east and west ends of the Strip as you witness stunning views aboard a “limousine” in the sky, Maverick’s ECO-Star helicopter. Return back to the Hotel. Overnight in Las Vegas.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 13",
    header: "Las Vegas - Los Angeles",
    details:
      "Check out from hotel and we transfer to coach station for bus to Los Angeles. On arrival transfer to hotel. Day is free at leisure. Overnight stay at Los Angeles.",
  },
  {
    id: "Day 14",
    header: "Los Angeles Warner Bros. Studios and movie stars' homes",
    details:
      "After breakfast proceed to experience Warner Bros and Movie Star Homes on Seat in Coach basis.See behind the scenes and into the scenes of your favourite shows and movies at the world's busiest motion picture and television studio. There are plenty of exciting things to see and do on the Warner Brothers' Studios Tour. For any fan of TV and movies this is a once in a lifetime opportunity to get a taste of what it’s all about. Be amazed as you find yourself one minute in the midst of the tropical jungle and the next minute in the heart of a Midwest street. Step onto the recreated set of the hit television show Friends and take a seat on their famous couch. Visit the props department and spot the furnishings from your favourite movies and TV shows, then stock up on presents for friends and family in the WB gift shop. As a fully operational film studio celebrities are always around so keep your eyes peeled and your camera at the ready. The action is happening all around you! You will also experience the glitz and glamour of Hollywood on the Movie Stars’ Homes Tour in Los Angeles. This provides an exclusive tour of breath-taking stars' homes in Hollywood, Beverly Hills, Bel-Air and Los Angeles as well as introducing you to Hollywood's iconic sign, the mile long Walk of Fame, West Hollywood's glittering Sunset Strip with its colourful billboards and Beverly Hills's glamorous Rodeo Drive!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 15",
    header: "Los Angeles – San Francisco",
    details:
      "Fly to San Francisco, which is one of the few North American cities to achieve the legendary status of such places as Paris or London. The city's well-known hills offer stunning views of the Pacific Ocean and San Francisco Bay, and feature a wide array of shops, restaurants, and cosmopolitan nightlife. In addition to its diversity and charm, San Francisco is a gateway to Silicon Valley, America's premier high-technology centre. Upon Arrival, check in to the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 16",
    header: "San Francisco City Tour",
    details:
      "Today we proceed on a sightseeing tour of San Francisco. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 17",
    header: " Departure San Francisco",
    details:
      "Check out from hotel and get transferred to San Francisco airport for onward journey. End of tour.",
  },
];

const inclusionData = [
  "16 Night’s Accommodation with breakfast as per mentioned or similar (No breakfast in Las Vegas)",
  "New York city tour with entrance to Empire State Building & Statue of Liberty Ferry Ride only on Seat in Coach basis",
  "Night tour of Washington DC on Seat in Coach basis",
  "Niagara Discovery Pass",
  "2Days Universal Orlando Base Ticket",
  "1Day Disney Orlando Base Ticket (Choice of any 1 Disney Park : Animal Kingdom, Magic Kingdom, Hollywood Studios or Epcot Centre)",
  "Helicopter Night tour of Vegas with Limousine transfers from Hotel",
  "Double Decker Bus tour of Vegas",
  "Warner Bros Studios and Movie star Homes on Seat in Coach basis",
  "San Francisco city tour with Bay Cruise on Seat in Coach basis",
  "New York Airport / Hotel transfers on Seat in Coach basis",
  "New York Hotel / Coach Station Transfers on Private basis",
  "One way Coach Ticket New York to Washington",
  "Washington Coach Station / Hotel transfers on Private basis",
  "Washington Hotel / Airport transfers on Seat in Coach Basis",
  "Buffalo Airport / Niagara Hotel round trip transfers on Private basis",
  "Orlando Airport / Hotel round trip transfers on Seat in Coach basis",
  "Las Vegas Airport / Hotel transfers on Seat in Coach basis",
  "Las Vegas Hotel / Coach Station transfers on Private basis",
  "One way coach ticket from Las Vegas to Los Angeles",
  "Los Angeles Coach station / Hotel transfers on Private basis",
  "Los Angeles Hotel / Airport transfers on Seat in Coach basis",
  "San Francisco Airport / Hotel round trip transfers on Seat in Coach basis",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U4;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
