import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { C2Image } from "../image";

const heroTextData = [
  {
    title: "Northern Light Experience",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Toronto",
    endCity: "Toronto",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Day 1",
    details:
      "Yellowknife is the capital city of the Northwest Territories. On arrival, transfer to The Explorer Hotel, Yellowknife’s largest full service hotel, where you will spend the next 4 nights and relax. room_serviceDinner at local restaurant Aurora Viewing via Snow-Tracking Van to a cabin on Great Salve Lake. Depart 9:30 pm return 1:30am",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day 2",
    details:
      "Buffet Breakfast at the hotel Aboriginal Cultural Tour: Take this Dene journey through the communities of Yellowknife, Dettah and N’dilo. Be introduced to the Dene people and the significance of their land and cultural traditions. Visit B. Dene Adventures’ camp to share Dene culture, art and foods. Listen to the beat of the traditional drums and join the excitement of traditional Dene Hand Games. room_serviceDinner at local restaurant Aurora Viewing via Van to a cabin on the Ingraham Trail Enjoy the warmth of a crackling wood stove, hot beverages and snacks at a designated location on the Ingraham Trail. This northern lights tour package includes return transportation from your hotel, leaving Yellowknife at 9 pm and returning approximately 1:30 am.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day 3",
    details:
      "Buffet Breakfast at the hotel Ice Caves Tour (3 Hours) The tour begins with visit to the Mining Heritage Site at the old Giant Mine to enjoy the ravens playing along the Back Bay cliff and soak up some history. From there, it is off to the secret ice cave, known to locals, to strum a song on the icicles and capture its flowing beauty with our cameras. A short distance from the ice cave is Yellowknife’s old Cemetery where we can enjoy its quiet beauty with a view onto Great Slave Lake and the Old Town. Hiking poles and anti-slip devices for your boots will be provided. room_serviceDinner at local restaurant Aurora Viewing at Aurora Village Relax and take in nature’s most spectacular show in comfort. Aurora-viewing comes complete with warm, wood stove heated teepees, and hot beverages.",
  },
  {
    id: "Day 4",
    header: "Day 4",
    details:
      "rviceBuffet Breakfast at the hotel City Tour Dreams of gold began in Yellowknife in 1934 when a couple of prospectors discovered the precious metal. Join local experts on a tour of the City and learn about the building of this gold mining town to the diamond capital it is today. You will also see local art and learn about the history and culture of the people that make this area unique. This tour is approximately 2 hours in length and showcases highlights of the City. This tour can be adapted to accommodate specific interests. Optional Dog Sled Activity Travel across the land as northern Aboriginals had once exclusively done – by dog team! Our huskies are a traditional dog team, pulling wood and canvas sleds for an authentic Aboriginal experience. Watch the world whiz by from the comfort of our traditional sleds as our dogs work Together to take you on an exhilarating and safe ride along our beautiful wooded trail. room_serviceDinner at local restaurant",
  },
  {
    id: "Day 5",
    header: "Day 5",
    details:
      "Buffet Breakfast at the hotel Today transfer to Airport for departure flight",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodations in Yellowknife - 4 nights",
  "Transfers to Hotel/Airport on Seat in Coach Basis",
  "4 buffet Breakfasts and 4 Local Dinners",
  "3 Evenings of Aurora Viewing",
  "City tour of Yellowknife on Seat in Coach Basis",
  "Aboriginal tour on Seat in Coach Basis",
];

const exclusionsData = [
  "Airfare, Visa, any kind of personal expenses.",
  "Winter Clothes Rentals*",
];

const C2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={C2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default C2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
