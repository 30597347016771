export const WT1Image = "/assets/img/worldTour/wt1.png";
export const WT2Image = "/assets/img/worldTour/wt2.png";
export const WT3Image = "/assets/img/worldTour/wt3.png";
export const WT4Image = "/assets/img/worldTour/wt4.png";
export const WT5Image = "/assets/img/worldTour/wt5.png";
export const WT6Image = "/assets/img/worldTour/wt6.png";
export const WT7Image = "/assets/img/worldTour/wt7.png";
export const WT8Image = "/assets/img/worldTour/wt8.png";
export const WT9Image = "/assets/img/worldTour/wt9.png";
export const WT10Image = "/assets/img/worldTour/wt10.png";
export const WT11Image = "/assets/img/worldTour/wt11.png";
export const WT12Image = "/assets/img/worldTour/wt12.png";
export const WT13Image = "/assets/img/worldTour/wt13.png";
export const WT14Image = "/assets/img/worldTour/wt14.png";
export const WT15Image = "/assets/img/worldTour/wt15.png";
export const WT16Image = "/assets/img/worldTour/wt16.png";
export const WT17Image = "/assets/img/worldTour/wt17.png";
export const WT18Image = "/assets/img/worldTour/wt18.png";
export const WT19Image = "/assets/img/worldTour/wt19.png";
export const WT20Image = "/assets/img/worldTour/wt20.png";
export const WT21Image = "/assets/img/worldTour/wt21.png";
export const WT22Image = "/assets/img/worldTour/wt22.png";
export const WT23Image = "/assets/img/worldTour/wt23.png";