import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { CH1Image } from "../image";

const heroTextData = [
  {
    title: "Chardham",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "Haridwar",
    endCity: "Rishikesh",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Haridwar – Barkot Drive 210 km ",
    details:
      "Morning meet our representative at your Hotel / Airport / Railway Station. After some basic information start your journey to Barkot. Later drive to Barkot. Check into the hotel arrival in Barkot. Overnight stay at Barkot. (Meals: Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:
      "Barkot - Janakichatti - Yamunotri - Jankichatti - Barkot Drive 36 km Trek 12km Alt 3235m ",
    details:
      "Early morning after breakfast, drive to Jankichatti / Phoolchatti. Start your trek from here to Yamunotri (6kms) (Either by walk or by horse or by Doli at own cost). After arrival at Yamunotri take bath in warm waters of Surya Kund (Hot Spring). Enjoy Darshan of  Yamunaji. Return trek to Jankichatti. Later drive towards Barkot. Overnight stay at Barkot. (Meals: Breakfast and Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Barkot – Uttarkashi Drive 100km Alt 1352m ",
    details:
      "Morning after breakfast, relax for a while. Later drive to Uttarkashi. Visit Shivgufa on the way to Uttarkashi. In Uttarkashi visit Kashi Vishwanath Temple. After that, check in hotel. Day free for rest. Overnight stay at Uttarkashi. (Meals: Breakfast and Dinner) ",
  },
  {
    id: "Day 4",
    header:
      "Uttarkashi – Gangotri – Uttarkashi (100 kms each side) Drive 200 km Alt 3048 m ",
    details:
      "Early morning after breakfast, drive to Gangotri. On the way visit Gangnani Hot Spring. Take a holy dip in Kund. Later drive to Gangotri via beautiful Harsil valley. On arrival at Gangotri, take a holy dip in the sacred river Ganges which is also called Bhagirathi at its origin. Perform Pooja and Darshan, after that relax for some time in the lovely surroundings. Later return drive to Uttarkashi. Overnight stay at Uttarkashi. (Meals: Breakfast and Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Uttarkashi – Guptkashi (Phata) Drive 220 km Alt 1319 m ",
    details:
      "Morning after breakfast, drive to Guptkashi. Enroute you can see the beautiful river Mandakini at Tilwara. The Mandakini River comes from Kedarnath, drive alongside the river to reach Guptakashi, visit Ardh Narishwar Temple in Guptakashi. Check into the hotel arrival in Guptkashi. Overnight stay at Guptkashi. (Meals: Breakfast and Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Guptkashi (Phata) – Sonprayag – Kedarnath Drive 30 km Trek 14 km ",
    details:
      "Morning after breakfast, today we will go to Kedarnath. To reach Kedarnath you will have two options. One if by trekking and other is by Air via Helicopter (at your own cost). By Trek: To reach Kedarnath by trekking route first you have to reach Gaurikund. We will drive to Sonprayag early in the morning after breakfast. Our car will drop to Sonprayag. From Sonprayag 5 kms drive to Gaurikund local jeep is available. This sharing basis jeeps guests can book directly at own cost. Gaurikund to Kedarnath is further 14 Kms trek. After reaching the Kedarnath visit Kedarnath Temple. Check in to the hotel/ashram. Visit Shankaracharya Samadhi. Overnight stay at Kedarnath. (Meals: Breakfast) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Kedarnath – Sonprayag – Guptkashi (Phata) Trek 14 km Trek 14 km ",
    details:
      "Morning after breakfast explore nearby areas by walk at Kedarnath. Later reach Sonprayag as per your choice by trek or Helicopter. By Trek: Inform the driver approximate return time. Once you reach Gaurikund from Kedarnath by trek you can take local jeep till Sonprayag. Our driver will meet here. Due to parking issue keep driver’s contact number with you and call him when you reach sonprayag so he can pick you up. Gaurikund to Sonprayag local jeep at your own cost. Get packed breakfast from hotel to start early. Horse / Pony / Palakhi is also available for trekking at Gaurikund. Guests can book that at own cost. At arrival check in to hotel in Guptkashi. Overnight stay at Guptkashi. (Meals: Dinner) ",
  },
  {
    id: "Day 8",
    header: "Guptkashi (Phata) – Joshimath – Badrinath Drive 220 km ",
    details:
      "Morning after breakfast, drive to Badrinath. Check in to the hotel arrival in Badrinath. Pilgrims after having a bath in the Taptkund have the Darshan of Badrivishal & Aarti in evening. Overnight stay at Badrinath. (Meals: Breakfast and Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Badrinath – Joshimath – Pipalkoti",
    details:
      "Badrinath – Joshimath – Pipalkoti Morning enjoy the after breakfast, drive for Rudraprayag via Joshimath. Visit on the way Darshan of VishuPrayag, Narsingh Temple in Joshimath, KarnPrayag and NandPrayag. Check into the hotel arrival in Rudraprayag. Overnight stay at Rudraprayag. (Meals: Breakfast and Dinner) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Pipalkoti – Rishikesh – Haridwar",
    details:
      "Morning after breakfast, drive for Haridwar / Dehradun / Rishikesh. Drop at Hotel / Airport / railway station. (Meals: Breakfast) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Hotel accommodation as per package same or similar hotels.",
  "Meals as per package (Breakfast &  Dinner at Hotel)",
  "Transfers and sightseeing as per above.",
  "All toll tax, parking, fuel and driver allowance",
];

const exclusionsData = [
  "Horse / Paalki / Helicopter for Trekking at Kedarnath. ",
  "Meals in Kedarnath are not included.",
  "Anything not mentioned under ‘Package Inclusions’",
  "All personal expenses, optional tours and extra meals.",
  "Any kind of entry fees anywhere if not mentioned in including.",
  "Tips, travel insurance, medical insurance, laundry charges, liquors, mineral water, telephone charges.",
  "Porter, pony, horse, cable car, boat, train tickets, air tickets charges.",
];

const CH1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={CH1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CH1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
