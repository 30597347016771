export const O1Image = "/assets/img/domesticTour/odisha/O1.png";
export const O2Image = "/assets/img/domesticTour/odisha/O2.png";
export const O3Image = "/assets/img/domesticTour/odisha/O3.png";
export const O4Image = "/assets/img/domesticTour/odisha/O4.png";
export const O5Image = "/assets/img/domesticTour/odisha/O5.png";
export const O6Image = "/assets/img/domesticTour/odisha/O6.png";
export const O7Image = "/assets/img/domesticTour/odisha/O7.png";
export const O8Image = "/assets/img/domesticTour/odisha/O8.png";
export const O9Image = "/assets/img/domesticTour/odisha/O9.png";
export const O10Image = "/assets/img/domesticTour/odisha/O10.png";
