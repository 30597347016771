import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { AS1Image, AS2Image, AS3Image, AS4Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const ASSAM__TOUR = "/assets/img/domesticTour/Assam.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "3 Nights / 4 Days",
    title: "Eastern Meadows",
    image: AS1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/as1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "North-East Beauty",
    image: AS2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/as2",
  },
  {
    id: 3,
    fromToDays: "04 Nights / 05 Days",
    title: "Splendour Himalaya",
    image: AS3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/as3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Wet & Wild",
    image: AS4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/as4",
  },
];


const Assam = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Assam"}
            subTitle={"Amazing Holidays to Assam"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={ASSAM__TOUR}
            title={"Popular Assam Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Assam"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Assam;
