import React from "react";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { Box, CardMedia, Container, Grid, Fab, Typography } from "@mui/material";

const FloatingIcons = () => {
  return (
    <Box
      sx={{
        "& > :not(style)": { m: 1 },
        display: { xs: "none", sm: "flex", md: "flex" },
      }}
    >
      <Fab
        variant="extended"
        sx={{
          position: "fixed",
          bottom: 60,
          right: 16,
          // backgroundColor: "#0052fc",
          // "&:hover": {
          //   backgroundColor: "#0052fc",
          // },
          backgroundColor: "primary.main",
          "&:hover": {
            backgroundColor: "primary.main",
          },
          boxShadow:3,
        }}
        href="tel:+91-9730747576"
      >
        <CallIcon
        fontSize="small"
          sx={{
            color: "#ffffff",
            "& : hover": {
              color: "#ffffff",
            },
          }}
        />
        <Typography variant="caption" color="text.light">+91-9730747576</Typography>
      </Fab>
      {/* <Fab
        color="primary"
        aria-label="like"
        sx={{ position: "fixed", bottom: 16, right: 84 }}
        href="mailto:enquiry@radhatours.com"
        target="_blank"
      >
        <EmailIcon
          sx={{
            color: "#ffffff",
            "& : hover": {
              color: "#ffffff",
            },
          }}
        />
      </Fab> */}
      {/* <Fab
        aria-label="like"
        sx={{
          position: "fixed",
          bottom: 140,
          right: 16,
          color: "primary",
          zIndex: 20,
          backgroundColor: "#0052fc",
          "&:hover": {
            backgroundColor: "#0052fc",
          },
        }}
        href="https://wa.me/+919730747576"
        target="_blank"
      >
        <WhatsAppIcon
          sx={{
            color: "#ffffff",
            "& : hover": {
              color: "#ffffff",
            },
          }}
        />
      </Fab> */}
    </Box>
  );
};

export default FloatingIcons;
