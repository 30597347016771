import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  O1Image,
  O2Image,
  O3Image,
  O4Image,
  O5Image,
  O6Image,
  O7Image,
  O8Image,
  O9Image,
  O10Image
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const ODISHA_TOUR = "/assets/img/domesticTour/Odisha.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Nights / 03 Days",
    title: "Odisha Short Golden Triangle",
    image: O1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/O1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Odisha Golden Triangle-i",
    image: O2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/O2",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Odisha Golden Triangle-ii",
    image: O4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/O4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "Odisha Diamond Triangle-i ",
    image: O5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/O5",
  },
  {
    id: 6,
    fromToDays: "05 Nights / 06 Days",
    title: "Odisha Golden Triangle-iii",
    image: O6Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/O6",
  },
];

const rulesData = [
  "Accommodation at Bhitarkanika NP is very basic. Both AC &Non-AC rooms are available, but operation of AC is subject to availability of electricity. ",
  "Simple & local food serves in restaurant only. Food service at room not allowed at Bhitarkanika Nature Camp. ",
  "Advisable to check-in the nature camp before 18.00hrs. ",
  "Advisable to carry torch light while visit to Bhitarkanika NP ",
  "If possible, carry a binocular to observe birds and other small animals. ",
  "Alcohol, music is strictly prohibited inside the National Park. ",
  "Obey the rules and regulations of the park. ",
  "Do not venture out at night. It might be dangerous. ",
  "Do not tease animals. It keeps the animal under stress. ",
  "Do not go near the water, there might be a lurking Crocodile. ",
  "Do not carry firearms and explosives-It is punishable. ",
  "Do not carry meat & meat products. It is prohibited. ",
  "Do not pluck flowers, fruits or any other plant parts. ",
  "Do not use plastic & plastic derivative containers. ",
  "Carry your ID proof. It is mandatory while entry in to the park. ",
  "Use sports shoes/full shoes while trekking inside NP. ",
  "Visitors have to walk about 1km long nature trails from Jetty to Dangmal Nature Camp of Bhitarkanika NP, if they come by boat from Khola entry gate. ",
  "Visitors have to walk about 2.8kms long nature trails from Jetty to reach the Habalikati Nature Camp of Bhitarkanika NP. ",
  "Bhitarkanika NP remains close from 01 May to 31 July every year and 10-days in the last week of December/1st week of January. ",
  "Mobile phones, cameras, leather items, bags tobacco masala, paan masala etc. are not allowed inside the Lingaraj temple. ",
  "Non-Hindu are not allowed to the temple premises. They can see from the specific platform. ",
  "At Lingaraj temple we have our Panda, who will escort you inside temple, on request. ",
  "Advisable to hire battery car at Nandankanan Zoological Park for senior citizen, while visiting. ",
  "As per recent Govt. notification all guest needs to submit valid ID proof like DL, Passport, Voter’s Card, Aadhar at the time of hotel check-in. Foreign guest needs to fill-up ‘Form C’ at the time of check-in. PAN is not accepted as ID proof. ",
  
];

const OdishaYatra = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Odisha"}
            subTitle={"Amazing Holidays to Odisha"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={ODISHA_TOUR}
            title={"Popular Odisha Holiday Packages"}
            tourCardContent={tourCardContent}
            ruleTitle={"Odisha"}
            rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default OdishaYatra;










