import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";

import Diversity1Icon from '@mui/icons-material/Diversity1';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import Groups2Icon from '@mui/icons-material/Groups2';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

const aboutIconsData = [
  {
    id: 1,
    icon: 
      <Diversity1Icon fontSize="large" sx={{ color: "#4d4d4d" }} />,
    text: "Core Values",
  },
  {
    id: 2,
    icon: <SettingsAccessibilityIcon fontSize="large" sx={{ color: "#4d4d4d" }} />,
    text: "Client Satisfaction",
  },
  {
    id: 3,
    icon: <Groups2Icon fontSize="large" sx={{ color: "#4d4d4d" }} />,
    text: "Valued Teamwork",
  },
  {
    id: 4,
    icon: <FamilyRestroomIcon fontSize="large" sx={{ color: "#4d4d4d" }} />,
    text: "Integrity",
  },
];

const AboutIconSection = () => {
  return (
      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {aboutIconsData.map((item) => (
          <Grid item xs={6} sm={2} md={3}>
            <Card sx={{borderRadius:2,height:80}}>
              <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {item.icon}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    color: "text.secondary",
                    //   textShadow: "1px 1px 2px black",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutIconSection;
