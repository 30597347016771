import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { IS1Image } from "../image";

const heroTextData = [
  {
    title: "Israel",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jerusalem ",
    endCity: "Amman",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Tel Aviv - Jerusalem",
    details: "Welcome to Tel Aviv! Upon arrival, you will be transferred to the Jerusalem hotel. Rest of the day is at leisure to explore the country’s second largest city blessed with sun bronzed coastline strip on your own. Overnight in Jerusalem. (D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jerusalem (New City)",
    details: "Today after breakfast, start the day with sightseeing of the Jerusalem new city. You will proceed to Mt Scopus for a panoramic view of Jerusalem. Later proceed on to the Garden of Gethsemane and Church of the Agony. Later continue to Israel Museum's Shrine of the Book, then a visit Herodian city of Jerusalem. Proceed to Yad Vashem and visit Ein Karem, the birth place of John the Baptist. Overnight in Jerusalem. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jerusalem (Old City)",
    details: "Today after breakfast, enjoy sites of the Old City of Jerusalem. Enter through one of the gates to the walled old city to visit the renewed Jewish Quarter, the Western Wall, The Temple area and the colourful bazaars. Walk along the Via Dolorosa and the Stations of the Cross ending at the Church of the Holy Sepulcher. Later you will be driven to Mt. Zion and visit the tomb of King David, the Room of the Last Supper and Dormition Abbey. In the afternoon visit Bethlehem to visit the Church of the Nativity. Overnight in Jerusalem. (B,D)",
  },
  {
    id: "Day 4",
    header: "Jerusalem – Beit Shean – Nazreth - Galilee",
    details: "Today after breakfast, drive north, passing by Jericho, the oldest city in the world. Travel to the Jordan Valley to Beit Shean to visit the excavation of this beautifully preserved Roman City. Next proceed to Nazareth, where Jesus spent his childhood, and the Church of Annunciation, built on the site where archaeologists believe the Archangel Gabriel appeared to the Virgin Mary. Later, continue to Safed city of the Kabala for a walk through the mystic alleys, a visit to a synagogue and the artists' colony. Proceed to Galilee and check into your hotel. Overnight in Galilee. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Galilee (Golan Heights  – Around Sea of Galilee)",
    details: "Today after breakfast, ascend to the Golan heights passing by Druze Villages. Visit a former Syrian fortification on the heights. Pass by Banias, source of the Jordan river to visit the Mount of Beatitudes overlooking the Sea, where Jesus preached the Sermon on the Mount and it is believed that Jesus picked his 12 apostles. Later, visit the ruins of the ancient Synagogue and Peter's house at Capernaum, Tabgha, just north of Tiberias, the site of Christ's miracle of feeding the 5,000 and at the Church of Multiplication, where the mosaic floor depicts a basket with loaves and two fishes. Later you will be driven to Tiberias for a short tour of the old center of Jewish learning and later return to your hotel. Overnight in Galilee. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Galilee – Acre – Haifa – Caesarea – Tel Aviv",
    details: "Today after breakfast, you will be driven west to Acre for a visit of the old, famous Crusader stronghold and medieval fortifications. Continue to Haifa for an orientation tour of the city. Visit the Bahai Shrine, Persian Garden, Mount Carmel and Haifa Bay. Proceed southwards to Caesarea to visit the ancient port, Roman Theater and Hippodrome. Continue to Tel Aviv - Jaffa short walking tour in the picturesque alleys of the old fortified town. Later arrive into Tel Aviv and check into your hotel. Overnight in Tel Aviv. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Tel Aviv FREE DAY – Masada – Dead Sea – Tel Aviv",
    details: "Today after breakfast, the day is at leisure to explore the city, or take an optional visit to Masada & Dead Sea by beginning your day with visiting the site of Qumran Caves. Enjoy a remarkable scenery drive of the Dead Sea, as you arrive Masada an isolated cliff in the Judean Desert. Ascend by cable car to the fortress, which was the last stronghold of the Jewish Zealots in their war against the Romans. Later visit Dead Sea, nearly 1,300 feet below sea-level is the lowest point on the earth's surface. If weather permits you have an opportunity to 'float' on the salty mineral rich water. – optional tour – THIS WILL BE A SUPPLEMENT. Overnight in Tel Aviv. (B,D)",
  },
  {
    id: "Day 8",
    header: "Depart Tel Aviv",
    details: "Today after breakfast, you will be transferred to Tel Aviv Airport to take your flight back home. (B)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Transfer from Airport upon arrival and departure.",
"Sightseeing in modern air conditioned private transfer.",
"English speaking licensed guide on duty for 5 days",
"Entrance fees to places of interests whilst on tour in the Holy Land and parking.",
"Accommodation in double occupancy based on B.B. mentioned above or similar",
"Hotel Portage",
];

const exclusionsData = [
"Tips for guide and driver, $4 per person per day for the guide, $3 per person per day for the driver.",
"All personal expenses.",
"Lunches and dinners.",
"Flights/Bridge expenses.",
"Anything not mentioned above.",
];

const IS1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={IS1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default IS1;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
