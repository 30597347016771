import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AR2Image } from "../image";

const heroTextData = [
  {
    title: "Armenia Guaranteed Departures",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Yerevan",
    endCity: "Yerevan",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival / Evening sightseeing in Yerevan",
    details: "Arrival in Zvartnots International Airport by Fly Dubai. Transfer to the hotel. Check-in at 14:00 Rest. Evening short walk in the city center to explore night Yerevan. Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Yerevan City Tour",
    details: "Breakfast at the hotel. Sightseeing tour around the vibrant city Yerevan. Being established in 782 BC, nowadays Yerevan is a thriving city with the excitement, hustle and bustle of a modern capital. We start our exploration from the Republic Square, the masterpiece of the architect Alexander Tamanian. Proceed to Opera House, a stunning architectural piece and the hub of Yerevan. We visit Cascade Complex, an open-air museum of modern art with massive stairways leading up to the Victory Park providing a fantastic overlook to the entire downtown from above. The complex links the historic residential and cultural centers of the city. Optional: Visit Yerevan Brandy Factory (11 USD p/p). The legendary Ararat brandy is considered the pride of many generations and a global symbol of Armenia. For many years Stalin provided Armenian brandy to Winston Churchill. Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Yerevan / Garni / Geghard / Tsakhkadzor / Yerevan",
    details: "Breakfast at the hotel. We start our trip to the Cave Monastery Geghard inscribed in UNESCO World Heritage list. Geghard monastery is the masterpiece of the 13th century Armenian architecture partly carved out of a huge rock. We proceed to the Garni Temple, the only Pagan Temple in Armenia, which dates back to the 1st century. Optional: Jeeping tour to Garni Gorge typically referred to as the Symphony of Stones, a unique and picturesque monument of the nature (10 USD p/p). Millions of years are required for the shaping of such formations. Optional: Lunch at a local house in Garni where you will have opportunity to watch Armenian national bread “lavash” baking and barbeque making process in the underground stove called “tonir”. We continue our trip visiting Tsaghkadzor. Tsaghkadzor is a spa town and a popular health resort in Armenia. It is one of the places of our mountainous country, where the beauty of the nature appears with its whole variety. The name literally means ‘gorge of flowers’ and the whole city is surrounded with virgin woods and alpine meadows. We take a ropeway over Teghenis Mountains offering a bird’s eye over surrounding mountain valleys, lush forests Armenia is so rich with. Drive back to Yerevan. Overnight at the hotel Yerevan.",
  },
  {
    id: "Day 4",
    header: "Shopping day",
    details: "After breakfast proceed to Yerevan Fruit and Food market complete with fresh vegetables and dried fruits, alleys of Armenian lavash and sweets. Continue to Vernissage flea market to get the best variety of souvenirs, handmade rugs and local jewelry items (open on weekends). Our next stop will be the biggest mall to enjoy your shopping leisure. Free time. Overnight at the hotel in Yerevan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure",
    details: "Check out from the hotel. Transfer to the airport. Departure home by Fly Dubai.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation.",
  "4 x overnights at the hotel in Yerevan.",
  "Airport / hotel / airport transfers.",
  "Transportation as mentioned in the program in comfortable A/C vehicle.",
  "English speaking professional guide throughout the tour.",
  "All activities as per program with entrance fees.",
  "Catering BB: breakfast at the hotel.",
  "1 bottle of mineral water per day/per person.",
];

const exclusionsData = [
  "Alcoholic beverages except described.",
  "Optional activities.",
  "Flights",
  "Visa",
  "Meals except described.",
  "Travel insurance",
];

const AR2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AR2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AR2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
