import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KT9Image } from "../image";

const heroTextData = [
  {
    title:  "Mangalore / Udupi / Murudeshwara / Gokarna / Hubli",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Mangalore",
    endCity: "Hubli",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Mangalore",
    details: "On arrival at Mangalore, meet our chauffeur waiting for you and transfer to the hotel. Later enjoy sightseeing of Mangalore visiting temples, Church & beaches. Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Drive Mangalore – Udupi (60 Kms / 1 Hrs)",
    details: "After breakfast, check out of the Hotel and later dive to Udupi. Arrive Udupi in the noon and check into the hotel. Later enjoy sightseeing of Udupi temples, Malpe beach & Evening free for relaxation overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Udupi – Murudeshwara (100 Kms / 2 – 3 Hrs)",
    details: "After breakfast, check out of the Hotel and later dive to Murudeshwara. Arrive Murudeshwara in the noon and check into the hotel. Later enjoy sightseeing of Murudeshwara visiting the famous Murudeshwara temple Sultan Masjid, Jamai Masjid, beaches & Evening free for relaxation overnight in the hotel.",
  },
  {
    id: "Day 4",
    header: "Drive Murudeshwara – Gokarna (100 Kms / 2 – 3 Hrs)",
    details: "After breakfast, check out of the Hotel and later dive to Gokarna. Arrive Gokarna in the noon and check into the hotel. Later enjoy sightseeing of Gokarna visiting visiting the Temples. Evening free for relaxation. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Gokarna",
    details: "After breakfast, enjoy sightseeing of Gokarna visiting the temples and Beaches. Evening free for relaxation. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Drive Gokarna – Hubli Drop (170 Kms / 3 – 4 Hrs)",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Hubli rail station / airport in time for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const KT9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KT9Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KT9;
