import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { J2Image } from "../image";

const heroTextData = [
  {
    title: "Japan Golden Route 4N/5D",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Tokyo",
    endCity: "Tokyo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Tokyo",
    details: "On Arrival meet & greet by ESG and transfer to Railway station Proceed to Nagoya On arrival Drive to Gero En route Sightseeing of Gujohachiman, visit to Old Town, Gujo-Hachiman Museum On arrival Transfer to Hotel Enjoy Hot Spring Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Dep. Gero",
    details: "Breakfast At Hotel Hotel Departure Drive to Takayama On arrival Sightseeing of Old Town, Hidatakayama Machi Museum, Yatai Kaikan Hotel Check-in Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Dep. Takayama",
    details: "Breakfast at Hotel Hotel Departure Drive to Shirakawa On arrival Sightseeing of Shirakawa Village (Free Time) Drive to Kanazawa On arrival Sightseeing of Kenrokuen Hotel Check-in Overnight at Hotel",
  },
  {
    id: "Day 4",
    header: "Kanazawa",
    details: "reakfast at Hotel Hotel Departure Sightseeing of Higashichaya town, Bukeyashiki town Lunch at Local restaurant Transfer to Kanazawa Station On arrival Proceed to Tokyo On arrival Arrival at Hotel Overnight at Hotel",
  },
  {
    id: "Day 5",
    header: "At.Tokyo",
    details: "Breakfast at Hotel Drive to International Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [""];

// const exclusionsData = [""];

const J2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={J2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default J2;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
