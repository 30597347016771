import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  B1Image,
  B2Image,
  B3Image,
  B4Image,
  B5Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const BHUTAN_TOUR = "/assets/img/worldTour/wt2.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "07 Nights / 08 Days",
    title: "Bhutan 7N/8D",
    image: B1Image,
    description:"",
    path: "/b1",
  },
  {
    id: 2,
    fromToDays: "07 Nights / 08 Days",
    title: "Bhutan 7N/8D",
    image: B2Image,
    description:"",
    path: "/b2",
  },
  {
    id: 3,
    fromToDays: "07 Nights / 08 Days",
    title: "Bhutan 6N/7D",
    image: B3Image,
    description:"",
    path: "/b3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Bhutan 6N/7D",
    image: B4Image,
    description:"",
    path: "/b4",
  },
  {
    id: 5,
    fromToDays: "05 Nights / 06 Days",
    title: "Bhutan 5N/6D",
    image: B5Image,
    description:"",
    path: "/b5",
  },
];

// const rulesData = [
//   ""
// ];

const Bhutan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Bhutan"}
            subTitle={"Amazing Holidays to Bhutan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={BHUTAN_TOUR}
            title={"Popular Bhutan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Bhutan"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Bhutan;










