import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR6Image } from "../image";

const heroTextData = [
  {
    title: "Journey Around Iceland",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bergen",
    endCity: "Oslo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival At Keflavik",
    details: "Arrival Reykjavik, meet and greet and private transfer to accommodation. Overnight at Hotel Klettur or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Farmlands And Mountains Of The North West",
    details: "Breakfast. The guide meets the group the hotel in Reykjavik at 8:45 am. Drive northwards via the tunnel of Hvalfjördur to Borgarnes and Skagafjördur, the valley of horses. A visit to the folk museum of Glaumbær (entrance included), an ancient farm with a turf roof, before continuing via highlands, Öxnadalsheidi, to the capital of the north of Iceland, Akureyri. Overnight and dinner in Icealand air Hotel Akureyri or Similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Lake Mývatn Area",
    details: "Breakfast. Visit Jardbödin, the Blue lagoon of the North, where you bathe in the Natural Geothermal Spa. Entrance is included. Phot stop at Goðafoss Waterfall. Whale Watching tour from AkureSyri in the afternoon Dinner is not included today. You are free to enjoy the local restaurants in the capital of the north.",
  },
  {
    id: "Day 4",
    header: "Powerful Waterfall And East Iceland",
    details: "Breakfast. Phot stop at Dettifoss, one of Europe’s biggest waterfalls. Scenic views of highlands Mödrudalsöræfi, via the town of Egilsstadir (East Fjords) Overnight and dinner at Hotel Bláfell, Breiddalsvik, or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Fjords, Glacier And Glacier Lagoon",
    details: "Breakfast Scenic drive the East fjords dramatic coastline, passing Djúpivogur and Höfn. Glacier lagoon visit of Jökulsárlón. 30 Mins boat trip on an amphibian in between the floating icebergs. The bus trip continues further on to National park Skaftafell. It is a beautiful, green oasis at the root of glacier Vatnajökull. Here Iceland’s highest point can also be found (Hvannadalshnúkur, 2119 m). Overnight and dinner at Hotel Núpar or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "South Shore Black Beaches And Lava Fields",
    details: "Breakfast. Scenic drive past lava fields of Eldhraun and observing the black glacial deposits at Mýrdalssandur. Vík and stop by the black lava sand beach of Reynisfjara. Photo stops at Skógafoss and Seljalandsfoss. Entrance tickets for Lava Center. Scenic drive e towards Selfoss, via Hvolsvöllur and Hella. Overnight and dinner at Hotel Selfoss or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Golden Circle Day",
    details: "Breakfast Scenic transfers and photo stop at majestic Gullfoss, one of Iceland’s most beautiful waterfalls. Next Photo Stop at the geothermal area around Geysir. Here we experience the active geyser Strokkur which erupts every five minute a high column of boiling hot water National park of Tingvellir, where the oldest parliament still in function was founded. From Tingvellir drive on to Reykjavik. Overnight at Hotel Klettur or similar. Dinner is not included.",
  },
  {
    id: "Day 8",
    header: "Reykjavik – Departure",
    details: "Breakfast Private airport transfers included. Departure with fond memories.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"7 Nights’ accommodation in Standard double room.",
"Daily breakfasts.",
"4 dinners outside Reykjavik (Veg options available).",
"Guided tour according to itinerary from day 2-7 (SIC).",
"English speaking guide during the tour.",
"Entrance to museum of Glaumbær and Lava Center.",
"Whalewatching, Akureyri.",
"Nature Baths, Mývatn.",
"Glacier sAmphibian boat tour, Jökulsárlón.",
"Private airport transfers on day of arrival and departure.",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
  ];

const SR6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR6;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
