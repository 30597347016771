import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W6Image } from "../image";

const heroTextData = [
  {
    title: "Lost Kingdom",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "NJP Rly Station / IXB Airport - Pelling (140 Kms. / 6 Hrs.)",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Gangtok (5,500 Ft.). On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Pelling Full Day Sightseeing with Skywalk",
    details:
      "After breakfast, start for a full day sightseeing tour. In first half, cover Darap village, Rimbi waterfalls, Khecheopalri Lake (Wishfulling Lake) and Khangchendzongha waterfalls. In the second half cover the Pemayangtse monastery, Rabdantse ruins (2nd capital of Sikkim), Sang-Ngag Choling (Skywalk), New helipad ground.  Evening free for leisure. Overnight stay at Pelling.", 
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Pelling - Gangtok via Namchi (150 Kms. / 5 Hrs.)",
    details:
      "After breakfast, start for Gangtok (5,500 Ft.). Enroute visit Sai Temple and Siddheshwar Dham (Chardham), in Namchi. On arrival, check-in to your hotel. Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: " Excursion to Tsomgo Lake and Baba Mandir (53 Kms. / 3 Hrs. one-way)",
    details:
      "After breakfast, start for an excursion to Tsomgo Lake (12,400 ft. / 37 Kms. / 02 Hrs. from Gangtok) and further drive to Baba Mandir (13,200 ft. / 16 Kms. / 01 Hrs. from Tsomgo Lake). Evening back to Gangtok. Overnight stay at Gangtok.",  
  },
  {
    id: "Day 5",
    header: "Gangtok - NJP Rly Station / IXB Airport (128 Kms. / 5 Hrs.",
    details:
      "After breakfast Checkout from hotel and take, the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
  "Accommodation on twin Sharing Basis. ",
  "Meal Plan (as per mentioned above).",
  "Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
  "All permit fees & hotel taxes (as per itinerary).",
  "Rates are valid for INDIAN NATIONALS only.",
  
  ];
  
  const exclusionsData = [
  "Air Fare / Train fare. ",
  "Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
  "Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
  "Entrance Fees & Guide charges. ",
  "Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
  "Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
  "Room Heater Charges.",
  "Insurance. ",
  "Anything which is not included in the inclusion",
  ];

const W6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W6;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
