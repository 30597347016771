import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O6Image} from "../image";

const heroTextData = [
  {
    title: "Odisha Diamond Triangle-iii",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Bhubaneswar ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and transfer to hotel. After fresh-up visit to the ancient temples like Lingaraj-the biggest temple in Bhubaneswar (non-Hindu are not allowed inside the temple premises), Mukteswar, Rajarani& 64 Yogini temple (Yogini sculpture having 64 different hair style & one of the four Yogini temple in India). Evening free for independent activities. O/N- Bhubaneswar. Note: 1.Lingaraj temple remain open from 05.00 to 21.00hrs and closed for 01hr between 11.00 to 13.00hrs. Devotee can go in to the garbhagriha (sanctum sanctorum) between 06.00 to 08.00hrs & 13.00 to 16.00hrs. 2. Non-Hindu are not allowed to Lingaraj temple. They can see from the specific platform. ", 
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Arrival Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:  "Excursion trip to Chilika (55 kms/ 1.25 hrs one way) ",
    details:
      "Post breakfast excursion trip to Satapada (One end of Chilika Lagoon). Enjoy the motor boat ride in the largest brackish water lagoon of Asia and explore Sea mouth, Rajhans Island (drive pass), rare Irrawaddy dolphins. Back to Puri and visit the Niladri beach & Blue flag beach. Rest of the day free for leisure. O/N-Puri. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Excursion trip to Konark (45 kms/40 minuits one way) ",
    details:
      "After breakfast visit to Gundicha templefrom outside. Later excursion trip to Konark. Enjoy your drive through beautiful marine drive road from Puri to Konark along with visit through Ramchandi temple & Chandrabhaga beach. On arrival visit the Sun temple (the UNESCO World heritage monument) & interpretation center. Evening you may witness the sound & light show at Konark. O/N-Puri Note: 1. Konark Sun temple remain open from 08.00 to 18.00hrs. Light & sound show time: 18.30 to 19.15hrs (Winter) & 19.00 to 19.40hrs(summer). 2. Light & sound show operates subject to weather condition and minimum pax (25-pax). It remainclose on every Monday. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Puri-Mangalajodi-Bhubaneswar (200km/4hrs)",
    details:
      "Post breakfast proceed to Mangalajodi-the bird’s Paradise of Asia (another end of chilika lagoon). Experience the country boat ride and explore bird sighting (local & migratory) in a very close by. (Winter is the best time to visit mangalajodi). Later proceed to Bhubaneswar. On arrival visit Khandagiri&Udaigiri Jain Caves and Jain temple, Tribal Museum/ Kala Bhoomi-a craft museum (both close on Monday and Govt. holidays). Evening you may visit to Ekamrahaat-the traditional market and a platform to get all handicrafts & handloom products of Odisha or local market for shopping. O/N-Bhubaneswar. Note: 1. Be aware of Monkeys at Khandagiri&Udaigiri. Keep your wallet & bag carefully. Udaigiriremain open from 08.00hrs to 17.00hrs.",
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Bhubaneswar departure ",
    details:
      "After breakfast visit to Nandankanan Zoological Park-popular for white tigers (close on Monday). In time transfer to railway station/airport for onwards journey. Tour End. Note: 1. NandankananZoological Parkremain open from 08.00hrs to 17.00hrs. Being a sanctuary Zoo it takes minimum 03-04hrs to cover. ",  
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O6;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

