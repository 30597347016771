import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { NE5Image } from "../image";

const heroTextData = [
  {
    title: "Kathmandu & Pokhara",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Kathmandu",
    endCity: "Kathmandu",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Kathmandu",
    details:
      "Meeting at airport upon arrival by Office Personnel. Kathmandu is the capital of Nepal and the Kathmandu Valley is the political and cultural heart of the Kingdom. After arrival you will be transferred to the hotel. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Kathmandu / Pokhara ",
    details:
      "Drive to Pokhara. Pokhara is 210 kms from Kathmandu and is 7 hours’ drive approx. From Pokhara town (2,352 feet) in good weather there are splendid views right from the hotel. On the way you have an option at an extra cost of doing the river rafting (Inr 2000 per person) or visit the Mana Kamna Temple “Goddess of Fulfilling Wishes” where you are taken by a cable ride (Inr 550 per person) of 10 mins. Overnight at hotel. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Pokhara",
    details:
      "This morning you will go for a excursion trip to Sarangkot. Sarangkot is the most popular tourist destination from where the tourists can enjoy the great view of the Pokhara Valley and the magnificent view of the Himalayas. Sarangkot is only 13 kms from Lake Side, Pokhara. It is the highest view point for a sunrise at an altitude of 1592mtsh. It is cooler than Pokhara. Ccan be reached easily within 45 to 60 minutes by a vehiclefrom Pokhara. Many tourists come to Sarangkot for sunrise. From Sarangkot, on the northern direction we can see Dhaulagiri in the far west. Annapurna range is visible when the weather is clear on the same side. On the southern direction the village overlooks the city of Pokhara and its lake on the north-western outskirts of the city. (The above sightseeing is for a maximum of 03 hours after the vehicle reports at the hotel) This afternoon you will visit Bindebasini Temple. This is one of the oldest temples in Pokhara was a part of Kaski Kingdom, the main deity of the temple, Goddess Durga, was brought from India. Apart from the peace and tranquility that can be felt in this region, the premises of the temple offers a good view of the Himalaya on a clear day. The temple is in the area of the bazaar; hence, a journey of the old bazaar can be combined with a visit to the temple. Sightseeing to Devi’s fall, also known as Patale Chango is an awesome waterfall lying 2 km southwest of Pokhara. During summer and rainy season, the waterfall takes its real form, with gushing water splashing and making its way through the rocks. Just opposite of Devi’s fall, on the other side of the road, there is Gupteshwor Cave. This cave is popular for the different natural forms made from limestone deposits. Photography is strictly prohibited inside the cave. Overnight at hotel. (The above sightseeing is for a maximum of 03 hours after the vehicle reports at the hotel) ",
  },
  {
    id: "Day 4",
    header: "Pokhara / Kathmandu ",
    details:
      "Retracing the same path, drive back to Kathmandu and transfer to hotel. Rest day at leisure. One can indulge in shopping or visit the casinos trying their lady luck on your own. Overnight at hotel.",
  },
  {
    id: "Day 5",
    header: "Kathmandu / Airport",
    details:
      "Morning will be comparatively free and closer to the time of your flight you will be transferred to the airport in time to board your flight for onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "02 nights accommodation in Kathmandu with Breakfast.",
  "02 nights accommodation in Pokhara with Breakfast.",
  "02 international airport transfers by an A/c vehicle exclusively",
  "Sightseeing as per the itinerary in Kathmandu and Pokhara by an A/c vehicle exclusively",
  "Transportation Kathmandu / Pokhara / Kathmandu by an A/c vehicle exclusively",
  "Presently applicable hotel taxes",
];

const exclusionsData = [
  "Services other than specified.",
  "Entrance fee at the monumental sites during the sightseeing",
  "Guide during the sightseeing",
  "Anything not mentioned in inclusions",
  "Any expenses caused by reasons beyond our control such as flight delays, rescheduling or cancellations, any accidents, medical evacuations, riots, strikes, etc. ",
  "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above. ",
  "Any other tax or service charge that could be enforced during this period will be extra & need to borne by you.",
];

const NE5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={NE5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default NE5;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
