import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR1Image } from "../image";

const heroTextData = [
  {
    title: "Bergen & Fjord Excursion",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bergen",
    endCity: "Bergen",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Bergen, the Gateway to the Fjords",
    details:
      "Arrival in Bergen according to your own itinerary. Bergen is Norway’s second largest city and the world’s only city surrounded by 7 mountains and 7 fjords. Bergen is often referred to as the Gateway to the Fjords, due to its close proximity to the world famous Norwegian fjords. This makes Bergen the perfect base for day trips into the fjord region. Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Fjord Excursion: Fjord Cruise & Flåm Railway",
    details:
      "Breakfast at the hotel. Today you will embark on a full day excursion into the Norwegian fjord region. In just one day you will experience some of Norway's most beautiful fjord scenery. The journey starts by train from Bergen to Voss, where you will board the bus to Gudvangen. You will experience Stalheimskleiva, with its 13 steep hairpin bends (only open during the summer) and the Nærøy Valley before continuing by boat for a cruise along the Nærøyfjord to Flåm. The Nærøyfjord is the narrowest fjord channel in Europe, and has recently been added to UNESCO’s World Heritage List. In Flåm you will have time to enjoy lunch on your own before boarding the Flåm Railway, which passes through wild and beautiful mountain scenery with waterfalls and steep mountain sides up to a height of 900 metres. There is no other adhesion railway in the world running on normal tracks that is so steep over a long distance. The last part of the excursion will be by train from Myrdal and back again to Bergen. Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "The Hanseatic City of Bergen",
    details:
      "Breakfast at the hotel. This day is at your own leisure in Bergen. We recommend that you start off by exploring the Hanseatic Bryggen Wharf. The very first buildings in Bergen were alongside the harbour called Bryggen. Bryggen has been the nerve centre of the city for hundreds of years and the silhouette of its ancient gables is perhaps the most familiar image in all of Norway. In 1360 the Hansas - a German guild of merchants - set up one of their import/export offices on Bryggen and dominated trade for the next 400 years. The wooden buildings at Bryggen have been devastated by fire several times, and the Great Fire of 1702 reduced the whole city to ashes. But Bryggen was quickly re-built on top of the foundations from the 11th century. Bryggen is now on UNESCO's World Heritage List. Other sights of interest include the Fløibanen Funicular, Edvard Grieg's home Troldhaugen, the Fish and Flower market, Bergen Aquarium and Gamle Bergen (Old Bergen Open Air Museum). Accommodation in Bergen.",
  },
  {
    id: "Day 4",
    header: "Departure from Bergen",
    details:
      "Breakfast at the hotel. Departure from Bergen according to your own itinerary.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "3 nights hotel accommodation in a standard room with private shower/WC, including breakfast.",
  "Train from Bergen to Voss",
  "Bus from Voss to Gudvangen",
  "Boat from Gudvangen to Flåm",
  "Train from Flåm via Myrdal to Bergen",
  "Accommodation in standard rooms",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
];

const SR1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
