import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { TH1Image } from "../image";

const heroTextData = [
  {
    title: "Pattaya – Bangkok ",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Pattaya",
    endCity: "Bangkok",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Suvarnabhumi Airport – Pattaya Hotel",
    details:
      "Pickup at Suvarnabhumi International to Pattaya Hotel by PVT transfer basis Day Leisure by own Pickup at Hotel Lobby to Alcazar Show – PVT basis Overnight at Hotel ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "pattaya",
    details:
      "Breakfast at Hotel Pickup at Hotel Lobby to Coral Island Tour by Speed Boat with Lunch – SIC basis Day Leisure by Own Overnight at Hotel ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Pattaya – Bangkok",
    details:
      "Breakfast at Hotel Check out from hotel Pickup at Hotel Lobby to Transfer at Bangkok Hotel – PVT basis Pickup at Hotel Lobby to Visit Bangkok City Tour (Golden Buddha + Marble Temple) – SIC basis Day Leisure by own Overnight at Hotel ",
  },
  {
    id: "Day 4",
    header: "Bangkok",
    details:
      "Breakfast at Hotel Pickup at Hotel Lobby to visit Safari world + Marine Park with Lunch – SIC basis Evening Leisure by own Overnight at Hotel ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Bangkok – Suvarnabhumi Airport",
    details:
      "Breakfast at Hotel Check out from hotel Pickup at Hotel Lobby Drop to Suvarnabhumi Airport ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Stay in a hygienic 3-star hotel on a double sharing basis",
  "Meals: Breakfast, Lunch/Dinner",
  "Transfers Airport pick up and drop as per your flight timings.",
  "Comfortable transfers for sightseeing as per the itinerary",
  "Guide Experienced driver cum guide",
  "Sightseeing as per the itinerary",
];

// const exclusionsData = [
//   "Alcoholic beverages except described.",
//   "Optional activities.",
//   "Flights",
//   "Visa",
//   "Meals except described.",
//   "Travel insurance",
// ];

const TH1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TH1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TH1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
