import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { JO1Image, JO2Image, JO3Image,JO4Image, JO5Image, JO6Image, JO7Image, JO8Image  } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const JORDAN_TOUR = "/assets/img/worldTour/wt17.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Wonders of Jordan",
    image: JO1Image,
    description: "",
    path: "/jo1",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "Jordan Wonders",
    image: JO2Image,
    description: "",
    path: "/jo2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Jordan Classic Tour",
    image: JO3Image,
    description: "",
    path: "/jo3",
  },
  {
    id: 4,
    fromToDays: "07 Nights / 08 Days",
    title: "Jordan Classic Tour with Wadi Rum",
    image: JO4Image,
    description: "",
    path: "/jo4",
  },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "The Best of Jordan",
    image: JO5Image,
    description: "",
    path: "/jo5",
  },
  {
    id: 6,
    fromToDays: "07 Nights / 08 Days",
    title: "The Jewels of Jordan",
    image: JO6Image,
    description: "",
    path: "/jo6",
  },
  {
    id: 7,
    fromToDays: "07 Nights / 08 Days",
    title: "Combined Programs Jordan with Holy Land",
    image: JO7Image,
    description: "",
    path: "/jo7",
  },
  {
    id: 8,
    fromToDays: "09 Nights / 10 Days",
    title: "Regular in The Eyes of The Holy Land & Jordan",
    image: JO8Image,
    description: "",
    path: "/jo8",
  },
];

// const rulesData = [
//   ""
// ];

const Jordan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Jordan"}
            subTitle={"Amazing Holidays to Jordan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={JORDAN_TOUR}
            title={"Popular Jordan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Jordan;
