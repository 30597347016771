import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";

const DOMESTIC_HERO = "/assets/img/tour/domestic_tour.png";

const KASHMIR_TOUR = "/assets/img/domesticTour/kashmir.png";
const RAHASTHAN_TOUR = "/assets/img/domesticTour/Rajasthan.png";
const HIMACHAL_TOUR = "/assets/img/domesticTour/Himachal.png";
const KERALA_TOUR = "/assets/img/domesticTour/Kerala.png";
const CHARDHAM_TOUR = "/assets/img/domesticTour/Chardham.png";
const ANDAMAN_TOUR = "/assets/img/domesticTour/Andaman.png";
const LEH_LADAKH_TOUR = "/assets/img/domesticTour/LehLadakh.png";
const DARJEELING_TOUR = "/assets/img/domesticTour/Darjeeling.png";
const ODISHA_TOUR = "/assets/img/domesticTour/Odisha.png";
const GUJARAT_TOUR = "/assets/img/domesticTour/Gujarat.png";
const ASSAM_TOUR = "/assets/img/domesticTour/Assam.png";
const UTTARPRADESH_TOUR = "/assets/img/domesticTour/uttarpradesh.png";
const KARNATAKA_TOUR = "/assets/img/domesticTour/karnataka.png";
const MADHYAPRADESH_TOUR = "/assets/img/domesticTour/madhyapradesh.png";
const TAMILNADU_TOUR = "/assets/img/domesticTour/tamilnadu.png";

const tourCardContent = [
  {
    id: 1,
    totalPackges: 6,
    title: "Kashmir",
    image: KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kashmir-tour",
  },
  {
    id: 2,
    totalPackges: 21,
    title: "Rajasthan",
    image: RAHASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/rajasthan-tour",
  },
  {
    id: 3,
    totalPackges: 6,
    title: "Himachal Pradesh",
    image: HIMACHAL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/himachal-tour",
  },
  {
    id: 4,
    totalPackges: 13,
    title: "Kerala",
    image: KERALA_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kerala-packages",
  },
  // {
  //   id: 5,
  //   totalPackges: 6,
  //   title: "Chardham",
  //   image:CHARDHAM_TOUR,
  //   description:
  //     "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
  //   path: "/kashmir-tour",
  // },
  {
    id: 6,
    totalPackges: 6,
    title: "Andaman",
    image: ANDAMAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/andaman-tour",
  },
  {
    id: 7,
    totalPackges: 9,
    image: LEH_LADAKH_TOUR,
    title: "Leh Ladakh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/lehladakh-tour",
  },
  {
    id: 8,
    totalPackges: 8,
    image: GUJARAT_TOUR,
    title: "Gujarat",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/gujarat-tour",
  },
  {
    id: 9,
    totalPackges: 10,
    image: ODISHA_TOUR,
    title: "Odisha",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/odisha-tour",
  },
  {
    id: 10,
    totalPackges: 31,
    image: DARJEELING_TOUR,
    title: "West Bengal & Sikkim",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/westbengalsikkim-tour",
  },
  {
    id: 11,
    totalPackges: 4,
    image: ASSAM_TOUR,
    title: "Assam",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/assam-tour",
  },
  {
    id: 12,
    totalPackges: 4,
    image: UTTARPRADESH_TOUR,
    title: "Uttar Pradesh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/uttar-pradesh-tour",
  },
  {
    id: 13,
    totalPackges: 15,
    image: KARNATAKA_TOUR,
    title: "Karnataka",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/karnataka-tour",
  },
  {
    id: 14,
    totalPackges: 9,
    image: MADHYAPRADESH_TOUR,
    title: "Madhya Pradesh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/madhya-pradesh-tour",
  },
  {
    id: 15,
    totalPackges: 3,
    image: TAMILNADU_TOUR,
    title: "Tamilnadu",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/tamilnadu-tour",
  },
];

const KashmirPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"India"}
              details={
                "Whether you're looking for a Tour package to relax and indulge, special holidays in Vacation India with friends and family, a trip to your favorite destinations you've come to the right place. Relive your life again and add a little spice to it with exciting Vacation holidays. Explore exciting Tour packages with RADHA TOURS. We offer great deals and discounts on various packages. Don't miss our best value deals for other holiday destinations across India. We offer customized Vacation Tour & Tour Packages across India. Plan a trip for your dream vacation and its various tourist attractions with Vacation Tour packages."
              }
              heroImg={DOMESTIC_HERO}
              // title={"Our Packages in India"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default KashmirPackages;
