import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  VC1Image,
  VC2Image,
  VC3Image,
  VC4Image,
  VC5Image,
  VC6Image,
  VC7Image,
  VC8Image,
  VC9Image,
  VC10Image,
  VC11Image,
  VC12Image,
  VC13Image,
  VC14Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const VIETNAMCAMBODIA = "/assets/img/worldTour/wt8.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Nights / 03 Days",
    title: "Danang - Hoi An - My Son - Danang",
    image: VC1Image,
    description: "",
    path: "/vc1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Cambodia",
    image: VC2Image,
    description: "",
    path: "/vc2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Danang - Hue - Hoi An",
    image: VC3Image,
    description: "",
    path: "/vc3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Danang - Hoi An- My Son - Hue",
    image: VC4Image,
    description: "",
    path: "/vc4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "Danang - Hoi An - Hue –phong Nha",
    image: VC5Image,
    description: "",
    path: "/vc5",
  },
  {
    id: 6,
    fromToDays: "04 Nights / 05 Days",
    title: "Central Vietnam",
    image: VC8Image,
    description: "",
    path: "/vc6",
  },
  {
    id: 7,
    fromToDays: "04 Nights / 05 Days",
    title: "Southern Vietnam",
    image: VC7Image,
    description: "",
    path: "/vc7",
  },
  {
    id: 8,
    fromToDays: "04 Nights / 05 Days",
    title: "Northern Vietnam",
    image: VC4Image,
    description: "",
    path: "/vc8",
  },
  {
    id: 9,
    fromToDays: "05 Nights / 6 Days",
    title: "Taste Of Vietnam",
    image: VC9Image,
    description: "",
    path: "/vc9",
  },
  {
    id: 10,
    fromToDays: "07 Nights / 08 Days",
    title: "Highlights Of Vietnam",
    image: VC10Image,
    description: "",
    path: "/vc10",
  },
  {
    id: 11,
    fromToDays: "07 Nights / 08 Days",
    title: "Essences Of Vietnam",
    image: VC11Image,
    description: "",
    path: "/vc11",
  },
  // {
  //   id: 12,
  //   fromToDays: "07 Nights / 08 Days",
  //   title: "Canadian Rockies Van Tour",
  //   image: VC12Image,
  //   description: "",
  //   path: "/vc12",
  // },
  // {
  //   id: 13,
  //   fromToDays: "07 Nights / 08 Days",
  //   title: "Classic Canadian Rockies",
  //   image: VC13Image,
  //   description: "",
  //   path: "/vc13",
  // },
  {
    id: 14,
    fromToDays: "09 Nights / 10 Days",
    title: "Amazing Vietnam",
    image: VC14Image,
    description: "",
    path: "/vc14",
  },
];

// const rulesData = [
//   ""
// ];

const VietnamCambodia = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Vietnam & Cambodia"}
            subTitle={"Amazing Holidays to Vietnam & Cambodia"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={VIETNAMCAMBODIA}
            title={"Popular Vietnam & Cambodia Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default VietnamCambodia;
