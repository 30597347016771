export const G1Image = "/assets/img/domesticTour/gujarat/G1.png";
export const G2Image = "/assets/img/domesticTour/gujarat/G2.png";
export const G3Image = "/assets/img/domesticTour/gujarat/G3.png";
export const G4Image = "/assets/img/domesticTour/gujarat/G4.png";
export const G5Image = "/assets/img/domesticTour/gujarat/G5.png";
export const G6Image = "/assets/img/domesticTour/gujarat/G6.png";
export const G7Image = "/assets/img/domesticTour/gujarat/G7.png";
export const G8Image = "/assets/img/domesticTour/gujarat/G8.png";
export const G9Image = "/assets/img/domesticTour/gujarat/G9.png";
export const G10Image = "/assets/img/domesticTour/gujarat/G10.png";
