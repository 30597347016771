import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { MP5Image } from "../image";

const heroTextData = [
  {
    title: "03 Nights / 04 Days",
    duration:  "Jyotirlingas Tour of Madhya Pradesh",
    typoOfTrip: "Road Trip",
    startCity: "Indore",
    endCity: "Indore",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Indore - Ujjain (55 Kms/ 01 Hrs)",
    details: "Morning Arrival At Indore. Proceed To Ujjain. Check In The Hotel. (Early Check In Subject To Room Availability). Later Visit Temples Like Harsidhi, Bada Ganesh, Kaalbhairav, Ram Ghat, Mangalnath, And Sandeepani Ashram. Overnight Stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Ujjain - Omkareshwar (135 Kms/3.5 Hrs) -maheshwar (70 Kms/ 1.5 Hrs)",
    details: "Morning Breakfast, Proceed For Omkareshwar, Visit Omkareshwar Temple (One Of The Twelve Jyotirlinga Temples).visit Mamleshwar Temple. Later Transferred To Maheshwar. Check In The Hotel. Overnight Stay",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Maheshwar - Indore (95 Kms/2.5 Hrs)",
    details: "Morning Breakfast, Check Out From The Hotel & Visit Maheshwar Ahilya Fort, Ahilya Mata's Cenotaph, Baneshwar Mahadev Temple, Rajarajeshwari Temple, Ek Mukhi Datta Temple. Later Transferred To Indore. Check-in At The Hotel. Evening Visit Chappan Gali (Food Hub Street). Return Back To The Hotel. Overnight Stay",
  },
  {
    id: "Day 4",
    header: "Indore Departure",
    details: "Morning Breakfast, Check-out Hotel & Visit Local Sightseeing Khajrana Ganesh Mandir & Lal Bagh. Later Drop To Indore Railway Station / Airport. End Of The Service!!!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const MP4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={MP5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default MP4;




// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
