import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { C5Image } from "../image";

const heroTextData = [
  {
    title: "Canadian Rockies Self Drive Tours",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "Calgary ",
    endCity: "Vancouver",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Calgary – Orientation Tour of Calgary - Banff",
    details:
      "Arrive into Calgary and transfer to Banff by Banff Transporter on SIC basis. Arrive into Banff and Check in at the hotel. Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:
      "Banff – City Tour of Banff – Banff Gondola Ride – Lake Minnewanka Cruise - (4.5 hours)",
    details:
      "After Breakfast, proceed for Explorer Banff City Tour, includes Banff avenue, Bow falls and Tunnel mountain to view the magnificent surprise corner. Also enjoy the Gondola ride to the top of 7,500 feet Sulphur mountain. Later Visit Lake Minnewanka (optional) and enjoy the Cruise ride on the biggest lake of Banff National Park. The highlight of this Cruise tour is the Devil's Gap; a glacial path carved through Lake Minnewanka from the foothills of the Rockies. Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Banff – Day Tour to Lake Louise – Banff",
    details:
      "After Breakfast, proceed for Day tour to Lake Louise called Mountain Lakes and Water Falls.  This amazing excursion takes you to the most treasured spots in Banff and Yoho National Parks. Explore the evolution of the land and discover how it has shaped the appearance of the natural wonders found in these spectacular mountain settings. Visit Lake Louise, Emerald Lake, BBQ lunch at Emerald Lake, Takkakaw Falls (if roads are operational) Spiral tunnel, Valley of Ten Peaks (if roads are operational). Return back to Banff. Overnight at the hotel.",
  },
  {
    id: "Day 4",
    header:
      " Banff – Columbia Ice Fields Parkway – Ice Explorer Ride – Glacier Skywalk – Jasper",
    details:
      "After breakfast, check out of the hotel and proceed towards Jasper. Enroute Visit Columbia IceFields Parkway – The Journey is through the world's most beautiful highways. Stop at Bow Lake before reaching the Columbia IceFields. Enjoy a ride on the Ice Explorer (Snocoach) onto the massive Athabasca Glacier – The Only Glacier accessible by Road. Later, enjoy the most memorable walk of Canadian Rockies on Glacier Skywalk. Experience a cliff-edge walkway that leads to a platform where glass is all that separates you from a 918 feet drop. Arrive into Jasper and check in at the hotel. Overnight at the hotel.",
  },
  {
    id: "Day 5",
    header: "MJasper – City Tour of Jasper - Kamloops",
    details:
      "After breakfast, check out of the hotel and proceed for Explorer Jasper City Tour. Visit the Jasper National Park which is a sweeping expanse of scenic beauty. Visit the Patricia and Pyramid Lakes & explore the natural attractions of the Maligne Canyon, with its Narrow Gorge and Limestone Walls created by 10,000 years of water erosion. Discover the mystery of the disappearing Medicine Lake. Return back to Jasper in late in the afternoon. In the evening take a leisurely stroll on Patricia street and enjoy the beautiful town site of Jasper. Overnight at the hotel.",
  },
  {
    id: "Day 6",
    header: "Kamloops – Via Rail",
    details:
      "After breakfast, check out of the hotel and board your Via rail train to Vancouver. Overnight at the hotel.",
  },
  {
    id: "Day 7",
    header: "Whistler – Blackcomb Mountain Tour – Peak 2 Peak Gondola Ride",
    details:
      "After breakfast, proceed for the day trip to Whistler on SIC basis. On arrival in Whistler, enjoy a ride on the brand new 4.4 km unsupported Peak 2 Peak Gondola ride (Operational after 25th May) connecting Whistler and Blackcomb mountains. Evening shall be free to stroll around the Whistler Village. Return back to Vancouver. Overnight at the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header:
      "Whistler – Shannon Falls – Sea to Sky Highway - City Tour of Vancouver – Flyover Canada",
    details:
      "After breakfast, check out of the hotel and proceed towards Vancouver. Enroute Visit Shannon Falls. Feel the mist of the spectacular 335-meter Shannon Falls. Enjoy the journey of Sea to Sky Highway enroute Vancouver. Arrive into Vancouver and enjoy the City Tour of Vancouver. Drive around the Stanley Park, at the Totem Poles, Canada Place, B C Place, Chinatown, historic Gastown (the oldest part of Vancouver) and Granville Island with its famous public market, art and craft shops. Drive across the magnificent Lions Gate bridge over Burrard Inlet to Vancouver’s North shore. Proceed to enjoy the ultimate Flying ride - FlyOver Canada which is a breathtaking, family-friendly, flight simulation ride like no other! Later check in at the hotel right in the heart of Vancouver Downtown. Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Vancouver – Capilano Suspension Bridge & Grouse Mountain Sky Ride",
    details:
      "After Breakfast, proceed for North Shore attractions of Vancouver. Capilano Suspension Bridge, the longest suspension bridge in Canada. Enjoy the Tree Top Adventure & the Cliff Walk. Later proceed to visit the Grouse Mountain and enjoy the Sky Ride here. Later on also visit Grouse Mountain to get the aerial view of Vancouver. Board the Gondola and climb up the summit of Grouse mountain. Enjoy various shows such as Lumberjack show, Birds in Motion demonstration and get up close and personal with Grizzly Bears on top of the mountain. Return back to the hotel in the evening.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Depart from Vancouver",
    details:
      "After breakfast, check out of the hotel and proceed to airport to board your flight back to your Home Town or to Cruise Terminal.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation in above mentioned Hotel or Similar category.",
  "Daily Hotel Breakfast.",
  "City tour of Banff with Gondola Ride & Lake Minnewanka Cruise.",
  "Excursion to Lake Louise.",
  "Visit Moraine Lake (Operational after 25th May).",
  "Visit Johnston Canyon.",
  "City tour of Jasper.",
  "Day trip to Whistler with Peak to Peak Gondola ride (Operational after 25th May)",
  "City tour of Vancouver.",
  "Entry to Flyover Canada.",
  "Entry to Capilano Suspension Bridge.",
  "Entry to Grouse Mountain.",
  "Self Drive Vehicle included from Day 1 Pick up at Calgary Airport till Day 10. The Self Drive vehicle details as mentioned below",
  "02 – 06 Pax – 7 seater Mini Van.",
  "08 Pax – 12 seater Van",
  "Unlimited Kms",
  "08 Pax – 12 seater Van",
  "Loss Damage protection",
  "One way drop off",
  "We have special blocks for the below dates for Deluxe Hotels",
  "The rates are subject to availability.",
];

const exclusionsData = [
  "Any Airfares or Visa Fees.",
  "Meals – Lunch & Dinner.",
  "Water Bottle in vehicle or Room.",
  "Porterage, Telephone calls, Mini Bar, Laundry, etc.",
  "Travel Insurance.",
  "City tax or Resort Fees or Environment Tax that hotel may collect only upon arrival.",
  "Deposit that guest may need to pay at the time of Check-in for incidental expenses.",
  "Any entrances or sightseeing not specifically mentioned in the above inclusion.",
];

const C5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={C5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default C5;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
