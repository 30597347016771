import { React } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Card,
  CardMedia,
  CardActionArea,
  Grid,
  Button,
} from "@mui/material";
import MenuButton from "./MenuButton";

const RADHA_TOUR_LOGO = "/assets/img/rada_tours_logo-transparent.png";

const drawerWidth = 240;

const Sidebar = ({ onClick, open, onClose, navItems }, props) => {
  const { window } = props;
  const drawer = (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ display: { sm: "none" } }}
      >
        <Grid item sx={{ m: 2 }}>
          <CardActionArea href="/home">
          <Grid
              item
              sx={{ m: 2, display: { xs: "block", sm: "block", md: "none" } }}
            >
              <img src={RADHA_TOUR_LOGO} alt="logo" style={{ width: 88, height: 54 }} />
            </Grid>
          </CardActionArea>
        </Grid>
      </Grid>
      <Divider />
      <Box>
        <List>
          {navItems.map((item) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton href={item.href} sx={{ textAlign: "center" }}>
                <ListItemText
                  primary={item.title}
                  sx={{ textTransform: "capitalize" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <MenuButton />
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            href={"/contact-us"}
            // sx={{
            //   color: "button.light",
            //   // bgcolor: "secondary.main",
            //   // color: "secondary.light",
            //   "&:Hover": {
            //     color: "secondary.light",
            //     bgcolor: "secondary.main",
            //   },
            // }}
          >
            Contact Us
          </Button>
        </Grid>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box component="nav">
      <Drawer
        anchor={"right"}
        container={container}
        variant="temporary"
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
