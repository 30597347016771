import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Main from "../../Components/layouts/Main";
import HeroSection from "./HeroSection";
import ServiceSection from "./ContentSection";
import ContentSection from "./ContentSection";

const PrivacyPolicy = () => {
  return (
      <Box sx={{ flexGrow: 1 }}>
        <Main>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HeroSection />
            </Grid>
            <Grid item xs={12}>
              <Container>
                <ContentSection />
              </Container>
            </Grid>
          </Grid>
          </Container>
        </Main>
      </Box>
  );
};

export default PrivacyPolicy;
