import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";

const CHARDHAM_TOUR = "/assets/img/domesticTour/Chardham.png";
const ODISHA_TOUR = "/assets/img/domesticTour/Odisha.png";
const GUJARAT_TOUR = "/assets/img/domesticTour/Gujarat.png";
const WT8Image = "/assets/img/worldTour/wt8.png";
const WT20Image = "/assets/img/worldTour/wt20.png";
const YATRA_HERO = "/assets/img/tour/Yatra.png";
const UTTARPRADESH_TOUR = "/assets/img/domesticTour/uttarpradesh.png";
const KARNATAKA_TOUR = "/assets/img/domesticTour/karnataka.png";
const MADHYAPRADESH_TOUR = "/assets/img/domesticTour/madhyapradesh.png";
const TAMILNADU_TOUR = "/assets/img/domesticTour/tamilnadu.png";

const tourCardContent = [
  {
    id: 1,
    totalPackges: 1,
    title: "Chardham Tour",
    image: CHARDHAM_TOUR,
    description: "",
    path: "/chardham-tour",
  },
  {
    id: 3,
    totalPackges: 5,
    image: GUJARAT_TOUR,
    title: "Gujarat",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/gujarat-yatra-tour",
  },
  {
    id: 4,
    totalPackges: 5,
    image: ODISHA_TOUR,
    title: "Odisha",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/odisha--yatra-tour",
  },
  {
    id: 12,
    totalPackges: 4,
    image: UTTARPRADESH_TOUR,
    title: "Uttar Pradesh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/uttar-pradesh-tour",
  },
  {
    id: 14,
    totalPackges: 3,
    image: MADHYAPRADESH_TOUR,
    title: "Madhya Pradesh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/madhya-pradesh-yatra-tour",
  },
  {
    id: 13,
    totalPackges: 4,
    image: KARNATAKA_TOUR,
    title: "Karnataka",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/karnataka-yatra-tour",
  },
  {
    id: 15,
    totalPackges: 3,
    image: TAMILNADU_TOUR,
    title: "Tamilnadu",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/tamilnadu-tour",
  },
  {
    id: 20,
    totalPackges: 3,
    title: "Nepal",
    image: WT20Image,
    description: "",
    path: "/nepal-yatra-tour",
  },
  {
    id: 5,
    totalPackges: 1,
    title: "Cambodia",
    image: WT8Image,
    description: "",
    path: "/cambodia-tour",
  },
];

const YatraPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"Yatra"}
              details={
                "If you are looking for a memorable Char Dham trip, RadhaTour holiday packages are the right choice for you. MakeMyTrip offers the widest range of customizable Char Dham tour . Explore from over 4 tour packages to Char Dham with unbeatable deals and discounts. Explore the main Char Dham sightseeing points with the variety of experiential tours and activities included in RadhaTour’s Char Dham Tour Packages."
              }
              heroImg={YATRA_HERO}
              // title={"World Tour Packages"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default YatraPackages;
