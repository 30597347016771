import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";

const ContentSection = () => {
  return (
    <Box>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        Our journey of excellence in travel and tourism commenced from the year
        2022. Before I incorporated this concept of travel and tourism business,
        I have extensively explored our country and also stayed at most of the
        places. Hence I can provide you with apt information about those places
        which will help our clients to have an exceptional travelling
        experience.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        Radha Tours is specialised in providing profitable and quality travel
        solutions to all our valued clients. We offer a wide spectrum of travel
        services like Flight Reservations (Domestic and International), Hotel
        Accommodations, Holidays and Honeymoon Packages, Group Travels, Car
        Rentals, and Train Tickets. We also assist our clients in obtaining
        Visa. We offer you amazing bargains with excellent service on all your
        travel bookings.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We believe in a long lasting partnership based on trust and teamwork.
        And we hope to be a part of your hospitality plan in providing our
        fellow guests a memorable stay and unforgettable experience.
      </Typography>
      <br />
      <Typography variant="h4" color={"text.secondary"} textAlign={"start"}>
        Vison
      </Typography>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        To be the trusted travelling agency recognized by our clients for
        delivering efficient travel services.
      </Typography>
      <br />
      <Typography variant="h4" color={"text.secondary"} textAlign={"start"}>
        Mission
      </Typography>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        To serve our clients with enhanced travel experience by providing
        quality services that address their travel needs.
      </Typography>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        To provide a professional service to the clients, ensuring they benefit
        from our experience and unique style
      </Typography>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        Since 2022, we have been committed to bringing our clients the best in
        value and quality travel arrangements.
      </Typography>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We are passionate about travel and sharing the world's wonders on the
        leisure travel side, and providing genuine travelers hi-touch services
        to facilitate their travel needs.
      </Typography>
      <br />
    </Box>
  );
};

export default ContentSection;
