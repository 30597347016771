import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

const inSectionData = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Services",
    href: "/services",
  },
  {
    title: "About Us",
    href: "/about-us",
  },
  {
    title: "Contact Us",
    href: "/contact-us",
  },
  {
    title: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    title: "Cancellation Policy",
    href: "/cancelation-policy",
  },
  {
    title: "Terms and conditions",
    href: "/terms-condition",
  },
  // {
  //   title: "Blog",
  //   href: "#",
  // },
];

const InSection = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" color={"text.primary"}>
                In Section
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {inSectionData.map((item) => (
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={12} md={12}>
                <Link href={item.href} underline="none" color={"text.primary"}>
                  {item.title}
                </Link>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InSection;
