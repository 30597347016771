import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { UP1Image, UP2Image, UP3Image, UP4Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const UTTARPRADESH_TOUR = "/assets/img/domesticTour/uttarpradesh.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "02 Nights/ 03 Days",
    title: "Kashi Darshan",
    image: UP1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/up1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Kashi -Prayag Darshan",
    image: UP2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/up2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Kashi - Gaya Darshan",
    image: UP3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/up3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Lucknow – Ayodhya",
    image: UP4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/up4",
  },
];


const UttarPradesh = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Uttar Pradesh"}
            subTitle={"Amazing Holidays to Uttar Pradesh"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={UTTARPRADESH_TOUR}
            title={"Popular Uttar Pradesh Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Assam"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default UttarPradesh;
