export const W1Image = "/assets/img/domesticTour/westbengalsikkim/W1.png";
export const W2Image = "/assets/img/domesticTour/westbengalsikkim/W2.png";
export const W3Image = "/assets/img/domesticTour/westbengalsikkim/W3.png";
export const W4Image = "/assets/img/domesticTour/westbengalsikkim/W4.png";
export const W5Image = "/assets/img/domesticTour/westbengalsikkim/W5.png";
export const W6Image = "/assets/img/domesticTour/westbengalsikkim/W6.png";
export const W7Image = "/assets/img/domesticTour/westbengalsikkim/W7.png";
export const W8Image = "/assets/img/domesticTour/westbengalsikkim/W8.png";
export const W9Image = "/assets/img/domesticTour/westbengalsikkim/W9.png";
export const W10Image = "/assets/img/domesticTour/westbengalsikkim/W10.png";
export const W11Image = "/assets/img/domesticTour/westbengalsikkim/W11.png";
export const W12Image = "/assets/img/domesticTour/westbengalsikkim/W12.png";
export const W13Image = "/assets/img/domesticTour/westbengalsikkim/W13.png";
export const W14Image = "/assets/img/domesticTour/westbengalsikkim/W14.png";
export const W15Image = "/assets/img/domesticTour/westbengalsikkim/W15.png";
export const W16Image = "/assets/img/domesticTour/westbengalsikkim/W16.png";
export const W17Image = "/assets/img/domesticTour/westbengalsikkim/W17.png";
export const W18Image = "/assets/img/domesticTour/westbengalsikkim/W18.png";
export const W19Image = "/assets/img/domesticTour/westbengalsikkim/W19.png";
export const W20Image = "/assets/img/domesticTour/westbengalsikkim/W20.png";
export const W21Image = "/assets/img/domesticTour/westbengalsikkim/W21.png";
export const W22Image = "/assets/img/domesticTour/westbengalsikkim/W22.png";
export const W23Image = "/assets/img/domesticTour/westbengalsikkim/W23.png";
export const W24Image = "/assets/img/domesticTour/westbengalsikkim/W24.png";
export const W25Image = "/assets/img/domesticTour/westbengalsikkim/W25.png";
export const W26Image = "/assets/img/domesticTour/westbengalsikkim/W26.png";
export const W27Image = "/assets/img/domesticTour/westbengalsikkim/W27.png";
export const W28Image = "/assets/img/domesticTour/westbengalsikkim/W28.png";
export const W29Image = "/assets/img/domesticTour/westbengalsikkim/W29.png";
export const W30Image = "/assets/img/domesticTour/westbengalsikkim/W30.png";
export const W31Image = "/assets/img/domesticTour/westbengalsikkim/W31.png";