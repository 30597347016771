import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O10Image} from "../image";

const heroTextData = [
  {
    title: "Eastern Divine Connection",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Kolkata ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Puri-Konark-Bhubaneswar (120 kms/2.5 hrs) ",
    details:
      "Morning visit to Loknath temple. After breakfast proceed to Bhubaneswar. En-route visit through Chandrabhaga beach,Sun temple& interpretation centre at Konark (the UNESCO World heritage monument), Pipiliappliqué market, Dhauli Buddhist shantistupa. On arrival at Bhubaneswar visit to the ancient temples like Lingaraj-the biggest temple in Bhubaneswar, Mukteswar& 64 Yogini temple (Yogini sculpture having 64 different hair style & one of the four Yogini temple in India). Evening free for leisure. O/N- Bhubaneswar. Note: 1. Lingaraj temple remain open from 05.00 to 21.00hrs and close for 01hr between 11.00 to 13.00hrs. Devotee can go in to the garbhagriha (sanctum sanctorum) between 06.00 to 08.00hrs & 13.00 to 16.00hrs. 2. Non-Hindu are not allowed in to the Lingaraj temple. They can see from the specific platform.", 
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:  " Bhubaneswar-Kolkata and s/s. ",
    details:
      "In-time transfer to Bhubaneswar railway station/airport for Kolkata. On arrival at Kolkata, half day city tour covering Howrah bridge (drive pass), Belur Math (H.Q of Ramakrishna Mission), Dakhineswar Kali temple, The house of Rabindranath Tagore (campus of Rabindra Bharati University). Later visit the British Colonial Area (well known as Dalhousie square where we see Raj Bhawan, General Post Office Building, Kolkata High Court, Kolkata Town Hall, Writers Building etc. (drive pass). If time permits visit to Victoria Memoria (the best building made by the British in India, which was inaugurated on 1921 by Prince of Wales in memories of Queen Victoria (Close on Monday). O/N-Kolkata Note: 1. Belur Math Opening time: 06.30hrs to 11.30hrs and 16.00hrs to 20.00hrs. Entry- Free. Ramkrishna Museum opening time: 06.30hrs to 11.30hrs and 16.00hrs to 17.30hrs. 2. Dakhineswar Kali temple: Opening time: 06.00hrs to 12.30hrs and 15.00hrs to 20.30hrs. 3. Victoria Memoria: Close on Monday, Opening time of Victoria Memoria Gallery – 10.00hrs to 17.00hrs. Entry fees: Rs.100/- Indian & Rs.500/- Foreigner ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Excursion trip to Gangasagar (110kms/2.5hrs one way)",
    details:
      "After breakfast full day excursion trip to Ganga sagar and back to Kolkata. Gangasagar-an Island in the confluence of River Ganga and the Bay of Bengal and famous for Kapilamuni Ashram which is considered to be a great religious significance. O/N-Kolkata NB: Ganga Sagar Trip, our cars will drop the guest at Harud Point. From there they have to cross the river Muriganga by boat & from another end of the river local cars will be available to take them up to Sagar Island & back on spot payment. Our drivers will help the guest to board the boat to cross the river & will wait at the Harud Point for their return. On their return, the same car will pick them up & will drop at Kolkata. Schedule: 06.30hrs: Drive from Hotel for Harwood Point (130kms) 08.15hrs: Reach Harwood Point 08.30hrs: From Harwood Point takes a ferry (available at an interval of 01 hour) to reach Kachuberia 09.30hrs: From Kachuberia to Gangasagar by local Car (34kms) 10.30hrs: Reach Gangasagar 10.30hrs to 13.00hrs at Gangasagar 3.30hrs: Start for your return journey to Kolkata/ reach Kolkata by 19.30hrs",
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Kolkata departure",
    details:
"Post breakfast visit to Kali temple (Kalighat) -the great pilgrimage center of Hindu, Birla temple. If time permits free for shopping at different market. In-time proceed to railway station/airport for onwards journey. Tour End. Note: 1. Birla temple: Opening time: 05.00am to 11.30am and 04.00pm to 09.00pm. Entry- Free.",
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O10Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O9;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

