import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { L1Image } from "../image";

const heroTextData = [
  {
    title: "Srinagar To Leh",
    duration:  "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Srinagar",
    endCity: "Leh",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Srinagar Arrival",
    details: "Transferred to Hotel Srinagar /Sonamarg. In the evening local Sightseeing at Srinagar. O/n stay at hotel/house boat.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Srinagar /Sonamarg to Kargil via Zojila",
    details: "After B/fast set off for Kargil via Sonamarg, Zojila pass, Drass. En route visit vijay samarg (war memorial of drass) kargil & O/n stay at Kargil Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Kargil to Leh -Mulbek-Lamayuru (230 km",
    details: "After B/fast set off for Leh.en route visit Mulbek monastery and Lamyuru (Moon land Area) Sangam point, Magnetic hill, Pathar sahib Gurudwara and O/n stay at Leh Hotel",
  },
  {
    id: "Day 4",
    header: "Leh to Nubra Valley via Khardong la Pass (126Km)",
    details: "After B/fast set off for Nubra valley via Khardong la paas (the world’s highest motorable road in the world) (18,640 feet) and o/n in stay at Nubra Tent/ Hotel. (3033Mtrs) places to visit are Diskit monastery &Hunder sand dunes.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Hunder – Turtuk – Hunder (160 Km)",
    details: " A full day excursion: After B/fast set off for Turtuk. A border village that was recaptured by the Indian Army in 1971. Locals have relatives across the border (Line of Control) A full day excursion evening back Hunder. After being settled in a hotel/ fixed Camp at Hunder/ Disket 2nd Option: Or If weather Permit, 2nd route, that is to reach Pangong Lake directly from Nubra Valley. But it’s depending on the weather conditions",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Nubra- Pangong Lake via Shayok River – Pangong – Leh (314 Kms)",
    details: "After early B/fast set off for Pangong Lake Via Shayok River, Agyam Village and Tangtse , It is situated at alt: 14,000 ft. Pangong is 5-6Kms wide . Post Lunch drive back to Leh via Changala Pass- world’s 3rd highest motor able road) 17,350ft/5486 Mtrs. Later Kharu continue drive to Leh.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Local Sightseeing East Indus Valley: - Thiksey Monasteries- Rincho School- Sindhu Ghat",
    details: "After b/fast set off for Hemis Monastery. En route visit Druk Pama Karpo (Rincho School), Thiksay monastery & Sindhu Ghat visit - Spituk-Hall of Fame-Shanti Stupa. Tsemo and Leh Palace explore Leh market for Shopping and have the day for Leisure. Overnight at hotel in Leh",
  },
  {
    id: "Day 8",
    header: "Departure",
    details: "Our teams thank you for your patronage and ensure a punctual drop to Leh Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodations on double/twin sharing basis as per package Selected.",
  "Meal Plan based on MAP (Room + Breakfast +Dinner) included in the package cost.",
  "All transport Services as per the itinerary by Non-A/C Crytsa Innova/Innova/ xylo / Ertiga. The same car shall do the complete itinerary (Maximum 6 persons including kids) are possible in one vehicle, for more than 6 please ask for Tempo Traveller).",
  "Meeting and Assistance on Arrival and Dept by our representative",
  "An experienced driver would work as a basic guide (You can hire a dedicated guide as well)",
  "Parking, toll, fuel, driver allowances.",
  "Inner Line Permits/Wild Life Fees. Except 11year old below",
  "01 Oxygen Cylinders will be available in the cars during Nubra Valley and Pangong Tour only. If you require Oxygen other than these days, ",
];

const exclusionsData = [
  "Any kind of Medical & Travel insurance.",
  "Expenses of personal nature such as Tips, Laundry, Telephone, Table Drinks & portage et",
  "Extra nights in hotel due to ill health, flight cancellation or any other reason",
  "Service of vehicle is not included on leisure days & after finishing sightseeing tour",
  "Adventure activity like Rafting, Camel Safari, Mountain Biking, Motor Biking etc",
  "Monastery entrance fees, camera fee, and monument guide service",
];

const L1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={L1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default L1;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];


