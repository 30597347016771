import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  L1Image,
  L2Image,
  L3Image,
  L4Image,
  L5Image,
  L6Image,
  L7Image,
  L8Image,
  L9Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const LADAKH_TOUR = "/assets/img/domesticTour/LehLadakh.png";

const tourCardContent = [
  {
    id: 4,
    fromToDays: "05 Nights / 06 Days",
    title: "Exotic Ladakh",
    image: L4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l4",
  },
  {
    id: 5,
    fromToDays: "05 Nights / 06 Days",
    title: "Silk Route",
    image: L5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l5",
  },
  {
    id: 2,
    fromToDays: "06 Nights / 07 Days",
    title: "Best Of Ladakh",
    image: L2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Discover Ladakh",
    image: L3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l3",
  },
  {
    id: 1,
    fromToDays: "07 Nights / 08 Days",
    title: "Srinagar To Leh",
    image: L1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l1",
  },
  {
    id: 6,
    fromToDays: "07 Nights / 08 Days",
    title: "Amazing Ladakh",
    image: L6Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l6",
  },
  {
    id: 7,
    fromToDays: "08 Nights / 09 Days",
    title: "Moon Valley & Pangong Lake",
    image: L7Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l7",
  },
  {
    id: 8,
    fromToDays: "08 Nights / 09 Days",
    title: "Ladakh Kashmir Tour Package",
    image: L8Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l8",
  },
  {
    id: 9,
    fromToDays: "11 Nights / 12 Days",
    title: "Ladakh Panorama Tour",
    image: L9Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/l9",
  },
];

const rulesData = [
  "Age between 10 to 60 years, physically fit.",
  "Drinking alcohol/smoking is strictly prohibited during the tour.",
  "Clients are requested to carry, body lotion, sun glasses, and warm clothes along with them",
  "Prepaid mobile numbers of other states do not work in J&K. Carry Post - paid SIM (BSNL, JIO OR AIRTEL)",
  "Skip of Meals - Due to any unavoidable circumstances, your major meals (Lunch) may get skipped and we will not be able to provide any such meals in remote places. Please take sufficient dry food with you and carry the same throughout the tour for any such emergency situation.",
  "Ladakh Tours reserves the right to re-arrange itinerary to suit hotel availability without changing the total number of days in each destination and without compromising any services.",
  "Accommodation at Pangong and Tsomoriri lakes are mainly in tented accommodation with limited facilities, such as restricted or no power supply, limited hot water provision, no TV etc.",
  "Visitors must also be prepared to pass the night in low temperature even at the height of summer. The stress of passing the night at high altitude is also to be kept in mind",
  "Cost incidental to any change in the itinerary/stay on account of flight cancellation due to bad weather, ill health, road blocks or any factors beyond control on any circumstances",
  "The vehicle used is non-air-conditioned and is available for point-to-point services only and is not at disposa",
  " If you planning your next vacation to Ladakh here are few things that are must have in your kitty, to make your trip a fulfilling and a comfortable experience. Since the weather in Ladakh is highly unpredictable, you need to be prepa",
  "CLOTHES:",
  "Warm coat or jacket • Scarf, hat, gloves• Short sleeved shirt or Long-sleeved, sweater: 1-2 pcs• Thin (quickly dries) • Scarf to cover head: 1-2 pcs",
  " TECHNICAL SUPPLY:",
  "Sunglass (with cover) • Additional sun-glass • First aid or medicine box, sun cream, lip-gloss and female tampon et",
  "All Transport Services as per Itinerary by Non-AC. The same shall do the complete Itinerary",
  " The vehicle provided is for sightseeing as per the itinerary on point-to-point basis and not at disposal after or outside the prescribed tour",
  " Places / sites not visited as per the given tour sequence / route on guest's own advice / choice will not be revisited except on extra payment as per applicable transport rate",
  "Driver of the taxi is not a guide. He will only provide basic information about the tour route.",
  "Usage of vehicle's air-conditioning will be subject to extra payment for the distance covered as per the prescribed rates available with the driver.",
  "In the event of illness, accident, emergency medical evacuation either by foot, vehicle, helicopter or otherwise, the responsibility for the payment of such costs lies with the client and should be paid directl",
  " TRAVEL INSURANCE",
  "Travel insurance is NOT included in the Taxi price. Infinity Adventures & Tours strongly recommends that passengers arrange their own travel insurance to cover any cases of illness, injury, loss/damage to personal belongings and not being able to travel on the confirmed departure. Emergencies including hospitalization, hotel bills, flights (if you have to return early), helicopter rescue, etc. Infinity Adventures & Tours carries no type of liability insurance, personal medical insurance, emergency rescue or evacuation, or any type of insurance whatsoever for participants",
];

const LehLadakhPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Leh Ladakh Tour Packages"}
            subTitle={"Amazing Holidays to Leh Ladakh"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={LADAKH_TOUR}
            title={"Popular Leh Ladakh Holiday Packages"}
            tourCardContent={tourCardContent}
            ruleTitle={"Leh Ladakh"}
            rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default LehLadakhPackages;
