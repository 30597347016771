import { React } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

const DOMESTIC_TOUR = "/assets/img/tour/domestic_tour.png";
const YATRA_TOUR = "/assets/img/tour/Yatra.png";
const INTERNATIONAL_TOUR = "/assets/img/tour/international_tour.png";
const HONEYMOON_TOUR = "/assets/img/tour/honeymoon_tour.png";
const STUDENT_SPECIAL_TOUR = "/assets/img/tour/student_special_tour.png";
const POPULAR_TOUR = "/assets/img/tour/popular_tour.png";

const tourPackage = [
  {
    id: 1,
    title: "India",
    image: DOMESTIC_TOUR,
    path: "/domestic-tour",
  },
  {
    id: 2,
    title: "World",
    image: INTERNATIONAL_TOUR,
    path: "/world-tour",
  },
  {
    id: 3,
    title: "Yatra",
    image: YATRA_TOUR,
    path: "/yatra-tour",
  },
  {
    id: 4,
    title: "Honeymoon",
    image: HONEYMOON_TOUR,
    path: "/honeymoon-tour",
  },
  {
    id: 5,
    title: "Students Special",
    image: STUDENT_SPECIAL_TOUR,
    path: "/student-tour",
  },
  {
    id: 6,
    title: "Popular",
    image: POPULAR_TOUR,
    path: "/popular-tour",
  },
];

const TourSection = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h2"
          marginBottom={1}
          sx={{ color: "text.secondary" }}
        >
          Tour Packages
        </Typography>
        <Grid container spacing={2}>
          {tourPackage.map((item) => (
            <Grid item xs={6} sm={4} md={2}>
              <Card sx={{ maxWidth:400, position: "relative" }}>
                <CardActionArea href={item.path}>
                  <CardMedia
                    height={"180"}
                    component="img"
                    image={item.image}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 128,
                      left: 10,
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        color: "text.light",
                        textShadow: "1px 1px 1px black",
                      }}
                    >
                      {item.title}
                      <br />
                      Tours
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default TourSection;
