import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

const holidayThemeData = [
  {
    title: "India Tours",
    href: "/domestic-tour",
  },
  {
    title: "World Tours",
    href: "/world-tour",
  },
  {
    title: "Yatra Tour",
    href: "/yatra-tour",
  },
  {
    title: "Student special Tour",
    href: "/student-tour",
  },
  {
    title: "Honeymoon Tours",
    href: "/honeymoon-tour",
  },
  {
    title: "Popular Tours",
    href: "/popular-tour",
  },
];

const HolidayTheme = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" color={"text.primary"}>
                Holiday Theme
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {holidayThemeData.map((item) => (
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={12} md={12}>
                <Link href={item.href} underline="none" color={"text.primary"}>
                  {item.title}
                </Link>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HolidayTheme;
