import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { G9Image } from "../image";

const heroTextData = [
  {
    title: "Statue Of Unity",
    duration: "02 Nights / 03 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhuj ",
    endCity: "Bhuj ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "By Air / Train – Arrive Ahmedabad Followed By Road – 200 Kms / 04 Hrs – Ahmedabad To Kevadia Or By Air / Train – Arrive Vadodara Followed By Road – 90 Kms / 02 Hrs – Vadodara To Kevadia",
    details:
      "Arrive Ahmedabad / Vadodara and drive to Kevadia, arrive and transfer to your Tent City. The world’s tallest statue – the Statue of Unity, against the backdrop of the dramatic Satpura and Vindhyachal hills in Kevadia, Gujarat. The 182-metre (600 feet approx.) statue is dedicated to Sardar Vallabhbhai Patel, the architect of independent India. The colossal monument towers over River Narmada, a tribute to India from the people of Gujarat’ to the leader who placed people’s welfare first. The Statue of Unity overlooks the vast surrounds and the river basin of the Narmada River and the sprawling Sardar Sarovar dam. It stands on the Sadhu Bet hillock, connected by a 300-metre bridge, which offers access from the mainland to the statue. Visit the different attractions at Statue of Unity Can be booked directly from https://soutickets.in/. CLOSED ON MONDAY Overnight in Tented Accommodation. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "In Kevadia",
    details:
      "Visit the different attractions at Statue of Unity Can be booked directly from https://soutickets.in/. CLOSED ON MONDAY Overnight in Tented Accommodation.",
  },
  {
    id: "Day 3",
    header: "By Road – 90 Kms / 02 Hrs – Kevadia To Vadodara Or By Road – 200 Kms / 04 Hrs – Kevadia To Ahmedabad Followed By Air / Train – Depart Vadodara / Ahmedabad",
    details:
      "After breakfast drive to Ahmedabad / Vadodara and in time transfer to Ahmedabad / Vadodara Airport / Railway Station to board your flight / train for onward destination.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "2 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
  "Meal Plan – As Mentioned above.",
  "All currently applicable taxes.",
  "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
  "Road taxes, parking fee, fuel charges, interstate taxes.",
];

const exclusionsData = [
  "Monument Entrance Fee.",
  "Services of English-Speaking Local Guide.",
  "Game Drive at Sasangir National Park or any other national park.",
  "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
  "International or Domestic airfare / Train Fare.",
  "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
];
const G9 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={G9Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default G9;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
