import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { JO1Image } from "../image";

const heroTextData = [
  {
    title: "Wonders of Jordan",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Amman ",
    endCity: "Amman",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Amman (40 KM)",
    details:
      "Arrival in Amman, capital of Jordan, visa formalities and transfer to hotel. Dinner and overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Amman – Madaba – Mount Nebo – Karak or Shobak – Petra (360 KM)",
    details:
      "Breakfast at the hotel. Departure to Madaba the City of Mosaics where the church of Saint George which houses the map of the Holy Land made ​​in the year 571 D.C. Continue to the tomb of Moses and from the top of a panoramic magnificent Jordan Valley can be seen. Then exit thru the Kings Highway to Karak or Shobak fortress built between 1110 and 1213 DC by the Templers during the Crusades, which then were taken by Saladin. Departure to Petra. Dinner and overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Petra (Hotels 5-10 KM)",
    details:
      "Breakfast at the hotel. Classic full day visit to the Nabataea city of Petra, where the movie Indiana Jones was filmed. Petra one of the Seven Wonders of the World, carved out of rock rose over 2000 years Aug. Proceed to the entrance of the gorge (Siq) from there continue to reach the impressive Treasury (El Khazneh). Visit the theater, the street columns, and royal tombs. (Climb Up to the monastery on behalf of the clients, without guide). Return to the hotel. Dinner and overnight at the hotel.",
  },
  {
    id: "Day 4",
    header: "Petra - Wadi Rum – Aqaba - Amman (550 KM)",
    details:
      "Breakfast at the hotel. Proceed to the desert of Wadi Rum, one of the scenes from the film Lawrence of Arabia and The Martian by Mat Damon, also one of the most spectacular settings in the Middle East. It is a desert of red sand where granite mountains and peaks of red and gold colors stand. A classic tour in 4x4 local vehicles for a breathtaking scenery of approximately 2 hours. Depart to Aqaba for free time in the city and / or beach. (Entrance to the beach not included, direct payment by the customer. Beaches in Aqaba are private, there is only one public beach north with basic services). Drive to Amman. Dinner and overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Amman (550 KM)",
    details: "Breakfast at the hotel in Amman. Transfer to the airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Meet and Assist with Our Representative",
  "Transfers Airport - Hotel - Airport",
  "Leisure Transportation according number of participants with 1 luggage and 1 hand bag per person",
  "Accommodations in double or twin rooms with single supplement on selected hotel options: 2 nights Amman and 2 nights Petra",
  "Meals as per itinerary (B = Breakfast, D = Dinner)",
  "Classic visits mentioned with entrance fees included in the tour itinerary and Jeep 4x4 (classic tour) in Wadi Rum",
  "English / French Speaking Local Guide for visits",
  "Visa Exemption Service",
];

const exclusionsData = [
  "Visa and Exit Fees. Visa fees: 40 JOD approximately 60 USD per person and exit fees thru Airport 30 JOD approximately 45 USD per person included in the air ticket. Exit fees by any bridge 10 JOD approximately 15 USD per person. Consult for visa exemption services and free group collective visa entry.",
  "Lunches.",
  "All kinds of drinks and water.",
  "All kinds of tips and portages.",
  "Personal expenses.",
  "Travel Insurance.",
  "Any Service not mentioned in the program",
];

const JO1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={JO1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default JO1;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
