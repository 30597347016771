export const MA1Image = "/assets/img/worldTour/Maldives/MA1.png";
export const MA2Image = "/assets/img/worldTour/Maldives/MA2.png";
export const MA3Image = "/assets/img/worldTour/Maldives/MA3.png";
export const MA4Image = "/assets/img/worldTour/Maldives/MA4.png";
export const MA5Image = "/assets/img/worldTour/Maldives/MA5.png";
export const MA6Image = "/assets/img/worldTour/Maldives/MA6.png";
export const MA7Image = "/assets/img/worldTour/Maldives/MA7.png";
export const MA8Image = "/assets/img/worldTour/Maldives/MA8.png";
export const MA9Image = "/assets/img/worldTour/Maldives/MA9.png";
export const MA10Image = "/assets/img/worldTour/Maldives/MA10.png";
export const MA11Image = "/assets/img/worldTour/Maldives/MA11.png";
