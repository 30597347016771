import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const BEST_OF_GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Best_of_Golden_Triangle.png";


const heroTextData = [
  {
    title: "Best of Golden Triangle",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Delhi ",
    endCity: "Jaipur",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Delhi",
    details:
      "Welcome and Arrive Delhi Airport / Railway Station and meet our local representative who will transfer to the hotel. Check into the Hotel then Proceed to Delhi Sight seen the Red Fort, India Gate, in evening Proceed to Akshardham Temple. Overnight Stay in Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Delhi",
    details:
      "After Break Fast. Proceed to Sight seen Lotus Temple, Jama masjid Delhi, Qutab Minar, Old Delhi & Free for Shopping. In Evening Back to Hotel. Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Delhi - Agra",
    details:
      "After Breakfast, Check out Hotel. Proceed to Agra Enjoy sight seen Taj mahal, Agra Fort, Moti Masjid and Local Sightseeing. Overnight stay in hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Agra - Jaipur",
    details:
      "After Breakfast & Check out from hotel. Proceed to Jaipur Via Fatehpur Sikri transfer to the hotel, Birla Mandir, Albert Hall. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Jaipur",
    details:
      "After breakfast, proceed to tour of Jaipur. Jaipur - the capital of Rajasthan was given a colour coat of pink a century ago in honour of a visiting Prince and ever since, it has retained this colour. Built by Maharaja Jai Singh, the notable astronomer, this city is 260 years old. Proceed to Amber fort, built in 16th century, enjoy an Elephant ride (By Own) at Amber, the Observatory Visit the Sheesh Mahal or the Hall of Victory glittering with mirrors. Photo stop at Jal mahal. Drive past the Hawa Mahal, Visit Maharaja's City Palace, Jantar Mantar, Albert Hall, Birla Temple. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Jaipur",
    details:
      "After Breakfast, Check out from hotel. Proceed to Drop at Jaipur Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
  ];
  
  const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
  ]
  
const BestofGoldenTriangle = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={BEST_OF_GOLDEN_TRIANGLE_TOUR_RAJASTHAN_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default BestofGoldenTriangle;
