import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KA3Image } from "../image";

const heroTextData = [
  {
    title: "Almaty Mountains",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Astana",
    endCity: "Astana",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Day 1",
    details: "Departure from Delhi by flight KC 908 Air Astana Arrival in Almaty, meeting at the airport and transfer to the hotel - Check-in To the hotel 17:30 - Check-in 19:00 transfer from hotel to Indian Restaurant ,dinner Transfer from Indian restaurant to hotel Welcome to Kazakhstan! local_diningMeals: Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day 2",
    details: "Breakfast in the hotel “Big Almaty Lake-”this excursion can be provided whole year but depends on the weather conditions and provided by request. The tour starts with the lunch at the local cafe, and then you can climb the gorge, where at an altitude of 2510 m above sea level there is the Big Almaty Lake - one of the most picturesque lakes of the Zailiysky Alatau. The lake fascinates with its beauty, especially when its surface is absolutely calm and reflects the sky and surrounding mountains. BAL was formed as a result of glaciers melting. Due to high-mountain location the water temperature never exceeds +10 degrees. The route of the tour will take place at an altitude of 3300 meters above sea level, where the bases for scientific astronomical and space research are located. Here, at the base of the Big Almaty peak, you can enjoy the enchanting panorama of the mountains and BAO surrounded by picturesque mountains covered with Teen Shan spruce, aspen and juniper. On the way back to the city you will visit the falcon nursery, where you can see a spectacular show with hunting birds of prey. arrival to city, transfer to hotel Free time Option: Transfer to night clubs for additional payment. Or maybe you would like enjoy Casino … It is special casino space not far from Almaty ( about Breakfast, lunch 1 hour by transfer ) where you can relax and check your success. Transfer to casino can be also provided by request for additional payment Enjoy your time and see all the colors of Almaty… Dinner in Indian restaurant Transfer to hotel local_diningMeals: Breakfast, lunch",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day 3",
    details: "Breakfast in the hotel “Surrounded by mountains” The tour route gives you a wonderful opportunity to get acquainted with the most beautiful mountains of Zailiyskiy Alatau, surrounding the city of Almaty. The first stop awaits you in the Medeo natural boundary, where you see the dam that protects the city from the devastating mudflows that form on the tops of the Western Tien Shan. Further, the tour route will take you to a height of 2260 meters above sea level ( the first level of mountains), where the ski resort Shymbulak is located, and which is considered to be one of the best ski bases in the territory of the CIS. The tour ends with the descent from the mountain top into the very center of the megapolis in the cabin of the famous cable road of Almaty... lunch in Indian restaurant Transfer to the hotel by request for additional payment Free time Option: Or maybe you would like enjoy Casino … It is special casino space not far from Almaty ( about 1 hour by transfer ) where you can relax and check your success. Transfer to casino can be also provided by request for additional payment local_diningMeals: Breakfast, Lunch",
  },
  {
    id: "Day 4",
    header: "Day 4",
    details: "Breakfast in the hotel Relax and go shopping! First you will visit Zhinoli bazaar where you can buy different presents and sweets. Then our tour will continue to chocolate factory “Rakhat” . It is well known factory in Kazkahstan and abroad where you can buy delicious sweets and cakes in the shop of factory. Next stop is the biggest Mall “Mega” where guests can go shopping, visit cinemas , entertainment centers and spend wonderful day! Transfer to the hotel by request for additional payment Free time Option: transfer to night clubs for additional payment. Or maybe you would like enjoy Casino … It is special casino space not far from Almaty ( about 1 hour by transfer ) where you can relax and check your success. Transfer to casino can be also provided by request for additional payment Enjoy your time and see all the colors of Almaty… local_diningMeals: Breakfast",
  },
  {
    id: "Day 5",
    header: "Day 5",
    details: "Transfer from the hotel to the airport Flight Almaty-Delhi, flight KC 907 airline Air Astana Arrival in Delhi local_diningMeals: Lunch box",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Visa Invitation",
"Transfers and transportation services according to the program",
"Accommodation in a hotel in a standard double room",
"The food indicated in the program",
"Excursions according to the program with a guide",
"Entrance tickets and environmental fees according to the program",
"Water during excursions ( 2 battles )",
];

const exclusionsData = [
"Flight",
"Food not indicated in the program",
"Visa support and registration on the territory of the Republic of Kazakhstan (if necessary)",
"Personal and other expenses not indicated in the program",
];

const KA3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KA3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KA3;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
