import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { E2Image } from "../image";

const heroTextData = [
  {
    title: "Cairo + Nile Cruise",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Cairo",
    endCity: "Cairo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Cairo",
    details: "For your added comfort, upon your arrival at the airport, an airport representative will greet you in the tunnel and escort you through customs and immigration. The representative will facilitate the included visa process for you. Continue to the baggage claim hall and passenger pick up. Here an airport representative will help you connect with your private transfer to your hotel. Enjoy a private airport transfer accompanied by an English-speaking representative. Enjoy time at leisure to explore at your own pace, or relax at your hotel. Accommodation: At your chosen hotel on Bed & Breakfast basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Cairo / Aswan",
    details: "Arrival transfer: Fly from Cairo Airport to Aswan Aswan: Enjoy a private airport transfer accompanied by an English-speaking guide. Welcome aboard! Take some time to get settled into your room and to explore the length of your ship. Your Guide will meet you at the cruise to start your sightseeing Embarkation before lunch. Visit the High Dam & the temples of Philae. PM: Sailing around Aswan Islands in the traditional felucca (sailing boat) viewing the Agha Khan Mausoleum and the Botanical Garden (Kitchener Island). Overnight in Aswan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Aswan",
    details: "Navigation to Kom Ombo. Visit Kom Ombo Temple the only double Temple in Egypt shared by the crocodile-headed God Sobek and the falcon-headed God Haroeris. Navigation to Edfu Visit the most well preserved Temple in Egypt dedicated to the Falcon God Horus Navigation to Luxor via Esna Overnight in Luxor",
  },
  {
    id: "Day 4",
    header: "Aswan - Luxor",
    details: "Visit the Ancient Theban Necropolis in the west bank of Luxor to see the Valley of Kings where more than 60 of Egypt Pharaohs have their resting place carved out of the mountain cliffs. The visit Medinet Habu, the Mortuary Temple of Ramses III which is one of the last great buildings of the Egyptian monumental architecture of the New Kingdom. Visit the Colossi of Memnon, the only remains of the funerary Temple of Amen phis III. PM: Visit the fabulous Luxor Temple and the enormous Temple complex of Karnak. Among its splendors is the Hypostyle Hall, the largest of any temple in the world covering an area of 5356 sq. meters with its roof blocks supported by 134 colossal columns 70 feet high. Overnight in Luxor.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Full Day Egyptian Museum, Old Cairo & Khan El Khalil",
    details: "Transfer To Fly To Cairo From Luxor Airport Arrival transfer: For your added comfort, upon your arrival at the airport, an airport representative will greet you before passport Control and Escort you through customs and immigration. The representative will facilitate the included visa process for you. Continue to the baggage claim hall and passenger pick up. Here an airport representative will help you connect with your private transfer to your hotel. Enjoy a private airport transfer accompanied by an English-speaking representative. Enjoy time at leisure to explore at your own pace, or relax at your hotel. Accommodation: At your chosen hotel on Bed and Breakfast basis Your Guide will meet you at the lobby to start the sightseeing Enjoy visiting famous attractions in Cairo such as the Egyptian Museum, which features artifacts from the Pharaonic period. The museum displays a rare collection of 5000 years of art which is considered the most precious collection of Egyptian art in the world. Over 250,000 genuine artifacts are presented, including an overwhelming collection of the young pharaoh king TUT whose tomb was discovered at the beginning of the 20th century when the whole world was shocked by the amount of gold discovered in his tomb.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Full Day Pyramids and Sakkara",
    details: "Your Guide will meet you at the hotel to start your sightseeing Get excited by a wonderful tour to The Pyramids of Cheops, Chephren, and Mykerinus. Visit the Great Sphinx, which has the head of a pharaoh with a lion's body and it dates to the time of Chephren. The tour also includes a visit to the Valley Temple, which belongs to the Pyramid of Chephren. Move to have an exciting tour to the Step Pyramids of Zoser; the world's oldest major stone structure. It was built in the 3rd Dynasty (around 2630 BC) for King Djoser. of Egypt, and see the Statue of Ramses II. Back to the hotel evening at Lei.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Final Departure",
    details: "Check out from the Hotel, enjoy a private transfer accompanied by an English-speaking Representative to Q.A.I.A for the final departure",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"Accommodation as per program",
"Transfers and sightseeing by A/C coach",
"All Excursions and Tours with English speaking guide except optional tours.",
"One bottle of Mineral Water per person per day during the tour",
];

const exclusionsData = [
"Tips",
"Domestic Flights and International flights",
"Entry Visa will cost 45.00 USD each per each passport. Any Extras",
];

const E2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={E2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default E2;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
