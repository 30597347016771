import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC8Image } from "../image";

const heroTextData = [
  {
    title: "Northern Vietnam",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Hanoi",
    endCity: "Hanoi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
  id: "Day 1",
  header: "Hanoi arrival",
  details: "Welcome to Hanoi.Hanoi, the capital of Vietnam, is known for its centuries-old architecture and a rich culture with Southeast Asian, Chinese and French influences. At its heart is the chaotic Old Quarter, where the narrow streets are roughly arranged by trade. Upon arrival at the airport, you will be transferred to Hanoi for sightseeing Visit Ho Chi Minh complex including Ho Chi Minh Mausoleum from (Outside), his house-on-stilts, the One-Pillar pagoda and Hoa Lo Prison. Lunch at IndianRestaurant Afternoon, visit the Temple of Literature built in 1070 and regarded as the First University in Vietnam, the Hoan Kiem Lake and Ngoc Son temple and take the Cyclo tour at the Old Quarter to experience the exciting local daily life of Hanoians, shopping free around The Old Quarter that have stock of trendy to basic clothing and thousands of small craft and boutique shops offering variety of Vietnamese handicraft products Dinner at Indianrestaurant ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 2",
  header: "Hanoi – Ninh Binh",
  details: "Breakfast at Hotel Departure to visit Ninh Binh -90 km far from Hanoi. We will visit the ancient capital of Hoa Lu with the temples of Dinh and le Dynasties. Continue to visit Tam Coc – known as Halong Bay on land Lunch at Local Restaurant. Afternoon boat trip to visit Tam Coc, explore the beautiful Karst formations and mystery caves. Back to the pier, visit to Bich Dong Pagoda Dinner at local restaurants ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 3",
  header: "Ninh Binh – Halong",
  details: "Breakfast at Hotel and Check-out Transfer to Ha Long Bay in Quang Ninh Province (around 160KM). Upon arrival in Halong Bay, boarding the Cruise for exploring wonderful Bay of Halong Lunch at boat while cruising around the Bay Afternoon: explore hundreds of beautiful karst formations arising from green emerald water, explore Cave, swimming and enjoy Sunset on the Cruise (Program might change due to the weather and the management) Dinner on the Cruise. After dinner is fishing time for everyone who wishes to join Overnight on the Cruise ",
},
{
  id: "Day 4",
  header: "Halong – Hanoi",
  details: "Breakfast at Boat Cruise Continue to discover the Halong bay then check-out Lunch on Cruise Restaurant Transfer from Halong Bay to Hanoi En-route visit Ceramic workshop at Dong Trieu ",
  labelData: ["label1", "label2", "label3", "label4"],
},
  {
    id: "Day 5",
    header: "Hanoi Departure",
    details: "Breakfast at Hotel and Free at your leisure Transfer to Noi Bai International Airport for flight to departure flight",
  },
];

const inclusionData = [
  "English speaking tour guide",
  "Private air-conditioned transportation",
  "Accommodation as mentioned or similar",
  "Boat trips",
  "Meals as mentioned on tour dating: B=Breakfast, L=Lunch, D=Dinner",
  "All entrance fees",
  "Two way airport transfers",
  "Water & tissues",
  
  ];
  
  const exclusionsData = [
  "International/Domestic flights costs",
  "Personal expenses, meals and drinks unless otherwise specified",
  "Travel insurance",
  "Visa fees to Vietnam",
  "Compulsory Tipping of 3$/pax/day",
  "Surcharge for peak season, New year, Holiday… ",
  
  ];

const VC8 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC8Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC8;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
