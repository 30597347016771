import React from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Divider,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PropTypes from "prop-types";

const ItinetaryAccordion = ({ title, dayDetails, labelData }) => {
  return (
    <Grid>
      <Accordion
        sx={{
          boxShadow: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                textTransform={"capitalize"}
                color="text.primary"
              >
                {title}
              </Typography>
            </Grid>
            {labelData?.map((item) => (
              <Grid item xs={12}>
                <Chip label={item} variant="outlined" sx={{ m: 1 }} />
              </Grid>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="caption"
            color="text.primary"
            textTransform={"capitalize"}
          >
            {dayDetails}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Grid>
  );
};

ItinetaryAccordion.propTypes = {
  title: PropTypes.string,
  dayDetails: PropTypes.string,
  labelData: PropTypes.func,
};

export default ItinetaryAccordion;
