import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EU6Image } from "../image";

const heroTextData = [
  {
    title: "European Delight",
    duration: "12 Nights / 13 Days",
    typoOfTrip: "Road Trip",
    startCity: "Paris",
    endCity: "Rome",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Paris",
    details:
      "On arrival in Paris, 'The City of Light', transfer on your own to your hotel. Rest of the day is free at leisure to explore the city on your own. Overnight at your hotel in Paris. Paris, has a timeless familiarity for first-time and frequent visitors, with instantly recognisable architectural icons, along with exquisite cuisine, chic boutiques and priceless artistic treasures. Paris is confident that it’s the world’s most beautiful, fashionable and artistic capital. Visit just once and it’s hard to disagree. Paris can be seen as the most interesting city of Europe and probably even as one of the most amazing city’s worldwide. People from all over the world travel to Paris to discover and experience this fairy-like city. Paris is the city of love, inspiration, art and fashion. The night scene, the Eiffel tower and the warm atmosphere will make you feel directly at home. Paris has a lot of interesting architecture and museums to offer, and is also a Walhalla for shopaholics. A city as Paris is one that everyone should visit and experience.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Paris – City Tour + River Seine Cruise + Eiffel / Montparnasse",
    details:
      "After breakfast at the hotel, depart for a Half day city tour of Paris. This excursion will give you the opportunity to discover Paris, its history and great buildings with a tour of the city followed by a one hour cruise along the Seine river. Enjoy a nice tour of Paris to see the top monuments of Paris: Champs Elysées, Arc de Triumph, Notre Dame de Paris Cathedral, Louvre Museum, Saint Germain des Prés, Invalides... Later enjoy the view from the glass fitted terrace and gangways all round the boat which is perfectly designed to discover and admire the rich architecture of the city. Later you will visit either Eiffel Tower or Montparnasse Tower (Any one); to admire the superb panoramic views over Paris. Overnight at your hotel in Paris.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Paris – Nice",
    details:
      "Have breakfast at the hotel. Check out of your hotel and transfer to the station to board your super fast TGV to Nice. Arrive Nice, a wonderful French Riviera city, with rich blue-green sea, and lovely art-deco façades. In the evening, is at leisure or we recommend you to opt for one of our exciting optional tours. Overnight at your hotel in Nice. Nice - Nice’s appeal is universal. Everyone from backpackers to romance-seeking couples and families will love sitting at a cafe on cours Saleya in Vieux Nice or a bench on the legendary Promenade des Anglais for an epic sunset. Eating options are some of the best you’ll find in France, the nightlife is buzzing and the art scene thriving. You could happily spend a week here and still be hungry for more.",
  },
  {
    id: "Day 4",
    header: "Nice – Le Grand Tour 1 Day Pass",
    details:
      "Buffet breakfast at the hotel. Proceed to Discover the charming city of Nice and enjoy its fantastic and unique views from our open top deck buses. Your pass is valid for 1 day and allows you to hop on and off as often as you please, at any of our 12 stops. Listen to the original commentary with personal earphones, in a choice of languages. Get free coupons in exchange of your voucher offering discounts for museums, shops and restaurants in Nice and the Cote d'Azur. Overnight at your hotel in Nice.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Nice – Milan",
    details:
      "Buffet breakfast at the hotel. Check – out and proceed to the station on your own to board your train to Milan via Ventimiglia. Arrive Milan and transfer to the hotel on your own. Rest of the day is free at leisure. Overnight at your hotel in Milan. Milan is a seething metropolis. At times it can seem brash and soulless but beneath the veneer is a serious sense of history and place. The grand Gothic cathedral, the Duomo, lies at the geographical heart of this one-time Imperial Roman capital, and expresses the love of beauty and power that still drives the city today. Art collections old and new, unparalleled shopping, one of Europe's biggest trade-fair complexes, sparkling nightlife, the prestige of opera at La Scala, the mark of Leonardo da Vinci's genius, a religious addiction to calcio (football), and endless opportunities to eat the best of Lombard and Italian food make Milan much more than the puritanically work-obsessed city it is often portrayed as",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Milan – Morning City tour with Last Supper",
    details:
      "Buffet breakfast at the hotel. Depart for a guided coach and walking tour of the historical city centre of Milan, passing many of Milan's most important monuments. After beginning the tour with a visit to the Scala museum and theatre we proceed with a stroll through the sumptuous Galleria Vittorio Emanuele (the world's oldest arcade) reaching Piazza Fontana. We continue the tour on foot to view the Palazzo Reale and to visit the breathtaking Duomo. The tour proceeds with a panoramic drive past many of Milan's most important sights including the Arco della Pace, the churches of Santa Maria delle Grazie (home to Leonardo da Vinci's Last Supper) and San Lorenzo, Corso Venezia and Piazza San Babila and the courtyard of Milan's magnificent Sforzesco Castle. Our final stop is to see Leonardo da Vinci's great masterpiece - The Last Supper. Overnight at your hotel in Milan.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Milan – Venice",
    details:
      "Buffet breakfast at the hotel. Check out and proceed on your own to the station to board your train to Venice. Welcome to Venice, the city of canals, the city of romance, architecture and culture situated in a Lagoon and accessible only by boat. The evening is at leisure to stroll around the wonderful canals or you have the choice to join a flotilla of gondolas gathered around a barge containing the musicians and singers to float along the fascinating Grand Canal. Overnight at your hotel in Venice. Venice is a beautiful city located in northeast Italy that is famous for its intricate transport system of interconnected canals. A perfect setting for a magical Valentines break. It has been described as the most beautiful city built by man and one of Europe’s most romantic cities. Venice is also known for its art, architecture and culture. So you’ll never be short of something to do in the “City of Bridges”.",
  },
  {
    id: "Day 8",
    header: "Venice - Murano, Burano and Torcello Tour",
    details:
      "Buffet breakfast at the hotel. Proceed for the half day tour to Murano, Burano and Torcello. After sailing past the island of San Giorgio Maggiore, the Public Gardens, the tip of Sant'Elena and the famous Lido beach resort, the boat arrives in Murano, known throughout the world for its glass manufacturing industry. Here we stop for around 40 minutes to visit to one of the factories. The second stop is at the picturesque island of Burano, famous not only for its lace but also for the fishermen's houses painted in bright colours. The stop will last about 30 minutes. Then after a short cruise, we reach Torcello, the earliest centre of civilization in the estuary. Only the Cathedral with its magnificent mosaics and the church of Santa Fosca remain as testimony of its former glory. The stop will be for about 30 minutes then we return to St. Mark's Square. Overnight at your hotel in Venic",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Venice - Florence",
    details:
      "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station on own to board your train to Florence. Florence, the capital city of the Tuscany region is full of beautiful Renaissance-style buildings, including wonderful churches and enormous palaces. Overnight at your hotel in Florence. Florence (Firenze) is magnetic, romantic and busy. Its urban fabric has hardly changed since the Renaissance, its narrow streets evoke a thousand tales, and its food and wine are so wonderful the tag 'Fiorentina' has become an international label of quality assurance. Return time and again and you still won't see it all. Stand on a bridge over the Arno river several times in a day and the light, mood and view changes every time. Surprisingly small as it is, this riverside city is like no other. Cradle of the Renaissance and of tourist masses that flock here to feast on world-class art.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Florence – Excursion to Pisa and visit of Leaning Tower.",
    details:
      "Buffet breakfast at the hotel. Proceed for an excursion to Pisa and visit of Leaning Tower. This enchanting excursion will take you through the unforgettable Tuscan countryside to the marvelous city of Pisa, known all over the world for its impressive monuments and for its prestigious University. A professional guided walking tour will take you to the Piazza dei Miracoli, where you will be able to admire the Leaning Tower (exterior), the Baptistery (exterior) and other important monuments. You will enjoy then an interior guided visit of the Cathedral, real masterpiece of Romanesque art, built between the 11th and 12th centuries. The guided tour will end by the magnificent Leaning Tower: climbing to its top by mounting its 294 steps rising up in a spiral, will be one of the most amazing experiences you can ever try. The tower is 55.86 m tall (183.27 ft) from the ground on the low side and 56.70 m tall (186.02 ft) on the high side, around 3.99 degrees leaning. The weak and unstable subsoil caused this unique phenomenon, contributing to create one of the wonders of the world. Return back and overnight at your hotel in Florence.",
  },
  {
    id: "Day 11",
    header: "Florence – Rome",
    details:
      "Buffet breakfast at the hotel. Check – out and proceed to the train station on own to board your train to Rome, the capital city of Italy. It’s hard to say what you find most breathtaking about the eternal city. Spend the evening at leisure or select one of our exciting optional tours. Overnight at your hotel in Rome. Rome is Italy’s capital and largest city, and while history reverberates all around, modern life is lived to the full. A trip to Rome is as much about lapping up the lifestyle as it is gorging on art and historic sights. Rome is the Eternal City, a treasure inside and out. The Colosseum, the Catacombs and the Pantheon are all among Europe's first tier of ancient sites. But Rome isn't just a gloriously ancient city. It's also a glorious medieval city, a glorious Renaissance city and a glorious modern city.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Rome – Hop on hop off, Open Bus Tour (24 hours)",
    details:
      "Buffet breakfast at the hotel. Proceed to Explore Rome at your own pace on an extensive hop on hop off itinerary through the heart of Rome. The buses are open top which allows you to enjoy full 360 degree panoramic views as you travel along your route. A recorded commentary in 8 languages via headphones will be at your disposal. Overnight at your hotel in Rome.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 13",
    header: "Depart Rome",
    details:
      " Breakfast at the hotel. Check – out and transfer on your own to the airport for your flight to onwards destination or back to India with memories which will last a life time. * The above itinerary is tentative and subjected to change without prior notice",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Paris City Tour and Cruise by Coach",
"Entry Tickets to Eiffel Tower / Montparnasse Tower, without transfers (Any one. Eiffel Tower tickets are subject to availability. Incase of Non – Availability; Montparnasse Tower will be included",
"Le Grand Hop on Hop off Tour with 1 Day Pass",
"Milan: Milan Morning City Tour with Last Supper",
"Half Day Murano, Burano and Torcello Tour",
"Excursion to Pisa and visit of Leaning Tower",
"Hop on hop off Rome Open Tour 24 hours"
];

const exclusionsData = [
  "Airfare, Airport Taxes, Porterage",
  "Visas & Overseas Travel Insurance",
  "Meals other than mentioned in the itinerary",
  "High Season, Tra de Fair & Special Event surcharge",
  "Anything not specifically mentioned in the Price Includes section.",
];

const EU6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EU6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EU6;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
