import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O3Image} from "../image";

const heroTextData = [
  {
    title: "Bhitarkanika Wildlife Tour",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Bhubaneswar ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:  "Bhubaneswar-Bhitarkanika N.P (170 kms/3.5 hrs)",
    details:
      "After early breakfast drive to Bhitarkanika N.P (170 kms/3.5 hrs). On arrival board on boat and cruise in different creeks of Bhitarkanika NP to spot crocodiles, different birds, Mangrove Forest etc. Visit to Bagagahana-bird sanctuary, Sikarakathi-the hunting tower of ancient king, Shiv temple. Later visit crocodile breeding centre& museum at Dangamal. You can see Deer, Water lizard in and around your cottage. O/N-BKNP. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: ": Bhitarkanika NP-Bhubaneswar (260 km/5hrs) ",
    details:
      "Morning nature walk in nature trail. Post breakfast drive back to Bhubaneswar. En-route visit through Lalitgiri-Ratnagiri-Udayagiri Buddhist circuit, popularly known as Diamond triangle of Odisha Explore the Stupas, Chaityas, Viharas etc. Lalitgiri is the oldest Buddhist complex of the state and one of the earliest Buddhist establishments of world. Visit the museum at Ratnagiri & Lalitgiri (close on Friday).O/N-Bhubaneswar. ",
      
  },
  {
    id: "Day 4",
    header: " Departure from Bhubaneswar ",
    details:
      "After breakfast, if time permits visit to Khandagiri & Udayagiri Jain caves, Tribal Museum/ Kala Bhoomi-a craft museum (both close on Monday & govt holidays) OR visit to Nandankanan Zoological Park-popular for white tiger (close on Monday). In-time transfer to airport/railway station for onwards journey. Tour End. Note: 1. Be aware of Monkeys at Khandagiri & Udaigiri. Keep your wallet & bag carefully. Udaigiri remain open from 08.00hrs to 17.00hrs 2. Nandankanan Zoological Park remain open from 08.00hrs to 17.00hrs. Being a sanctuary Zoo it takes minimum 3-4hrs to cover. It remains close on every Monday.",
      
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

