import { React } from "react";
import {
  Box,
  CardMedia,
  Grid,
  Card,
  Container,
  Typography,
  Button,
} from "@mui/material";

const HeroCardimg = ({ image, heroText, alt }) => {
  return (
    <Box sx={{ flexGrow: 1,pb:1 }}>
      <Card
        sx={{ maxWidth: 1920, borderRadius: "8px 8px 8px 8px", height: "600" }}
      >
        <CardMedia
          component="image"
          image={image}
          height="600"
          alt={alt}
          loading="lazy"
          sx={{ objectFit: "cover" }}
        >
          <Container>
            <Box
              sx={{
                height: {
                  xs: "30vh",
                  sm: "30vh",
                  md: "70vh",
                  ls: "60vh",
                  xl: "50vh",
                },
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    sx={{
                      textAlign: "center",
                      color: "text.light",
                      textShadow: "1px 1px 2px black",
                    }}
                  >
                    {heroText}
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={2} sx={{display:{xs:'none',sm:'contents',md:'contents'}}}>
                  <Button variant="contained" href="/contact-us" >
                    Enquire Now
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </CardMedia>
      </Card>
    </Box>
  );
};

export default HeroCardimg;
