import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { DU6Image } from "../image";

const heroTextData = [
  {
    title: "Dubai Special",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Dubai",
    endCity: "Dubai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival and Dhow Cruise",
    details: "On your arrival, you’ll be picked from the airport and dropped at your hotel. Complete check-in formalities and relax. In the evening, set off for a lavish dinner on the traditional Dhow Cruise. Have a mouth-watering buffet dinner on the cruise and admire the beautiful Arabic music. End the first day of your Dubai tour with a sound sleep at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Half day city tour and visit Burj Khalifa",
    details: "After a filling breakfast at the hotel, get ready to explore Dubai. Drive through the skyscrapers with the detailed information provided by our knowledgable tour guides about all the significant places that include extravagant shopping malls, world-class architecture, cultural heritage, and visiting the tallest tower in the world- Burj Khalifa. Your city tour will end at the Dubai Frame Photo Stop & Dubai Mall, where the entrance for Burj Khalifa is located. Overnight stay at the hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Desert Safari and dinner",
    details: "After breakfast, your morning will be at leisure, where you can explore the city on your own. Enjoy the Arabian sands on our desert safari tour that provides you with the ultimate adventurous experience. You will get a chance to indulge in several activities like dune bashing in a 4x4 vehicle, camel riding, belly dancing show, Tanura dance show, a delicious barbeque dinner with veg and non-veg options. Overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Abu Dhabi City Tour and Ferrari World",
    details: "You will also be taken for a full day city tour of the capital emirate, Abu Dhabi after your breakfast. You will be able to witness various attractions like Sheikh Zayed Grand Mosque with Entry, Au Dhabi Palace, corniche, and a lot more of this city filled with rich ancient heritage and modern life. After the city tour, we will be heading to the epic Ferrari World to experience the adrenaline rush of several rides and entertainment at this place. You will be back at the hotel in Dubai for the overnight stay. ",
  },
  {
    id: "Day 5",
    header: "Aquaventure Waterpark and Lost Chambers",
    details: "Today will be filled with a whole lot of adventure and fun. You will be taken to Aquaventure Waterpark Zoo with Ticket in Atlantis the Palm to enjoy many rides and water activities. Later, you will be taken to the Lost Chambers where you can explore several species of the vibrant marine world. Entry and transfers to miracle garden and global village three way transfers. Entry and transfers to aya universe Overnight stay will be at the hotel along with dinner.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure to Dubai Airport",
    details: "Post a delicious breakfast at the hotel, you’ll visit Aquaventure and the Lost Chambers at Atlantis The Palm. Enjoy exciting water rides throughout the day and explore the exotic marine life. You can try a plethora of activities such as sailing, windsurfing, floating climbing walls, and kayaking. Kids can have a great time at the water playground. You will also get to see ruins of Atlantis, more than thousands of years old, deep down the sea. Another highlight of the park are the Aqua Theatre Show and a Touch Tank. Come back to the hotel for a sound sleep.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];
// const inclusionData = [
//   "03 nights’ accommodation at respective hotel in Kathmandu with breakfast only ",
//   "Airport / Hotel / Airport transfers by an a/c vehicle exclusively",
//   "Sightseeing as per the itinerary by an a/c vehicle exclusively (Guide and entrance fee not included payable directly)",
//   "Applicable taxes",
// ];

// const exclusionsData = [
//   "Services other than specified.",
//   "Entrance fee at the monumental sites during the sightseeing",
//   "Guide during the sightseeing",
//   "Anything not mentioned in inclusions",
//   "Any expenses caused by reasons beyond our control such as flight delays, rescheduling or cancellations, any accidents, medical evacuations, riots, strikes, etc. ",
//   "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above. ",
//   "Any other tax or service charge that could be enforced during this period will be extra & need to borne by you.",
// ];

const DU4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={DU6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default DU4;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
