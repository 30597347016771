import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { KA1Image, KA2Image, KA3Image,KA4Image, KA5Image, KA6Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const KAZAKHSTAN_TOUR = "/assets/img/worldTour/wt16.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Almaty 3N/4D",
    image: KA1Image,
    description: "",
    path: "/ka1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Almaty 4N/5D",
    image: KA2Image,
    description: "",
    path: "/ka2",
  },
  {
    id: 3,
    fromToDays: "04 Nights / 05 Days",
    title: "Almaty Mountains",
    image: KA3Image,
    description: "",
    path: "/ka3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Golf Tour to Almaty",
    image: KA4Image,
    description: "",
    path: "/ka4",
  },
  {
    id: 5,
    fromToDays: "05 Nights / 06 Days",
    title: "Big Almaty Lake",
    image: KA5Image,
    description: "",
    path: "/ka5",
  },
  {
    id: 6,
    fromToDays: "05 Nights / 06 Days",
    title: "Charyn Canyon",
    image: KA6Image,
    description: "",
    path: "/ka6",
  },
];

// const rulesData = [
//   ""
// ];

const Kazakhstan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Kazakhstan"}
            subTitle={"Amazing Holidays to Kazakhstan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={KAZAKHSTAN_TOUR}
            title={"Popular Kazakhstan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Kazakhstan;
