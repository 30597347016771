import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const DESERT_DUSK_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Desert_Dusk.png";


const heroTextData = [
  {
    title: "Desert Dusk",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jodhpur",
    endCity: "Jodhpur ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Jodhpur Arrival - Local",
    details:
      "Welcome and Arrive Jodhpur Airport / Railway Station and meet our local representative who will transfer to the hotel, Free for Leisure or can visit Toorji Ka Jhalra, Kaylan Lake. Overnight Stay at Jodhpur",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jodhpur",
    details:
      "After Breakfast, proceed   for local city sightseeing tour. Jodhpur, stronghold of the fierce Rathore clan, was founded in 1459 by Prince Jodha. Visit the Mehrangarh Fort, situated on a low sandstone hill. Visit Mandor Garden. Also visit Jaswant Thada, an imposing marble cenotaph, built in memory of Maharaja Jaswant Singh II around 1899 and Umaid Bhawan Palace. Overnight Stay at Hotels.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jodhpur-Jaisalmer",
    details:
      "After breakfast check out from Hotel, Proceed to The Golden City - Jaisalmer via visit Jaisalmer war Museum. Check in at hotel. Overnight stay",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Jaisalmer-Sam",
    details:
      "After Breakfast, Check Out Hotel. Proceed to Visit the 12th century magnificent fort at Jaisalmer (This is the highlight of the tour). Also visit the beautifully and magnificently carved Havelis of Salim Singh ki Haveli and Patwon ki Haveli and Gadisar Lake, followed by a visit to the Barabagh Hill and In Late Afternoon around 4:00pm check in at Camps and Enjoy Camel Ride, Folk dance, Evening Tea/Coffee with Snacks, Dinner at Camps, Overnight stay at Camps.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Sam- Jaisalmer/Jodhpur Drop",
    details:
      "After Breakfast, check out from Hotel and Proceed to Drop at Jaisalmer/Jodhpur Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
  ];
  
  const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
  ]
  

const DesertDusk = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={DESERT_DUSK_TOUR_RAJASTHAN_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default DesertDusk;
