import React from "react";

import {
  ThemePage as ThemePage,
  Home as HomePage,
  Services as ServicesPage,
  AboutUs as AboutUsPage,
  ContactUs as ContactUsPage,
  PrivacyPolicy as PrivacyPolicyPage,
  CancelationPolicy as CancelationPolicyPage,
  TermsAndCondition as TermsAndConditionPage,
  DomesticPackages as DomesticPackagesPage,
  KashmirPackages as KashmirPackagesPage,
  ValleyOfKashmir as VelleyOfKashmirPage,
  MajesticKashmir as MajesticKashmirPage,
  SplendidKashmir as SplendidKashmirPage,
  AmazingKashmir as AmazingKashmirPage,
  ExoticKashmir as ExoticKashmirPage,
  ColorfulKashmir as ColorfulKashmirPage,
  RajasthanPackages as RajasthanPackagesPage,
  HeritagePinkCity as HeritagePinkCityPage,
  HeritageLakeCity as HeritageLakeCityPage,
  HeritageReligionCity as HeritageReligionCityPage,
  TajwithPinkCity as TajwithPinkCityPage,
  TigerRoarwithHeritage as TigerRoarwithHeritagePage,
  LakendMarwar as LakendMarwarPage,
  RajasthanHeaven as RajasthanHeavenPage,
  DesertDusk as DesertDuskPage,
  RajasthanWildlife as RajasthanWildlifePage,
  GoldenTriangle as GoldenTrianglePage,
  MarwarJourney as MarwarJourneyPage,
  GloryRajasthan as GloryRajasthanPage,
  BestofGoldenTriangle as BestofGoldenTrianglePage,
  RoyalMarwar as RoyalMarwarPage,
  SpiritualRajasthan as SpiritualRajasthanPage,
  PearlofRajasthan as PearlofRajasthanPage,
  RangeeloRajasthan as RangeeloRajasthanPage,
  ColourfulRajasthan as ColourfulRajasthanPage,
  ATasteofRoyalty as ATasteofRoyaltyPage,
  RoyalJourney as RoyalJourneyPage,
  RoyalKingdom as RoyalKingdomPage,
  HimachalPackages as HimachalPackagesPage,
  HillsOfQueen as HillsOfQueenPage,
  WeekendgetawaytoManali as WeekendgetawaytoManaliPage,
  FeelHappyatHimachal as FeelHappyatHimachalPage,
  HappyplaceHimachal as HappyplaceHimachalPage,
  HeavenlyHimachal as HeavenlyHimachalPage,
  SplendidHimachal as SplendidHimachalPage,
  KeralaPackages as KeralaPackagesPage,
  CochinAlleppeyAndKovalamTourPackages as CochinAlleppeyAndKovalamTourPackagesPage,
  CochinMunnarAndAlleppeyTourPackages as CochinMunnarAndAlleppeyTourPackagesPage,
  CochinMunnarThekkadyandAlleppeyTourPackages as CochinMunnarThekkadyandAlleppeyTourPackagesPage,
  MunnarThekkadyKumarakomandAlleppeyTourPackages as MunnarThekkadyKumarakomandAlleppeyTourPackagesPage,
  KovalamKanyakumariRameshwaramandMadurai as KovalamKanyakumariRameshwaramandMaduraiPage,
  CochinMunnarThekkadyKovalamandKanyakumariTourPackages as CochinMunnarThekkadyKovalamandKanyakumariTourPackagesPage,
  CochinMunnarThekkadyKumarakomKovalamandTrivandrumTourPackages as CochinMunnarThekkadyKumarakomKovalamandTrivandrumTourPackagesPage,
  MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackages as MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackagesPage,
  KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackages as KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackagesPage,
  CalicutWayanadCoorgHassanandBangaloreTourPackages as CalicutWayanadCoorgHassanandBangaloreTourPackagesPage,
  CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackages as CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackagesPage,
  BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackages as BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackagesPage,
  TrivandrumAlleppeyThekkadyKodaikanalOotyMysoreBangalore as TrivandrumAlleppeyThekkadyKodaikanalOotyMysoreBangalorePackagesPage,
  AndamanPackages as AndamanPackagesPage,
  A1 as AndamanA1Page,
  A2 as AndamanA2Page,
  A3 as AndamanA3Page,
  A4 as AndamanA4Page,
  A5 as AndamanA5Page,
  A6 as AndamanA6Page,
  LehLadakhPackages as LehLadakhPackagesPage,
  L1 as LehLadakhL1Page,
  L2 as LehLadakhL2Page,
  L3 as LehLadakhL3Page,
  L4 as LehLadakhL4Page,
  L5 as LehLadakhL5Page,
  L6 as LehLadakhL6Page,
  L7 as LehLadakhL7Page,
  L8 as LehLadakhL8Page,
  L9 as LehLadakhL9Page,
  WestBengalSikkimPackages as WestBengalSikkimPackagesPage,
  W1 as WestBengalSikkimW1Page,
  W2 as WestBengalSikkimW2Page,
  W3 as WestBengalSikkimW3Page,
  W4 as WestBengalSikkimW4Page,
  W5 as WestBengalSikkimW5Page,
  W6 as WestBengalSikkimW6Page,
  W7 as WestBengalSikkimW7Page,
  W9 as WestBengalSikkimW8Page,
  W9 as WestBengalSikkimW9Page,
  W10 as WestBengalSikkimW10Page,
  W11 as WestBengalSikkimW11Page,
  W12 as WestBengalSikkimW12Page,
  W13 as WestBengalSikkimW13Page,
  W14 as WestBengalSikkimW14Page,
  W15 as WestBengalSikkimW15Page,
  W16 as WestBengalSikkimW16Page,
  W17 as WestBengalSikkimW17Page,
  W18 as WestBengalSikkimW18Page,
  W19 as WestBengalSikkimW19Page,
  W20 as WestBengalSikkimW20Page,
  W21 as WestBengalSikkimW21Page,
  W22 as WestBengalSikkimW22Page,
  W23 as WestBengalSikkimW23Page,
  W24 as WestBengalSikkimW24Page,
  W25 as WestBengalSikkimW25Page,
  W26 as WestBengalSikkimW26Page,
  W27 as WestBengalSikkimW27Page,
  W28 as WestBengalSikkimW28Page,
  W29 as WestBengalSikkimW29Page,
  W30 as WestBengalSikkimW30Page,
  W31 as WestBengalSikkimW31Page,
  OdishaPackages as OdishaPackagesPage,
  O1 as OdishaO1Page,
  O2 as OdishaO2Page,
  O3 as OdishaO3Page,
  O4 as OdishaO4Page,
  O5 as OdishaO5Page,
  O6 as OdishaO6Page,
  O7 as OdishaO7Page,
  O8 as OdishaO8Page,
  O9 as OdishaO9Page,
  O10 as OdishaO10Page,
  GujaratPackages as GujaratPackagesPage,
  G1 as GujaratG1Page,
  G2 as GujaratG2Page,
  G3 as GujaratG3Page,
  G4 as GujaratG4Page,
  G5 as GujaratG5Page,
  G6 as GujaratG6Page,
  G7 as GujaratG7Page,
  G8 as GujaratG8Page,
  G9 as GujaratG9Page,
  G10 as GujaratG10Page,
  AssamPackages as AssamPackagesPage,
  AS1 as AssamAS1Page,
  AS2 as AssamAS2Page,
  AS3 as AssamAS3Page,
  AS4 as AssamAS4Page,
  UttarPradeshPackages as UttarPradeshPackagesPage,
  UP1 as UttarPradeshUP1Page,
  UP2 as UttarPradeshUP2Page,
  UP3 as UttarPradeshUP3Page,
  UP4 as UttarPradeshUP4Page,
  KarnatakaPackages as KarnatakaPackagesPage,
  KT1 as KarnatakaKT1Page,
  KT2 as KarnatakaKT2Page,
  KT3 as KarnatakaKT3Page,
  KT4 as KarnatakaKT4Page,
  KT5 as KarnatakaKT5Page,
  KT6 as KarnatakaKT6Page,
  KT7 as KarnatakaKT7Page,
  KT8 as KarnatakaKT8Page,
  KT9 as KarnatakaKT9Page,
  KT10 as KarnatakaKT10Page,
  KT11 as KarnatakaKT11Page,
  KT12 as KarnatakaKT12Page,
  KT13 as KarnatakaKT13Page,
  KT14 as KarnatakaKT14Page,
  KT15 as KarnatakaKT15Page,
  MadhyaPradeshPackages as MadhyaPradeshPackagesPage,
  MP1 as MadhyaPradeshMP1Page,
  MP2 as MadhyaPradeshMP2Page,
  MP3 as MadhyaPradeshMP3Page,
  MP4 as MadhyaPradeshMP4Page,
  MP5 as MadhyaPradeshMP5Page,
  MP6 as MadhyaPradeshMP6Page,
  MP7 as MadhyaPradeshMP7Page,
  MP8 as MadhyaPradeshMP8Page,
  TamilnaduPackages as TamilnaduPackagesPage,
  TN1 as TamilnaduTN1Page,
  TN2 as TamilnaduTN2Page,
  TN3 as TamilnaduTN3Page,
  WorldPackages as WorldPackagesPage,
  UsaPackages as UsaPackagesPage,
  U1 as UsaU1Page,
  U2 as UsaU2Page,
  U3 as UsaU3Page,
  U4 as UsaU4Page,
  U5 as UsaU5Page,
  U6 as UsaU6Page,
  U7 as UsaU7Page,
  U8 as UsaU8Page,
  U9 as UsaU9Page,
  U10 as UsaU10Page,
  U11 as UsaU11Page,
  U12 as UsaU12Page,
  U13 as UsaU13Page,
  U14 as UsaU14Page,
  U15 as UsaU15Page,
  BhutanPackages as BhutanPackagesPage,
  B1 as BhutanB1Page,
  B2 as BhutanB2Page,
  B3 as BhutanB3Page,
  B4 as BhutanB4Page,
  B5 as BhutanB5Page,
  EgyptPackages as EgyptPackagesPage,
  E1 as EgyptE1Page,
  E2 as EgyptE2Page,
  E3 as EgyptE3Page,
  E4 as EgyptE4Page,
  E5 as EgyptE5Page,
  AzerbaijanAndGeorgiaPackages as AzerbaijanAndGeorgiaPackagesPage,
  AG1 as AzerbaijanAndGeorgiaAG1Page,
  AG2 as AzerbaijanAndGeorgiaAG2Page,
  AG3 as AzerbaijanAndGeorgiaAG3Page,
  AG4 as AzerbaijanAndGeorgiaAG4Page,
  AG5 as AzerbaijanAndGeorgiaAG5Page,
  AG6 as AzerbaijanAndGeorgiaAG6Page,
  AG7 as AzerbaijanAndGeorgiaAG7Page,
  AG8 as AzerbaijanAndGeorgiaAG8Page,
  Japan as JapanPackagesPage,
  J1 as JapanJ1Page,
  J2 as JapanJ2Page,
  J3 as JapanJ3Page,
  J4 as JapanJ4Page,
  J5 as JapanJ5Page,
  Uzbekistan as UzbekistanPackagesPage,
  UB1 as UzbekistanUB1Page,
  UB2 as UzbekistanUB2Page,
  UB3 as UzbekistanUB3Page,
  UB4 as UzbekistanUB4Page,
  Canada as CanadaPackagesPage,
  C1 as CanadaC1Page,
  C2 as CanadaC2Page,
  C3 as CanadaC3Page,
  C4 as CanadaC4Page,
  C5 as CanadaC5Page,
  C6 as CanadaC6Page,
  C7 as CanadaC7Page,
  VietnamCambodia as VietnamCambodiaPackagesPage,
  VC1 as VietnamCambodiaVC1Page,
  VC2 as VietnamCambodiaVC2Page,
  VC3 as VietnamCambodiaVC3Page,
  VC4 as VietnamCambodiaVC4Page,
  VC5 as VietnamCambodiaVC5Page,
  VC6 as VietnamCambodiaVC6Page,
  VC7 as VietnamCambodiaVC7Page,
  VC8 as VietnamCambodiaVC8Page,
  VC9 as VietnamCambodiaVC9Page,
  VC10 as VietnamCambodiaVC10Page,
  VC11 as VietnamCambodiaVC11Page,
  VC12 as VietnamCambodiaVC12Page,
  VC13 as VietnamCambodiaVC13Page,
  VC14 as VietnamCambodiaVC14Page,
  Europe as EuropePacakgesPage,
  EU1 as EuropeEU1Page,
  EU2 as EuropeEU2Page,
  EU3 as EuropeEU3Page,
  EU4 as EuropeEU4Page,
  EU5 as EuropeEU5Page,
  EU6 as EuropeEU6Page,
  EU7 as EuropeEU7Page,
  Armenia as ArmeniaPacakgesPages,
  AR1 as ArmeniaAR1Page,
  AR2 as ArmeniaAR2Page,
  AR3 as ArmeniaAR3Page,
  ScandiReady as ScandiReadyPacakgesPages,
  SR1 as ScandiReadySR1Pages,
  SR2 as ScandiReadySR2Pages,
  SR3 as ScandiReadySR3Pages,
  SR4 as ScandiReadySR4Pages,
  SR5 as ScandiReadySR5Pages,
  SR6 as ScandiReadySR6Pages,
  SR7 as ScandiReadySR7Pages,
  SR8 as ScandiReadySR8Pages,
  Turkey as TurkeyPackagesPage,
  T1 as TurkeyT1Pages,
  T2 as TurkeyT2Pages,
  T3 as TurkeyT3Pages,
  T4 as TurkeyT4Pages,
  EastAfirca as EastAfircaPackagesPages,
  EA1 as EastAfircaEA1Page,
  EA2 as EastAfircaEA2Page,
  EA3 as EastAfircaEA3Page,
  EA4 as EastAfircaEA4Page,
  EA5 as EastAfircaEA5Page,
  EA6 as EastAfircaEA6Page,
  EA7 as EastAfircaEA7Page,
  EA8 as EastAfircaEA8Page,
  Croatia as CroatiaPackgesPage,
  CR1 as CroatiaCR1Page,
  CR2 as CroatiaCR2Page,
  Russia as RussianPackagesPage,
  RU1 as RussianRU1Page,
  RU2 as RussianRU2Page,
  RU3 as RussianRU3Page,
  RU4 as RussianRU4Page,
  Kazakhstan as KazakhstanPackagesPage,
  KA1 as KazakhstanKA1Page,
  KA2 as KazakhstanKA2Page,
  KA3 as KazakhstanKA3Page,
  KA4 as KazakhstanKA4Page,
  KA5 as KazakhstanKA5Page,
  KA6 as KazakhstanKA6Page,
  Jordan as JordanPackagesPage,
  JO1 as JordanJO1Page,
  JO2 as JordanJO2Page,
  JO3 as JordanJO3Page,
  JO4 as JordanJO4Page,
  JO5 as JordanJO5Page,
  JO6 as JordanJO6Page,
  JO7 as JordanJO7Page,
  JO8 as JordanJO8Page,
  Israel as IsraelPackagesPage,
  IS1 as IsraelIS1Page,
  IS2 as IsraelIS2Page,
  Maldives as MaldivesPackagesPage,
  MA1 as MaldivesMA1Page,
  MA2 as MaldivesMA2Page,
  MA3 as MaldivesMA3Page,
  MA4 as MaldivesMA4Page,
  MA5 as MaldivesMA5Page,
  MA6 as MaldivesMA6Page,
  MA7 as MaldivesMA7Page,
  MA8 as MaldivesMA8Page,
  MA9 as MaldivesMA9Page,
  MA10 as MaldivesMA10Page,
  MA11 as MaldivesMA11Page,
  Bali as BaliPackagesPage,
  BA1 as BaliBA1Page,
  BA2 as BaliBA2Page,
  BA3 as BaliBA3Page,
  BA4 as BaliBA4Page,
  BA5 as BaliBA5Page,
  BA6 as BaliBA6Page,
  Thailand as ThailandPackagesPage,
  TH1 as ThailandTH1Page,
  TH2 as ThailandTH2Page,
  TH3 as ThailandTH3Page,
  Nepal as NepalPackagesPage,
  NE1 as NepalNE1Page,
  NE2 as NepalNE2Page,
  NE3 as NepalNE3Page,
  NE4 as NepalNE4Page,
  NE5 as NepalNE5Page,
  NE6 as NepalNE6Page,
  NE7 as NepalNE7Page,
  NE8 as NepalNE8Page,
  NE9 as NepalNE9Page,
  NE10 as NepalNE10Page,
  NE11 as NepalNE11Page,
  Dubai as DubaiPackagesPage,
  DU1 as DubaiDU1Page,
  DU2 as DubaiDU2Page,
  DU3 as DubaiDU3Page,
  DU4 as DubaiDU4Page,
  DU5 as DubaiDU5Page,
  YatraPackages as YatraPackagesPage,
  Chardham as ChardhamPage,
  CH1 as ChardhamCH1Page,
  Cambodia as CambodiaPackagesPage,
  CA1 as CambodiaCA1Page,
  NepalYatra as NepalYatraPage,
  NEY1 as NepalNEY1Page,
  NEY2 as NepalNEY2Page,
  NEY3 as NepalNEY3Page,
  MadhyaPradeshYatra as MadhyaPradeshYatraPage,
  MPY1 as MPY1Page,
  MPY2 as MPY2Page,
  MPY3 as MPY3Page,
  KarnatakaYatra as KarnatakaYatraPage,
  KTY1 as KTY1Page,
  KTY2 as KTY2Page,
  KTY3 as KTY3Page,
  KTY4 as KTY4Page,
  GujaratYatra as GujaratYatraPage,
  OdishaYatra as OdishaYatraPage,
  HoneymoonPackages as HoneymoonPackagesPage,
  RajasthanHoneymoon as RajasthanHoneymoonPackagePage,
  RH1 as RajasthanHoneymoonRH1Page,
  RH2 as RajasthanHoneymoonRH2Page,
  RH3 as RajasthanHoneymoonRH3Page,
  RH4 as RajasthanHoneymoonRH4Page,
  AndamanHoneymoon as AndamanHoneymoonPackagePage,
  AH1 as AndamanHoneymoonAH1Page,
  AH2 as AndamanHoneymoonAH2Page,
  AH3 as AndamanHoneymoonAH3Page,
  AH4 as AndamanHoneymoonAH4Page,
  AH5 as AndamanHoneymoonAH5Page,
  StudentPackages as StudentPackagesPage,
  RajasthanStudent as RajasthanStudentPage,
  RST1 as RajasthanStudentRST1Page,
  RST2 as RajasthanStudentRST2Page,
  PopularPackages as PopularPackagesPage,
} from "../views";

const routes = [
  {
    path: "/themePage",
    element: <ThemePage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/cancelation-policy",
    element: <CancelationPolicyPage />,
  },
  {
    path: "/terms-condition",
    element: <TermsAndConditionPage />,
  },
  {
    path: "/domestic-tour",
    element: <DomesticPackagesPage />,
  },
  {
    path: "/kashmir-tour",
    element: <KashmirPackagesPage />,
  },
  {
    path: "/VelleyOfKashmir",
    element: <VelleyOfKashmirPage />,
  },
  {
    path: "/MajesticKashmir",
    element: <MajesticKashmirPage />,
  },
  {
    path: "/SplendidKashmir",
    element: <SplendidKashmirPage />,
  },
  {
    path: "/AmazingKashmir",
    element: <AmazingKashmirPage />,
  },
  {
    path: "/ExoticKashmir",
    element: <ExoticKashmirPage />,
  },
  {
    path: "/ColorfulKashmir",
    element: <ColorfulKashmirPage />,
  },
  {
    path: "/rajasthan-tour",
    element: <RajasthanPackagesPage />,
  },
  {
    path: "/HeritagePinkCity",
    element: <HeritagePinkCityPage />,
  },
  {
    path: "/HeritageLakeCity",
    element: <HeritageLakeCityPage />,
  },
  {
    path: "/HeritageReligionCity",
    element: <HeritageReligionCityPage />,
  },
  {
    path: "/TajwithPinkCity",
    element: <TajwithPinkCityPage />,
  },
  {
    path: "/TigerRoarwithHeritage",
    element: <TigerRoarwithHeritagePage />,
  },
  {
    path: "/LakendMarwar",
    element: <LakendMarwarPage />,
  },
  {
    path: "/RajasthanHeaven",
    element: <RajasthanHeavenPage />,
  },
  {
    path: "/DesertDusk",
    element: <DesertDuskPage />,
  },
  {
    path: "/RajasthanWildlife",
    element: <RajasthanWildlifePage />,
  },
  {
    path: "/GoldenTriangle",
    element: <GoldenTrianglePage />,
  },
  {
    path: "/MarwarJourney",
    element: <MarwarJourneyPage />,
  },
  {
    path: "/GloryRajasthan",
    element: <GloryRajasthanPage />,
  },
  {
    path: "/BestofGoldenTriangle",
    element: <BestofGoldenTrianglePage />,
  },
  {
    path: "/RoyalMarwar",
    element: <RoyalMarwarPage />,
  },
  {
    path: "/SpiritualRajasthan",
    element: <SpiritualRajasthanPage />,
  },
  {
    path: "/PearlofRajasthan",
    element: <PearlofRajasthanPage />,
  },
  {
    path: "/RangeeloRajasthan",
    element: <RangeeloRajasthanPage />,
  },
  {
    path: "/ColourfulRajasthan",
    element: <ColourfulRajasthanPage />,
  },
  {
    path: "/ATasteofRoyalty",
    element: <ATasteofRoyaltyPage />,
  },
  {
    path: "/RoyalJourney",
    element: <RoyalJourneyPage />,
  },
  {
    path: "/RoyalKingdom",
    element: <RoyalKingdomPage />,
  },
  {
    path: "/himachal-tour",
    element: <HimachalPackagesPage />,
  },
  {
    path: "/HillsOfQueen",
    element: <HillsOfQueenPage />,
  },
  {
    path: "/WeekendgetawaytoManali",
    element: <WeekendgetawaytoManaliPage />,
  },
  {
    path: "/FeelHappyatHimachal",
    element: <FeelHappyatHimachalPage />,
  },
  {
    path: "/HappyplaceHimachal",
    element: <HappyplaceHimachalPage />,
  },
  {
    path: "/HeavenlyHimachal",
    element: <HeavenlyHimachalPage />,
  },
  {
    path: "/SplendidHimachal",
    element: <SplendidHimachalPage />,
  },
  {
    path: "/kerala-packages",
    element: <KeralaPackagesPage />,
  },
  {
    path: "/CochinAlleppeyAndKovalamTourPackages",
    element: <CochinAlleppeyAndKovalamTourPackagesPage />,
  },
  {
    path: "/CochinMunnarAndAlleppeyTourPackages",
    element: <CochinMunnarAndAlleppeyTourPackagesPage />,
  },
  {
    path: "/CochinMunnarThekkadyandAlleppeyTourPackages",
    element: <CochinMunnarThekkadyandAlleppeyTourPackagesPage />,
  },
  {
    path: "/MunnarThekkadyKumarakomandAlleppeyTourPackages",
    element: <MunnarThekkadyKumarakomandAlleppeyTourPackagesPage />,
  },
  {
    path: "/KovalamKanyakumariRameshwaramandMadurai",
    element: <KovalamKanyakumariRameshwaramandMaduraiPage />,
  },
  {
    path: "/CochinMunnarThekkadyKovalamandKanyakumariTourPackages",
    element: <CochinMunnarThekkadyKovalamandKanyakumariTourPackagesPage />,
  },
  {
    path: "/CochinMunnarThekkadyKumarakomKovalamandTrivandrumTourPackages",
    element: (
      <CochinMunnarThekkadyKumarakomKovalamandTrivandrumTourPackagesPage />
    ),
  },
  {
    path: "/MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackages",
    element: (
      <MunnarThekkadyKumarakomAlleppeyKovalamandTrivandrumTourPackagesPage />
    ),
  },
  {
    path: "/KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackages",
    element: (
      <KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackagesPage />
    ),
  },
  {
    path: "/CalicutWayanadCoorgHassanandBangaloreTourPackages",
    element: <CalicutWayanadCoorgHassanandBangaloreTourPackagesPage />,
  },
  {
    path: "/CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackages",
    element: (
      <CochinMunnarThekkadyMaduraiRameswaramKanyakumariKovalamTrivandrumTourPackagesPage />
    ),
  },
  {
    path: "/BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackages",
    element: <BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackagesPage />,
  },
  {
    path: "/TrivandrumAlleppeyThekkadyKodaikanalOotyMysoreBangalore",
    element: (
      <TrivandrumAlleppeyThekkadyKodaikanalOotyMysoreBangalorePackagesPage />
    ),
  },
  {
    path: "/andaman-tour",
    element: <AndamanPackagesPage />,
  },
  {
    path: "/a1",
    element: <AndamanA1Page />,
  },
  {
    path: "/a2",
    element: <AndamanA2Page />,
  },
  {
    path: "/a3",
    element: <AndamanA3Page />,
  },
  {
    path: "/a4",
    element: <AndamanA4Page />,
  },
  {
    path: "/a5",
    element: <AndamanA5Page />,
  },
  {
    path: "/a6",
    element: <AndamanA6Page />,
  },
  {
    path: "lehladakh-tour",
    element: <LehLadakhPackagesPage />,
  },
  {
    path: "/l1",
    element: <LehLadakhL1Page />,
  },
  {
    path: "/l2",
    element: <LehLadakhL2Page />,
  },
  {
    path: "/l3",
    element: <LehLadakhL3Page />,
  },
  {
    path: "/l4",
    element: <LehLadakhL4Page />,
  },
  {
    path: "/l5",
    element: <LehLadakhL5Page />,
  },
  {
    path: "/l6",
    element: <LehLadakhL6Page />,
  },
  {
    path: "/l7",
    element: <LehLadakhL7Page />,
  },
  {
    path: "/l8",
    element: <LehLadakhL8Page />,
  },
  {
    path: "/l9",
    element: <LehLadakhL9Page />,
  },
  {
    path: "/westbengalsikkim-tour",
    element: <WestBengalSikkimPackagesPage />,
  },
  {
    path: "/w1",
    element: <WestBengalSikkimW1Page />,
  },
  {
    path: "/w2",
    element: <WestBengalSikkimW2Page />,
  },
  {
    path: "/w3",
    element: <WestBengalSikkimW3Page />,
  },
  {
    path: "/w4",
    element: <WestBengalSikkimW4Page />,
  },
  {
    path: "/w5",
    element: <WestBengalSikkimW5Page />,
  },
  {
    path: "/w6",
    element: <WestBengalSikkimW6Page />,
  },
  {
    path: "/w7",
    element: <WestBengalSikkimW7Page />,
  },
  {
    path: "/w8",
    element: <WestBengalSikkimW8Page />,
  },
  {
    path: "/w9",
    element: <WestBengalSikkimW9Page />,
  },
  {
    path: "/w10",
    element: <WestBengalSikkimW10Page />,
  },
  {
    path: "/w11",
    element: <WestBengalSikkimW11Page />,
  },
  {
    path: "/w12",
    element: <WestBengalSikkimW12Page />,
  },
  {
    path: "/w13",
    element: <WestBengalSikkimW13Page />,
  },
  {
    path: "/w14",
    element: <WestBengalSikkimW14Page />,
  },
  {
    path: "/w15",
    element: <WestBengalSikkimW15Page />,
  },
  {
    path: "/w16",
    element: <WestBengalSikkimW16Page />,
  },
  {
    path: "/w17",
    element: <WestBengalSikkimW17Page />,
  },
  {
    path: "/w18",
    element: <WestBengalSikkimW18Page />,
  },
  {
    path: "/w19",
    element: <WestBengalSikkimW19Page />,
  },
  {
    path: "/w20",
    element: <WestBengalSikkimW20Page />,
  },
  {
    path: "/w21",
    element: <WestBengalSikkimW21Page />,
  },
  {
    path: "/w22",
    element: <WestBengalSikkimW22Page />,
  },
  {
    path: "/w23",
    element: <WestBengalSikkimW23Page />,
  },
  {
    path: "/w24",
    element: <WestBengalSikkimW24Page />,
  },
  {
    path: "/w25",
    element: <WestBengalSikkimW25Page />,
  },
  {
    path: "/w26",
    element: <WestBengalSikkimW26Page />,
  },
  {
    path: "/w27",
    element: <WestBengalSikkimW27Page />,
  },
  {
    path: "/w28",
    element: <WestBengalSikkimW28Page />,
  },
  {
    path: "/w29",
    element: <WestBengalSikkimW29Page />,
  },
  {
    path: "/w30",
    element: <WestBengalSikkimW30Page />,
  },
  {
    path: "/w31",
    element: <WestBengalSikkimW31Page />,
  },
  {
    path: "/odisha-tour",
    element: <OdishaPackagesPage />,
  },
  {
    path: "/o1",
    element: <OdishaO1Page />,
  },
  {
    path: "/o2",
    element: <OdishaO2Page />,
  },
  {
    path: "/o3",
    element: <OdishaO3Page />,
  },
  {
    path: "/o4",
    element: <OdishaO4Page />,
  },
  {
    path: "/o5",
    element: <OdishaO5Page />,
  },
  {
    path: "/o6",
    element: <OdishaO6Page />,
  },
  {
    path: "/o7",
    element: <OdishaO7Page />,
  },
  {
    path: "/o8",
    element: <OdishaO8Page />,
  },
  {
    path: "/o9",
    element: <OdishaO9Page />,
  },
  {
    path: "/o10",
    element: <OdishaO10Page />,
  },
  {
    path: "/gujarat-tour",
    element: <GujaratPackagesPage />,
  },
  {
    path: "/g1",
    element: <GujaratG1Page />,
  },
  {
    path: "/g2",
    element: <GujaratG2Page />,
  },
  {
    path: "/g3",
    element: <GujaratG3Page />,
  },
  {
    path: "/g4",
    element: <GujaratG4Page />,
  },
  {
    path: "/g5",
    element: <GujaratG5Page />,
  },
  {
    path: "/g6",
    element: <GujaratG6Page />,
  },
  {
    path: "/g7",
    element: <GujaratG7Page />,
  },
  {
    path: "/g8",
    element: <GujaratG8Page />,
  },
  {
    path: "/g9",
    element: <GujaratG9Page />,
  },
  {
    path: "/g10",
    element: <GujaratG10Page />,
  },
  {
    path: "/assam-tour",
    element: <AssamPackagesPage />,
  },
  {
    path: "/as1",
    element: <AssamAS1Page />,
  },
  {
    path: "/as2",
    element: <AssamAS2Page />,
  },
  {
    path: "/as3",
    element: <AssamAS3Page />,
  },
  {
    path: "/as4",
    element: <AssamAS4Page />,
  },
  {
    path: "/uttar-pradesh-tour",
    element: <UttarPradeshPackagesPage />,
  },
  {
    path: "/up1",
    element: <UttarPradeshUP1Page />,
  },
  {
    path: "/up2",
    element: <UttarPradeshUP2Page />,
  },
  {
    path: "/up3",
    element: <UttarPradeshUP3Page />,
  },
  {
    path: "/up4",
    element: <UttarPradeshUP4Page />,
  },
  {
    path: "/karnataka-tour",
    element: <KarnatakaPackagesPage />,
  },
  {
    path: "/kt1",
    element: <KarnatakaKT1Page />,
  },
  {
    path: "/kt2",
    element: <KarnatakaKT2Page />,
  },
  {
    path: "/kt3",
    element: <KarnatakaKT3Page />,
  },
  {
    path: "/kt4",
    element: <KarnatakaKT4Page />,
  },
  {
    path: "/kt5",
    element: <KarnatakaKT5Page />,
  },
  {
    path: "/kt6",
    element: <KarnatakaKT6Page />,
  },
  {
    path: "/kt7",
    element: <KarnatakaKT7Page />,
  },
  {
    path: "/kt8",
    element: <KarnatakaKT8Page />,
  },
  {
    path: "/kt9",
    element: <KarnatakaKT9Page />,
  },
  {
    path: "/kt10",
    element: <KarnatakaKT10Page />,
  },
  {
    path: "/kt11",
    element: <KarnatakaKT11Page />,
  },
  {
    path: "/kt12",
    element: <KarnatakaKT12Page />,
  },
  {
    path: "/kt13",
    element: <KarnatakaKT13Page />,
  },
  {
    path: "/kt14",
    element: <KarnatakaKT14Page />,
  },
  {
    path: "/kt15",
    element: <KarnatakaKT15Page />,
  },
  {
    path: "/madhya-pradesh-tour",
    element: <MadhyaPradeshPackagesPage />,
  },
  {
    path: "/mp1",
    element: <MadhyaPradeshMP1Page />,
  },
  {
    path: "/mp2",
    element: <MadhyaPradeshMP2Page />,
  },
  {
    path: "/mp3",
    element: <MadhyaPradeshMP3Page />,
  },
  {
    path: "/mp4",
    element: <MadhyaPradeshMP4Page />,
  },
  {
    path: "/mp5",
    element: <MadhyaPradeshMP5Page />,
  },
  {
    path: "/mp6",
    element: <MadhyaPradeshMP6Page />,
  },
  {
    path: "/mp7",
    element: <MadhyaPradeshMP7Page />,
  },
  {
    path: "/mp8",
    element: <MadhyaPradeshMP8Page />,
  },
  {
    path: "/tamilnadu-tour",
    element: <TamilnaduPackagesPage />,
  },
  {
    path: "/tn1",
    element: <TamilnaduTN1Page />,
  },
  {
    path: "/tn2",
    element: <TamilnaduTN2Page />,
  },
  {
    path: "/tn3",
    element: <TamilnaduTN3Page />,
  },
  {
    path: "/world-tour",
    element: <WorldPackagesPage />,
  },
  {
    path: "/usa-tour",
    element: <UsaPackagesPage />,
  },
  {
    path: "/u1",
    element: <UsaU1Page />,
  },
  {
    path: "/u2",
    element: <UsaU2Page />,
  },
  {
    path: "/u3",
    element: <UsaU3Page />,
  },
  {
    path: "/u4",
    element: <UsaU4Page />,
  },
  {
    path: "/u5",
    element: <UsaU5Page />,
  },
  {
    path: "/u6",
    element: <UsaU6Page />,
  },
  {
    path: "/u7",
    element: <UsaU7Page />,
  },
  {
    path: "/u8",
    element: <UsaU8Page />,
  },
  {
    path: "/u9",
    element: <UsaU9Page />,
  },
  {
    path: "/u10",
    element: <UsaU10Page />,
  },
  {
    path: "/u11",
    element: <UsaU11Page />,
  },
  {
    path: "/u12",
    element: <UsaU12Page />,
  },
  {
    path: "/u13",
    element: <UsaU13Page />,
  },
  {
    path: "/u14",
    element: <UsaU14Page />,
  },
  {
    path: "/u15",
    element: <UsaU15Page />,
  },
  {
    path: "/bhutan-tour",
    element: <BhutanPackagesPage />,
  },
  {
    path: "/b1",
    element: <BhutanB1Page />,
  },
  {
    path: "/b2",
    element: <BhutanB2Page />,
  },
  {
    path: "/b3",
    element: <BhutanB3Page />,
  },
  {
    path: "/b4",
    element: <BhutanB4Page />,
  },
  {
    path: "/b5",
    element: <BhutanB5Page />,
  },
  {
    path: "/egypt-tour",
    element: <EgyptPackagesPage />,
  },
  {
    path: "/e1",
    element: <EgyptE1Page />,
  },
  {
    path: "/e2",
    element: <EgyptE2Page />,
  },
  {
    path: "/e3",
    element: <EgyptE3Page />,
  },
  {
    path: "/e4",
    element: <EgyptE4Page />,
  },
  {
    path: "/e5",
    element: <EgyptE5Page />,
  },
  {
    path: "/azerbaijanandgeorgia-tour",
    element: <AzerbaijanAndGeorgiaPackagesPage />,
  },
  {
    path: "/ag1",
    element: <AzerbaijanAndGeorgiaAG1Page />,
  },
  {
    path: "/ag2",
    element: <AzerbaijanAndGeorgiaAG2Page />,
  },
  {
    path: "/ag3",
    element: <AzerbaijanAndGeorgiaAG3Page />,
  },
  {
    path: "/ag4",
    element: <AzerbaijanAndGeorgiaAG4Page />,
  },
  {
    path: "/ag5",
    element: <AzerbaijanAndGeorgiaAG5Page />,
  },
  {
    path: "/ag6",
    element: <AzerbaijanAndGeorgiaAG6Page />,
  },
  {
    path: "/ag7",
    element: <AzerbaijanAndGeorgiaAG7Page />,
  },
  {
    path: "/ag8",
    element: <AzerbaijanAndGeorgiaAG8Page />,
  },
  {
    path: "/japan-tour",
    element: <JapanPackagesPage />,
  },
  {
    path: "/j1",
    element: <JapanJ1Page />,
  },
  {
    path: "/j2",
    element: <JapanJ2Page />,
  },
  {
    path: "/j3",
    element: <JapanJ3Page />,
  },
  {
    path: "/j4",
    element: <JapanJ4Page />,
  },
  {
    path: "/j5",
    element: <JapanJ5Page />,
  },
  {
    path: "/uzbekistan-tour",
    element: <UzbekistanPackagesPage />,
  },
  {
    path: "/ub1",
    element: <UzbekistanUB1Page />,
  },
  {
    path: "/ub2",
    element: <UzbekistanUB2Page />,
  },
  {
    path: "/ub3",
    element: <UzbekistanUB3Page />,
  },
  {
    path: "/ub4",
    element: <UzbekistanUB4Page />,
  },
  {
    path: "/canada-tour",
    element: <CanadaPackagesPage />,
  },
  {
    path: "/c1",
    element: <CanadaC1Page />,
  },
  {
    path: "/c2",
    element: <CanadaC2Page />,
  },
  {
    path: "/c3",
    element: <CanadaC3Page />,
  },
  {
    path: "/c4",
    element: <CanadaC4Page />,
  },
  {
    path: "/c5",
    element: <CanadaC5Page />,
  },
  {
    path: "/c6",
    element: <CanadaC6Page />,
  },
  {
    path: "/c7",
    element: <CanadaC7Page />,
  },
  {
    path: "/vietnam-cambodia-tour",
    element: <VietnamCambodiaPackagesPage />,
  },
  {
    path: "/vc1",
    element: <VietnamCambodiaVC1Page />,
  },
  {
    path: "/vc2",
    element: <VietnamCambodiaVC2Page />,
  },
  {
    path: "/vc3",
    element: <VietnamCambodiaVC3Page />,
  },
  {
    path: "/vc4",
    element: <VietnamCambodiaVC4Page />,
  },
  {
    path: "/vc5",
    element: <VietnamCambodiaVC5Page />,
  },
  {
    path: "/vc6",
    element: <VietnamCambodiaVC6Page />,
  },
  {
    path: "/vc7",
    element: <VietnamCambodiaVC7Page />,
  },
  {
    path: "/vc8",
    element: <VietnamCambodiaVC8Page />,
  },
  {
    path: "/vc9",
    element: <VietnamCambodiaVC9Page />,
  },
  {
    path: "/vc10",
    element: <VietnamCambodiaVC10Page />,
  },
  {
    path: "/vc11",
    element: <VietnamCambodiaVC11Page />,
  },
  {
    path: "/vc12",
    element: <VietnamCambodiaVC12Page />,
  },
  {
    path: "/vc13",
    element: <VietnamCambodiaVC13Page />,
  },
  {
    path: "/vc14",
    element: <VietnamCambodiaVC14Page />,
  },
  {
    path: "/europe-tour",
    element: <EuropePacakgesPage />,
  },
  {
    path: "/eu1",
    element: <EuropeEU1Page />,
  },
  {
    path: "/eu2",
    element: <EuropeEU2Page />,
  },
  {
    path: "/eu3",
    element: <EuropeEU3Page />,
  },
  {
    path: "/eu4",
    element: <EuropeEU4Page />,
  },
  {
    path: "/eu5",
    element: <EuropeEU5Page />,
  },
  {
    path: "/eu6",
    element: <EuropeEU6Page />,
  },
  {
    path: "/eu7",
    element: <EuropeEU7Page />,
  },
  {
    path: "/armenia-tour",
    element: <ArmeniaPacakgesPages />,
  },
  {
    path: "/ar1",
    element: <ArmeniaAR1Page />,
  },
  {
    path: "/ar2",
    element: <ArmeniaAR2Page />,
  },
  {
    path: "/ar3",
    element: <ArmeniaAR3Page />,
  },
  {
    path: "/scandiready-tour",
    element: <ScandiReadyPacakgesPages />,
  },
  {
    path: "/sr1",
    element: <ScandiReadySR1Pages />,
  },
  {
    path: "/sr2",
    element: <ScandiReadySR2Pages />,
  },
  {
    path: "/sr3",
    element: <ScandiReadySR3Pages />,
  },
  {
    path: "/sr4",
    element: <ScandiReadySR4Pages />,
  },
  {
    path: "/sr5",
    element: <ScandiReadySR5Pages />,
  },
  {
    path: "/sr6",
    element: <ScandiReadySR6Pages />,
  },
  {
    path: "/sr7",
    element: <ScandiReadySR7Pages />,
  },
  {
    path: "/sr8",
    element: <ScandiReadySR8Pages />,
  },
  {
    path: "/turkey-tour",
    element: <TurkeyPackagesPage />,
  },
  {
    path: "/t1",
    element: <TurkeyT1Pages />,
  },
  {
    path: "/t2",
    element: <TurkeyT2Pages />,
  },
  {
    path: "/t3",
    element: <TurkeyT3Pages />,
  },
  {
    path: "/t4",
    element: <TurkeyT4Pages />,
  },
  {
    path: "/eastafirca-tour",
    element: <EastAfircaPackagesPages />,
  },
  {
    path: "/ea1",
    element: <EastAfircaEA1Page />,
  },
  {
    path: "/ea2",
    element: <EastAfircaEA2Page />,
  },
  {
    path: "/ea3",
    element: <EastAfircaEA3Page />,
  },
  {
    path: "/ea4",
    element: <EastAfircaEA4Page />,
  },
  {
    path: "/ea5",
    element: <EastAfircaEA5Page />,
  },
  {
    path: "/ea6",
    element: <EastAfircaEA6Page />,
  },
  {
    path: "/ea7",
    element: <EastAfircaEA7Page />,
  },
  {
    path: "/ea8",
    element: <EastAfircaEA8Page />,
  },
  {
    path: "/croatia-tour",
    element: <CroatiaPackgesPage />,
  },
  {
    path: "/cr1",
    element: <CroatiaCR1Page />,
  },
  {
    path: "/cr2",
    element: <CroatiaCR2Page />,
  },
  {
    path: "/russia-tour",
    element: <RussianPackagesPage />,
  },
  {
    path: "/ru1",
    element: <RussianRU1Page />,
  },
  {
    path: "/ru2",
    element: <RussianRU2Page />,
  },
  {
    path: "/ru3",
    element: <RussianRU3Page />,
  },
  {
    path: "/ru4",
    element: <RussianRU4Page />,
  },
  {
    path: "/Kazakhstan-tour",
    element: <KazakhstanPackagesPage />,
  },
  {
    path: "/ka1",
    element: <KazakhstanKA1Page />,
  },
  {
    path: "/ka2",
    element: <KazakhstanKA2Page />,
  },
  {
    path: "/ka3",
    element: <KazakhstanKA3Page />,
  },
  {
    path: "/ka4",
    element: <KazakhstanKA4Page />,
  },
  {
    path: "/ka5",
    element: <KazakhstanKA5Page />,
  },
  {
    path: "/ka6",
    element: <KazakhstanKA6Page />,
  },
  {
    path: "/jordan-tour",
    element: <JordanPackagesPage />,
  },
  {
    path: "/jo1",
    element: <JordanJO1Page />,
  },
  {
    path: "/jo2",
    element: <JordanJO2Page />,
  },
  {
    path: "/jo3",
    element: <JordanJO3Page />,
  },
  {
    path: "/jo4",
    element: <JordanJO4Page />,
  },
  {
    path: "/jo5",
    element: <JordanJO5Page />,
  },
  {
    path: "/jo6",
    element: <JordanJO6Page />,
  },
  {
    path: "/jo7",
    element: <JordanJO7Page />,
  },
  {
    path: "/jo8",
    element: <JordanJO8Page />,
  },
  {
    path: "israel-tour",
    element: <IsraelPackagesPage />,
  },
  {
    path: "is1",
    element: <IsraelIS1Page />,
  },
  {
    path: "is2",
    element: <IsraelIS2Page />,
  },
  {
    path: "/maldives-tour",
    element: <MaldivesPackagesPage />,
  },
  {
    path: "/ma1",
    element: <MaldivesMA1Page />,
  },
  {
    path: "/ma2",
    element: <MaldivesMA2Page />,
  },
  {
    path: "/ma3",
    element: <MaldivesMA3Page />,
  },
  {
    path: "/ma4",
    element: <MaldivesMA4Page />,
  },
  {
    path: "/ma5",
    element: <MaldivesMA5Page />,
  },
  {
    path: "/ma6",
    element: <MaldivesMA6Page />,
  },
  {
    path: "/ma7",
    element: <MaldivesMA7Page />,
  },
  {
    path: "/ma8",
    element: <MaldivesMA8Page />,
  },
  {
    path: "/ma9",
    element: <MaldivesMA9Page />,
  },
  {
    path: "/ma10",
    element: <MaldivesMA10Page />,
  },
  {
    path: "/ma11",
    element: <MaldivesMA11Page />,
  },
  {
    path: "/thailand-tour",
    element: <ThailandPackagesPage />,
  },
  {
    path: "/th1",
    element: <ThailandTH1Page />,
  },
  {
    path: "/th2",
    element: <ThailandTH2Page />,
  },
  {
    path: "/th3",
    element: <ThailandTH3Page />,
  },
  {
    path: "/yatra-tour",
    element: <YatraPackagesPage />,
  },
  {
    path: "/chardham-tour",
    element: <ChardhamPage />,
  },
  {
    path: "/ch1",
    element: <ChardhamCH1Page />,
  },
  {
    path: "/cambodia-tour",
    element: <CambodiaPackagesPage />,
  },
  {
    path: "/ca1",
    element: <CambodiaCA1Page />,
  },
  {
    path: "/nepal-yatra-tour",
    element: <NepalYatraPage />,
  },
  {
    path: "/nay1",
    element: <NepalNEY1Page />,
  },
  {
    path: "/nay2",
    element: <NepalNEY2Page />,
  },
  {
    path: "/nay3",
    element: <NepalNEY3Page />,
  },
  {
    path: "/madhya-pradesh-yatra-tour",
    element: <MadhyaPradeshYatraPage />,
  },
  {
    path: "/mpy1",
    element: <MPY1Page />,
  },
  {
    path: "/mpy2",
    element: <MPY2Page />,
  },
  {
    path: "/mpy3",
    element: <MPY3Page />,
  },
  {
    path: "/karnataka-yatra-tour",
    element: <KarnatakaYatraPage />,
  },
  {
    path: "/kty1",
    element: <KTY1Page />,
  },
  {
    path: "/kty2",
    element: <KTY2Page />,
  },
  {
    path: "/kty3",
    element: <KTY3Page />,
  },
  {
    path: "/kty4",
    element: <KTY4Page />,
  },
  {
    path: "/gujarat-yatra-tour",
    element: <GujaratYatraPage />,
  },
  {
    path: "/odisha--yatra-tour",
    element: <OdishaYatraPage />,
  },
  {
    path: "/bali-tour",
    element: <BaliPackagesPage />,
  },
  {
    path: "/ba1",
    element: <BaliBA1Page />,
  },
  {
    path: "/ba2",
    element: <BaliBA2Page />,
  },
  {
    path: "/ba3",
    element: <BaliBA3Page />,
  },
  {
    path: "/ba4",
    element: <BaliBA4Page />,
  },
  {
    path: "/ba5",
    element: <BaliBA5Page />,
  },
  {
    path: "/ba6",
    element: <BaliBA6Page />,
  },
  {
    path: "/nepal-tour",
    element: <NepalPackagesPage />,
  },
  {
    path: "/ne1",
    element: <NepalNE1Page />,
  },
  {
    path: "/ne2",
    element: <NepalNE2Page />,
  },
  {
    path: "/ne3",
    element: <NepalNE3Page />,
  },
  {
    path: "/ne4",
    element: <NepalNE4Page />,
  },
  {
    path: "/ne5",
    element: <NepalNE5Page />,
  },
  {
    path: "/ne6",
    element: <NepalNE6Page />,
  },
  {
    path: "/ne7",
    element: <NepalNE7Page />,
  },
  {
    path: "/ne8",
    element: <NepalNE8Page />,
  },
  {
    path: "/ne9",
    element: <NepalNE9Page />,
  },
  {
    path: "/ne10",
    element: <NepalNE10Page />,
  },
  {
    path: "/ne11",
    element: <NepalNE11Page />,
  },
  {
    path: "/dubai-tour",
    element: <DubaiPackagesPage />,
  },
  {
    path: "/du1",
    element: <DubaiDU1Page />,
  },
  {
    path: "/du2",
    element: <DubaiDU2Page />,
  },
  {
    path: "/du3",
    element: <DubaiDU3Page />,
  },
  {
    path: "/du4",
    element: <DubaiDU4Page />,
  },
  {
    path: "/du5",
    element: <DubaiDU5Page />,
  },
  {
    path: "/honeymoon-tour",
    element: <HoneymoonPackagesPage />,
  },
  {
    path: "/rajasthan-honeymoon-tour",
    element: <RajasthanHoneymoonPackagePage />,
  },
  {
    path: "/rh1",
    element: <RajasthanHoneymoonRH1Page />,
  },
  {
    path: "/rh2",
    element: <RajasthanHoneymoonRH2Page />,
  },
  {
    path: "/rh3",
    element: <RajasthanHoneymoonRH3Page />,
  },
  {
    path: "/rh4",
    element: <RajasthanHoneymoonRH4Page />,
  },
  {
    path: "/andaman-honeymoon-tour",
    element: <AndamanHoneymoonPackagePage />,
  },
  {
    path: "/ah1",
    element: <AndamanHoneymoonAH1Page />,
  },
  {
    path: "/ah2",
    element: <AndamanHoneymoonAH2Page />,
  },
  {
    path: "/ah3",
    element: <AndamanHoneymoonAH3Page />,
  },
  {
    path: "/ah4",
    element: <AndamanHoneymoonAH4Page />,
  },
  {
    path: "/ah5",
    element: <AndamanHoneymoonAH5Page />,
  },
  {
    path: "/student-tour",
    element: <StudentPackagesPage />,
  },
  {
    path: "/rajasthan-student-tour",
    element: <RajasthanStudentPage />,
  },
  {
    path: "/rst1",
    element: <RajasthanStudentRST1Page />,
  },
  {
    path: "/rst2",
    element: <RajasthanStudentRST2Page />,
  },
  {
    path: "/popular-tour",
    element: <PopularPackagesPage />,
  },
];

export default routes;
