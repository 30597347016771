import React from "react";

import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import HeroCardimg from "./HeroCardimg";

const HERO1 = "assets/img/banners/hero1.png";
const HERO2 = "assets/img/banners/hero2.png";
const HERO3 = "assets/img/banners/hero3.png";
const HERO4 = "assets/img/banners/hero4.png";
const HERO5 = "assets/img/banners/hero5.png";
const HERO6 = "assets/img/banners/hero6.png";
const HERO7 = "assets/img/banners/hero7.png";
const HERO8 = "assets/img/banners/hero8.png";
const HERO9 = "assets/img/banners/hero9.png";
const HERO10 = "assets/img/banners/hero10.png";
const HERO11 = "assets/img/banners/hero11.png";

const MyCollection = [
  {
    label: "First Picture",
    imgPath: HERO11,
    text:"It's Time to Explore the World",
  },
  {
    label: "First Picture",
    imgPath: HERO1,
    text:"Visit God's Own Country",
  },
  {
    label: "Second Picture",
    imgPath: HERO2,
    text:"Come & Explore Royal Rajasthan with Luxury Hospitality"
  },
  {
    label: "Third Picture",
    imgPath: HERO3,
    text:"Feel the Khushboo Gujarat Ki"
  },
  {
    label: "Fouth Picture",
    imgPath: HERO4,
    text:"Perfect Family Destination"
  },
  {
    label: "Fifth Picture",
    imgPath: HERO5,
    text:"Must visit Places with Family & Friends"
  },
  {
    label: "Sixth Picture",
    imgPath: HERO6,
    text:"Most Popular Destination where you can Enjoy"
  },
  {
    label: "Seventh Picture",
    imgPath: HERO7,
    text:"Extraordinary Travel Experiences"
  },
  {
    label: "Eight Picture",
    imgPath: HERO8,
    text:"Looking for the Perfect Honeymoon Destination"
  },
  {
    label: "Nineth Picture",
    imgPath: HERO9,
    text:"Stay with the most Gorgeous Landscapes"
  },
  {
    label: "Tenth Picture",
    imgPath: HERO10,
    text:"Enjoy Once in a Lifetime Tour"
  },
];

function HeroSlider() {
  const delay = 3000;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setActiveStep((prevIndex) =>
          prevIndex === MyCollection.map((item) => <>{item.label}</>).length - 1
            ? 0
            : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [activeStep]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeroCardimg
          image={MyCollection[activeStep].imgPath}
          alt={MyCollection[activeStep].label}
          heroText={MyCollection[activeStep].text}
        />
        <MobileStepper
          variant="dots"
          steps={11}
          position="static"
          activeStep={activeStep}
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            ".MuiMobileStepper-dot": {
              backgroundColor: "#443A4F25",
              width: "10px",
              height: "10px",
              boxShadow: 4,
            },
            ".MuiMobileStepper-dotActive": { backgroundColor: "primary.main" },
          }}
          // nextButton={
          //   <Button size="small" onClick={handleNext} disabled={activeStep === 4}>
          //     Next
          //     {theme.direction === "rtl" ? (
          //       <KeyboardArrowLeft />
          //     ) : (
          //       <KeyboardArrowRight />
          //     )}
          //   </Button>
          // }
          //   backButton={
          //     <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          //       {theme.direction === "rtl" ? (
          //         <KeyboardArrowRight />
          //       ) : (
          //         <KeyboardArrowLeft />
          //       )}
          //       Back
          //     </Button>
          //   }
        />
      </Grid>
    </Grid>
  );
}

export default HeroSlider;
