import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U7Image } from "../image";

const heroTextData = [
  {
    title: "Best of East Coast",
    duration: "11 Nights / 12 Days",
    typoOfTrip: "Road Trip",
    startCity: "Orlando",
    endCity: "New York",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Orlando",
    details: "Welcome to Orlando. Orlando is the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. The city is said to welcome more than 52 million tourists every year. Transfer to your hotel upon arrival.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Orlando - Universal’s Island of Adventure",
    details: "Today you proceed to have an experience of a lifetime at Universal’s Island of Adventure. At Universal’s Islands of Adventure® the most cutting edge rides, shows, and interactive attractions ever created challenge all your senses. You can step into the magic and excitement of The Wizarding World of Harry Potter™; swing high above the streets in The Amazing Adventures of Spider-Man®, now in high-def 3-D; splash through the comical water rides of Toon Lagoon®; face dinosaurs as you visit earth’s primordial past in Jurassic Park®; encounter epic adventures in The Lost Continent®; and enter the colourful worlds of children’s author Dr. Seuss at Seuss Landing™. Live the adventure℠ of a lifetime at Islands of Adventure®…it’s the stuff legends are made of.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Orlando – Universal Studios Florida",
    details: "Jump into the Action of the Movies. Go beyond the screen, behind the scenes, and jump right into your favourite scenes from your favourite movies and TV shows at Universal Studios Florida®. At this real, working production studio you’ll find an amazing array of rides, shows, movie sets and attractions that make YOU the star. Join the ranks of the minions on the heartwarming and hilarious 3-D ride Despicable Me Minion Mayhem; experience Shrek 4-D; the Hollywood Rip Ride Rockit® multi-sensory coaster; Revenge of the Mummy®; The Simpsons Ride™; MEN IN BLACK™ Alien Attack™; and the ground-breaking ride experience, TRANSFORMERS™: The Ride-3D.",
  },
  {
    id: "Day 4",
    header: "Orlando – Animal Kingdom",
    details: "Today you visit Disney’s Animal Kingdom. Asia, Africa, under the sea, and prehistoric encounters all await the adventurers who travel to this Theme Park. Take on Expedition Everest ™, a high-altitude, high-speed train adventure. Then take your seat for two big-as-Broadway musical stage shows: Finding Nemo — The Musical (inspired by Disney·Pixar’s ‘Finding Nemo’), and Festival of the Lion King. Next, explore over 100 acres of African savannah on Kilimanjaro Safaris. Plus, coming soon in 2016, Rivers of Light - an immersive nighttime celebration of the beauty of nature, with the liver performers, soaring music and innovative special effects.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Orlando - Sea World OR Kennady Space Centre",
    details: "Today you have an option to either visit Sea World or Kennady Space Centre.Sea World Today you can visit the world's largest Marine Park Sea World - Here you shall discover many attractions. We dare you to take a one-of-a kind water coaster thrill ride through the mysterious lost city of Atlantis. Soar through danger on a jet copter ride to the Wild Arctic. At Sea World, touch, feed or get face to face with awesome and amazing wild animals, and get in on all the action that draws you back again and again! ORKennady Space Centre Lunch with an AstronautWelcome to NASA’s John F. Kennedy Center, our nation’s gateway to exploring, discovering and understanding our Universe! An out of this world experience! The Space Center opened in 1962 and was named in honor of President Kennedy after his assassination in 1963. Ever since, it has been a world of fun and excitement for all! The Visitors Complex has everything from real life artifacts to fascinating exhibits to attractions on the history and future of human and robotic spaceflight.This trip consists of a lunch with a real life astronaut! Hear from a veteran NASA astronaut about what it’s really like to launch, live and work in space as you enjoy a delicious buffet lunch. See photos and videos from the astronaut’s missions, ask questions and snap a photo with your new astronaut friend! A wonderful and unique experience, you will leave feeling inspired and amazed!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Orlando – Washington Night Tour",
    details: "Check out from hotel and we transfer you to Orlando airport for flight to Washington DC. On arrival check in to hotel. Evening proceed for Night tour of Washington on Seat in coach basis. When the sun goes down, Washington turns into a different city, and Old Town Trolley Tours knows just the right way to show off the Capitol City at night! See the Capitol Building, the White House, the Jefferson Memorial and the Washington Monument. The trolley will also make stops at the Franklin D. Roosevelt Memorial, the Lincoln Memorial, the Vietnam Veteran's Memorial, the Korean War Veteran's Memorial and the Iwo Jima Memorial. Enjoy of the most emblematic monuments of the city, crossing them in the light of the moon.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Washington - Buffalo (Niagara Falls)",
    details: "Check out from hotel and we transfer you to airport for flight to Buffalo. On arrival we transfer to Niagara the town of the world famous falls, which forms one of the natural wonders of the World. Transfer to Niagara Falls. The evening is free at leisure. Overnight stay in Niagara Falls.",
  },
  {
    id: "Day 8",
    header: "Niagara Falls Discovery Pass",
    details: "What else can be a better way than Niagara Discovery Pass to explore the Niagara Falls. Discovery Pass allows admission to Maid of the Mist, Cave of the Winds, Niagara Adventure Theater, Gorge Discovery Centre & Aquarium of Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Niagara Falls (Buffalo) – New York",
    details: "After breakfast check out from hotel and we transfer you to Buffalo airport for flight to New York. Upon arrival at the airport, you will be transferred to the hotel The day is free at leisure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "New York Hop on Hop Off Tour 48Hrs",
    details: "Create your own sightseeing itinerary with the New York City Hop-On Hop-Off Tour! A hop-on-hop-off bus tour is the ideal way to explore every corner of the Big Apple and, with lively tour guides to keep you entertained and informed, you are sure to learn facts old and new about the city that never sleeps! Proceed for the 48 Hr Hop on and Hop and Hop off Tour. The Classic New York Tour includes: Uptown Loop (2 hours, full loop duration without stops)Stops include Central Park, Fifth Avenue, Lincoln Center, American Museum of Natural History, Cathedral of St. John the Divine, Grant's Tomb, Apollo Theater, Harlem Market, The Museum Mile, Guggenheim and the Metropolitan Museum., Brooklyn Loop (2 hours, full loop duration without stops)See the Botanic Garden, Brooklyn Museum of Art, Antique Furniture District, Fulton Mall, Brooklyn Museum and much more. Choose from 8 stops to hop on and hop off with pickup every 30 minutes. Night Tour (2.5 hours, not hop-on hop-off)View over 20 sites from Central Park, Times Square, Empire State Building to Greenwich Village, SoHo, Chinatown and Little Italy. See the glittering lights of the city that never sleeps!",
  },
  {
    id: "Day 11",
    header: "New York One World Observatory",
    details: "Make your way to One World Observatory. On the 100th floor, take your time exploring expansive, panoramic views in every direction. Discover Manhattan, Brooklyn, New Jersey and the surrounding waters from an entirely new perspective.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 12",
    header: "Departure New York",
    details: "After breakfast, transfer to the New York Airport fly back home or to the next destination. End of tour.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"11 Night’s Accommodation with breakfast as per mentioned or similar",
"2 Days Universal Orlando Base Ticket",
"1 Day Disney Orlando Base Ticket (Choice of any 1 Disney Park : Animal Kingdom, Magic Kingdom, Hollywood Studios or Epcot Centre)",
"1 Day Sea World Orlando Base Ticket OR Kennady Space Centre with Lunch with Astronaut on Seat in Coach basis",
"Night tour of Washington DC on Seat in Coach basis",
"Niagara Discovery Pass",
"Super New York Hop on and Hop off Pass with visit to Empire State Building and Statue of Liberty",
"Orlando Airport / Hotel round trip transfers on Seat in Coach basis",
"Washington Airport / Hotel round trip transfers on Seat in Coach basis",
"Buffalo Airport / Niagara Hotel round trip transfers on Private basis",
"New York Airport / Hotel round trip transfers on Seat in Coach basis",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U7;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
