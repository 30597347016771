import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U5Image } from "../image";

const heroTextData = [
  {
    title: "West Coast USA with Hawaii",
    duration: "10 Nights / 11 Days",
    typoOfTrip: "Road Trip",
    startCity: "Las Vegas",
    endCity: "Honolulu",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Las Vegas",
    details: "Welcome to the fabulous city of Las Vegas. On arrival you will be met by your driver at the Baggage claim area holding a signboard in your name and get transferred to the Hotel. The day is free at leisure. Overnight in Las Vegas.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Las Vegas Open Top Big Bus Las Vegas by Night Tour",
    details: "The day is free at leisure. In the evening enjoy the Las Vegas by night experience, join the Night Tour and enjoy spectacular views of the entire Strip and Down Town in all their neon glory. The Night Tour takes you all the way along the Strip to the famous Las Vegas welcome sign, before heading back to Down Town where you'll have the change to enjoy the Fremont Street Experience .As you travel past Las Vegas' illuminated landmarks, The experienced guides on tour will tell the story of Las Vegas and will highlight the city's most impressive views. You can start the tour at either Circus Circus at 19:00 or at the Excalibur at 19:30. The Night Tour lasts approximately 3 hours including time at Fremont Street. Return back to the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Las Vegas Helicopter City lights Tour",
    details: "This evening enjoy the Helicopter ride over the Vegas strip. Helicopter Flight over the Las Vegas Strip Enjoy the “Entertainment Capital of the World” from the best view possible – the sky. Soar high above the world-famous Las Vegas Strip as you witness iconic landmarks such as the Bellagio, Caesars Palace, Stratosphere Tower, MGM Grand, Eiffel Tower, Luxor, historic downtown and more. This 12-15 minute flight takes you high above both the east and west ends of the Strip as you witness stunning views aboard a “limousine” in the sky, Maverick’s ECO-Star helicopter. Return back to the Hotel. Overnight in Las Vegas.",
  },
  {
    id: "Day 4",
    header: "Las Vegas – Los Angeles",
    details: "Check out from hotel and you will be transferred to Las Vegas coach station for onward journey to Los Angeles. Arrive Los Angeles the city of Angels, you will meet your driver and will be transferred to the Hotel. Rest of the day is free at leisure . Overnight in Los Angeles.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Los Angeles: Universal Studios Tour",
    details: "Breakfast at the Hotel, assemble at the Hotel lobby by 8.15 am where you will be picked up for your Universal Studios full day tour. Universal Studios, the largest Film and Television studio in the world. Where the greatest moments from movies and television come to life, like the reality shattering, all-new Terminator 2; 3D, starring the cast of Terminator 2. Plus have your worst nightmare come true when a man-eating T-Rex attacks them on Jurassic Park...The Ride. You'll speed through time on Back to the Future...The Ride and go Totally Nickelodeon in the place where kids rule. It's all waiting for you at Universal Studios Hollywood. Overnight in Los Angeles.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Los Angeles – Kona",
    details: "Breakfast at the Hotel. You will be transferred to the Airport for your flight to Kona. Arrive Kona, Aloha, welcome to Hawaii, On arrival you will be met by your driver and transferred to the Hotel. The day is free at leisure. Overnight in Kona.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Kona: Grand Circle Island Tour of Hawaii",
    details: "Early Breakfast at the Hotel. Be ready at the Hotel Lobby at 7.10 am where you will be picked up for the Island Tour.This daylong adventure is the perfect showcase for the Big Island’s most unique sights and locations. It’s a great way to explore this fascinating place. There will be a stop at Royal Kona Coffee in the midst of the coffee-growing region. It’s a remarkable place of organic beauty. The lush coffee bean trees seem to spring up among the other island foliage. The area also overlooks Kealakekua Bay, offering spectacular views of its pristine waters. Then visit lava fields where various shades of solidified lava reveal the entire island’s past volcanic activity. You’ll actually be able to see a distinct dividing line where the lava fields end and the lush farmland begins. While you enjoy the changing landscape, you’ll want to prepare yourself for a visit to the nearby is Punaluu Beach, the amazing black sand beach where sea turtles often nest. Next you’ll be on your way to Hawaii Volcanoes National Park. You’ll begin at the Jaggar Museum and Hawaii Volcano Observatory. Be on the lookout for the active, smoldering crater nearby. Active volcanoes are still present and continuing to add to the landscape of dried lava flow. Here is also where you’ll find Mauna Loa, the world’s largest volcano, and Kilauea, the world’s most active volcano. There’s also Thurston Lava Tube, and located in a small crater, the Fern Tree Jungle. If the conditions are safe, you’ll actually circle a crater when you ride along Crater Rim Drive. Overnight in Kona.",
  },
  {
    id: "Day 8",
    header: "Kona – Honolulu",
    details: "Breakfast at the Hotel. You will be transferred to Kona Airport for your flight to Honolulu. Arrive Honolulu one of the most famous Islands of Hawaii, You will be greeted with Hawaiian Fresh Lei Greeting Service at the Honolulu International Airport.  This is one of the most beautiful Hawaiian traditions that give you the Aloha feeling Transfer to the Hotel .The day is free at leisure to explore the world famous Waikiki beach. Overnight in Honolulu.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Honolulu Island Tour with Dole Plantation",
    details: "Early breakfast at Hotel. Take a 120-mile adventure around the island of Oahu with the grand circle island tour, and then enjoy a visit to the historic Dole pineapple plantation. The ancient and colourful land of Hawaii offers plenty of surprises for visitors, and this action-packed tour takes in all the main sites of Oahu island in just a single day. Pass by famous surfing beaches, spot the Amelia Earheart Lookout, established on Diamond Head to commemorate the world's first solo flight from Hawaii to North America, and see the powerful blowhole at Hanauma Bay. Sandy Beach and Makapuu Point are both paradise beaches with incredible white sand and a turquoise ocean so clear it seems to glow. You will also get a chance to snap some pictures at the Nuuanu Pali Lookout, and visit the picturesque Byodo-In Temple located at the base of stunning 2,000-foot lush green cliffs, as well as the distinctly shaped Chinaman's Hat. Finally, enjoy a trip to the Dole Plantation, Hawaii's complete pineapple experience. Here you can take the Pineapple Express Train Tour, explore the lush plantation gardens and learn about the history of pineapple cultivation and agriculture in Hawaii. This colourful tour with a sweet twist is an ideal way to get to know beautiful Oahu.Overnight in Honolulu.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Honolulu Oahu Whale Watching Cruise",
    details: "Breakfast at the Hotel Every December through April, thousands of Humpback whales migrate to the warm waters of Hawaii. Come along and catch these beautiful mammals at their best on this exciting, eco-friendly whale watching excursion. Outdoor viewing decks and large windows in the air-conditioned cabins allow wonderful sighting opportunities and views. Truly a dedicated whale watching experience, an expert naturalist will share insightful information and educational commentary on the humpback whale's behaviour and biology. Overnight in Honolulu. Meeting/pick-up point: Pier 6, near Aloha Tower Marketplace.",
  },
  {
    id: "Day 11",
    header: "Depart Honolulu",
    details: "Breakfast at the Hotel transfer to the Airport for your flight back home",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"10 Night’s Accommodation with breakfast as per mentioned or similar (No Breakfast in Las Vegas)",
"1Day Universal Studios Hollywood base ticket with return transfers on Seat in Coach basis",
"Helicopter Night tour of Vegas with Limousine transfers from Hotel",
"Double Decker Bus tour of Vegas",
"Grand Circle Island Tour of Kona on Seat in Coach basis",
"Oahu Island Tour with Dole Plantation on Seat in Coach basis",
"Whale watching (Seasonal : December to May)",
"Las Vegas Airport / Hotel transfers on Seat in Coach basis",
"Las Vegas Hotel / Coach Station transfers on Private basis",
"One way coach ticket from Las Vegas to Los Angeles",
"Los Angeles Coach station / Hotel transfers on Private basis",
"Los Angeles Hotel / Airport transfers on Seat in Coach basis",
"Kona Airport / Hotel round trip transfers on Seat in Coach basis",
"Honolulu Airport / Hotel round trip transfers on Seat in Coach basis",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U5;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];





