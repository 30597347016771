import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W3Image } from "../image";

const heroTextData = [
  {
    title: "Green Dooars",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: " NJP Rly Station / IXB Airport - Jaldapara (136 Kms. / 4½ Hrs.)",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Darjeeling (6,950 Ft.). On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Darjeeling",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Jaldapara Jungle Safari (Elephant / Jeep) - Lataguri / Chalsa (80 Kms. / 2 Hrs.)",
    details:
      "Early morning at 06:00 hrs, visit Jaldapara Wild Life Sanctuary on the back of Elephant / by Jeep (as per availability and on direct payment basis). Back to hotel for breakfast. After breakfast, transfer to Lataguri / Chalsa. On arrival, check-in to the hotel. Evening free for leisure. Overnight stay at Lataguri / Chalsa.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Gorumara National Park + Local Sightseeing ",
    details:
      "Early morning at 06:00 hrs. visit Gorumara National Park (Watch Tower visit on direct payment basis) by Jeep. Back to hotel. After breakfast, visit Samsing, Suntalekhola and Murti River side. Afternoon, visit another Watch Tower by Jeep. Overnight stay at Lataguri / Chalsa."
  },
  {
    id: "Day 4",
    header: "Lataguri / Chalsa - NJP Rly Station / IXB Airport (100 Kms. / 3 Hrs.)",
    details:
      "After breakfast Checkout from hotel and take, the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on twin Sharing Basis. ",
  "Meal Plan (as per mentioned above).",
  "Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
  "All permit fees & hotel taxes (as per itinerary).",
  "Rates are valid for INDIAN NATIONALS only.",
  
  ];
  
  const exclusionsData = [
  "Air Fare / Train fare. ",
  "Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
  "Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
  "Entrance Fees & Guide charges. ",
  "Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
  "Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
  "Room Heater Charges.",
  "Insurance. ",
  "Anything which is not included in the inclusion",
  ];

const W3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
