import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

import {AH1Image,AH2Image,AH3Image,AH4Image,AH5Image,AH6Image} from "./image";
import { HO2Image } from "../image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";




const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "3 Nights 4 Days Special Honeymoon Holiday Tour Package ",
    image: AH1Image,
    description: "",
    path: "/ah1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "4 Nights 5 Days Special Honeymoon Holiday Tour Package",
    image: AH2Image,
    description: "",
    path: "/ah2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "5 Nights 6 Days Special Honeymoon Holiday Tour Package ",
    image: AH3Image,
    description: "",
    path: "/ah3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "6 Nights 7 Days Special Honeymoon Holiday Tour Package",
    image: AH4Image,
    description: "",
    path: "/ah4",
  },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "7 Nights 8 Days Special Honeymoon Holiday Tour Package",
    image: AH3Image,
    description: "",
    path: "/ah5",
  },
  {
    id: 6,
    fromToDays: "08 Nights / 09 Days",
    title: "8 Nights 9 Days Special Honeymoon Holiday Tour Package ",
    image: AH4Image,
    description: "",
    path: "/ah6",
  },
];

// const rulesData = [
//   ""
// ];

const AndamanHoneymoon = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Andaman & Nicobar Islands Honeymoon Tour"}
            subTitle={"Amazing Holidays to Andaman & Islands Honeymoon Tour"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={HO2Image}
            title={"Popular Andaman & Nicobar Islands Honeymoon Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default AndamanHoneymoon;
