import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  J1Image,
  J2Image,
  J3Image,
  J4Image,
  J5Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const JAPAN_TOUR = "/assets/img/worldTour/wt5.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Japan Golden Route 3N/4D",
    image: J1Image,
    description:"",
    path: "/j1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Japan Golden Route 4N/5D",
    image: J2Image,
    description:"",
    path: "/j2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Japan Golden Route 5N/6D",
    image: J3Image,
    description:"",
    path: "/j3",
  },
  {
    id: 4,
    fromToDays: "09 Nights / 10 Days",
    title: "Japan 9N/10D",
    image: J4Image,
    description:"",
    path: "/j4",
  },
  {
    id: 5,
    fromToDays: "10 Nights / 11 Days",
    title: "Japan 10N/11D",
    image: J5Image,
    description:"",
    path: "/j5",
  },
];

// const rulesData = [
//   ""
// ];

const Japan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Japan"}
            subTitle={"Amazing Holidays to Japan"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={JAPAN_TOUR}
            title={"Popular Japan Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Japan;










