import { React } from "react";
import {
  Grid,
  Box
} from "@mui/material";
import Copyright from "./CopyRights.js";
import Links from "./Links/index.js";

const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12} bgcolor={"#f5f5f5"}>
          <Links />
        </Grid>
        <Grid item xs={12} sx={{pb:{xs:6,sm:0,md:0}}}>
          <Copyright />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
