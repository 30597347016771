import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Button,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
} from "@mui/material";

import PropTypes from "prop-types";

const TourCard = ({ title, tourCardContent }) => {
  return (
    <Box>
      <Typography variant="h2" color={"text.secondary"} textAlign={"center"}>
        {title}
      </Typography>
      <br />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {tourCardContent?.map((item) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                container
              >
                <Grid item xs={12}>
                  <Card sx={{ maxWidth: 400,borderRadius:1 }}>
                    <CardActionArea href={item.path}>
                      <CardMedia
                        height={"150"}
                        component="img"
                        image={item.image}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 120,
                          left: 10,
                        }}
                      >
                        <Typography
                          variant="h3"
                          color={"text.secondary"}
                          sx={{
                            color: "text.light",
                            textShadow: "1px 1px 1px black",
                          }}
                        >
                          {item.fromToDays}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Typography variant="h3" color={"text.secondary"}>
                      {item.title}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

TourCard.propTypes = {
  title: PropTypes.string,
  tourCardContent: PropTypes.func,
};

export default TourCard;
