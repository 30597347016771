import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { L6Image } from "../image";

const heroTextData = [
  {
    title: "AMAZING LADAKH",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Leh",
    endCity: "Leh",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Leh Arrival",
    details:
      "Arrival at Leh and we recommend you complete relax for the rest of the day to enable yourselves to acclimatize, as high-altitude sickness occurs when your body does not adjust due to lack of oxygen at the high altitude. O/n stay at Leh Hotel. Alts (3500Mtrs)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Leh to kagil: Sangam-Pathar Sahib- Lamayuru- Kargil (230km)",
    details: "After B/fast set off for Kargil. En route visit Spituk Monastery, Magnetic Hill, Zanskar & Indus River confluence (Sangam point) Lamayuru Monastery (Moonland Area). Arrive kargil o/n in hotel in the evening visit Kargil market. O/n stay at Kargil Hotel. (2700Mtrs) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header:
      "kagil- Drass to Leh-Alchi-Leh (265 km",
    details:
      " After B/fast set off for Drass. Vijay Smarak (war memorial of Drass) returns back to Leh visit Enroute visit Alchi Monastery, pathar Sahib & visit Hall of Fame (War memorial). O/n stay at Leh Hotel. (3500Mtrs)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Leh to Nubra Valley via Khardong la Pass (126Km)",
    details:
      "After B/fast set off for Nubra valley via Khardong la paas (the world’s highest motorable road in the world) (18,640 feet) and o/n in stay at Nubra Tent/ Hotel. (3033Mtrs) places to visit are Diskit monastery &Hunder sand dunes",
  },
  {
    id: "Day 5",
    header: "Nubra Valley to Turtuk (A Day Excursion",
    details:
      "After breakfast you will set off for Turtuk (A village that was won by India in the 1971 war with Pakistan) and extremely close to the border. Return to Hunder for an overnight stay. . O/n in stay at Nubra Tent/ Hotel. (3033Mtrs)",
  },
  {
    id: "Day 6",
    header:
      " Nubra- Pangong Lake via Shayok River – Pangong – Leh Back to Leh same day (314 Kms)",
    details:
      "After early B/fast set off for Pangong Lake Via Shayok River, Agyam Village and Tangtse , It is situated at alt: 14,000 ft. Pangong is 5-6Kms wide . Post Lunch drive back to Leh via Changala Pass- world’s 3rd highest motor able road) 17,350ft/5486 Mtrs. Later Kharu continue drive to Leh.",
  },
  {
    id: "Day 7",
    header: "Local Sightseeing East Indus Valley",
    details:
      "Hemis- Thiksay Monasteries- Rincho School- Leh Palace (101Kms). After b/fast set off for Hemis Monastery. En route visit Druk Pama Karpo (Rincho School), Thiksay monastery& Hemis Monastery explore Leh market for Shopping and have the day for Leisure. Overnight at hotel in Leh ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Departure",
    details:
      "Our teams thank you for your patronage and ensure a punctual drop to Leh Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodations on double/twin sharing basis as per package Selected.",
  "Meal Plan based on MAP (Room + Breakfast +Dinner) included in the package cost.",
  "All transport Services as per the itinerary by Non-A/C Crytsa Innova/Innova/ xylo / Ertiga. The same car shall do the complete itinerary (Maximum 6 persons including kids) are possible in one vehicle, for more than 6 please ask for Tempo Traveller).",
  "Meeting and Assistance on Arrival and Dept by our representative",
  "An experienced driver would work as a basic guide (You can hire a dedicated guide as well)",
  "Parking, toll, fuel, driver allowances.",
  "Inner Line Permits/Wild Life Fees. Except 11year old below",
  "01 Oxygen Cylinders will be available in the cars during Nubra Valley and Pangong Tour only. If you require Oxygen other than these days, ",
];

const exclusionsData = [
  "Any kind of Medical & Travel insurance.",
  "Expenses of personal nature such as Tips, Laundry, Telephone, Table Drinks & portage et",
  "Extra nights in hotel due to ill health, flight cancellation or any other reason",
  "Service of vehicle is not included on leisure days & after finishing sightseeing tour",
  "Adventure activity like Rafting, Camel Safari, Mountain Biking, Motor Biking etc",
  "Monastery entrance fees, camera fee, and monument guide service",
];

const L6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={L6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default L6;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
