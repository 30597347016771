import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC14Image } from "../image";

const heroTextData = [
  {
    title: "Amazing Vietnam",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "Hanoi",
    endCity:  "Ho Chi Minh City",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
{
  id: "Day 1",
  header: "Hanoi arrival",
  details: "Welcome to Hanoi.Hanoi, the capital of Vietnam, is known for its centuries-old architecture and a rich culture with Southeast Asian, Chinese and French influences. At its heart is the chaotic Old Quarter, where the narrow streets are roughly arranged by trade. Upon arrival at the airport, you will be transferred to the hotel. Lunch at IndianRestaurant Afternoon, visit the Temple of Literature built in 1070 and regarded as the First University in Vietnam, the Hoan Kiem Lake and Ngoc Son temple and take the Cyclo tour at the Old Quarter to experience the exciting local daily life of Hanoians, shopping free around The Old Quarter that have stock of trendy to basic clothing and thousands of small craft and boutique shops offering variety of Vietnamese handicraft products Enjoy the Water Puppet Show- A form of folk arts originating in the north of Vietnam, in which wood-puppets play their roles according to the direction of puppeteers and singers of Cheo (a kind of traditional theater in Vietnam) sing songs to tell the story in words ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 2",
  header: "Hanoi – Ninh Binh - Hanoi",
  details: "Breakfast at Hotel Departure to visit Ninh Binh -90 km far from Hanoi. We will visit the ancient capital of Hoa Lu with the temples of Dinh and le Dynasties. Continue to visit Tam Coc – known as Halong Bay on land Lunch at Local restaurant Afternoon boat trip to visit Tam Coc, explore the beautiful Karst formations and mystery caves. Back to the pier, visit to Bich Dong Pagoda Late afternoon drive back to Hanoi. Dinner at Indian restaurant Overnight in Hanoi ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 3",
  header: "Hanoi – Halong",
  details: "Breakfast at Hotel and Check-out Transfer to Ha Long Bay in Quang Ninh Province (around 160KM). Upon arrival in Halong Bay, boarding the Cruise for exploring wonderful Bay of Halong Lunch at boat while cruising around the Bay Afternoon: explore hundreds of beautiful karst formations arising from green emerald water, explore Cave, swimming and enjoy Sunset on the Cruise (Program might change due to the weather and the management) Dinner on the Cruise. After dinner is fishing time for everyone who wishes to join Overnight on the Cruise ",
},
{
  id: "Day 4",
  header: "Halong – Hanoi – Danang – Hoi An",
  details: "Breakfast at Boat Cruise Continue to discover the Halong bay then check-out Lunch at local Restaurant Transfer from Halong Bay to Hanoi. Enjoy the Water Puppet Show- A form of folk arts originating in the north of Vietnam, in which wood-puppets play their roles according to the direction of puppeteers and singers of Cheo (a kind of traditional theater in Vietnam) sing songs to tell the story in words Dinner at Indian restaurant Overnight in Hanoi",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 5",
  header: "Banahills",
  details: "Breakfast at Hotel Drive to visit Marble Mountains, Dragon Bridgethen we will drive to Bana hills (30km from Danang) take Cable Car which gains 5 world scores to Ba Na hill station. First Stop we will visit Linh Ung pagoda, Old wine tunnel, Orchid Garden, Le Jardin D’Amour , Golden Bridge. Lunch on Bana Hills with local food Afternoon we will continue the trip to visit Fantasy Park then drive to Hoi An. Dinner at Indian Restaurant Overnight inHoi An City",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 6",
  header: "Hoi An - Danang – Hue",
  details: "Breakfast at Hotel In the morning, take a walking tour of Hoi An to discover the historic town which used to be a prosperous seaport city during the 16th to 18th centuries. Visit Hoi An colorful local market, Tan Ky old house, Fukien Assembly Hall, and the 400-year-old Japanese covered bridge. Travelers can also see local residents raise silkworms and produce silk for Hoi An's burgeoning textile industry. Lunch at Indian Restaurant Afternoon we will drive to Hue, on the way photostop on Hai Van Pass. Upon arrival in Hue, check in hotel. Dinner at Indian Restaurant ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 7",
  header: "Hue – Ho Chi Minh City",
  details: "Breakfast at Hotel In the morning Boat trip in Perfume river to visit Thien Mu pagoda and King Minh Mang’s tomb. Lunch at Indian Restaurant with indian food. Afternoon: visit Imperial Citadel then transfer to Phu Bai Airport for the flight to Ho Chi Minh City. Dinner at Indian Restaurant Overnight in Ho Chi Minh City ",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 8",
  header: "Hanoi – Ho Chi Minh City",
  details: "Breakfast at Hotel Visit Ho Chi Minh complex including Ho Chi Minh Mausoleum from outside, his house-on-stilts, the One-Pillar pagoda and Hoa Lo Prison. Continue the trip visit to the Ethnology museum. 12:00 Checkout hotel Lunch at local restaurants Transfer to Noi Bai Airport for flight to Ho Chi Minh City Upon arrival in Ho Chi Minh city, your expert guide will meet you at the Airport while your private transportation brings you directly to your chosen hotel. Dinner at local restaurant with Indian Food Overnight in Ho Chi Minh City",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 9",
  header: "Mekong Delta – Ho Chi Minh city",
  details: "Breakfast at Hotel, drive to Cu Chi tunnels for 1 hour 30 minute- an amazing complex of the underground tunnels used during the Vietnam War. After a short introduction to the tunnel network of more than 200 km you will have a chance to crawl into a short section of the tunnels to feel the oppressive narrowness and heat inside. Lunch at local restaurant After lunch, drive to My Tho, a prosperous town of 170,000 inhabitants of the Mekong Delta. It is noted for its exuberant orchards and immense rice fields. Enjoy boat rides on the Mekong River and along the lush canopy of water coconuts. Visit an orchard on an island and taste some exotic fruits, green tea. Rowing boat along the canals and walk around the traditional villages; immerse yourself in nature when listening Southern traditional music Transfer back to Ho Chi Minh City. Dinner at IndianRestaurant Overnight in Ho Chi Minh City ",
},
{
  id: "Day 10",
  header: "Ho Chi Minh City – Departure",
  details: "Breakfast at Hotel Transfer to the airport for departure flight. ",
  labelData: ["label1", "label2", "label3", "label4"],
},
];

const inclusionData = [
  "English speaking tour guide",
  "Private air-conditioned transportation",
  "Accommodation as mentioned or similar",
  "Boat trips",
  "Meals as mentioned on tour dating: B=Breakfast, L=Lunch, D=Dinner",
  "All entrance fees",
  "Two way airport transfers",
  "Water & tissues",
];

const exclusionsData = [
  "International/Domestic flights costs",
  "Personal expenses, meals and drinks unless otherwise specified",
  "Travel insurance",
  "Visa fees to Vietnam",
  "Compulsory Tipping of 3$/pax/day",
  "Surcharge for peak season, New year, Holiday… ",
];

const VC14 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC14Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC14;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
