import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { AS3Image } from "../image";

const heroTextData = [
  {
    title: "Splendour Himalaya",
    duration: "4 Nights / 5 Days",
    typoOfTrip: "Road Trip",
    startCity: "Guwahati",
    endCity: "Guwahati",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Guwahati Airport / Rly Station - Shillong (100 Kms / 03 Hrs)",
    details: "On Arrival At Guwahati Airport / Railway Station & Transfer To Shillong (4,900 Ft.), Called 'scotland Of The East. Enroute Visit Umium Lake, A Majestic Placid Lake With Its Surrounding Sylvan Hills. On Arrival At Shillong, Check In At Your Hotel. Overnight Stay At Shillong.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Excursion To Cherrapunjee (60 Kms / 2 Hrs)",
    details: "After Early Breakfast Drive To Cherrapunjee (4,400 Ft.),The Wettest Place In The World. You Will See The Beautiful Waterfall Known As Nohkalikai. You Can Also Explore The Caves Known As Mawsmai. Evening Return To Shillong, Enroute Visit Elephanta Falls And Shillong Peak For Some Breathtaking Views. Overnight Stay At Shillong",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Excursion To Mawlynnong (90 Kms / 3 Hrs)",
    details: "After Early Breakfast Drive To Mawlynnong - asia's Cleanest Village. Mawlynnong Offers Many Interesting Sights Such As The Living Root Bridge And Another Strange Natural Phenomenon Of A Boulder Balancing On Another Small Rock. Stay Overnight At Your Hotel In Shillong.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Shillong Sightseeing - Guwahati (100 Kms / 03 Hrs)",
    details: "After Breakfast Visit Don Bosco Museum & Ward's Lake. After That Drive To Guwahati. Arrive And Check In At Hotel. In The Evening Visit 'kalakshetra', A Place Where Assamese History And Culture Is Presented Through Light And Sound Show. Return To Hotel. Overnight Stay At Guwahati.",
  },
  {
    id: "Day 5",
    header: "Guwahati Airport / Rly Station",
    details: "After Breakfast Visit Kamakhya Temple, Etc. Transfer To Guwahati Airport / Railway Station For Your Onward Journey",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const AS3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={AS3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default AS3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
