import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";
import { HO1Image,WT19Image ,WT22Image,HO2Image} from "./image";

const HOONEYMOON_HERO = "/assets/img/tour/honeymoon_tour.png";

const KASHMIR_TOUR = "/assets/img/domesticTour/kashmir.png";
const DARJEELING_TOUR = "/assets/img/domesticTour/Darjeeling.png";
const HIMACHAL_TOUR = "/assets/img/domesticTour/Himachal.png";
const KERALA_TOUR = "/assets/img/domesticTour/Kerala.png";

const tourCardContent = [
  {
    id: 1,
    totalPackges: 6,
    title: "Kashmir",
    image: KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kashmir-tour",
  },
  {
    id: 2,
    totalPackges: 13,
    title: "Kerala",
    image: KERALA_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kerala-packages",
  },
  {
    id: 3,
    totalPackges: 6,
    title: "Himachal Pradesh",
    image: HIMACHAL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/himachal-tour",
  },
  {
    id: 4,
    totalPackges: 11,
    title: "Maldives",
    image: WT19Image,
    description: "",
    path: "/maldives-tour",
  },
  {
    id: 5,
    totalPackges: 6,
    title: "Bali",
    image: WT22Image,
    description: "",
    path: "/bali-tour",
  },
  {
    id: 6,
    totalPackges: 4,
    title: "Rajasthan Tour",
    image: HO1Image,
    description: "",
    path: "/rajasthan-honeymoon-tour",
  },
  {
    id: 7,
    totalPackges: 6,
    title: "Andaman",
    image: HO2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/andaman-honeymoon-tour",
  },
];

const HoneymoonPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"Honeymoon"}
              details={
                "Planning a memorable Honeymoon starts with choosing an exciting destination that matches your dreamy expectations of an ideal getaway. But settling on a place that both of you will absolutely adore is not easy. So if you are still trying to figure where you should be, you must go through our Honeymoon Tour Packages. "
              }
              heroImg={HOONEYMOON_HERO}
              // title={"World Tour Packages"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default HoneymoonPackages;
