import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const TIGER_ROAR_WITH_HERITAGE_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Tiger_Roar_with_Heritage.png";

const heroTextData = [
  {
    title: "Tiger Roar with Heritage",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jaipur ",
    endCity: "Jaipur ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Jaipur Arrival",
    details:
      "Welcome and Arrive Jaipur Airport / Railway Station and meet our local representative who will transfer to the hotel, proceed to Amber fort, built in 16th century, enjoy an Elephant ride (By Own) at Amber, the Observatory Visit the Sheesh Mahal or the Hall of Victory glittering with mirrors. Photo stop at Jal mahal. Drive past the Hawa Mahal. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jaipur",
    details:
      "After breakfast, proceed to tour of Jaipur. Jaipur - the capital of Rajasthan was given a colour coat of pink a century ago in honour of a visiting Prince and ever since, it has retained this colour. Built by Maharaja Jai Singh, the notable astronomer, this city is 260 years old.  Visit Maharaja's City Palace, Jantar Mantar, Albert Hall, Birla Temple. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jaipur- Ranthambore",
    details:
      "After Breakfast, check out from Hotel and Proceed to Ranthambore. Free for Leisure. Overnight Stay at Ranthambore.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Ranthambore- Jaipur Drop",
    details:
      "Proceed Early morning 6.00Am for jungle safari to Ranthambore National Park. The jungle visits last for about three hours. Return to the hotel for breakfast time. Check out from Hotel and Proceed to Drop at Jaipur Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
];

const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
];

const TigerRoarwithHeritage = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TIGER_ROAR_WITH_HERITAGE_RAJASTHAN_TOUR_RAJASTHAN_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TigerRoarwithHeritage;
