import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const COCHIN = "/assets/img/domesticTour/kerala/cochin.png";

const heroTextData = [
  {
    title: " Cochin, Alleppey And Kovalam",
    duration: "04 Nights / 05 Day",
    typoOfTrip: "Road Trip",
    startCity: "Cochin ",
    endCity: "Kovalam",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival at Cochin and all day tour",
    details:
      "You will be welcomed at the airport by our executive. After checking in and freshening up at the hotel we have arranged for you, you can start your Cochin trip. Mangalavanam Bird Sanctuary and Kerala Folklore museum are two places that are the nearest and hence serve as a convenient start for the trip. The bird sanctuary is a paradise for nature lovers, whereas the museum is the place to go if you are interested in learning more about the culture and heritage of God’s Own Country. The Santa Cruz Cathedral Basilica and St Francis Church can be visited not only for a spiritual retreat, but also to check out its architecture. Cochin has many palaces, most of which have now been turned into a museum. In Fort Kochi and Mattancherry, you would find palaces build by the Portuguese, Dutch, British, etc. Visit the Paradesi Synagogue to have an informative time. While you visit the Fort Kochi Beach, make sure to check out the Chinese Fishing Nets.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Arrival at Alleppey and all day tour",
    details:
      "From Cochin as you arrive at Alleppey, you will find the landscape drastically changing from an urban setup to a total green, rural background. In Alleppey, after freshening up, you can start having fun by taking the quintessential houseboat rides in the backwaters of Kuttanad, Vembanad Lake, etc and chilling at the beach. The lush green paddy fields are sure to serve as a feast for your eyes. It is also one great opportunity to taste the delicious cuisine of Kerala. For those who want to have some serene spiritual time, there are temples and churches to visit in the town of Alleppey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Arrival at Kovalam and all day tour",
    details:
      "Once again, you will witness the landscape slowly changing into something new. Kovalam, located in the capital city of Trivandrum, is a quaint coastal town with a major hippie vibe to it. After freshening up, get dressed in your beach attires as you will be spending the whole days at the many different beaches in Kovalam. You can also engage in water sports like surfing, snorkeling, etc while you are in the beach, apart from just chilling or playing in the waves. By afternoon, climb the lighthouse to catch a stunning view of the beach and the town.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "All day tour at Kovalam",
    details:
      "After all the adventures and play in the beach, you must be exhausted. Don’t worry. You can reserve the second day in Kovalam for some self pampering. There are many centres in and around Kovalam offering Ayurvedic massages and spas. Have truly unwinding time at one of these centres. You can also avail treatment for specific ailments. You can also visit the Halcyon Castle which was build for the royal family in the early 30s. It has now been converted into a heritage hotel. Before you return, shop for souvenirs from Kovalam. You’ll get beautiful handicraft items and antique items in the shops lined along the beach.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure",
    details:
      "On the last day of your trip, our executive will drop you at the airport and bid you farewell. As you return with a bunch of memories, you will be a rejuvenated person.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const CochinAlleppeyAndKovalamTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={COCHIN}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CochinAlleppeyAndKovalamTourPackages;
