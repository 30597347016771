import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";

import {
  WT1Image,
  WT2Image,
  WT3Image,
  WT4Image,
  WT5Image,
  WT6Image,
  WT7Image,
  WT8Image,
  WT9Image,
  WT10Image,
  WT11Image,
  WT12Image,
  WT13Image,
  WT14Image,
  WT15Image,
  WT16Image,
  WT17Image,
  WT18Image,
  WT19Image,
  WT20Image,
  WT21Image,
  WT22Image,
  WT23Image,
} from "./image";

const POPULAR_TOUR = "/assets/img/tour/popular_tour.png";

const KASHMIR_TOUR = "/assets/img/domesticTour/kashmir.png";
const RAHASTHAN_TOUR = "/assets/img/domesticTour/Rajasthan.png";
const HIMACHAL_TOUR = "/assets/img/domesticTour/Himachal.png";
const KERALA_TOUR = "/assets/img/domesticTour/Kerala.png";
const CHARDHAM_TOUR = "/assets/img/domesticTour/Chardham.png";
const ANDAMAN_TOUR = "/assets/img/domesticTour/Andaman.png";
const LEH_LADAKH_TOUR = "/assets/img/domesticTour/LehLadakh.png";
const DARJEELING_TOUR = "/assets/img/domesticTour/Darjeeling.png";
const ODISHA_TOUR = "/assets/img/domesticTour/Odisha.png";
const GUJARAT_TOUR = "/assets/img/domesticTour/Gujarat.png";

const tourCardContent = [
  {
    id: 23,
    totalPackges: 5,
    title: "Dubai",
    image: WT23Image,
    description: "",
    path: "/dubai-tour",
  },
  {
    id: 1,
    totalPackges: 6,
    title: "Kashmir",
    image: KASHMIR_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kashmir-tour",
  },
  {
    id: 2,
    totalPackges: 21,
    title: "Rajasthan",
    image: RAHASTHAN_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/rajasthan-tour",
  },
  {
    id: 3,
    totalPackges: 6,
    title: "Himachal Pradesh",
    image: HIMACHAL_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/himachal-tour",
  },
  {
    id: 4,
    totalPackges: 13,
    title: "Kerala",
    image: KERALA_TOUR,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kerala-packages",
  },
  {
    id: 7,
    totalPackges: 9,
    image: LEH_LADAKH_TOUR,
    title: "Leh Ladakh",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/lehladakh-tour",
  },
  {
    id: 8,
    totalPackges: 10,
    image: GUJARAT_TOUR,
    title: "Gujarat",
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/gujarat-tour",
  },
  {
    id: 1,
    totalPackges: 15,
    title: "USA",
    image: WT1Image,
    description: "",
    path: "/usa-tour",
  },
  {
    id: 2,
    totalPackges: 5,
    title: "Bhutan",
    image: WT2Image,
    description: "",
    path: "/bhutan-tour",
  },
  {
    id: 3,
    totalPackges: 5,
    title: "Egypt",
    image: WT3Image,
    description: "",
    path: "/egypt-tour",
  },
  {
    id: 4,
    totalPackges: 8,
    title: "Azerbaijan And Georgia",
    image: WT4Image,
    description: "",
    path: "/azerbaijanandgeorgia-tour",
  },
  {
    id: 5,
    totalPackges: 5,
    title: "Japan",
    image: WT5Image,
    description: "",
    path: "/japan-tour",
  },
  {
    id: 6,
    totalPackges: 4,
    title: "Uzbekistan",
    image: WT6Image,
    description: "",
    path: "/uzbekistan-tour",
  },
  {
    id: 7,
    totalPackges: 7,
    title: "Canada",
    image: WT7Image,
    description: "",
    path: "/canada-tour",
  },
  {
    id: 8,
    totalPackges: 14,
    title: "Vietnam & Cambodia",
    image: WT8Image,
    description: "",
    path: "/vietnam-cambodia-tour",
  },
  {
    id: 9,
    totalPackges: 7,
    title: "Europe",
    image: WT9Image,
    description: "",
    path: "/europe-tour",
  },
  {
    id: 10,
    totalPackges: 3,
    title: "Armenia",
    image: WT10Image,
    description: "",
    path: "/armenia-tour",
  },
  {
    id: 11,
    totalPackges: 8,
    title: "Scandi Ready",
    image: WT11Image,
    description: "",
    path: "/scandiready-tour",
  },
  {
    id: 12,
    totalPackges: 4,
    title: "Turkey ",
    image: WT12Image,
    description: "",
    path: "/turkey-tour",
  },
  {
    id: 13,
    totalPackges: 8,
    title: "East Africa",
    image: WT13Image,
    description: "",
    path: "/eastafirca-tour",
  },
  {
    id: 14,
    totalPackges: 2,
    title: "Croatia",
    image: WT14Image,
    description: "",
    path: "/croatia-tour",
  },
  {
    id: 15,
    totalPackges: 4,
    title: "Russia",
    image: WT15Image,
    description: "",
    path: "/russia-tour",
  },
  {
    id: 16,
    totalPackges: 6,
    title: "Kazakhstan",
    image: WT16Image,
    description: "",
    path: "/kazakhstan-tour",
  },
  {
    id: 17,
    totalPackges: 8,
    title: "Jordan",
    image: WT17Image,
    description: "",
    path: "/jordan-tour",
  },
  {
    id: 18,
    totalPackges: 2,
    title: "Israel",
    image: WT18Image,
    description: "",
    path: "/israel-tour",
  },
  {
    id: 19,
    totalPackges: 11,
    title: "Maldives",
    image: WT19Image,
    description: "",
    path: "/maldives-tour",
  },
  // {
  //   id: 20,
  //   totalPackges: 6,
  //   title: "Napel",
  //   image: WT20Image,
  //   description: "",
  //   path: "/kashmir-tour",
  // },
  // {
  //   id: 21,
  //   totalPackges: 6,
  //   title: "Thailand",
  //   image: WT21Image,
  //   description: "",
  //   path: "/kashmir-tour",
  // },
  {
    id: 22,
    totalPackges: 6,
    title: "Bali",
    image: WT22Image,
    description: "",
    path: "/bali-tour",
  },
];

const PopularPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"Popular"}
              details={
                "Planning a memorable starts with choosing an exciting destination that matches your dreamy expectations of an ideal getaway. But settling on a place that all of you will absolutely adore is not easy. So if you are still trying to figure where you should be, you must go through our Popular Tour Packages."
              }
              heroImg={POPULAR_TOUR}
              // title={"World Tour Packages"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default PopularPackages;
