import React from "react";
import { Box, Typography } from "@mui/material";

const ContentSection = () => {
  return (
    <Box>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        If cancellations are made 30 days before the start date of the trip,
        30% of Total Package Cost will be charged as cancellation fees.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        If cancellations are made 15-30 days before the start date of the trip,
        50% of Total Package Cost will be charged as cancellation fees.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        If cancellations are made within 0-15 days before the start date of the
        trip, 100% of Total Package Cost will be charged as cancellation fees.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        In case of unforeseen weather conditions or government restrictions,
        certain activities may be cancelled and, in such cases, we will try our
        best to provide an alternate feasible activity. However, no refund will
        be provided for the same.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        100% cancelation would be charged from the total booking amount in case
        of last-minute booking cancellation due to flight cancellation, any
        natural calamity, and change in flight/Train schedule due to
        technical/Political/weather conditions.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        In case of Lockdown in Jammu & Kashmir due to Covid, will provide a
        credit note which a traveller can redeem within 1 year from the date of
        issuance of Credit Note. In such Sinario revised package will be
        provided as per the current applicable season and off-season rate. Do
        check the final cost with the reservation team.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        If due to reasons beyond our control like riots, flood, accidents or any
        other natural or man-made calamities, the program of the tour has to be
        changed or extended, the additional expenditure for the same will have
        to be borne by the passengers. As if any passengers, has to leave the
        tour mid-way, due to any reasons like death of relative, or illness or
        any other reasons, there shall be no refund for the remaining portion of
        the tour
      </Typography>
      <br />
    </Box>
  );
};

export default ContentSection;
