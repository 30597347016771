import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { E3Image } from "../image";

const heroTextData = [
  {
    title: "Cairo + Nile Cruise",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Cairo",
    endCity: "Cairo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Cairo",
    details: "For your added comfort, upon your arrival at the airport, an airport representative will greet you in the tunnel and escort you through customs and immigration. The representative will facilitate the included visa process for you. Continue to the baggage claim hall and passenger pick up. Here an airport representative will help you connect with your private transfer to your hotel. Enjoy a private airport transfer accompanied by an English-speaking representative. Enjoy time at leisure to explore at your own pace, or relax at your hotel. Accommodation: At your chosen hotel on Bed & Breakfast basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Egyptian Museum, Citadel and Old Cairo. Cairo by night at Khan El Khalili",
    details: "Your Guide will meet you at the lobby to start the sightseeing The Egyptian Museum of Antiquities, also known as the Egyptian Museum, is a veritable treasure trove of Ancient Egypt’s artifacts, with an extensive collection of more than 120,000 items split between its exhibits and storerooms. Delve into the historical background of Egypt on a full day private tour with your Egyptologist guide through the world’s largest collection of Pharaonic antiquities, admiring the treasures that are stored here for safekeeping. Tie together the grandeur of Cairo, Luxor, Aswan and Abu Simbel with a collection of archaeological finds including statues, jewelry, amulets, sarcophagi, papyrus, ancient coins and the stories of famous Egyptologists. Marvel at the exquisite grandeur of the Mask of Tutankhamun and his coffin – Egypt’s most famous teenage king – although his mummy is still in its original tomb in the Valley of the Kings. Explore the Royal Mummy Hall with your included entrance fee, where some of the world’s most powerful people are now resting, excavated from their tombs in the Valleys of the Kings and Queens. Relax and enjoy a buffet lunch at a local restaurant, including a choice of a small bottle of water, soft drink, tea or coffee Then proceed to visit Mohamed Ali Alabaster Mosque; one of Cairo's most famous landmarks and Citadel of Salah El Din then move on to Old Cairo visiting the Hanging Church, Abu Sirga Church and the Jewish Synagogue Accommodation: At your chosen hotel on Bed & Breakfast basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Full day pyramids, Memphis, sakkara",
    details: "The Great Pyramids of Giza are the most iconic image of Egyptology and an absolute must-see on any trip to Cairo. Meet with your private guide, driver, and Egyptologist, and head to Giza to discover this marvel and the only surviving site of the original Seven Wonders of the Ancient World. Start with a visit to the adjacent Solar Boat Museum, home to the reconstructed Khufu solar ship. Step back from the museum and marvel at the breathtaking scenery of the UNESCO World Heritage Site of the Great Pyramids of Giza juxtaposed against the famous Sphinx statue. The sheer beauty and allure of the Sphinx have mystified world travelers the likes of Alexander the Great and Napoleon Bonaparte, an imposing scene against the backdrop of the infinite desert behind it. The mysteries of the Great Pyramids have piqued the curiosity of visitors for thousands of years. Enter the Pyramid of Chephren, also known as the Pyramid of Khafre. The second tallest and second largest of the major pyramids, it sets on bedrock 33 feet higher than Khufu’s pyramid, which makes it appear to be taller. Move to have an exciting tour to the Step Pyramids of Zoser; the world's oldest major stone structure. It was built in the 3rd Dynasty (around 2630 BC) for King Djoser. After that transfer to Memphis which was founded by King Menes, and was the capital of Old Egypt during the Old Kingdom, it was considered to be a center of rule and culture for over 3000 years the first capital of Egypt, and see the Statue of Ramses II. Relax and enjoy a buffet lunch at a local restaurant, including a choice of a small bottle of water, soft drink, tea or coffee. Accommodation: At your chosen hotel on Bed & Breakfast basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Fly from Cairo to Aswan",
    details: "Enjoy a private airport transfer accompanied by an English-speaking representative. Board a flight in Cairo with direct service to Aswan. Approximate flight duration is one hour. Aswan: Enjoy a private airport transfer accompanied by an English-speaking guide. Welcome aboard! Take some time to get settled in to your room and to explore the length of your ship. Your Guide will meet you at the cruise to start your sightseeing Embarkation before lunch. Visit the High Dam & the temples of Philae. PM: Sailing around Aswan Islands in the traditional felucca (sailing boat) viewing the Agha Khan Mausoleum and the Botanical Garden (Kitchener Island). Overnight in Aswan. Accommodation: Lunch & Dinner ",
  },
  {
    id: "Day 5",
    header: "Aswan",
    details: "headed God Sobek and the falcon headed God Haroeris. Navigation to Edfu Visit the most well preserved Temple in Egypt dedicated to the Falcon God Horus Navigation to Luxor via Esna Overnight in Luxor Accommodation: Breakfast, Lunch & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Aswan - Luxor",
    details: "Visit the Ancient Theban Necropolis in the west bank of Luxor to see the Valley of Kings where more than 60 of Egypt Pharaohs have their resting place carved out of the mountain cliffs. The visit Medinet Habu, the Mortuary Temple of Ramses III which is one of the last great buildings of the Egyptian monumental architecture of the New Kingdom. Visit the Colossi of Memnon, the only remains of the funerary Temple of Amen phis III. PM: Visit the fabulous Luxor Temple and the enormous Temple complex of Karnak. Among its splendours is the Hypostyle Hall, the largest of any temple in the world covering an area of 5356 sq. meters with its roof blocks supported by 134 colossal columns 70 feet high. Overnight in Luxor. Accommodation: Breakfast, Lunch & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Fly from Luxor – Sharm El Sheikh",
    details: "Disembarkation after breakfast Then Transfer to Luxor airport to fly to Sharm El Sheikh (one flying hour). Meet & assist through formalities then transfer to the hotel (Approx. 30 – 60 Minutes). Accommodation: At your chosen hotel on Full board basis (Breakfast, Lunch, Dinner)",
  },
  {
    id: "Day 8",
    header: "Sharm El sheikh",
    details: "At Leisure Accommodation: At your chosen hotel on Full board basis (Breakfast, Lunch, and Dinner)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Final Departure",
    details: "Check out from the Hotel, enjoy a private transfer accompanied by an English-speaking Representative to Q.A.I.A for the final departure",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"Accommodation as per program",
"Transfers and sightseeing by A/C coach",
"All Excursions and Tours with English speaking guide except optional tours.",
"One bottle of Mineral Water per person per day during the tour",
];

const exclusionsData = [
"Tips",
"Domestic Flights and International flights",
"Entry Visa will cost 45.00 USD each per each passport. Any Extras",
];

const E3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={E3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default E3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
