import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const termsConditionData = [
  "Above is just an offer and we are not holding any booking against this query. All services/rooms are subject to availability at the time of receiving final confirmation from your end.",
  "The final price of the same package combination may vary from time to time and shall not be fixed. Any claims on the package price shall not be entertained.",
  "In case of any unavailability in the listed hotels, we will try to provide alternate accommodation in hotel of similar standard.",
  "Final vouchers can only be issued once we have received the full payment.",
  "Hotel Check-In/Check-Out Policy: Official Check-In 1400hrs & Check-Out 1200noon",
  "Early Check-In and late Check-Out is strictly subject to hotel availability and applicable rates at the time of Check-In/Check-Out",
  "No rooms have been blocked yet",
  "The above quote is valid only for this query and may vary in case the number of passenger’s change.",
  "Please remember that all special requests like early Check-In, smoking, non-smoking, views, floors, king, twin, adjoining and/or interconnecting rooms are strictly subject to availability upon arrival and same cannot be guaranteed prior.",
  "If the Hotels or Suppliers change their quote for the services that they offer, the same will be forwarded to your Company.",
  "During blackout dates above quote is not valid. Please get in touch with our Customer Service Executive for booking during this period.",
  "The quotation is tentative and rates are subject to change",
  "Peak Season / Weekend like New Year and Christmas Surcharges may be applicable at the time of confirmation.",
  "One standard room can accommodate 2 Adults + 1 Child (With Bed up to 11 Years) and 1 Child (without bed up to 5 Years) only.",
];

const ContentSection = () => {
  return (
    <Box>
      <Grid container spacing={0.5}>
        {termsConditionData.map((item) => (
          <Grid item xs={12}>
            <Typography>{item}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ContentSection;
