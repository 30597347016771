import React from "react";
import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CommuteIcon from "@mui/icons-material/Commute";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";

const inclusionIconsData = [
  {
    id: 1,
    icon: (
      <EmojiTransportationIcon fontSize="medium" sx={{ color: "#4d4d4d" }} />
    ),
    text: "Pick and Drop",
  },
  {
    id: 2,
    icon: <CommuteIcon fontSize="medium" sx={{ color: "#4d4d4d" }} />,
    text: "Private Transport",
  },
  {
    id: 3,
    icon: <ApartmentIcon fontSize="medium" sx={{ color: "#4d4d4d" }} />,
    text: "Hotel Stay",
  },
  {
    id: 4,
    icon: <DinnerDiningIcon fontSize="medium" sx={{ color: "#4d4d4d" }} />,
    text: "Food",
  },
  {
    id: 5,
    icon: <Diversity3Icon fontSize="medium" sx={{ color: "#4d4d4d" }} />,
    text: "Sightseeing",
  },
  {
    id: 6,
    icon: <SupportAgentIcon fontSize="medium" sx={{ color: "#4d4d4d" }} />,
    text: "24/7 Support",
  },
];

const InclusionIcons = () => {
  return (
   <Container>
     <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {inclusionIconsData.map((item) => (
          <Grid item xs={4} sm={2} md={2}>
            <Card sx={{borderRadius:2,height:80}}>
              <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {item.icon}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    color: "text.secondary",
                    //   textShadow: "1px 1px 2px black",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
   </Container>
  );
};

export default InclusionIcons;
