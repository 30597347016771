import React from "react";
import { Box, Container, Grid } from "@mui/material";
import HolidayTheme from "./HolidayTheme";
import TopTouristDestination from "./TopTouristDestination";
import TopIndiaTouristDestination from "./TopIndiaTouristDestination";

const Links = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1,py:2,}}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={3}>
            <HolidayTheme />
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <TopIndiaTouristDestination />
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
          <TopTouristDestination />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Links;
