import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { UP2Image } from "../image";

const heroTextData = [
  {
    title: "Kashi -Prayag Darshan",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Varanasi",
    endCity: "Varanasi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Varanasi Arrive",
    details: "Varanasi Arrive Varanasi - meet our driver / representative and drive towards your pre-booked hotel at Varanasi, check-in to hotel and relax. In the evening drive through the crowded heritage lanes of old city towards Ganga Ghats to watch evening Aarti.The Aarti ceremony an experience of life. The GANGA Aarti is a spectacular sight. An offering of lamps and flowers are made to the river immediately following this ceremony, it is a moving sight to watch hundreds of miniature lamps float along the river. Return to hotel, overnight at hotel in Varanasi.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Varanasi",
    details: "Early morning, visit the majestic Ghats of Varanasi by the Ganges. It’s a mystical and spiritual experience as you watch people offering water to the Sun God and devotees taking holy dip in the Ganges and performing rituals further visit the Kashi Vishwanath temple / Corridor, Annapurna Devi Temple & Vishalakshi Shakti Peetha, followed by visit of Sankat Mochan Temple, Durga Temple; return to hotel for breakfast. Post breakfast visit the Kal-bhairava Temple. drive for an excursion to Sarnath. Sarnath is the place where Buddha delivered his first sermon to his disciples. The attractions at Sarnath are the monasteries of different countries, the Dhamekha stupa, Chaukhandi stupa, the archaeological Site and a museum. Overnight stay at hotel in Varanasi.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Varanasi – Sitamarhi –Prayag - Varanasi",
    details: "Around 8 in morning post-breakfast start for a day excursion of Prayag via Sitamarhi. Sitamarhi,the place where mother Sita spent her 11years of exile, the birth place of Luv-Kusha, at Maharishi Valmiki Ashram after visit of this holy site drive to Prayag, check-in at hotel. Later proceed to see the Sangam, the confluence of three rivers- Ganga, Yamuna and Saraswati (a mythological river). Prayag is an important pilgrimage center of the Hindus. Iis one of the four holy place where KumbhMela is organized every 12 years and “ArdhaKumbhMela” every 6 years. One should take a holy dip at Sangam. You also have option to visit temples of Bade hanuman, Alopi Devi Temple and can also visit Anand Bhavan & Swaraj Bhavan then proceed back to Varanasi. Overnight at hotel in Varanasi.",
  },
  {
    id: "Day 4",
    header: "Varanasi",
    details: "Varanasi Morning free for shopping and visit of silk weaving, in-time check-out and drive to airport for your onward flight.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
]; 

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const UP2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={UP2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default UP2;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
