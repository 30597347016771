import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  KT1Image,
  KT2Image,
  KT3Image,
  KT4Image,
  KT5Image,
  KT6Image,
  KT7Image,
  KT8Image,
  KT9Image,
  KT10Image,
  KT11Image,
  KT12Image,
  KT13Image,
  KT14Image,
  KT15Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const KARNATAKA_TOUR = "/assets/img/domesticTour/karnataka.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Bangalore / Mysore / Coorg / Bangalore",
    image: KT1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Hubli / Hospet / Hampi / Badami / Hubli",
    image: KT2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt2",
  },
  {
    id: 3,
    fromToDays: "04 Nights / 05 Days",
    title: "Hubli / Hospet / Hampi / Badami / Dandeli / Hubli",
    image: KT3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Hubli / Hospet / Hampi / Badami / Aihole & Pattadakal / Hubli",
    image: KT4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt4",
  },
  {
    id: 5,
    fromToDays: "04 Nights/ 05 Days",
    title: "Bangalore / Coorg / Mysore / Bangalore",
    image: KT5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt5",
  },
  {
    id: 6,
    fromToDays: "04 Nights / 05 Days",
    title: "Bangalore / Ooty / Mysore / Bangalore",
    image: KT6Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt6",
  },
  {
    id: 7,
    fromToDays: "05 Nights / 06 Days",
    title: "Bangalore / Chikmagalur / Mysore / Kabini / Bangalore",
    image: KT7Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt7",
  },
  {
    id: 8,
    fromToDays: "05 Nights / 06 Days",
    title: "Bangalore / Hassan / Coorg / Mysore / Bangalore",
    image: KT8Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt8",
  },
  {
    id: 9,
    fromToDays: "05 Nights/ 06 Days",
    title: "Mangalore / Udupi / Murudeshwara / Gokarna / Hubli",
    image: KT9Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt9",
  },
  {
    id: 10,
    fromToDays: "06 Nights / 07 Days",
    title: "Mangalore / Udupi / Murudeshwara / Jof Falls / Karwar Dandeli / Hubli",
    image: KT10Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt10",
  },
  {
    id: 11,
    fromToDays: "06 Nights / 07 Days",
    title: "Bangalore / Coorg / Mysore / Ooty / Coimbatore",
    image: KT11Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt11",
  },
  {
    id: 12,
    fromToDays: "06 Nights / 07 Days",
    title: "Bangalore / Chikmagalur / Hassan / Coorg / Mysore / Bangalore",
    image: KT12Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt12",
  },
  {
    id: 13,
    fromToDays: "06 Nights / 07 Days",
    title: "Bangalore / Mysore / Ooty / Kodaikanal / Coimbatore",
    image: KT13Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt13",
  },
  {
    id: 14,
    fromToDays: "07 Nights / 08 Days",
    title: "Hubli / Belgaum / Bijapur / Gulbarga / Badami / Aihole & Pattadakal / Hospet / Hampi / Hubli",
    image: KT14Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt14",
  },
  {
    id: 15,
    fromToDays: "09 Nights / 10 Days",
    title: "Hubli / Hospet / Hampi / Badami / Dandeli / Karwar / Murudeshwara / Udupi / Mangalore",
    image: KT15Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/kt15",
  },
];

const Karnataka = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Karnataka"}
            subTitle={"Amazing Holidays to Karnataka"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={KARNATAKA_TOUR}
            title={"Popular Karnataka Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Assam"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Karnataka;
