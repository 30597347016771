import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";
import { ST1Image } from "./image";

const STUDENT_HERO = "/assets/img/tour/student_special_tour.png";

const tourCardContent = [
  {
    id: 1,
    totalPackges: 2,
    title: "Rajasthan Tour",
    image: ST1Image,
    description: "",
    path: "/rajasthan-student-tour",
  },
];

const StudentPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"Student"}
              details={
                "If you’re ready to inspire what’s next for your students, this is the perfect place to start. The itineraries in this travel collection are chosen time and time again and it’s easy to see why. From tasting cultural cuisines to discovering hidden gems, these destinations always deliver. Planning a memorable Student starts with choosing an exciting destination that matches your dreamy expectations of an ideal getaway. But settling on a place that all of you will absolutely adore is not easy. So if you are still trying to figure where you should be, you must go through our Student Tour Packages."
              }
              heroImg={STUDENT_HERO}
              // title={"World Tour Packages"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default StudentPackages;
