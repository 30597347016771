import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { JO7Image } from "../image";

const heroTextData = [
  {
    title: "Combined Programs Jordan with Holy Land",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Queen",
    endCity: "Amman",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Amman Airport (D) 40 KM",
    details:
      "Arrival to Queen Alia International Airport, transfer to Amman hotel. Dinner and overnight at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Amman - Madaba – Mount Nebo – Dead Sea - Petra (B, D) 320 KM",
    details:
      "Breakfast at the hotel. Departure to Madaba the City of Mosaics where you will visit the church of St. George which houses the map of the Holy Land made ​​in 571 D. C. Continue to Mount Nebo, known as the tomb of Moses, from whose summit a magnificent view of the Jordan Valley. Then transfer to the Dead Sea which sits 400 meters below sea level. The Dead Sea is one of the most incredible places in the world. It is known for the Biblical story of the destruction of Sodom and Gomorrah, which was turned into salt. The Dead Sea speaks for itself; there is no living fish! It is by far one of Jordan's most impressive and spectacular places to visit. Its calm shining water glowing from its scorching sun known for the concentration of salt and minerals, which makes it a therapeutic as well as a unique swimming experience not to be missed! Free time at the Dead Sea Tourist Beach Rest house, for a short swim before departure to Petra. Dinner and overnight at the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Petra (B, D) 15 KM",
    details:
      "After breakfast, visit the Nabatean Red Rose City of Petra, which is one of the Seven Wonders of the World. A city carved in stone more than 2000 years ago to dominate the trade routes of ancient Arabia. First, you will walk the “siq”, a narrow path of 1.2 kilometers, which is the main entrance to the city. At the end of the “siq”, you will see the treasury Khazneh - which was believed to hold countless treasures. The first view of the Khazneh is breath-taking. Other monuments you will visit are the Theatre, the Byzantine Church, and the Royal Tombs. Dinner and overnight at Petra hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Petra - Wadi Rum - Amman (B, D) 420 KM",
    details:
      "After breakfast depart to Wadi Rum, called the valley of moon where the movie Lawrence of Arabia was filmed for a classic 2 hours visit of Wadi Rum in a 4x4 local jeeps. Wadi Rum is a uniquely shaped massive mountain that rose amongst the rosy red sand of the desert with towering cliffs of various brownish, reddish, and golden colors. Return to Amman for dinner and overnight in Amman hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Amman Panoramic - Jerash - Sheikh Hussein - Tiberias (B, D) 165 KM",
    details:
      "After breakfast depart for a panoramic visit of Amman. Continue to Jerash, one of the most complete and best preserved of the Roman Empire. A walk-through time visiting its streets, theaters, temples and other places like the Great Colonnade, the Arc of Triumph, the Oval Plaza, as well as the Temples of Zeus and Artemis. Transfer to Sheikh Hussein Bridge. Arrive to Tiberias for dinner and overnight at the hotel.",
  },
  {
    id: "Day 6",
    header: "Mount Tabor -Mount of Beatitudes - Boat Ride - Galilee -Bethlehem (B, D) 180 KM",
    details:
      "After breakfast depart for a visit of Mount of Beatitudes, site of the Sermon on the Mount. Continue to Tabgha where the miracle of the multiplication of the loaves and fishes took place the visit Saint Peter’s Primacy Church. Continue to visit the ruins of Capernaum, the town where Jesus made his home after leaving Nazareth. Have a boat ride on the Sea of Galilee. Transfer to Bethlehem for dinner and overnig",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Bethlehem - Jerusalem - Mount of Olives - Old City of Jerusalem - Bethlehem (B, D) 100 KM",
    details:
      "After breakfast depart to visit the Church of the Nativity, marking the birthplace of Christ. Visit Saint Catherine’s Church and Saint Jerome’s Cave where the bible was translated. Continue to visit the Shepherd’s Field. Drive to Jerusalem and go to the Mount of Olives and visit Pater Noster Church. Continue to an observation point that overlooks the Old City of Jerusalem. Proceed to Dominus Flevit then walk the Palm Sunday route and descend to the Garden of Gethsemane where Jesus spent the last night before he was arrested. Visit the famous Church of All Nations. Enter the old city of Jerusalem and visit Church of the Sepulcher. Drive back to Bethlehem for dinner and overnight at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Bethlehem - Tel Aviv Airport (B, D) 120 KM",
    details:
      "After breakfast Transfer to Ben Gurion Airport for departure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Meet and Assist with Our Representative",
  "Transfers Airport - Hotel - Airport",
  "Leisure Transportation according number of participants with 1 luggage and 1 hand bag per person",
  "Accommodations in double or twin rooms with single supplement on selected hotel options: 2 nights Amman and 2 nights Petra",
  "Meals as per itinerary (B = Breakfast, D = Dinner)",
  "Classic visits mentioned with entrance fees included in the tour itinerary and Jeep 4x4 (classic tour) in Wadi Rum",
  "English / French Speaking Local Guide for visits",
  "Visa Exemption Service",
];

const exclusionsData = [
  "Visa and Exit Fees. Visa fees: 40 JOD approximately 60 USD per person and exit fees thru Airport 30 JOD approximately 45 USD per person included in the air ticket. Exit fees by any bridge 10 JOD approximately 15 USD per person. Consult for visa exemption services and free group collective visa entry.",
  "Lunches.",
  "All kinds of drinks and water.",
  "All kinds of tips and portages.",
  "Personal expenses.",
  "Travel Insurance.",
  "Any Service not mentioned in the program",
];

const JO7 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={JO7Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default JO7;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
