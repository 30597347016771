import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  W1Image,
  W2Image,
  W3Image,
  W4Image,
  W5Image,
  W6Image,
  W7Image,
  W8Image,
  W9Image,
  W10Image,
  W11Image,
  W12Image,
  W13Image,
  W14Image,
  W15Image,
  W16Image,
  W17Image,
  W18Image,
  W19Image,
  W20Image,
  W21Image,
  W22Image,
  W23Image,
  W24Image,
  W25Image,
  W26Image,
  W27Image,
  W28Image,
  W29Image,
  W30Image,
  W31Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const DARJEELING_TOUR = "/assets/img/domesticTour/Darjeeling.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Queen Of The Hills",
    image: W1Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Splendour Himalaya",
    image: W2Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Green Dooars",
    image: W3Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w3",
  },
  {
    id: 4,
    fromToDays: "03 Nights / 04 Days",
    title: "Heaven On Hills",
    image: W4Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "Himalayan Tranquility",
    image: W5Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w5",
  },
  {
    id: 6,
    fromToDays: "04 Nights / 05 Days",
    title: "Lost Kingdom",
    image: W6Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w6",
  },
  {
    id: 7,
    fromToDays: "05 Nights / 06 Days",
    title: "Mystery Mountain ",
    image: W7Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w7",
  },

  {
    id: 8,
    fromToDays: "05 Nights / 06 Days",
    title: "Himalayan Golden Triangle",
    image: W8Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w8",
  },
  {
    id: 9,
    fromToDays: "05 Nights / 06 Days",
    title: "Rendezvous Sikkim",
    image: W9Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w9",
  },
  {
    id: 10,
    fromToDays: "06 Nights / 07 Days",
    title: "Cool Khangchendzongha",
    image: W10Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w10",
  },
  {
    id: 11,
    fromToDays: "06 Nights / 07 Days",
    title: "Rendezvous Sikkim Part",
    image: W11Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w11",
  },
  {
    id: 12,
    fromToDays: "06 Nights / 07 Days",
    title: "Eastern Mystery",
    image: W12Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w12",
  },
  {
    id: 13,
    fromToDays: "06 Nights / 07 Days",
    title: "Unique Himalaya",
    image: W13Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w13",
  },
  {
    id: 14,
    fromToDays: "06 Nights / 07 Days",
    title: "Mesmerising Himalayas",
    image: W14Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w14",
  },
  {
    id: 15,
    fromToDays: "06 Nights / 07 Days",
    title: "Unleashed Himalaya",
    image: W15Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w15",
  },
  {
    id: 16,
    fromToDays: "07 Nights / 08 Days",
    title: "Sweet Eastern Himalaya",
    image: W16Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w16",
  },
  {
    id: 17,
    fromToDays: "07 Nights / 08 Days",
    title: "Splendid Hills",
    image: W17Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w17",
  },
  {
    id: 18,
    fromToDays: "07 Nights / 08 Days",
    title: "Treasures Of Sikkim",
    image: W18Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w18",
  },
  {
    id: 19,
    fromToDays: "07 Nights / 08 Days",
    title: "Hills Of Bengal",
    image: W19Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w19",
  },
  {
    id: 20,
    fromToDays: "07 Nights / 08 Days",
    title: "Eastern Himalayan 3 Jewels",
    image: W10Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w20",
  },
  {
    id: 21,
    fromToDays: "07 Nights / 08 Days",
    title: "Peaceful Sikkim",
    image: W21Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w21",
  },
  {
    id: 22,
    fromToDays: "08 Nights / 09 Days",
    title: "Sweet Memory Of Khangchendzongha",
    image: W22Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w22",
  },
  {
    id: 23,
    fromToDays: "08 Nights / 09 Days",
    title: "Himalayan Orchid",
    image: W23Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w23",
  },
  {
    id: 24,
    fromToDays: "08 Nights / 09 Days",
    title: "Himalayan Treasures",
    image: W24Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w24",
  },
  {
    id: 25,
    fromToDays: "08 Nights / 09 Days",
    title: "Sikkim Engrossed",
    image: W25Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w25",
  },
  {
    id: 26,
    fromToDays: "09 Nights / 10 Days",
    title: "Anmol Himalaya",
    image: W26Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w26",
  },
  {
    id: 27,
    fromToDays: "09 Nights / 10 Days",
    title: "Breathless Whiteout",
    image: W27Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w27",
  },
  {
    id: 28,
    fromToDays: "09 Nights / 10 Days",
    title: "Himalayan Trail",
    image: W28Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w28",
  },
  {
    id: 29,
    fromToDays: "10 Nights / 11 Days",
    title: "Discover Eastern Himalaya",
    image: W29Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w29",
  },
  {
    id: 30,
    fromToDays: "11 Nights / 12 Days",
    title: "Discover Eastern Himalaya II",
    image: W30Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w30",
  },
  {
    id: 31,
    fromToDays: "14 Nights / 15 Days",
    title: "Amorous Dale",
    image: W31Image,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/w31",
  },
];

const rulesData = [
  "  Documents for Tsomgo Lake & New Baba Mandir – 02 Passport Size Photo along with Photo Copy of Photo ID Proof (Passport or Driving License or Voter ID (Pan Card & Adhaar Card is not accepted). For Children below 18 years, Birth Certificate / School Id / College Id are must. Original needs to carry while travelling. ",
  "  In case of Land slide or any other reason, if Tsomgo Lake is closed we will provide alternate sightseeing.",
];

const WestBengalSikkim = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"West Bengal & Sikkim Packages"}
            subTitle={"Amazing Holidays to West Bengal & Sikkim"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={DARJEELING_TOUR}
            title={"Popular West Bengal & Sikkim Holiday Packages"}
            tourCardContent={tourCardContent}
            ruleTitle={"West Bengal & Sikkim"}
            rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default WestBengalSikkim;
