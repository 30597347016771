import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { TN3Image } from "../image";

const heroTextData = [
  {
    title: "Chennai / Tirupati / Mahabalipuram / Trichy / Kodaikanal / Madurai / Rameswaram / Madurai",
    duration:  "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Chennai",
    endCity: "Madurai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Chennai + Drive to Tirupati (135 Kms / 3 – 4 Hrs)",
    details: "On arrival meet our local chauffer waiting for you at designated area and proceed towards Tirupati hotel. Later proceed for Tirumala temple Darshan. Overnight at Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Drive Tirupati – Mahabalipuram (195 Kms / 4 – 5 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Mahabalipuram. Arrive Mahabalipuram and check into the hotel. Later visit Mahabalipuram the shore Temple, Panch Rath and other close by monuments. Overnight at Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Mahabalipuram – Trichy (290 Kms / 4 – 5 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Trichy. Arrive Trichy and check into the hotel. Later visit Vekkali Amman Temple, Rock fort and Sri Ranganathaswamy temple. Rest of the day free at leisure. Overnight in the hotel.",
  },
  {
    id: "Day 4",
    header: "Drive Trichy – Kodaikanal (200 Kms / 4 – 5 Hrs)",
    details: "After breakfast check out of the hotel and drive to Kodaikanal. Arrive and check into the hotel in the noon. Rest of the day free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Kodaikanal",
    details: "After breakfast, enjoy local sightseeing of Kodaikanal visiting Pillar’s Rock, Kodai Lake and Fairy falls. Also enjoy a bridal walk at Coaker’s walk with panoramic view of the mountains. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Drive Kodaikanal – Madurai (116Kms / 3 – 4 hrs)",
    details: "After breakfast, check out of the hotel and proceed towards Madurai. Arrive and check into the hotel. Later visit Sri Meenakshi Amman Temple and Alagarkoyil temple. Evening free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Drive Madurai – Rameswaram (175 Kms / 3 – 4 Hrs)",
    details: "After breakfast check out of the hotel and proceed towards Rameswaram. Arrive Rameshwaram in the noon and check in to the hotel and relax. Later visit Ramanathswamy temple. Overnight at hotel.",
  },
  {
    id: "Day 8",
    header: "Rameswaram",
    details: "After breakfast, visit Dhanushkodi Beach, Pamban bridge and Five faced Hanuman temple. Rest of the evening free at leisure. Overnight at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Drive Rameswaram – Madurai + Drop (175 kms / 3 – 4 Hrs)",
    details: "After breakfast, time free until noon. Later check out of the hotel and transfer to Madurai rail station / airport in time for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const TN3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={TN3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default TN3;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
