import React from "react";
import {
  CardMedia,
  Grid,
  Typography,
  Card,
  Box,
  Container,
  Divider,
} from "@mui/material";

import PropTypes from "prop-types";
import HeroTextDetails from "./HeroTextDetails";



const HeroTourDetailSection = ({heroImg,heroTextData}) => {
  return(
    <Box sx={{ flexGrow: 1 }}>
    <Card
      sx={{ maxWidth: 1920, borderRadius: "8px 8px 8px 8px", height: "484" }}
    >
      <CardMedia
        component="image"
        image={heroImg}
        height="484"
        sx={{ objectFit: "cover" }}
      >
        
        <Container>
          <Box
            sx={{
              height: { xs: "30vh", sm: "40vh", md: "55vh" },
              alignItems: "end",
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            
            <Grid container >
                <Grid item xs={12} sm={12} md={6}>
                <HeroTextDetails
                  heroTextData={heroTextData}
                />
                </Grid>
            </Grid>
          </Box>
        </Container>
      </CardMedia>
    </Card>
  </Box>
  )
};

HeroTextDetails.propTypes = {
  heroImg: PropTypes.string,
  heroTextData: PropTypes.func,

};

export default HeroTourDetailSection;
