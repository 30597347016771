import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC1Image } from "../image";

const heroTextData = [
  {
    title: "Danang - Hoi An - My Son - Danang",
    duration: "02 Nights / 03 Days",
    typoOfTrip: "Road Trip",
    startCity: "Danang",
    endCity: "Danang",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Danang - Hoi An (L/- )",
    details:
      " Meet on arrival at Danang International airport and visit Cham Museum, Marble Mountains, and Da Nang Beach before proceeding to Hoi An Ancient Town. Have lunch at a local restaurant - Discover the historic town was a prosperous seaport city during the 16th to 18th centuries. Its sizeable community of foreign merchants created a unique architectural style from Vietnamese, Chinese, and Japanese influences. You will have a walking tour of the Chinese Assembly Hall & Temples, ancient houses & Japanese Covered Bridge. Dinner and overnight in Hoi An. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Hoi An - My Son – Hoi An (L/-)",
    details:
      "Breakfast at the hotel. Full-day excursion to My Son Holy Land of the former Cham Kingdom (the ruins of the former Cham Capital in the 4th century), Vietnam’s most evocative Cham site recently named by UNESCO as a World Heritage site. The Cham people thought the shadow of Cat’s Tooth Peak was the domain of the gods and the religious and intellectual center of the culture. They inhabited the area continuously from the late fourth century A.D. until the 13th century. Excavations reveal kings’ tombs that date back to the fourth century. The towers are more than 1,300 years old and were built with bricks and carved as a final step. Have lunch at a local restaurant. 4Pm: Drive back to Hoi An and free for shopping. Have dinner and overnight in Hoi An. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Hoi An - Danang - Departure",
    details:
      "At leisure time until transfer to Danang airport for departure",
  },
];

const inclusionData = [
"All transfers and excursion per tour programs",
"Good English speaking guide",
"Sightseeing fees & boat rides as mentioned in itinerary",
"Meals as specified in program",
];

const exclusionsData = [
"International airfares",
"Personal expenese, drinks, ... and the others not mentioned above.",
];

const VC1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
