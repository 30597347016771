import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O10Image} from "../image";

const heroTextData = [
  {
    title: "Odisha Dream Destination-i",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Bhubaneswar ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar-Puri (70kms/1.5hrs)",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.",

    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header:  "Excursion trip to Chilika(Satapada)&Konark",
    details:
      "Post breakfast excursion trip to Satapada (One end of Chilika Lagoon) (55km/1.25hrs one way). Enjoy the motor boat ride in the largest brakish water lagoon of Asia and explore Sea mouth, Rajhans Island (drive pass), rare Irrawady dolphins, local birds etc. Later proceed to Konark (100km/2hrs). Enjoy the marine drive through Chandrabhaga beach and Ramchandi temple. On arrival visit the Sun temple & interpretation centre at Konark (the UNESCO World heritage monument). You may witness the light & sound show at Konark(subject to weather condition and close on Monday). Back to Puri. O/N- Puri. Note: 1. Konark Sun temple remain open from 08.00 to 18.00hrs. Light & sound show time: 18.30 to19.15hrs (Winter) & 19.00 to 19.40hrs (summer) and operate subject to weather condition and minimum 25-pax. The show remain close on every Monday.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: " Puri-Chilika-Gopalpur (225 kms/4.5 hrs) ",
    details:
      "After early breakfast visit to Niladri beach & Blue flag beach. Later proceed to Gopalpur, en-route visit through Mangalajodi-the bird’s Paradise of Asia (another end of chilika lagoon). Experience the country boat ride and explore birds sighting (local & migratory) in a very close by. (Winter is the best time to visit Mangalajodi) Or visit Kalijai temple and Kalijai Island from Barkul end of chilika lagoon. Later proceed to Gopalpur-on-sea. O/N-Gopalpur-on-sea.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Gopalpur-Bhubaneswar (200 kms/3.5 hrs) ",
    details:
      "Morning leisure at the virgin beach resort of Gopalpur-on-sea. Later proceed to Bhubaneswar. On arrival, visit Khandagiri&Udayagiri Jain Caves and Jain temple. Later you may visit to the local market for shopping. O/N-Bhubaneswar. \Note: 1.Be aware of Monkeys at Khandagiri&Udaigiri Jain caves. Keep your wallet & bags carefully. Udaigiriremain open from 08.00hrs to 17.00hrs ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Bhubaneswar local",
    details:
      "After breakfast visit to the ancient temples like Lingaraj-the biggest temple in Bhubaneswar (non-Hindu are not allowed inside the temple premises), Mukteswar, Rajarani& 64 Yogini temple (Yogini sculpture having 64 different hair style & one of the four Yogini temple in India). Later visit to Dhauli Buddhist shanti stupa, Tribal Museum/ Kala bhoomi-a craft museum (both close on Monday & govt. holidays). O/N-Bhubaneswar. Note: 1. Lingaraj temple remain open from 05.00 to 21.00hrs and close for 01hr between 11.00 to 13.00hrs. Devotees can go in to the garbhagriha (sanctum sanctorum) in between 06.00 to 08.00hrs & 13.00 to 16.00hrs. 2. Non-Hindu are not allowed in to the Lingaraj temple. They can see from the specific platform.",
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Bhubaneswar departure",
    details:
      "After breakfast proceed to Bhubaneswar.  NandankananZoological Parkremain open from 08.00hrs to 17.00hrs. Being a sanctuary Zoo it takes minimum 03-04hrs to cover. ",  
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O10 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O10Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O10;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

