import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  AG1Image,
  AG2Image,
  AG3Image,
  AG4Image,
  AG5Image,
  AG6Image,
  AG7Image,
  AG8Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const AZERBAIJANANDGEORGIA_TOUR = "/assets/img/worldTour/wt4.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Baku",
    image: AG1Image,
    description:"",
    path: "/ag1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Baku",
    image: AG2Image,
    description:"",
    path: "/ag2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Baku",
    image: AG3Image,
    description:"",
    path: "/ag3",
  },
  {
    id: 4,
    fromToDays: "03 Nights / 04 Days",
    title: "The best of Georgia",
    image: AG4Image,
    description:"",
    path: "/ag4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "The best of Georgia",
    image: AG5Image,
    description:"",
    path: "/ag5",
  },
  {
    id: 6,
    fromToDays: "05 Nights / 06 Days",
    title: "The best of Georgia",
    image: AG6Image,
    description:"",
    path: "/ag6",
  },
  {
    id: 7,
    fromToDays: "05 Nights / 06 Days",
    title: "Azerbaijan",
    image: AG7Image,
    description:"",
    path: "/ag7",
  },
  {
    id: 8,
    fromToDays: "07 Nights / 08 Days",
    title: "Azerbaijan & Georgia",
    image: AG8Image,
    description:"",
    path: "/ag8",
  },
];

// const rulesData = [
//   ""
// ];

const AzerbaijanAndGeorgia = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Azerbaijan And Georgia"}
            subTitle={"Amazing Holidays to Azerbaijan And Georgia"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={AZERBAIJANANDGEORGIA_TOUR}
            title={"Popular Azerbaijan And Georgia Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default AzerbaijanAndGeorgia;










