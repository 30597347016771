import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { IS2Image } from "../image";

const heroTextData = [
  {
    title: "Israel & Jordan Package",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jerusalem ",
    endCity: "Amman",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Tel Aviv",
    details: "Welcome to Israel! Upon arrival, you will be transferred to your hotel. The rest of the day is free for you to explore the city on your own. Overnight in Tel Aviv. (D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Tel Aviv - Caesarea - Haifa  - Acre - Galilee",
    details: "Today after breakfast, head to Jaffa for a short walking tour in the picturesque alleys of the old fortified town. Proceed north to Caesarea to visit the ancient port, Roman Theater and Hippodrome. Later continue to Haifa for an orientation tour of the city. Visit the Bahai Shrine, Persian Garden, Mount Carmel and Haifa Bay. Later you will be driven to Acre for a visit of the old, famous Crusader stronghold and medieval fortifications before reaching Galilee. Overnight in Galilee. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Galilee (Golan Heights –Around Sea of Galilee)",
    details: "Today after breakfast, ascend to the Golan heights passing by Druze Villages. Visit a former Syrian fortification on the heights. Pass by Banias, source of the Jordan river to visit the Mount of Beatitudes overlooking the Sea, where Jesus preached the Sermon on the Mount and it is believed that Jesus picked his 12 apostles. Later, visit the ruins of the ancient Synagogue and Peter's house at Capernaum, Tabgha, just north of Tiberias, the site of Christ's miracle of feeding the 5,000 and at the Church of Multiplication, where the mosaic floor depicts a basket with loaves and two fishes. Later you will be driven to Tiberias for a short tour of the old center of Jewish learning and later return to your hotel. Overnight in Galilee. (B,D)",
  },
  {
    id: "Day 4",
    header: "Galilee – Nazreth – Beit Shean - Jerusalem",
    details: "Today after breakfast, proceed to Safed city of the Kabala for a walk through the mystic alleys, a visit to a synagogue and the artists' colony. Next proceed to Nazareth, where Jesus spent his childhood, and the Church of Annunciation, built on the site where archaeologists believe the Archangel Gabriel appeared to the Virgin Mary. Later travel to the Jordan Valley to Beit Shean to visit the excavation of this beautifully preserved Roman City. Pass by Jericho - the oldest city in the world before you reach Jerusalem. Overnight in Jerusalem. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Jerusalem (New City)",
    details: "Today after breakfast, start the day with sightseeing of the Jerusalem new city. You will proceed to Mt Scopus for a panoramic view of Jerusalem. Later proceed on to the Garden of Gethsemane and Church of the Agony. Later continue to Israel Museum's Shrine of the Book, then a visit Herodian city of Jerusalem. Proceed to Yad Vashem and visit Ein Karem, the birth place of John the Baptist.. Overnight in Jerusalem. (B,D)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Jerusalem (Old City)",
    details: "Today after breakfast, enjoy sites of the Old City of Jerusalem. Enter through one of the gates to the walled old city to visit the renewed Jewish Quarter, the Western Wall, The Temple area and the colourful bazaars. Walk along the Via Dolorosa and the Stations of the Cross ending at the Church of the Holy Sepulcher. Later you will be driven to Mt. Zion and visit the tomb of King David, the Room of the Last Supper and Dormition Abbey. In the afternoon visit Bethlehem to visit the Church of the Nativity. Overnight in Jerusalem. (B,D",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
  id: "Day 7",
  header: "Jerusalem – Sheikh Hussein - Amman",
  details: " DAY 1 Sheikh Hussein (Jordan Side) - Amman Hotel DAY 2 Amman (Citadel, Roman Theater Visit) - Jerash (Visit) - Amman Hotel DAY 3 Amman - Madaba (Visit) - Mount Nebo (Visit) - Kerak (Visit) - Petra Hotel DAY 4 Petra (Visit) - Petra Hotel DAY 5 Petra - Wadi Rum (Visit) - Amman Hotel DAY 6 Amman - Amman Airport",
},
];

const inclusionData = [
"Transfer from Airport upon arrival and departure.",
"Sightseeing in modern air conditioned private transfer.",
"English speaking licensed guide on duty for 5 days",
"Entrance fees to places of interests whilst on tour in the Holy Land and parking.",
"Accommodation in double occupancy based on B.B. mentioned above or similar",
"Hotel Portage",
];

const exclusionsData = [
"Tips for guide and driver, $4 per person per day for the guide, $3 per person per day for the driver.",
"All personal expenses.",
"Lunches and dinners.",
"Flights/Bridge expenses.",
"Anything not mentioned above.",
];

const IS2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={IS2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default IS2;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
