import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { BA6Image } from "../image";

const heroTextData = [
  {
    title: "Kuta and Ubud Package",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Kuta",
    endCity: "Ubud",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Transfer",
    details: "Arrival Transfer Ngurah Rai International Airport to Kuta Hotel on Private Basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Full Day Benoa Beach With Uluwatu Sunset Tour",
    details: "Full Day Benoa Beach With Uluwatu Sunset Tour An ideal Bali holiday is incomplete without water sports & what better than the famous Tanjung Benoa beach. This beach best known for its aquatic adventure is located in the Benoa peninsula far away from the hustle & bustle. North of Nusa Dua this beach offers a wide range of water sports from Para-Sailing to Banana boat ride & so on. Please check with our representatives for all the water sports information. Many shacks & restaurants lined up along the beach make sure that your tummy is full & you enjoy the most. A great southern Bali trip in the evening introduces you to the southern part of the island. The beautiful Uluwatu temple, built on the top of a cliff about 825 feet high facing the vast deep blue Indian Ocean also offers a wonderful view of the sunset. In the late afternoon proceed to the southern tip of Bali for Uluwatu Sunset tour. Uluwatu Temple, or Pura Luhur Uluwatu, is renowned for its magnificent location, perched on top of a steep cliff approximately 70 metres above the waves. As one of the 6 key temples considered to be Bali's 'spiritual pillars', Uluwatu also offers a splendid sunset backdrop. It is definitely one of the top places in Bali for witnessing a delightful sunset, with direct views overlooking the beautiful waves of the Indian Ocean and daily Kecak dance performances (Entrance not included) held at an amphitheatre nearby. Balinese architecture, traditionally-designed gateways, and ancient sculptures all add to Uluwatu Temple's appeal. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Bali Safari & Marine Park",
    details: "Bali Safari & Marine Park Home to over a thousand amazing animals - Bali Safari Park is your destination for an adventurous, fun, educational experience more than just a safari. The park represents more than 120 species, including rare & endangered species - the Komodo dragon, Orang-utan, and the Bali Starling bird. You can watch the animals roaming freely in their natural designed habitat while you are hopping on to a special Bus for the Safari Journey! This bus literally transports you right to their natural habitats where you can spot deer, African hippos, and wildebeests roaming freely. Indulge in the wildest fine dining experience with the pride of lions, Meer cats, porcupines and experience one and only close up with the giraffe at the Tsavo Lion restaurant, or splash around with family and friends at our Water Park and enjoy the ride at Fun Zone! Bali Safari Park is also at the frontline of wildlife conservation in Indonesia. They are actively involved in ensuring the future survival and wellbeing of many Indonesian animal species. ",
  },
  {
    id: "Day 4",
    header: "Nusa Penida Tour",
    details: "Nusa Penida Tour A Full day tour to Nusa Penida by Speed Boat will give you an experience you will never forget. This is a relatively undeveloped island south-east of the mainland of Bali. Nusa means “island” and Penida means “priests” in Balinese. You won’t find the snazzy beach clubs, organic cafes or trendy restaurants here, but Nusa Penida is still worthy of a visit. It houses some of the most photogenic sights in Bali. Nowadays, Nusa Penida is known mostly for Kelingking Beach, Angel’s Billabong and Broken Beach, but you can still visit temples where the dark spirit is worshipped – and kept at bay. The easiest and most popular option is to hop on a speedboat from Sanur Beach. Go tripping, relax or just get clicking for your Insta feed, you will only have fun here. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Inter Hotel Transfer + Bali Swing Tour",
    details: "Inter Hotel Transfer + Bali Swing Tour Swings in Bali are the most trending activities across all social media platforms. And the experiences definitely justify the popularity. Set in Ubud you must try and enjoy this incredible experience. A professional swing guide, will company you to catch the unforgettable memory. Proper set of instructions will guide you through the equipment & safety standards. Soak in the pleasure of being a free bird, reminiscing through your childhood days. Continue further with a guided tour of the Coffee plantation & you will be briefed about the one of the world’s most expensive Kopi Luwak. An evening well spent will help you relax with great memories. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Kintamani Volcano Tour with Ubud Village",
    details: "Kintamani Volcano Tour with Ubud Village A visit to Kintamani Volcano is a rare opportunity to experience one of Bali’s last remaining active volcanos up close. This is one of the most popular and best day trip in Bali. The tour begins with the sights of a vibrant art village. Here you can meet with the people who keep the techniques of the regions handicrafts alive with a stop at Tohpati village, widely known as the centre of handmade Balinese Batik weaving. Next stop is Celuk village famous for producing high-grade gold and silver handicrafts. Continue trip to Mas Village, one of the many artists villages surrounding Ubud, is the best place on the island to find high-quality Balinese woodcarvings, considered some of the best woodcarvings in the world. The tour continues to the Mount & Lake Batur in Kintamani village for some stunning views of the beautiful locales in this region. Your tour ends in one of the most famous coffee plantations in the area. Here you can look & taste many kinds of coffees from Indonesia. Drive past the Tegalalang village, with some of the most beautiful views of rice terraces. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure",
    details: "Departure Transfer Ubud Hotel to Airport on Private Basis",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Meet & Greet assistance at the Airport",
"06 Nights Accommodation on BB basis",
"Return Airport Transfer on Private basis",
"Half Day Benoa Beach Tour including Banana Boat on private basis",
"Full Day Kintamani Volcano Tour with Ubud Village + Bali Swing on Private Basis",
"Full Day Tanah Lot Temple followed by Uluwatu Temple tour on private basis",
"VAT in Indonesia and all other taxes",
];

const exclusionsData = [
"Airfare, Airport Taxes, meals not mentioned, any cost of personal nature, any cost not mentioned in the ‘Cost Includes’ section",
"5% GST on the total invoice & TCS declaration if payment received in India, Remittance charges of USD 35 if payment received",

];

const BA6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={BA6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default BA6;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
