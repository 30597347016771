import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { E4Image } from "../image";

const heroTextData = [
  {
    title: "Egypt Ancient Treasures Culture segment (Cairo/Alexandria)",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Cairo",
    endCity: "Cairo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival to Cairo international airport",
    details: " Arrival to Cairo international airport, meet and assist at the APT through one of our Airport guides.Transfer to Cairo hotel.Dinner at the Hotel.Overnight at Cairo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Full day Pharonic Egypt Tour",
    details: "Breakfast at the Hotel - Full day Pharonic Egypt Tour - Back to the hotel for refreshments (Free Time) - Optional Sound and light show - Dinner & Overnight at the hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Alexandria for over day with one of our professional Egyptologists",
    details: "Early Breakfast (takeaway box) - Transfer to Alexandria for over day with one of our professional Egyptologists * (English Language) - Afternoon Lunch (Sea Food Lunch) - visit El Montaza Palace - Transfers Back to Cairo Hotel (around 3 hours) – by bus - “Omar Oasis rest*” for 30 min - Dinner at Oriental Restaurant - Overnight at the hotel",
  },
  {
    id: "Day 4",
    header: "Full day Old Cairo & Egyptian Museum Tour",
    details: "- Breakfast at the hotel - Full day Old Cairo & Egyptian Museum Tour - Overnight at the Hotel Pharonic Egypt Tour * (9:00 am Visit Pyramids and Sphinx + 11:30 Lunch at Pyramids Area + 12:30 am Shopping (Papyrus & Natural Oils) + 14:00 afternoon Sakkara) Alexandria for over day with one of our professional Egyptologists * Visit Qaytbai Citadel + 11:30 afternoon Bombay column + 13:30 afternoon Alexandria Library from Outside",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Full day Old Cairo Tour",
    details: " Breakfast at the hotel - Full day Old Cairo Tour - Dinner at oriental restaurant - Overnight at the Hotel Full day Old Cairo Tour * (9:30 Citadel of Saladin + 12:00 visit El-Moazz Street and El-Akmar Mosque and Fatimid School + 13:30 Lunch at Mahoney Restaurant + 16:30 City tour at Khan El-Khalily) Full day Old Cairo & Egyptian Museum Tour * (9:30 Egyptian museum + 12:30 Lunch by the River Nile + 13:30 pm Old Cairo area (Coptic & Islamic) + 19:00 Nile Cruise Dinner",
  },
  {
    id: "Day 6",
    header: "Transfer to Cairo Airport",
    details: "Breakfast at the Hotel and Check out - Transfer to Cairo Airport for Departure",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];


const inclusionData = [
"Accommodation as per program",
"Transfers and sightseeing by A/C coach",
"All Excursions and Tours with English speaking guide except optional tours.",
"One bottle of Mineral Water per person per day during the tour",
];

const exclusionsData = [
"Tips",
"Domestic Flights and International flights",
"Entry Visa will cost 45.00 USD each per each passport. Any Extras",
];

const E4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={E4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default E4;

// const intineraryTimelineData = [
  // {
  //   id: "Day 1",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 2",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
  // {
  //   id: "Day 3",
  //   header: "",
  //   details: "",
  // },
  // {
  //   id: "Day 4",
  //   header: "",
  //   details: "",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
// ];
