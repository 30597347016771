import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const FEEL_HAPPY_AT_HIMACHALL_TOUR =
  "/assets/img/domesticTour/himachal/Feel_Happy_at_Himachal.png";

const heroTextData = [
  {
    title: "Feel Happy at Himachal",
    duration: "05 Nights / 06 Day",
    typoOfTrip: "Road Trip",
    startCity: "Delhi ",
    endCity: "Delhi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Delhi Arrival",
    details:
      "Pick up from Delhi Airport and Proceed Towards Shimla. By Evening Reached and Check in to hotel. Dinner and stay at Shimla Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "- Shimla - Kufri - Shimla",
    details:
      "After Breakfast Excursion to Kufri. Kufri is famous for its Himalayan National Park, Pony and Yak Ride and One can see the endless Himalayan Panorama from Kufri, after lunch sightseeing of various places in and around. Shimla mosques built in (1830), Vice regal Lodge built in (1885), Christ Church built in (1844), The Gaiety Theatre- the first beauty contest of world was supposed to start (1887), The Grind lays Bank (1912)- the world-wide bank started by Robert Melville Grind lay, The Scandal Point and The famous Shopping Plaza of the North The Mall. Overnight stay at Shimla",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Shimla - Manali",
    details:
      "After Breakfast, Check Out from Hotel and Proceed towards Manali. En-route visit Mandi, Sunder Nagar, Kullu Rafting Point Etc. Dinner and Stay in Manali Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Manali - Solang valley",
    details:
      "Excursion to Solang Valley. Situated. It affords a wide panoramic view of the mountains. Here one sees the majesty of the mountains at its height and splendor. At the top of the pass the air seems to glitter against the snow as you look down over herringboned ridges into the Lahaul valley. Evening at Leisure. Overnight stay at Manali.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Local Manali Sightseeing",
    details:
      "After breakfast sightseeing of places in and around Manali, Like Hadimba temple, Manu temple, Vashisht Bath, Van Vihar, Club House and evening shopping at the local market. Overnight stay at Manali",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure",
    details:
      "Today morning after breakfast check out from the hotel, by cab you will be proceed for Delhi. Arrive Delhi in the evening time and you will be drop at railway station / airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Shimla Mall Road, Kufri",
  "Christ Church, Jakhu Temple, Lakkar Bazaar",
  "Hadimba Temple, Manali Mall Road",
  "Vashisht Temple, Nehru Kund",
  "Van Vihar, Solang Valley, Kullu, Shawl Factory",
  "Pick up and drop from Delhi",
  "All transfer and sightseeing included.",
  "MAP (Breakfast and Dinner)",
];

const exclusionsData = [
  "All personal expenses, optional tours and extra meals.",
  "Surcharges as applicable due to peak season, surcharge dates, trade fair dates.",
  "Camera fees, alcoholic/non-alcoholic beverages and starters.",
  "Vehicle service on leisure days for sightseeing not included in the itinerary.",
  "Mandatory Christmas/New Year Eve dinner cost will be applicable as advised by the hotels.",
  "Any services not mentioned in the inclusions list.",
  "Services of Tour Manager are not included in this package.",
  "Tips and porter-age.",
  "Tourism tax is excluded wherever applicable.",
  "Tips are excluded wherever applicable.",
];

const FeelHappyatHimachal = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={FEEL_HAPPY_AT_HIMACHALL_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default FeelHappyatHimachal;
