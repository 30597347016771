import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { W20Image } from "../image";

const heroTextData = [
  {
    title: "Eastern Himalayan 3 Jewels",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Darjeeling ",
    endCity: "Darjeeling ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "NJP Rly Station / IXB Airport - Darjeeling (75 Kms. / 3 Hrs.) ",
    details:
      "Meet and Greet on arrival at New Jalpaiguri Railway Station / Bagdogra International Airport and transfer to Darjeeling (6,950 Ft.). On arrival check-in to the hotel and rest of the day at leisure. Overnight stay at Darjeeling.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Darjeeling Full Day Sightseeing with Tiger Hill",
    details:
      "Early morning (4:00 AM) visit Tiger Hill (Subject to availability of Permit / Token) back to hotel. After breakfast start at 8.30 AM visit Himalayan Mountaineering Institute, P.N. Zoological Park (Thursday closed), Tenzing Rock, Ropeway, Tibetan Refugee self-help Centre (Sunday closed), Tea Garden (outer view), Japanese Temple, Ghoom Monastery, Batasia loop. Evening free for shopping or leisure. Overnight stay at Darjeeling.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: " Darjeeling - Pelling (80 Kms. / 4 Hrs.)",
    details:
      "After breakfast, transfer to Pelling (6,300 Ft.). On arrival check-in to hotel and rest of the day at leisure. Overnight stay at Pelling.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Pelling Full Day Sightseeing with Skywalk",
    details:
      "After breakfast, start for a full day sightseeing tour. In first half, cover Darap village, Rimbi waterfalls, Khecheopalri Lake (Wishfulling Lake) and Khangchendzongha waterfalls. In the second half cover the Pemayangtse monastery, Rabdantse ruins (2nd capital of Sikkim), Sang-Ngag Choling (Skywalk), New helipad ground.  Evening free for leisure. Overnight stay at Pelling.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Gangtok - Pelling via Namchi (150 Kms. / 7 Hrs.)",
    details:
      "After breakfast, start for Pelling (6,300 Ft. / 120 Kms. / 5 Hrs.) via Namchi to visit Siddheshwar Dham (Chardham) and Sai Temple. On arrival check-in to your hotel. Overnight stay at Pelling.",
  },
  {
    id: "Day 6",
    header:
      "Excursion to Tsomgo Lake and Baba Mandir (53 Kms. / 3 Hrs. one-way)",
    details:
      "After breakfast, start for an excursion to Tsomgo Lake (12,400 ft. / 37 Kms. / 02 Hrs. from Gangtok) and further drive to Baba Mandir (13,200 ft. / 16 Kms. / 01 Hrs. from Tsomgo Lake). Evening back to Gangtok. Overnight stay at Gangtok.",
  },
  {
    id: "Day 7",
    header: " Gangtok Full Day Local Sightseeing",
    details:
      "Start for a Full day city tour covering Tashi view point, Ganesh Tok, Hanuman Tok, Flower show, Cottage Industry and Handicraft Centre (Closed on Sunday and 1st and 3rd Saturday of the month), Institute of Tibetology (Closed on Sunday), Dro-dul Chorten, Gonjang Monastery and Banjakhri Falls (maximum 6 hours). Overnight stay at Gangtok.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Gangtok - NJP Rly Station / IXB Airport (128 Kms. / 5 Hrs.)",
    details:
      "After breakfast, checkout from the hotel and take the departure transfers to New Jalpaiguri Railway Station / Bagdogra International Airport for your onward journey.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on twin Sharing Basis. ",
  "Meal Plan (as per mentioned above).",
  "Exclusive Non-a/c vehicle for transfers & sightseeing. Please brief to guest that vehicle will not be at disposal it will be available to guest as per itinerary only (point to point basis).  ",
  "All permit fees & hotel taxes (as per itinerary).",
  "Rates are valid for INDIAN NATIONALS only.",
];

const exclusionsData = [
  "Air Fare / Train fare. ",
  "Personal Expenses such as Laundry, telephone calls, tips & gratuity, mineral water, soft & hard drinks, rafting, rock climbing, paragliding, joy ride (Toy Train), Ropeway, porterage.",
  "Additional sightseeing or extra usage of vehicle, other than mentioned in the itinerary. ",
  "Entrance Fees & Guide charges. ",
  "Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot). ",
  "Any increase in taxes or fuel price, leading to increase in cost on surface transportation & land arrangements, which may come into effect prior to departure. ",
  "Room Heater Charges.",
  "Insurance. ",
  "Anything which is not included in the inclusion",
];

const W20 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={W20Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default W20;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
