import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  NE1Image,
  NE2Image,
  NE3Image,
  NE4Image,
  NE5Image,
  NE6Image,
  NE7Image,
  NE8Image,
  NE9Image,
  NE10Image,
  NE11Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const NEPAl_TOUR = "/assets/img/worldTour/wt20.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Kathmandu",
    image: NE1Image,
    description: "",
    path: "/ne1",
  },
  {
    id: 2,
    fromToDays: "03 Nights / 04 Days",
    title: "Kathmandu & Nagarkot",
    image: NE2Image,
    description: "",
    path: "/ne2",
  },
  {
    id: 3,
    fromToDays: "03 Nights / 04 Days",
    title: "Kathmandu With Chandagiri Hills",
    image: NE3Image,
    description: "",
    path: "/ne3",
  },
  {
    id: 4,
    fromToDays: "04 Nights / 05 Days",
    title: "Kathmandu,Chandragiri Hills & Nagarkot",
    image: NE4Image,
    description: "",
    path: "/ne4",
  },
  {
    id: 5,
    fromToDays: "04 Nights / 05 Days",
    title: "Kathmandu & Pokhara",
    image: NE5Image,
    description: "",
    path: "/ne5",
  },
  {
    id: 6,
    fromToDays: "05 Nights / 06 Days",
    title: "Kathmandu & Pokhara",
    image: NE6Image,
    description: "",
    path: "/ne6",
  },
  {
    id: 7,
    fromToDays: "05 Nights / 06 Days",
    title: "Kathmandu,Pokhara & Nagarkot",
    image: NE7Image,
    description: "",
    path: "/ne7",
  },
  {
    id: 8,
    fromToDays: "07 Nights / 08 Days",
    title: "Kathmandu,Pokhara & Chitwan Surface",
    image: NE8Image,
    description: "",
    path: "/ne8",
  },
  {
    id: 9,
    fromToDays: "07 Nights / 08 Days",
    title: "Kathmandu,Pokhara & Nagarkot",
    image: NE9Image,
    description: "",
    path: "/ne9",
  },
  {
    id: 10,
    fromToDays: "07 Nights / 08 Days",
    title: "Kathmandu,Pokhara & Chitwan Surface",
    image: NE10Image,
    description: "",
    path: "/ne10",
  },
  {
    id: 11,
    fromToDays: "07 Nights / 08 Days",
    title: "Kathmandu,Chitwan,Pokhara & Nagarkot",
    image: NE11Image,
    description: "",
    path: "/ne11",
  },
];


// const rulesData = [
//   ""
// ];

const Nepal = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Nepal"}
            subTitle={"Amazing Holidays to Nepal"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={NEPAl_TOUR}
            title={"Popular Nepal Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Nepal;
