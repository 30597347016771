import React from "react";
import { Box, Container, Grid, Card } from "@mui/material";
import SimpleMap from "./SimpleMap";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

const ContentSection = () => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <SimpleMap />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ContactForm />
            <ContactInfo />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContentSection;
