import { React } from "react";
import {
  Box,
  Button,
  Toolbar,
  Typography,
  CardMedia,
  Grid,
  Container,
  IconButton,
  CardActionArea,
  Card,
} from "@mui/material";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import CallIcon from "@mui/icons-material/Call";
import { Navigate, useNavigate } from "react-router-dom";
import MenuButton from "./MenuButton";

const RADHA_TOUR_LOGO = "/assets/img/rada_tours_logo-transparent.png";
const RADHA_TOUR_MOBILE_LOGO =
  "/assets/img/rada_tours_logo-transparent_mobile.png";
const Header = ({ leftLink, rightLink, onClick, link, navItems }) => {
  const navigate = useNavigate();

  return (
    //  <Container>
    <Toolbar style={{ padding: "0px" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ display: { sm: "none" } }}
      >
        <Grid item>
          <CardActionArea href="/home">
            <img
              src={RADHA_TOUR_LOGO}
              alt="logo"
              style={{ width: 56, height: 36 }}
            />
          </CardActionArea>
        </Grid>
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={onClick}
            // sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
      >
        <Grid item>
          <CardActionArea href="/home">
            <Card sx={{ maxWidth: 256, boxShadow: 0, p: 1 }}>
              <CardMedia
                component="img"
                image={RADHA_TOUR_LOGO}
                height="56px"
              />
            </Card>
          </CardActionArea>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Grid container spacing={1}>
                {navItems.map((item) => (
                  <Grid item>
                    <Button
                      variant="text"
                      key={item.id}
                      href={item.href}
                      // sx={{
                      //   color: "button.light",
                      //   // bgcolor: "secondary.main",
                      //   // color: "secondary.light",
                      //   "&:Hover": {
                      //     color: "secondary.light",
                      //     bgcolor: "secondary.main",
                      //   },
                      // }}
                    >
                      {item.title}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <MenuButton />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                href={"/contact-us"}
                // sx={{
                //   color: "button.light",
                //   // bgcolor: "secondary.main",
                //   // color: "secondary.light",
                //   "&:Hover": {
                //     color: "secondary.light",
                //     bgcolor: "secondary.main",
                //   },
                // }}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
    //  </Container>
  );
};

Header.prototype = {
  leftLink: PropTypes.string,
  link: PropTypes.string,
  rightLink: PropTypes.string,
  onClick: PropTypes.func,
  navItems: PropTypes.func,
};

export default Header;
