import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const COLORFUL_KASHMIR_TOUR =
  "/assets/img/domesticTour/kashmir/Kashmir-Colorful.png";

const heroTextData = [
  {
    title: "Colorful Kashmir",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Srinagar",
    endCity: "Srinagar",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Srinagar | Srinagar Local Sightseeing",
    details:
      "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Srinagar – Doodhpathri – Srinagar",
    details:
    "Doodhpathri is also known as the 'valley of milk' which is located 40 km away from Srinagar. After a fulfilling breakfast, you would be taken to Doodhpathri, a tourist spot well known for the grazing ground of the sheep and a strikingly beautiful cascading waterfall. You may touch the river with your feet and wander about in the heart of those valleys. You will be then back to the hotel for a dinner session and an overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Srinagar – Sonmarg – Srinagar",
    details:
      "Today you embark on a full day excursion to the picturesque 'meadow of gold’ Sonamarg. Sonamarg is last stop of Kashmir district. From here starts the territory of ladhakh. Situated at an altitude of 2743m above sea level. The fast flowing Sindh River makes it popular for trout and masher fishing in summer. You can opt for a pony ride to one of the glaciers for some fun in snow and ice (by your own payment).",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Srinagar – Gulmarg",
    details:
      "In the morning drive to Gulmarg, the meadow of flowers. The route from Srinagar to Gulmarg is fascinating. You will be mesmerized by its beauty. Gulmarg is famous all over the world for Skiing and Snowboarding. During the British Raj, Gulmarg hosted the Britishers and Europeans and as such they created a European hill resort and named it Gulmarg. In Gulmarg you would be able to find European surroundings and 18-hole Golf Course and enjoy Gondola Cable Car Ride world’s highest and the Asia’s longest cable car (at your own cost). Enjoy photography, horse riding and shopping at this beautiful snow point and enjoy gondola ride to Kongdoori & Appahrwhat. Overnight in Srinagar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Gulmarg – Pahalgam",
    details:
      "Early In The Morning Leave For Pahalgam The 'valley Of Shepherds' Which Is Situated At The Confluence Of The Streams Flowing From River Lidder And Sheeshnag Lake. En Route Visit The Awantipura Ruins, An 1100 Year Old Temple Dedicated To Lord Vishnu And Built By King Awantivarman. On Arrival Into Pahalgam You Will Proceed For Aru Valley, And Chandanwari By Car (At Your Own Cost). Aru Is Small Village, 11 Kms Upstream The Lidder River, Is A Beautiful Locality. The Route Is Picturesque With Ample Campsites. This Village Is The Starting Point For Treks To The Kolahoi Glacier .hajan And Beetab Valley On The Way To Chandanwari. Overnight In Pahalgam.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Pahalgam (Baisaran – Mini Switzerland)",
    details:
    "Baisaran Valley, located just 5 kilometers away from Pahalgam in the Anantnag district of Kashmir, is famous as a top sightseeing destination, called mini-Switzerland because of its picturesque beauty. Either you go by walk or by Horse Riding (on direct Payment). Some of the famous tourist points which you can see en route to Baisaran are Kanimarg, Pahalgam Old Village, Kashmir Valley Point, Dabyan and Deon Valley Point.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Pahalgam – Srinagar",
    details:
    "After a leisure breakfast, drive to Srinagar. Later in the evening do some local shopping. Check in to Houseboat and night halt at hotel in Houseboat at Dal Lake.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Departure From Srinagar",
    details:
      "Return Home with Happy Memories After breakfast you will be transferred to Srinagar Airport for your onwards journey with Everlasting Memories.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation in rooms as given at hotels in Srinagar and Pahalgam on Double sharing basis.",
  "1 Night stay at Houseboat",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Shikara Ride for 1 Hrs in Dal Lake.",
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera",
  "All Toll Taxes, Parking Charges & Driver Allowances included",
  "Assistance at all arrival and departure point.",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
];

const exclusionsData = [
  "Note for Gulmarg: Cost for Gondola Cable Car, Gulmarg: INR 800/- Per Pax for Level 1 & INR 1000/- Per Pax for Level 2 (Total INR 1800/- for both) you can book on link in advance to avoid last minute issue. https://www.jammukashmircablecar.com/",
  "Any Airfare, Train fare, Overland Journey which is not mentioned in the Itinerary.",
  "Any other item not specified in cost includes.",
  "Transfers from Tang Marg to Gulmarg to Tang Marg by chained vehicle in case of heavy snowfall.",
  "Aru Chandan Wari and Betaab valley Trip at Pahalgam. (It’s done by Union Taxi at Pahalgam and is on direct payment basis.)",
  "Thajwas Glacier Trip at Sonmarg in Summer Season. (It’s done by pony ride and is on direct payment basis.)",
  "Transfers from Gagangir to Sonmarg to Gagangir because our vehicle can’t go beyond Gagangir due to heavy snow in Winter Season. (It’s done by Union Taxi at Gagangir and is on direct payment basis.)",
  "Garden Entrance fees and guide charges, where ever applicable.",
  "Item of personal nature viz, tips, laundry, room service, telephone, alcoholic or non-alcoholic drinks",
  "Beverages, room heaters, boat rides, pony / horse rides, safaris, rafting, skiing, skating, cable car and Union Taxi.",
];

const ColorfulKashmir = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={COLORFUL_KASHMIR_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default ColorfulKashmir;
