import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { VC3Image } from "../image";

const heroTextData = [
  {
    title: "Danang - Hue - Hoi An",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Da Nang",
    endCity: "Danang",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Da Nang - Hue (D/-)",
    details:
      "Meet on arrival at Danang and directly transfer to Hue via Hai Van Pass (Pass of the Ocean & Clouds), passing through many pretty red-tiled villages with fruit orchards, and enjoying spectacular views of sky and sea. Enjoy a seafood lunch at Lang Co fishing village. Upon arrival transfer to the hotel for check-in. Visit Imperial Citadel, Imperial Museum and Dong Ba market. Dinner at a local restaurant. Overnight in Hue.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Hue (L/-)",
    details:
      "Breakfast at the hotel. Arrival visit Thien Mu Pagoda and Minh Mang tomb. Lunch at local restaurant followed by a visit to Tu Duc & Khai Dinh tombs. Dinner at local restaurant. In the evening: Free enjoy traditional folk music on boat. Overnight in Hue. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Hue - Danang - Hoi An (L/-)",
    details:
      "Breakfast at the hotel. Drive back to Danang - Upon arrival visit Cham Museum, Marble Mountains and Da Nang Beach before proceeding to Hoi An Ancient Town. Lunch at local restaurant - Discover the historic town which used to be a prosperous seaport city during the 16th to 18th centuries. Its sizeable community of foreign merchants resulted in a unique architectural style with Vietnamese, Chinese and Japanese influences. Walking tour in Hoi An to Chinese Assembly Hall & Temples, ancient houses & Japanese Covered Bridge. Dinner and Overnight in Hoi An.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Danang - Departure",
    details:
      "Breakfast at the hotel. Short city tour: Han River and market. Transfer to Danang airport for departure. (USD /Person) ",
  },
];

const inclusionData = [
"All transfers and excursion per tour programs",
"Good English speaking guide",
"Sightseeing fees & boat rides as mentioned in itinerary",
"Meals as specified in program",
];

const exclusionsData = [
"International airfares",
"Personal expenese, drinks, ... and the others not mentioned above.",
];

const VC3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={VC3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default VC3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
