import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { AR1Image, AR2Image, AR3Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const ARMENIA_TOUR = "/assets/img/worldTour/wt10.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Beauty of Armenia",
    image: AR1Image,
    description: "",
    path: "/ar1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Armenia Guaranteed Departure",
    image: AR2Image,
    description: "",
    path: "/ar2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Armenia Guaranteed Departure",
    image: AR3Image,
    description: "",
    path: "/ar3",
  },
];

// const rulesData = [
//   ""
// ];

const Armenia = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Armenia"}
            subTitle={"Amazing Holidays to Armenia"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={ARMENIA_TOUR}
            title={"Popular Armenia Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Armenia;
