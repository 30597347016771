import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { L3Image } from "../image";

const heroTextData = [
  {
    title: "Discover Ladakh",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Leh",
    endCity: "Leh",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Leh Arrival",
    details: "Arrival at Leh and we recommend you complete relax for the rest of the day to enable yourselves to acclimatize, as high-altitude sickness occurs when your body does not adjust due to lack of oxygen at the high altitude. O/n stay at Leh Hotel. Alts (3500Mtrs)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: " Local Sightseeing West Indus Valley: - Sangam-Pathar Sahib-Spituk-Hall of fame (140 km).",
    details: "After B/fast set off for Sham Valley sightseeing. En route visit Hall of Fame (War memorial) Spituk Monastery, Magnetic Hill, Zanskar & Indus River confluence (Sangam point) Gurudhwara pathar Sahib, Shanti Stupa and Leh Main Market Drop O/n stay at Leh Hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Leh to Nubra Valley via Khardong la Pass (126Km)",
    details: "After B/fast set off for Nubra valley via Khardong la paas (the world’s highest motorable road in the world) (18,640 feet) and o/n in stay at Nubra Tent/ Hotel. (3033Mtrs) places to visit are Diskit monastery &Hunder sand dunes",
  },
  {
    id: "Day 4",
    header: "Hunder- Turtuk- Hunder (160 Kms)",
    details: "After B/fast set off forA border village that was recaptured by the Indian Army in 1971. Locals have relatives across the border (Line of Control). Baltistan.A beautiful village with charming. A full day rough excursion evening back Hunder. After being settled in a hotel/ fixed Camp at Hunder/ Disket. 2nd Option: Or If weather Permit, 2nd route, that is to reach Pangong Lake directly from Nubra Valley.But it’s depending on the weather conditions ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: " Nubra- Pangong Lake via Shayok River – Pangong – Leh Back to Leh same day (314 Kms)",
    details: "After early B/fast set off for Pangong Lake Via Shayok River, Agyam Village and Tangtse , It is situated at alt: 14,000 ft. Pangong is 5-6Kms wide . Post Lunch drive back to Leh via Changala Pass- world’s 3rd highest motor able road) 17,350ft/5486 Mtrs. Later Kharu continue drive to Leh.",
  },
  {
    id: "Day 6",
    header: "Local Sightseeing East Indus Valley",
    details: "Hemis- Thiksay Monasteries- Rincho School- Leh Palace (101Kms). After b/fast set off for Hemis Monastery. En route visit Druk Pama Karpo (Rincho School), Thiksay monastery& Hemis Monastery explore Leh market for Shopping and have the day for Leisure. Overnight at hotel in Leh",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Departure",
    details: "Our teams thank you for your patronage and ensure a punctual drop to Leh Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodations on double/twin sharing basis as per package Selected.",
  "Meal Plan based on MAP (Room + Breakfast +Dinner) included in the package cost.",
  "All transport Services as per the itinerary by Non-A/C Crytsa Innova/Innova/ xylo / Ertiga. The same car shall do the complete itinerary (Maximum 6 persons including kids) are possible in one vehicle, for more than 6 please ask for Tempo Traveller).",
  "Meeting and Assistance on Arrival and Dept by our representative",
  "An experienced driver would work as a basic guide (You can hire a dedicated guide as well)",
  "Parking, toll, fuel, driver allowances.",
  "Inner Line Permits/Wild Life Fees. Except 11year old below",
  "01 Oxygen Cylinders will be available in the cars during Nubra Valley and Pangong Tour only. If you require Oxygen other than these days, ",
];

const exclusionsData = [
  "Any kind of Medical & Travel insurance.",
  "Expenses of personal nature such as Tips, Laundry, Telephone, Table Drinks & portage et",
  "Extra nights in hotel due to ill health, flight cancellation or any other reason",
  "Service of vehicle is not included on leisure days & after finishing sightseeing tour",
  "Adventure activity like Rafting, Camel Safari, Mountain Biking, Motor Biking etc",
  "Monastery entrance fees, camera fee, and monument guide service",
];

const L3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={L3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default L3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
