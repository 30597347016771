import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EU4Image } from "../image";

const heroTextData = [
  {
    title: "Central Europe",
    duration: "10 Nights / 11 Days",
    typoOfTrip: "Road Trip",
    startCity: "Zurich ",
    endCity: "Rome",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Zurich – Onto Lucerne",
    details: "Arrive Zurich airport. Board your train to Lucerne, the city of lights, surrounded by alpine lakes and mountains. Transfer to the hotel on your own.Day is free at leisure to explore the city on your own. Visit the Chapel Bridge, The Lion Monument, Swiss Transport Museum, the IMAX Theatre, Old City Squares or take a boat trip on Lake Lucerne. Overnight is at your hotel in Lucerne. Lucerne, the gateway to central Switzerland, sited on Lake Lucerne, is embedded within an impressive mountainous panorama. Thanks to its attractions, its souvenir and watch shops, the beautiful lakeside setting and the nearby excursion mountains of the Rigi, Pilatus and Stanserhorn, the town is a destination for many travel groups and individuals on their journey through central Switzerland.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Lucerne – Mt. Titlis",
    details: "Buffet breakfast at the hotel. A memorable experience awaits you as you depart on an excursion to the glacier paradise of Mount Titlis. Your journey today begins with a train ride from Lucerne station to Engelberg station. Walk to the Titlis base point and enjoy 3 different cable car rides up to the beautiful summit of Mt. Titlis. Ride up the world’s first ever 360 degree revolving ROTAIR aerial gondola to the Mt. Titlis Glacier Paradise at 10,000 ft. for unlimited attractions. You have free time to play and enjoy in the snow. Visit the Ice Flyer, an enclosed sky lift that travels few meters over the Titlis glacier. Overnight is at your hotel in Lucerne.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Lucerne - Interlaken",
    details: "Buffet breakfast at the hotel. Check out of your hotel and transfer on your own to the station to board your train to Interlaken, the gateway to the Top of Europe. Day at leisure to explore Interlaken’s Hoheweg- an inviting 700 meter long boulevard with hotels, cosy cafes, fashion boutiques, watch and jewellery stores. Overnight is at your hotel in Interlaken. Interlaken – A premier holiday destination, situated in the heart of the Bernese Oberland, is the ideal starting point for any number of excursions. The town is famous for its delightful location and the sites of natural beauty that surround it. It’s not unusual for a town to be on a river, lake or an ocean. But for it to sit between two lakes may well be unique. Interlaken - between Lake Thun and Lake Brienz - can boast such a privileged position. Straddling the glittering Lakes Thun and Brienz and dazzled by the pearly whites of Eiger, Mönch and Jungfrau, the scenery here is mind-blowing. Particularly, some say, if you’re abseiling waterfalls, thrashing white water or gliding soundlessly above 4000m peaks, its Interlaken at its best!! Once Interlaken made the Victorians swoon with mountain vistas from the chandelier-lit confines of grand hotels; today it makes daredevils scream with adrenalin-loaded adventures. ",
  },
  {
    id: "Day 4",
    header: "Interlaken – Mt. Jungfraujoch",
    details: "Buffet breakfast at the hotel. Depart on an excursion from Interlaken to Jungfraujoch- Top of Europe. This memorable train trip from Interlaken Ost takes you via Lauterbrunnen and Wengen to the Kleine Scheidegg, at the foot of the famous Eiger North Wall. Then ascend by the Jungfrau Railway to the magnificent mountain world of eternal ice and snow, Jungfraujoch-at 3454mts, the highest railway station in Europe. Visit the Ice Palace, where you see a beautiful display of sculptures made of ice and Sphinx Terraces offering breathtaking views of the Aletsch Glacier, the longest glacier in the Alps. Overnight is at your hotel in Interlaken.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Interlaken – Venice",
    details: "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station to board your train to Venice, via Brig. Welcome to Venice, the city of canals, the city of romance, architecture and culture situated in a Lagoon and accessible only by boat. The evening is at leisure to stroll around the wonderful canals or you have the choice to join a flotilla of gondolas gathered around a barge containing the musicians and singers to float along the fascinating Grand Canal. Overnight at your hotel in Venice.Venice is a beautiful city located in northeast Italy that is famous for its intricate transport system of interconnected canals. A perfect setting for a magical Valentines break. It has been described as the most beautiful city built by man and one of Europe’s most romantic cities. Venice is also known for its art, architecture and culture. So you’ll never be short of something to do in the City of Bridges.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Venice - Murano, Burano and Torcello Tour",
    details: "Buffet breakfast at the hotel. Proceed for the half day tour to Murano, Burano and Torcello. After sailing past the island of San Giorgio Maggiore, the Public Gardens, the tip of Sant'Elena and the famous Lido beach resort, the boat arrives in Murano, known throughout the world for its glass manufacturing industry. Here we stop for around 40 minutes to visit to one of the factories. The second stop is at the picturesque island of Burano, famous not only for its lace but also for the fishermen's houses painted in bright colours. The stop will last about 30 minutes. Then after a short cruise, we reach Torcello, the earliest centre of civilization in the estuary. Only the Cathedral with its magnificent mosaics and the church of Santa Fosca remain as testimony of its former glory. The stop will be for about 30 minutes then we return to St. Mark's Square. Overnight at your hotel in Venice.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Venice - Florence",
    details: "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station on own to board your train to Florence. Florence, the capital city of the Tuscany region is full of beautiful Renaissance-style buildings, including wonderful churches and enormous palaces. Overnight at your hotel in Florence. Florence (Firenze) is magnetic, romantic and busy. Its urban fabric has hardly changed since the Renaissance, its narrow streets evoke a thousand tales, and its food and wine are so wonderful the tag 'Fiorentina' has become an international label of quality assurance. Return time and again and you still won't see it all. Stand on a bridge over the Arno river several times in a day and the light, mood and view changes every time. Surprisingly small as it is, this riverside city is like no other. Cradle of the Renaissance and of tourist masses that flock here to feast on world-class art.",
  },
  {
    id: "Day 8",
    header: "Florence – Excursion to Pisa and visit of Leaning Tower",
    details: "Buffet breakfast at the hotel. Proceed for an excursion to Pisa and visit of Leaning Tower. This enchanting excursion will take you through the unforgettable Tuscan countryside to the marvelous city of Pisa, known all over the world for its impressive monuments and for its prestigious University. A professional guided walking tour will take you to the Piazza dei Miracoli, where you will be able to admire the Leaning Tower (exterior), the Baptistery (exterior) and other important monuments. You will enjoy then an interior guided visit of the Cathedral, real masterpiece of Romanesque art, built between the 11th and 12th centuries. The guided tour will end by the magnificent Leaning Tower: climbing to its top by mounting its 294 steps rising up in a spiral, will be one of the most amazing experiences you can ever try. The tower is 55.86 m tall (183.27 ft) from the ground on the low side and 56.70 m tall (186.02 ft) on the high side, around 3.99 degrees leaning. The weak and unstable subsoil caused this unique phenomenon, contributing to create one of the wonders of the world. Return back and overnight at your hotel in Florence.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Florence – Rome",
    details: "Buffet breakfast at the hotel. Check – out and proceed to the train station on own to board your train to Rome, the capital city of Italy. It’s hard to say what you find most breathtaking about the eternal city. Spend the evening at leisure or select one of our exciting optional tours. Overnight at your hotel in Rome. Rome is Italy’s capital and largest city, and while history reverberates all around, modern life is lived to the full. A trip to Rome is as much about lapping up the lifestyle as it is gorging on art and historic sights. Rome is the Eternal City, a treasure inside and out. The Colosseum, the Catacombs and the Pantheon are all among Europe's first tier of ancient sites. But Rome isn't just a gloriously ancient city. It's also a glorious medieval city, a glorious Renaissance city and a glorious modern city.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Rome – Hop on hop off, Open Bus Tour",
    details: "Buffet breakfast at the hotel. Proceed to Explore Rome at your own pace on an extensive hop on hop off itinerary through the heart of Rome. The buses are open top which allows you to enjoy full 360 degree panoramic views as you travel along your route. A recorded commentary in 8 languages via headphones will be at your disposal. Overnight at your hotel in Rome.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 11",
    header: "Depart Rome",
    details: "Breakfast at the hotel. Check – out and transfer to the airport on your own for your flight to onwards destination or back to India with memories which will last a life time. * The above itinerary is tentative and subjected to change without prior notice",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Accommodation on Bed & Breakfast Basis: (Daily breakfast except on the day of arrival)",
  "Paris City Tour and Cruise by Coach",
  "Entry Tickets to Eiffel Tower / Montparnasse Tower, without transfers (Any one. Eiffel Tower tickets are subject to availability. Incase of Non – Availability; Montparnasse Tower will be included",
  "Mt. Jungfraujoch (In Conjunction with Swiss Pass)",
  "Mt. Titlis (In Conjunction with Swiss Pass)",
  "The Zurich TROLLEY Experience - Zurich City Tour",
  "Swiss Travel Pass (Regular) for 8 days in Second Class",
  "Second Class train ticket: Paris to Basel",
];

const exclusionsData = [
  "Airfare, Airport Taxes, Porterage",
  "Visas & Overseas Travel Insurance",
  "Meals other than mentioned in the itinerary",
  "High Season, Trade Fair & Special Event surcharge",
  "Anything not specifically mentioned in the Price Includes section.",
];

const EU4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EU4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EU4;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
