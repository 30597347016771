import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const  KOVALAM_TRIVANDRUM = "/assets/img/domesticTour/kerala/KOVALAM_TRIVANDRUM.png";

const heroTextData = [
  {
    title: "Kovalam Trivandrum Alleppey Kumarakom Periyar Munnar Cochin",
    duration: "07 Nights / 08 Day",
    typoOfTrip: "Road Trip",
    startCity: "Kovalam",
    endCity:"Cochin"
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "From Trivandrum to Kovalam",
    details:"Our delegate will pick you up at the Trivandrum International Airport and drive you to Kovalam (15 km). Spend the night at recreation in the shoreline side.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Across Trivandrum",
    details:"Morning set out on a touring visit to Trivandrum. Your agenda will incorporate spots of interests like Padmanabhaswami sanctuary, prestigious for its transcending gopuras and sanctuaries expressions and compositions. The primary structure of the sanctuary stands noteworthy with 400 superbly stone cut columns. Your guided touring visit will cover whatever remains of the attractions as well. Later at night make a trip back to Kovalam.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Destination Alleppey",
    details:"After a reviving breakfast, drive to Alleppey and register with houseboat. Set out on a captivating backwater voyage in generally improved houseboats. While cruising in these drifting extravagance manors, let your eyes make do with the uncommon and the bizarre – skim past Chinese angling nets, bowed-down paddy fields, nearby occupants occupied with making coir items, natural homes, antiquated sanctuaries and influencing coconut forests.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "To Kumarakom",
    details:"After breakfast drive to Kumarakom. Register with the lodging. Evening continues for neighborhood touring in and around Kumarakom – a modest backwater village roosted on the western banks of the Vembanadu Lake. The stretch of backwater is spread more than 900 sq. km.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header:
      "Destination Periyar",
    details:"After breakfast continue to Periyar, a very beautiful retreat perfect for loosening up. Upon entry you will be taken to resort. At night cool off and get revived getting a charge out of a vessel safari in the grand Periyar Lake, at times winning the look of some uncommon assortments of flying creatures and creatures at Periyar National Park. Come back to lodging for a medium-term remain.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Destination Munnar",
    details:"Next morning drive down to Munnar. On landing, register with the inn and unwind for whatever remains of the day.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Across Munnar",
    details:"After a reviving breakfast leave for Munnar touring. The spots to visit incorporate Mattupetty Dam, Kundale Lake and Echo point. Evening continue to Rajamala were you can detect the uncommon types of goat “Nilgiri Thars”.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Destination Cochin",
    details:"After early breakfast drive to Cochin (140 km). Evening touring of Cochin covering Fort Cochin, as yet keeping alive its old Jew custom, Mattancherry royal residence, the Santa Cruz Basilica, St. Francis Church and the sky is the limit from there. The Jew’s road and the Chinese angling nets at Fort Cochin shoreline are the must see. Night social show at Fort Cochin.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Departure",
    details:"Your tour comes to an end today. After breakfast, our executives will pick you up and drop you to the airport or railway station",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const  KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KOVALAM_TRIVANDRUM}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KovalamTrivandrumAlleppeyKumarakomPeriyarMunnarCochinTourPackages;
