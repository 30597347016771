import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { J3Image } from "../image";

const heroTextData = [
  {
    title: "Japan Golden Route 5N/6D",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Narita",
    endCity: "Osaka",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arr. Narita/Haneda",
    details: "On arrival Meet & Greet at Airport and transfer to Hotel Sightseeing of Uneo Park Transfer to Hotel Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: ": At. Tokyo",
    details: "Hotel Departure Sightseeing of Asakusa Sensoji & Nakamise Shopping Arcade Free Time at Tokyo, Sky Tree Sightseeing of Imperial Palace Plaza Shopping at Akihabara Shopping at Ginza Sightseeing of Tokyo Metropolitan Government Building Observatories Arrival at Hotel Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: " At. Tokyo",
    details: "otel Departure Transfer to Shinjuku station Proceed to Hakoneyumoto On arrival Drive to Mt. Fuji 5th Station En route Lunch at Local Restaurant Sightseeing of Ashi Lake cruise Hotel Check-in Enjoy Hot spring and Japanese food Overnight at Hotel",
  },
  {
    id: "Day 4",
    header: "At. Hakone",
    details: "Hotel Departure Transfer to Odawara Station Proceed to Kyoto On arrival Lunch at Local Restaurant Sightseeing of Kinkakuji Temple Hotel Check-in Sightseeing of Gion & Shirakawa area Dinner at Local Restaurant Arrival at Hotel Overnight at Hotel",
  },
  {
    id: "Day 5",
    header: "Osaka",
    details: "Breakfast at Hotel Drive to International Airport",
  },
  // {
  //   id: "Day 6",
  //   header: "At.Tokyo",
  //   details: "Breakfast at Hotel Drive to International Airport",
  //   labelData: ["label1", "label2", "label3", "label4"],
  // },
];

// const inclusionData = [""];

// const exclusionsData = [""];

const J3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={J3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default J3;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
