import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { IS1Image, IS2Image  } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const ISRAEL_TOUR = "/assets/img/worldTour/wt18.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "07 Nights / 08 Days",
    title: "Israel",
    image: IS1Image,
    description: "",
    path: "/is1",
  },
  {
    id: 2,
    fromToDays: "06 Nights / 07 Days",
    title: "Israel & Jordan Package",
    image: IS2Image,
    description: "",
    path: "/is2",
  },
];

// const rulesData = [
//   ""
// ];

const Israel = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Israel"}
            subTitle={"Amazing Holidays to Israel"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={ISRAEL_TOUR}
            title={"Popular Israel Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Israel;
