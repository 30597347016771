import { React } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const HeroContent = () => {
  return (
    <Box
      sx={{
        height: {
          xs: "30vh",
          sm: "30vh",
          md: "70vh",
          ls: "60vh",
          xl: "50vh",
        },
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              color: "text.light",
              textShadow: "1px 1px 2px black",
            }}
          >
          Cancellation Policy
          </Typography>
        </Grid>
        {/* <Grid item>
          <Button href="#contactus" variant="contained">
            Our Services
          </Button>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default HeroContent;
