import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KA4Image } from "../image";

const heroTextData = [
  {
    title: "Golf Tour to Almaty",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Astana",
    endCity: "Astana",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Day 1",
    details: "Arrival in Almaty, meeting at the airport and transfer to hotel Check-in (Standart time) Overnight at hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day 2",
    details: "Breakfast in hotel Transfer to golf course Enjoy golf at wonderful place «Nurtau Golf Club» NURTAU is the first 18-hole Golf Club in Kazakhstan. It is located in the foothills of the Zailiysky Alatau, the incredible beauty of the mountains. The first nine fields were designed and built by Japanese designer Saito. This Golf Club is interesting in its complexity for both beginners and professionals. For 7 years greenkeepers have been working to improve the quality of grass, and now foreign golfers visiting the club, note the excellent condition of the fairways and greens. During the construction of the fields were preserved perennial trees: ash, oak, acacia, weeping willow, spruce, pine, poplar, birch, shrubs — more than 30 species of plants. The channels of mountain rivers were cleaned and modern drainage and irrigation systems were installed. In the fields there are several water obstacles in the form of man-made lakes with fountains surrounded by weeping willow trees. Option Dinner: at local café or in Indian restaurant Overnight at hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day 3",
    details: "Breakfast in hotel Transfer to golf course Enjoy golf at wonderful place «Nurtau Golf Club» Option Dinner: at restaurant-museum «Tandyr» where you will be offered delicious national kazak dishes. This is a unique institution where you can see the decoration of the XVIII-XX centuries. Guests are surrounded by Antiques: rugs and coasters, saddles and tools, works of applied art. You will feel the atmosphere and comfort of the Kazakh Yurt and appreciate the Eastern hospitality.",
  },
  {
    id: "Day 4",
    header: "Day 4",
    details: "Breakfast in hotel 11:00 Sight - seeing. We included ın our excursıon only main sightseeing. Starting point of excursion – hotel of stay. The excursion starts from the center of Almaty. You will get acquainted with the history of the origin and development of the southern capital of Kazakhstan, and you will see its main attractions as well: the Presidential Residence, the Republic Square and the Independence Monument, Astana Square, Central Mosque, Park of 28 Panfilov guardsmen. Then your adventure will continue to the ski Resort Shimbulak Excursion gives you a wonderful opportunity to get acquainted with the most beautiful mountains of Zailiyskiy Alatau, surrounding the city of Almaty. The first stop awaits you in the Medeo natural boundary, where you see the dam that protects the city from the devastating mudflows that form on the tops of the Western Tien Shan. Further, the tour route will take you to a height of 3200 meters above sea level, where the ski resort Shymbulak is located, and which is considered to be one of the best ski bases in the territory of the CIS. Here you wıll have tıme to enjoy lunch ın the best restaurants of the cıty. 15:00 Transfer to city. Time for leisure.",
  },
  {
    id: "Day 5",
    header: "Day 5",
    details: "Transfer from the hotel to the airport Flight Almaty-Delhi, flight KC 907 airline Air Astana Arrival in Delhi local_diningMeals: Lunch box",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Visa Invitation",
"Transfers and transportation services according to the program",
"Accommodation in a hotel in a standard double room",
"The food indicated in the program",
"Excursions according to the program with a guide",
"Entrance tickets and environmental fees according to the program",
"Water during excursions ( 2 battles )",
];

const exclusionsData = [
"Flight",
"Food not indicated in the program",
"Visa support and registration on the territory of the Republic of Kazakhstan (if necessary)",
"Personal and other expenses not indicated in the program",
];

const KA4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KA4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KA4;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
