import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { RU1Image, RU2Image, RU3Image, RU4Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const RUSSIA_TOUR = "/assets/img/worldTour/wt15.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "05 Nights / 06 Days",
    title: "Enchanting Russia",
    image: RU1Image,
    description: "",
    path: "/ru1",
  },
  {
    id: 2,
    fromToDays: "06 Nights / 07 Days",
    title: "Enchanting Russia",
    image: RU2Image,
    description: "",
    path: "/ru2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Enchanting Russia",
    image: RU3Image,
    description: "",
    path: "/ru3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Enchanting Russia (Petersburg Full)",
    image: RU4Image,
    description: "",
    path: "/ru4",
  },
];

// const rulesData = [
//   ""
// ];

const Russia = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Russia"}
            subTitle={"Amazing Holidays to Russia"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={RUSSIA_TOUR}
            title={"Popular Russia Holiday"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Russia;
