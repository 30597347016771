import React from "react";
import { Box } from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import PropTypes from "prop-types";

import ItinetaryAccordion from "./ItinetaryAccordion";

// const labelData = ["label1", "label2", "label3", "label4"];

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: " ARRIVE SRINAGAR | SRINAGAR LOCAL SIGHTSEEING",
//     details:
//       "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "SRINAGAR – PAHALGAM – SRINAGAR",
//     details:
//       "Early in the morning leave for Pahalgam the 'valley of shepherds' which is situated at the confluence of the streams flowing from river Lidder and Sheeshnag Lake. En route visit the Awantipura ruins, an 1100-year-old temple dedicated to Lord Vishnu and built by King Awantivarman. On arrival into Pahalgam enjoy the surrounding beauty or walk to Baisaran, which is about 2 km from Pahalgam. A small village set in pine and fir forest is pleasing and offers good view and u will proceed for Aru valley, and chandanwari by car (at your own cost). Aru is small village, 11 kms upstream the Lidder River, is a beautiful locality. The route is picturesque with ample campsites. This village is the starting point for treks to the Kolahoi glacier. The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly wooded forests of pine. Hajan and Beetab valley on the way to chandanwari. In the evening return to Srinagar.",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "SRINAGAR – GULMARG – SRINAGAR",
//     details:
//       "In the morning drive to Gulmarg, the meadow of flowers. The route from Srinagar to Gulmarg is fascinating. You will be mesmerized by its beauty. Gulmarg is famous all over the world for Skiing and Snowboarding. During the British Raj, Gulmarg hosted the Britishers and Europeans and as such they created a European hill resort and named it Gulmarg. In Gulmarg you would be able to find European surroundings and 18-hole Golf Course and enjoy Gondola Cable Car Ride world’s highest and the Asia’s longest cable car (at your own cost). Enjoy photography, horse riding and shopping at this beautiful snow point and enjoy gondola ride to Kongdoori & Appahrwhat. In the evening return to Srinagar. Check in to Houseboat and night halt at hotel in Houseboat at Dal Lake.",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 4",
//     header: "DEPARTURE FROM SRINAGAR",
//     details:
//       "Return Home with Happy Memories After breakfast you will be transferred to Srinagar Airport for your onwards journey with Everlasting Memories.",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

const ItineraryTimeline = ({ intineraryTimelineData }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      {intineraryTimelineData?.map((item) => (
        <Timeline
        sx={{
          m:'0px',
          py:'0px'
        }}
        >
          <TimelineItem sx={{py:'0px'}}>
            <TimelineOppositeContent
              color="text.secondary"
              sx={{
                textAlign:'end',
                // padding: 0,
                flex:{xs:0.2,sm:0.1,md:0.1}
              }}
            >
              {item.id}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                sx={{ bgcolor: "secondary.main", border: "solid" }}
              />
              <TimelineConnector
              sx={{height:'80%'}}
              />
            </TimelineSeparator>
            <TimelineContent sx={{px:'12px',py:'0px'}}>
              <ItinetaryAccordion
                title={item.header}
                // labelData={item.labelData}
                dayDetails={item.details}
              />
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ))}
    </Box>
  );
};

ItineraryTimeline.propTypes = {
  intineraryTimelineData: PropTypes.func,
};

export default ItineraryTimeline;
