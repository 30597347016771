import React from "react";

import { Grid, Box, Typography } from "@mui/material";
import GavelIcon from '@mui/icons-material/Gavel';

import PropTypes from "prop-types";

const Rules = ({ rules, ruleTitle }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h2" color={"text.secondary"} textAlign={"start"}>
            {ruleTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
        {rules?.map((item) => (
         <Grid container >
         <Grid
           item
           xs={1}
           sm={0.5}
           md={0.3}
           // sx={{ display: "flex", alignItems: "center" }}
         >
           <GavelIcon fontSize="small" sx={{ color: "secondary.main" }} />
         </Grid>
         <Grid
           item
           xs={11}
           sm={11.5}
           md={11.7}
           // sx={{ display: "flex", alignItems: "center" }}
         >
           <Typography variant="h5" color={"text.secondary"}>
             {item}
           </Typography>
         </Grid>
       </Grid>
        ))}
        </Grid>
      </Grid>
    </Box>
  );
};

Rules.propTypes = {
  rules: PropTypes.func,
  ruleTitle: PropTypes.string,
};

export default Rules;
