import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EA3Image } from "../image";

const heroTextData = [
  {
    title: "Intor to Kenya",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Nairobi",
    endCity: "Masai Mara",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
 {
  id: "Day 1",
  header: "Nairobi",
  details: "Arrive at Jomo Kenyatta International Airport Nairobi; after you disembark off the flight and pass through immigration and customs formalities, our staff will meet you and transfer you to your hotel. Overnight at a Nairobi hotel",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 2",
  header: "Nairobi – Nakuru/Naivasha (180 KM – 02.30 HRS/ 110 KM – 01.30 HRS)",
  details: "After breakfast, depart for the exotic Lake Nakuru National Park located in the Great Rift Valley. After lunch an exciting game drive is planned for the afternoon. The game in this Park is plentiful and the magnificent bird watching will make a devotee out of even the most reluctant. The Park is a home to stunning flocks of lesser and Greater Flamingos, which literally turn the lakeshores in to a magnificent pink stretch. The bird life here is world renown and over 400 bird’s species exist here, White Pelicans, Plovers, Egrets and Marabou Stork. It is also one of the very few places in Africa to see the White Rhino and rare Rothschild Giraffe.  Meals and overnight at a lodge/camp in Nakuru/Naivasha. (BLD)",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 3",
  header: "Nakuru/Naivasha – Masai Mara (300 KM – 05.30 HRS/ 230 KM – 05.30 HRS)",
  details: "If you spend the night at Naivasha, you have a chance to see the myriad of bird life at the Lake. After breakfast, drive through the dramatic Great Rift Valley to the Masai Mara National Reserve. This enormous reserve is actually part of the vast Serengeti plains famously known for its spectacular great wildebeest’s migration and is home of the Big Five: Lion, Elephant, Leopard, Buffalo and Rhino. Lunch at your Lodge/ camp and relax before departing for an afternoon game drive. The Mara Game Reserve – one of the greatest wildernesses of the world. Large mammals are varied, and easy to see. Residents among the Park's are: Maasai Giraffe, Buffalo, Eland and thousand of plain game including Impala, Zebra, Topi, both Thomson's and grants Gazelles. Meals and overnight at a lodge/ camp in the Mara. (BLD)",
},
{
  id: "Day 4",
  header: "Masai Mara",
  details: "After breakfast, proceed on a morning game-viewing drive to search for the myriad species of game that make this reserve their home. If you are lucky, you can witness the kill in action, moment by moment, right in front of your unbelieving eyes! After a sumptuous lunch at your lodge, proceed for another exciting game drive, where you can expect to see the famous 'Big 5'. Meals and overnight at a lodge/camp in Mara. (BLD)",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 5",
  header: "Masai Mara – Nairobi (270 KM – 05.00 HRS)",
  details: "After breakfast checkout and drive back to Nairobi arriving in time for lunch. Lunch on own. Later transfer to the Jomo Kenyatta International Airport for your departure flight back home with wonderful memories of your stay in Kenya. (B) END OF SERVICE",
  labelData: ["label1", "label2", "label3", "label4"],
},
];

const inclusionData = [
"Accommodation as indicated in the itinerary",
"Meals as described by B (Buffet Breakfast), L (Lunch), D (Dinner)",
"National parks and reserve entrance fees",
"Airport transfers as indicated",
"Transport in a 7 seater safari minibus with pop up hatch roof for easy photography",
"Game drives as per the itinerary",
"Services of an experienced safari driver guide",
"Complimentary mineral water {one litre per person per day}",
];

const exclusionsData = [
"Personal expenses such traveling insurance, excess baggage fee, communication charges that include, emails, faxes and phone calls beverages including luxury champagne, luxury spirits or meals not indicated in the itinerary",
"Gratuity for Porterage / waiters at hotels, camps and airports",
"Gratuity for your professional driver guide",
"Cost of obtaining passport and visas",
"International Airfare",
];

const EA3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EA3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EA3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
