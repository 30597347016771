import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KA5Image } from "../image";

const heroTextData = [
  {
    title: "Big Almaty Lake",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Astana",
    endCity: "Astana",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Day 1",
    details: "Departure by flight Arrival in Almaty, meeting at the airport and transfer to hotel Check-in and dinner in Indian restaurant Overnight",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Day 2",
    details: "Breakfast in hotel 11:00-13:00 Sight - seeing Starting point of excursion – hotel of stay. The excursion starts from the center of Almaty. You will get acquainted with the history of the origin and development of the southern capital of Kazakhstan, and you will see its main attractions as well: The Central State Museum of the Republic of Kazakhstan, the Presidential Residence, the Republic Square and the Independence Monument, the Theater of Opera and Ballet named after Abai, Astana Square, Railway Station, Central Mosque, Park of 28 Panfilov guardsmen, Abai Square and the Palace of the Republic. 13:00-14:00 Lunch in Indian restaurant 14:00-17:00 Amazing Kok Tobe ( by cable car) Kok-Tobe is the highest TV tower in the world, if you count from the sea level. A special viewing platform located on the mountain will allow you to admire the magnificent panorama of the city, as well as you can stroll along the winding paths of Kok-Tobe Park and visit souvenir shops. Our guide will tell the meaning of souvenirs and help to buy the best one. 20:00-22:00 Dinner in Indian Restaurant Overnight",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Day 3",
    details: "Breakfast in hotel 10:00-14:00 Big Almaty Lake You can climb the gorge, where at an altitude of 2510 m above sea level there is the Big Almaty Lake - one of the most picturesque lakes of the Zailiyskiy Alatau. The lake fascinates with its beauty, especially when its surface is absolutely calm and reflects the sky and surrounding mountains. BAL was formed as a result of glaciers melting. Due to high-mountain location the water temperature never exceeds +10 degrees. The route of the tour will take place at an altitude of 3300 meters above sea level, where the bases for scientific astronomical and space research are located. Here, at the base of the Big Almaty peak, you can enjoy the enchanting panorama of the mountains and BAO surrounded by picturesque mountains covered with Teen Shan spruce, aspen and juniper. 14:00-15:00 Lunch in Indian restaurant 15:00-17:00 Walking in the park of the 1st President. Duration 2 hours We invite you for a walk in the Park named after the First President of the Republic of Kazakhstan» The territory of this Park is more than 73 hectares, where there are more than 3 thousand conifers, 9 thousand deciduous trees, about 400 shrubs, many perennial and annual flowers. Here you can enjoy the quiet, clean mountain air and stunning views of the Zailisky Alatau mountains. Time for leisure 19:00-21:00 Dinner in Indian Restaurant Overnight",
  },
  {
    id: "Day 4",
    header: "Day 4",
    details: "Breakfast in hotel 10:00-14:00 Shymbulak (by cable car) Excursion gives you a wonderful opportunity to get acquainted with the most beautiful mountains of Zailiyskiy Alatau, surrounding the city of Almaty. The first stop awaits you in the Medeo natural boundary, where you see the dam that protects the city from the devastating mudflows that form on the tops of the Western Tien Shan. Further, the tour route will take you to a height of 2300 meters above sea level, where the ski resort Shymbulak is located, and which is considered to be one of the best ski bases in the territory of the CIS. 14:00-15:00 Lunch in Indian restaurant 15:00-19:00 Relax and go shopping! First you will visit Zelenyi bazaar where you can buy different presents and sweets. Next stop is the biggest Mall “Mega” where guests can go shopping, visit cinemas, entertainment centers and spend wonderful day! 20:00-22:00 Dinner in Indian Restaurant Overnight",
  },
  {
    id: "Day 5",
    header: "Day 5",
    details: "Breakfast in hotel 10:00-13:00 Relax and go shopping! 13:00-14:00 Lunch in Indian restaurant 14:00- 18:00 Visiting Gorkiy Park and Dolphin Show 20:00-22:00 Dinner in Indian Restaurant Overnight",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Day 6",
    details: "Тransfer from the hotel to the airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Visa Invitation",
"Transfers and transportation services according to the program",
"Accommodation in a hotel in a standard double room",
"The food indicated in the program",
"Excursions according to the program with a guide",
"Entrance tickets and environmental fees according to the program",
"Water during excursions ( 2 battles )",
];

const exclusionsData = [
"Flight",
"Food not indicated in the program",
"Visa support and registration on the territory of the Republic of Kazakhstan (if necessary)",
"Personal and other expenses not indicated in the program",
];

const KA5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KA5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KA5;



// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
