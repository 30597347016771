import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { A5Image } from "../image";

const heroTextData = [
  {
    title: "7 Nights 8 Days Family Holiday Tour Package",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Port Blair",
    endCity: " Port Blair",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];


const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Port Blair - Corbyn's Cove Beach, Cellular Jail Visit with Sound & Light Show",
    details: "Arrival at Port Blair by flight. Our tour representative will be present holding placard with your name at airport. After a brief with our representative you will be taken to your hotel. Check-in and relax there.After lunch, proceed to visit Corbyn's Cove Beach which is a coconut palm fringed, tranquil sandy beach with blue sea water making it ideal for sun-bathing and swimming and Water Sports Activities like Jet-Ski Ride and Speed Boat Ride.After relaxing and enjoying at this pleasant beach, visit Cellular Jail (a Colonial Prison used by the Britishers to exile prisoners of freedom struggle to this remote archipelago) and watch Sound & Light Show (Son-et-Lumiere) in which the heroic saga of our freedom fighters during freedom struggle is brought alive.On completion, you will be dropped back to your hotel for overnight stay at Port Blair.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Ross Island & North Bay, Joggers Park",
    details: "In the morning after breakfast, proceed to visit Ross Island and North Bay.First you will be taken to Rajiv Gandhi Water Sports Complex from where you will board a boat to reach Ross Island.Ross Island was the first headquarter of the British colony in these islands. The main attraction of this island are the ruins of some of the buildings that were built during British period, a British Church, Mansion, Market, etc. You can spot number of Peacocks, Deers and Ducks at the island. The place gives picturesque views from all around.After exploring this historical island, you will again board a boat from Ross Island to visit North Bay.North Bay is also known as Coral Island. Its rich under water corals makes this island an ideal place for many Water Sports Activities like Snorkeling, Scuba Diving, Sea Walk and Glass Bottom Boat Ride.After enjoying in this beautiful island, you will get back by boat to Rajiv Gandhi Water Sports Complex and relax for some time at Marina Park.After that, you will be dropped at hotel to freshen-up and relax.In the evening, visit Joggers Park which transforms into a breath-taking place after sunset. The view from the benches overlooking an illumined city and the sight of the lit runway of the adjacent airport are nothing short of magical.On completion, you will be dropped back to your hotel for overnight stay at Port Blair.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Port Blair -> Havelock Island (Radhanagar Beach)",
    details: "In the morning, check-out from hotel and proceed by a Private Ferry/Cruise to Havelock Island On arrival at Havelock Island, our representative will give a brief and take you to your hotel. Check-in and relax there After having lunch, visit Radhanagar Beach (ranked among Asia's top 10 beaches) which is locally known as Beach No. 7 and is the most beautiful beach in Andamans is the best place for beach sit-outs, beach walk, swimming, sun-bathing, relaxation and watching sunset. This beach has got the finest hue of unending sky, frolicking waves, thick sands, flora and fauna. The sun's rays bounce off the pure white sand making it sparkle and glitter like diamonds. The clean and composed ambiance with farseeing white and silky sand gleaming beneath the turquoise blue waters is a perfect beachscape to take those long walks at an ideal sunset brimming over the horizon After witnessing the stunning sunset in the horizon at Radhanagar Beach, you will be dropped back to your hotel for overnight stay at Havelock Island.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Havelock Island (Elephant Beach, Kalapatthar Beach)",
    details: "In the morning after breakfast, proceed to visit Elephant Beach.To visit Elephant Beach, first you will be taken to the jetty from where you will board a boat to reach Elephant Beach.The white sand beach, the blue green water and the rich coral reef at the Elephant Beach makes it one of the best places for Water Sports Activities like Snorkeling, Sea-Walk, Glass Bottom Boat Ride, Jet-Ski Ride, Banana Ride and Sofa Ride.After enjoying in this pristine beach, you will get back to Havelock Island jetty and after that you will be dropped at your hotel for lunch and relaxation.After lunch, proceed to visit Kalapatthar Beach.Kalapatthar Beach is a small and quiet beach. The colour of the water is so unique and beautiful with silvery sand making this beach a great place to relax. The beach has got its name from the black rocks that adorn the coastline here.After enjoying in this pristine beach, you will be dropped back to your hotel for overnight stay at Havelock Island.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Havelock Island -> Neil Island (Bharatpur Beach, Laxmanpur Beach, Natural Rock Bridge)",
    details: "In the morning after breakfast, check-out from hotel and proceed to Havelock Island jetty. From there you will be boarding a Private Ferry/Cruise to proceed to Neil Island.On arrival at Neil Island, our representative will give a brief and take you to your hotel. Check-in and relax there.After lunch, proceed to visit Bharatpur Beach.Bharatpur Beach is known for its pristine clear blue water and rich colourful coral reef making it ideal for Water Sports Activities like Snorkeling, Scuba Diving, Jet Ski Ride, Speed Boat Ride and Glass Bottom Boat Ride.After enjoying in this beautiful beach, you will be taken to Laxmanpur Beach.Laxmanpur Beach is known as the Sunset Point at Neil Island and is very wide and long. This beach is also known for its unique Natural Coral Bridge formation locally known as Natural Hawrah Bridge. The beach has white shell sand with shallow water making it perfect for an evening walk.After enjoying in this serene beach and witnessing the stunning sunset in the horizon at Laxmanpur Beach, you will be dropped back to your hotel for overnight stay at Neil Island.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Neil Island -> Afternoon Return to Port Blair (Chidiya Tapu Beach, Sagarika Emporium for shopping)",
    details: "In the morning after breakfast, check-out from hotel and proceed to Neil Island jetty. From there you will be boarding a Private Ferry/Cruise to return back to Port Blair.On arrival at Port Blair, you will be dropped at your hotel. Check-in and relax there.After lunch, proceed to visit Chidiya Tapu (approx 28 kms. away from Port Blair in the southernmost tip of South Andaman).Chidiya Tapu is also known as the 'Sunset Point' and 'Bird Island'. It is a small village with lush green mangroves, tranquil islands and forest bestowed with numerous chirping birds, its turquoise blue sea water and soft sandy beach, making it an ideal spot for picnic, sit-out, swimmning, sun-bathing and long beach walk. Chidiya Tapu is well known for its rich collection of birds, green landscape, crystal clear water and white sand, enthralling beaches, colourful sea-shells, variety of corals, vivid butterflies and most significantly, the exquisite view of the sunset in the horizon. The main attractions here include the Chidiya Tapu Biological Park, Chidiya Tapu Beach and Munda Pahad.After enjoying in the exquisite Chidiya Tapu Beach, witness the stunning sunset in the horizon in the evening.After that you will be taken to Sagarika Emporium for shopping.On completion, you will be dropped back to your hotel for overnight stay at Port Blair.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Port Blair -> Baratang Island Day Trip (Limestone Cave, Mud Volcano) -> Port Blair (Sagarika Emporium for shopping)",
    details: "Early morning (at 4.00 am / 6:00 am), depart for Baratang Island (about 100 kms. by road from Port Blair) which is famous for Limestone Cave and Mud Volcano. The journey to this island by road will mesmerise you as you move through the dense Jarawa (one of the Tribes of Andaman & Nicobar Islands) Reserve Forest.After reaching Baratang jetty, you will be taken by boat to Limestone Cave winding its way through enchanting Mangrove Forest.After exploring the enchanting Limestone Cave you will get back to Baratang jetty and proceed to visit Mud Volcano which is one of the fewest Mud Volcanoes in India.On completion, you will be making your way back to Port Blair.On arrival, you will be taken to Sagarika Emporium for shopping. On completion, you will be dropped at your hotel for overnight stay at Port Blair.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Depart Port Blair",
    details: "After check-out from hotel, you will be dropped at airport. Depart Port Blair with sweet and unforgettable memories",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "A Non-Alcoholic Welcome Drink / Beverage for each individual on arrival at Port Blair.",
  "One 1/2 ltr. bottle of Mineral Water per person on arrival at Port Blair.",
  "A Flower Bouquet on arrival at Port Blair.",
  "AC Accommodation in a Hotel / Resort at Port Blair for 2 Nights with Complementary Breakfast.",
  "AC Accommodation in a Hotel / Resort at Havelock Island for 1 Night with Complementary Breakfast. ",
  "Check-in and check-out times at hotels would be as per hotel policies.",
  "All Transportation for sight-seeing tours / airport transfers / hotel / jetty transfers by exclusive AC vehicle at Port Blair & Havelock Island.",
  "Private Ferry / Cruise Transfer for Port Blair -> Havelock Island -> Port Blair.",
  "Elephant Beach Visit with one Complementary Session of Snorkeling.",
  "All Entry Tickets, Ferry / Cruise / Boat Tickets, Permit Charges, Parking Charges.",
  "All sight-seeing program as mentioned in the itinerary. ",
  "Note : If any visit is missed due to any administrative or other reason, alternate arrangement incurring similar cost will be provided.",
  "Vehicle in case of any Medical Emergency.",
  "On-call assistance during your stay.",
  "Subject to weather conditions.",
  "All the boats and cruise are on sharing basis.",
  "The company has no liability what so ever for unforeseen natural calamities.",
  "The company has full rights to shift the Itinerary, and make changes which suit the best to our client, in situations which are beyond control of the company.",
];

const exclusionsData = [
  "Flight Tickets to and from Port Blair.",
  "Unspecified Meal – Lunch & Dinner.",
  "The services of vehicles is not included on leisure days & after finishing the sightseeing tour as per the itinerary.",
  "Any kind of personal expenses or optional tours or extra meals / beverages ordered at hotel.",
  "Tips, Insurance, Laundry, Phone Calls and Camera Charges (still or movie).",
  "Any kind of drinks (Alcohol, Mineral, Aerated, Bed Tea) or any other snack on Tour or while waiting at airport or waiting for ferry at jetty.",
  "Extra Cost incidental to any change in the itinerary / stay on account of flight cancellation, ill health, and / or any factors beyond control.",
  "Any Water Sports Activities / Adventurous Activity that is not mentioned in the Package Inclusions during your stay.",
  "Flower Bouquet, Fruit Basket, Cake, Flower Bed Decoration and Candle Light Dinner during your stay.",
  "Peak Season Surcharges of Hotels / Resorts (Applicable from 15th December to 20th January).",
  "Additional Supplement Charge for Christmas Eve (24th December) and New Years Eve (31st December) applicable at Hotels / Resort",
];

const A5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={A5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default A5;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
