import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { J1Image } from "../image";

const heroTextData = [
  {
    title: "Japan Golden Route 3N/4D",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Narita",
    endCity: "Tokyo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arr.Narita/Haneda",
    details: "On arrival Meet & Greet at Airport and transfer to Hotel Transfer to Hotel Overnight at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "At.Tokyo",
    details: "Breakfast at Hotel Hotel Departure Sightseeing of Asakusa Sensoji & Nakamise Shopping Arcade Free Time at Tokyo, Sky Tree Sightseeing of Imperial Palace Plaza Shopping at Akihabara Shopping at Ginza Sightseeing of Tokyo Metropolitan Government Building Observatories Arrival at Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "At.Tokyo",
    details: "Breakfast at Hotel Hotel Departure Drive to Kamakura Sightseeing of Hokoku Temple Sightseeing of Kotoku-in Lunch at Local Restaurant Sightseeing of Hase Temple Sightseeing of Tsuruoka Hachiman Shrine & Komachi Street Drive to Tokyo Arrival at Hotel",
  },
  {
    id: "Day 4",
    header: "At.Tokyo",
    details: "Breakfast at Hotel Drive to International Airport",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [""];

// const exclusionsData = [""];

const J1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={J1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default J1;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
