import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { O5Image} from "../image";

const heroTextData = [
  {
    title: "Odisha Diamond Triangle-i",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bhubaneswar ",
    endCity: "Bhubaneswar ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Bhubaneswar ",
    details:
      "Meet and greet on arrival at Bhubaneswar Airport/Railway station and proceed to Puri. On arrival check-in to the hotel. After fresh up visit to the temple of universal God Lord Jagannath (one of the four Dhaam of Hindu religion). After darshan visit other temples of God & goddesses, the Anandabazar, the vast Kitchen & Koili Baikuntha. The word Kolili means burial ground for the trinity & Baikuntha means the heaven. Later visit to the Niladri beach & Blue flag beach or visit to the beach market. O/N-Puri. Note: 1.Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 2. Non-Hindu are not allowed inside the Jagannath temple & Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath & Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Excursion trip to Ratnagiri (130 kms/one way)",
    details:
      "After breakfast proceed to Ratnagiri-Lalitgiri-Udaygiri Buddhist circuit which is popularly known as ‘Diamond triangle of Odisha’. On arrival explore Stupas, Chaityas,Viharas etc.Lalitgiri is the oldest Buddhist complex of the state and one of the earliest Buddhist establishments of world. Visit the museum at Ratnagiri&Lalitgiri (close on Friday). Back to Bhubaneswar. O/N-Bhubaneswar.", 
      
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Bhubaneswar-Konark-Puri (130 kms/2.5 hrs)",
    details:
      "After breakfast proceed to Puri. En-route visit through DhauliBuddhist shanti stupa, Sun temple& interpretation center at Konark (the UNESCO World heritage monument), Chandrabhaga beach. On arrival, check-in to the Hotel. After fresh-up visit to the temple of Universal God Lord Jagannath (One among four Dham of Hindu religion). After darshan visit other temples of God& goddesses, the Anandabazar, the vast Kitchen &KoiliBaikuntha. The word ‘Koili’ means burial ground for the trinity & ‘Baikuntha’ means the heaven. Evening leisure at thegolden beach Puri/visit to the beach market. O/N-Puri. Note: 1. Konark Sun temple remain open from 08.00 to 18.00hrs. Light & sound show time: 18.30 to 19.15hrs (Winter) & 19.00 to 19.40hrs (summer) and operated weather condition & minimum 25-pax. Show remain close on every Monday. 2. Jagannath temple remain open from 05.00 to 22.00hrs and close for 01hr between 12.00 to 14.00hrs. 3. Non-Hindu are not allowed inside the Jagannath temple &Gundicha temple premises. 4. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath&Gundicha temple premises. 5. Be careful to monkeys at Jagannath temple. Keep your wallet & bag carefully.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header:  "Excursion trip to Chilika (55km/1.25hrs one way) ",
    details:
      "Post breakfast visit to the temple of Universal God Lord Jagannath (One among four Dham of Hindu religion). After darshan visit other temples of God& goddesses, the Anandabazar, the vast Kitchen &KoiliBaikuntha. The word ‘Koili’ means burial ground for the trinity & ‘Baikuntha’ means the heaven. Post lunch excursion trip to Satapada (One end of Chilika Lagoon). Enjoy the motor boat ride in the largest brakish water lagoon of Asia and explore Sea mouth, Rajhans Island (drive pass), rare Irrawady dolphins, local birds etc. Back to Puri and visit the newly developed Niladri beach & Blue flag beach. Rest of the day leisure at golden beach/free for independent activities. O/N-Puri. Note: 1. Jagannath temple open from 05.00 to 22.00hrs and closed for 01hrs between 12.00 to 14.00hrs. 2. non-Hindu are not allowed inside the Jagannath temple &Gundicha temple 3. Mobile phones, cameras, leather items, paan, gutkha, tobacco, food items, bags are not allowed inside the Jagannath&Gundicha temple premises. 4. Be careful to monkeys at Jagannath temple. Keep your wallet & bags carefully. ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Puri-Bhubaneswar & departure ",
    details:
      "Post breakfast proceed to Bhubaneswar, en-route visit through Pipili appliqué market. If time permits you may visit to Nandankanan Zoological park-popular for white tigers (close on Monday). In time transfer to railway station/airport for onwards journey. Tour End. Note: NandankananZoological Parkremain open from 08.00hrs to 17.00hrs. Being a sanctuary Zoo it takes minimum 03-04hrs to cover. ",
      
  },
];

const inclusionData = [
  "Hotel Accommodation will be on double sharing basis at above mentioned hotels.",
  "Meal plan will be on CP (Room + breakfast) plan basis in all hotels except Mayfair Puri. At Mayfair Puri meal Plan will be MAP Plan basis",
  "All transfer & sightseeing by AC Vehicle as per the itinerary only from 07.00 AM to 07.00 PM. (all Sedan & SUV vehicle will be without luggage carrier)",
  "All Vehicle related expenses.",
  "Everyday 500ml mineral water bottle to each guest.",
  "Arrival assistance.",
  "Priest (Pandit) assistance at the Jagannath temple.",


];

const exclusionsData = [
  "Meals not mentioned in the itinerary",
  "Anything not mentioned in the “Inclusion” head.",
  "Monument entry tickets.",
  "Any supplementary services.",
  "Porterage at hotels and airports, tips, insurance, laundry, liquors, wine, etc",
  "Any cost arising due to natural calamities like landslides, roadblocks, etc",
  "Early Check-in Charges.",
];

const O5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={O5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default O5;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];

