import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { UP4Image } from "../image";

const heroTextData = [
  {
    title: "Lucknow – Ayodhya",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Lucknow",
    endCity: "Varanasi",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Lucknow –ayodhya",
    details: "Arrival at Lucknow Airport (early morning flight recommended), post arrival our driver / representative will meet you at airport and further proceed for Lucknow city tour visiting “Bara Imam-Bara”, Jama Masjid, Rumi Darwaza, ChatarManzil and Precedency. You can enjoy mouth-watering Nawabi delicacies of Lucknow for Lunch. Further proceed to Ayodhya. Arrive Ayodhya check-in at hotel. Evening visit the major attractions of Ayodhya, Saryu River front, Aarti at “Ram Ki Paidi”, Kanak Bhawan, Hanuman Gadhi, Dasrath Mahal. Overnight at Ayodhya.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Ayodhya - Prayagraj",
    details: "Early Morning visit the Ram Janambhoomi, return to hotel for breakfast. Post breakfast check-out and drive to Prayag, arrive Prayag and check-in at the hotel. In the evening visit Sangam Aarti at Triveni Sangam. Overnight at Prayagraj.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Prayagraj- Varanasi Via Sitamarhi",
    details: "Morning visit the Sangam meeting point of three rivers as per Hindu mythology. Take a holy dip there and visit Bade hanuman Temple, Alopi Devi Temple and after breakfast visit Anand Bhavan & Swaraj Bhavan then proceed towards Varanasi via Sitamarhi, Sitamarhi is the place where mata Sita spent her 11 years in Valmiki Ashram. Overnight at Varanasi.",
  },
  {
    id: "Day 4",
    header: "Varanasi",
    details: "Early morning, visit the majestic Ghats of Varanasi by the Ganges. It’s a mystical and spiritual experience as you watch people offering water to the Sun God and devotees taking holy dip in the Ganges and performing rituals further visit the Kashi Vishwanath temple / Corridor, Annapurna Devi Temple & Vishalakshi Shakti Peetha. After breakfast proceed for an excursion to Sarnath. Sarnath is the place where Buddha delivered his first sermon to his disciples. The attractions at Sarnath are the monasteries of different countries, the Dhamekha stupa, Chaukhandi stupa, the archaeological Site and a museum. Later in the evening witness the Aarti ceremony an experience of life. The GANGA Aarti is a spectacular sight. Hundreds throng to the Ghats to participate in this ceremony. Offerings of lamps and flowers are made to the river immediately following this ceremony and it is a moving sight to watch hundreds of miniature lamps float along the river. Overnight in Hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Varanasi",
    details: "In Morning Visit Birla Temple (BHU), Mani Temple, Sankat Mochan Temple, Durga Temple & Tulsi Manas Temple. Later transfer to Varanasi Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const UP4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={UP4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default UP4;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
