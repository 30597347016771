import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const KUMARAKOM =
 "/assets/img/domesticTour/kerala/kumarakom.png";

const heroTextData = [
  {
    title: "Munnar, Thekkady, Kumarakom and Alleppey",
    duration: "05 Nights / 06 Day",
    typoOfTrip: "Road Trip",
    startCity: "Munnar",
    endCity: "Cochin",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Munnar",
    details:
     "Once you arrive at Cochin, our executives will drive you to Munnar. Munnar is blessed with scenic green and the weather too is pleasant here. Once you arrive here, you will dropped at the hotel for an overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Across the lush green in Munna",
    details:
      "Arranged on the banks of three waterways Madupetti, Nallathanni and Periavaru, Munnar is likewise honored with characteristic view-focuses separated from the tea-manors. Munnar is partitioned into Old Munnar, where the traveler data office is, and Munnar, where the transport station and most visitor houses are found. The Eravikulam National Park, Salim Ali Bird Sanctuary and tea manors are its real attractions.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Into the wild at Thekkady",
    details:
     "Home to the nation’s biggest Tiger Reserve-Periyar, Thekkady is an incredible method to appreciate a wilderness get-away. Well known for its great eating joints, you can likewise make the most of its tremendous stretches of flavor gardens and different experience sports. You can also head out for a trek in the wild of Periyar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "To the destination of Lakes, Kumarako",
    details:
   "Arranged on the banks of Vembanad Lake, Kerala’s biggest lake, Kumarakom is a group of numerous little man-made islands recovered from the lake.The Kumarakom bird sanctuary covers over 14 sections of land, is a most loved frequent of transitory flying creatures and an ornithologist’s heaven. Egrets, darters, herons, greenish blues, waterfowl, cuckoo, wild duck and transient flying creatures like the Siberian Stork visit here in herds and interest the guests.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Cruise through and enjoy the best in Alleppey",
    details:
   "After breakfast in the wild, today you head to beautiful Alleppey. Once you arrive at Alleppey, you can directly head to the houseboat, where you will be staying for the night. The houseboats are the main attractions of Alleppey, you get the chance to cruise through the backwaters as you leisurely rest in the houseboat.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Back to Cochin",
    details:
  "On day 6, your tour comes to an end. After some great breakfast in the houseboat, you checkout and head back to Cochin. Our executives will then drop you to the airport or railway station. With umpteen memories, we part our ways.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const   MunnarThekkadyKumarakomandAlleppeyTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KUMARAKOM}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default MunnarThekkadyKumarakomandAlleppeyTourPackages;













