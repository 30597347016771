import { React, useState } from "react";
import {
  AppBar,
  Box,
  useScrollTrigger,
  Divider,
  Fab,
  Fade,
  Card,
  Toolbar,
  IconButton,
  Grid,
  Container,
} from "@mui/material";

import Header from "./Header";

import PropTypes from "prop-types";
import Footer from "./Footer";

import Sidebar from "./Sidebar";
import BottomBar from "./BottomBar";
import FloatingIcons from "../../FloatingIcons";

const navItems = [
  {
    id: 1,
    title: "Home",
    href: "/",
  },
  {
    id: 3,
    title: "services",
    href: "/services",
  },
  {
    id: 3,
    title: "about us",
    href: "/about-us",
  },
];

const Main = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 1000,
    target: window,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position={"sticky"}
        sx={{
          bgcolor: "#ffffff",
          height: { xs: 55, sm: 80, md: 80 },
          display: "flex",
          justifyContent: "center",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
        elevation={4}
        // position={"absolute"}
        // sx={{
        //   boxShadow: trigger ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "none",
        //   bgcolor: trigger ? "#ffffff" : "transparent",
        //   color: trigger ? "#ffffff" : "black",
        //   transition: trigger ? "0.3s" : "0.5s",
        //   display: "flex",
        //   justifyContent: "center",
        // }}
      >
        <Container>
          <Header navItems={navItems} onClick={handleDrawerToggle} />
        </Container>
      </AppBar>
      <Sidebar
        navItems={navItems}
        onClick={handleDrawerToggle}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12} pt={{ xs: 2, sm: 2, md: 3 }}>
          <Fade in timeout={3000}>
            <main>{children}</main>
          </Fade>
          <FloatingIcons />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            displayL: "flex",
            justifyContent: "flex-end",
            pt: { xs: 2, sm: 28, md: 4 },
          }}
        >
          <Box>
            <Divider />
            <Footer />
          </Box>
          <AppBar
            position="fixed"
            color="background"
            sx={{
              top: "auto",
              bottom: 0,
              height: 40,
              display: "flex",
              justifyContent: "center",
              display: { xs: "content", sm: "none", md: "none" },
            }}
          >
            <BottomBar />
          </AppBar>
        </Grid>
      </Grid>
    </Box>
  );
};

Main.prototype = {
  children: PropTypes.node,
};

export default Main;
