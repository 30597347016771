import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { DU2Image } from "../image";

const heroTextData = [
  {
    title: "Dubai Weekend",
    duration: "04 Nights / 05 Days",
    typoOfTrip: "Road Trip",
    startCity: "Dubai",
    endCity: "Dubai",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival Dubai Airport Transfer to Hotel",
    details: "Marhabaan and welcome to Dubai! On your arrival at Dubai International Airport, you will be received by our representative and transferred to your hotel. Settle into your luxurious room and spend the day at leisure exploring one of the most glamorous cities in the world.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Dubai City Tour with Burj Khalifa",
    details: "After having a delicious morning breakfast, Discover Dubai in an exciting city tour and ascend the magnificent Burj Khalifa, an iconic Dubai landmark. The city tour includes Palm Jumeirah Beach where you make your memories by clicking photos, and scores of other famous Dubai attractions. The highlight of your tour will be the breathtaking views from the “At the Top” viewpoint on the 124th floor of the Burj Khalifa. The Burj Khalifa is the tallest building in the world and a global icon. At 828m tall, this magnificent structure is located next to Dubai Mall and has drawn visitors from all over the world since opening in 2010. The unmatched Burj Khalifa view can be taken in from not one but two observation decks – the two-storey At the Top on the 124th and 125th floors, as well as one of the world’s highest observation decks (555m) on the 148th floor (at additional cost).",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Desert Safari with BBQ Dinner and Tanoura Show",
    details: "Enjoy a sumptuous breakfast in your hotel. In the afternoon we depart for a desert adventure on a 5-hour safari from Dubai. Travel by a comfortable 4x4 to a welcoming camp in the middle of the desert. You will sample some traditional Arabic coffee and dates before taking off on a thrilling dune ride across the sands. End the day with a starlit barbecue dinner back at the camp. As you feast on delicious Arabic dishes, watch a sequined belly dancing show and hypnotic ‘tanoura’ performance, with dancers spinning in time to traditional Emirati music.",
  },
  {
    id: "Day 4",
    header: "Dhow Cruise",
    details: "Enjoy breakfast at your hotel. Today you have free time in the morning to explore Dubai (on your own). Evening we proceeded to enjoy a dhow cruise at Dubai Marina. It takes place along the Dubai Marina, which is a man-made canal city lined with high-rise buildings, restaurants, and shops. The term Dhow refers to traditional wooden boats that were historically used for fishing and trade in the region. The Marina Dhow Cruise offers a unique way to enjoy the stunning views of the Dubai Marina skyline, especially in the evening when the city lights up. Passengers on the cruise can enjoy panoramic views of the modern architecture and skyscrapers that line the Dubai Marina, creating a beautiful backdrop for the experience. Marina Dhow Cruises offers buffet dinner meals where our guests have the opportunity to savor a variety of international and local dishes while cruising in the marina.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Departure to Dubai Airport",
    details: "After breakfast, we check-out from our hotel & transfer to the airport to catch a flight back home. We return home with bags full of souvenirs and happy memories.",
    labelData: ["label1", "label2", "label3", "label4"],
  },,
];

// const inclusionData = [
//   "03 nights’ accommodation at respective hotel in Kathmandu with breakfast only ",
//   "Airport / Hotel / Airport transfers by an a/c vehicle exclusively",
//   "Sightseeing as per the itinerary by an a/c vehicle exclusively (Guide and entrance fee not included payable directly)",
//   "Applicable taxes",
// ];

// const exclusionsData = [
//   "Services other than specified.",
//   "Entrance fee at the monumental sites during the sightseeing",
//   "Guide during the sightseeing",
//   "Anything not mentioned in inclusions",
//   "Any expenses caused by reasons beyond our control such as flight delays, rescheduling or cancellations, any accidents, medical evacuations, riots, strikes, etc. ",
//   "Any tips to drivers, hotel staff, any meals apart from the ones mentioned above. ",
//   "Any other tax or service charge that could be enforced during this period will be extra & need to borne by you.",
// ];

const DU1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={DU2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default DU1;

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
