import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../Components/layouts/Main";

import FloatingIcons from "../../Components/FloatingIcons";
import TourPackages from "../../Components/TourPackage";

import {
  WT1Image,
  WT2Image,
  WT3Image,
  WT4Image,
  WT5Image,
  WT6Image,
  WT7Image,
  WT8Image,
  WT9Image,
  WT10Image,
  WT11Image,
  WT12Image,
  WT13Image,
  WT14Image,
  WT15Image,
  WT16Image,
  WT17Image,
  WT18Image,
  WT19Image,
  WT20Image,
  WT21Image,
  WT22Image,
  WT23Image
} from "./image";

const WORLD_HERO = "/assets/img/tour/international_tour.png";

const tourCardContent = [
  {
    id: 22,
    totalPackges: 6,
    title: "Bali",
    image: WT22Image,
    description: "",
    path: "/bali-tour",
  },
  {
    id: 19,
    totalPackges: 11,
    title: "Maldives",
    image: WT19Image,
    description: "",
    path: "/maldives-tour",
  },
  {
    id: 23,
    totalPackges: 5,
    title: "Dubai",
    image: WT23Image,
    description: "",
    path: "/dubai-tour",
  },
  {
    id: 21,
    totalPackges: 3,
    title: "Thailand",
    image: WT21Image,
    description: "",
    path: "/thailand-tour",
  },
  {
    id: 9,
    totalPackges: 7,
    title: "Europe",
    image: WT9Image,
    description: "",
    path: "/europe-tour",
  },
  {
    id: 1,
    totalPackges: 15,
    title: "USA",
    image: WT1Image,
    description: "",
    path: "/usa-tour",
  },
  {
    id: 20,
    totalPackges: 11,
    title: "Nepal",
    image: WT20Image,
    description: "",
    path: "/nepal-tour",
  },
  {
    id: 5,
    totalPackges: 5,
    title: "Japan",
    image: WT5Image,
    description: "",
    path: "/japan-tour",
  },
  {
    id: 8,
    totalPackges: 12,
    title: "Vietnam & Cambodia",
    image: WT8Image,
    description: "",
    path: "/vietnam-cambodia-tour",
  },
  {
    id: 2,
    totalPackges: 5,
    title: "Bhutan",
    image: WT2Image,
    description: "",
    path: "/bhutan-tour",
  },
  {
    id: 3,
    totalPackges: 5,
    title: "Egypt",
    image: WT3Image,
    description: "",
    path: "/egypt-tour",
  },
  {
    id: 4,
    totalPackges: 8,
    title: "Azerbaijan And Georgia",
    image: WT4Image,
    description: "",
    path: "/azerbaijanandgeorgia-tour",
  },
  {
    id: 6,
    totalPackges: 4,
    title: "Uzbekistan",
    image: WT6Image,
    description: "",
    path: "/uzbekistan-tour",
  },
  {
    id: 7,
    totalPackges: 7,
    title: "Canada",
    image: WT7Image,
    description: "",
    path: "/canada-tour",
  },

  {
    id: 10,
    totalPackges: 3,
    title: "Armenia",
    image: WT10Image,
    description: "",
    path: "/armenia-tour",
  },
  {
    id: 11,
    totalPackges: 8,
    title: "Scandi Ready",
    image: WT11Image,
    description: "",
    path: "/scandiready-tour",
  },
  {
    id: 12,
    totalPackges: 4,
    title: "Turkey ",
    image: WT12Image,
    description: "",
    path: "/turkey-tour",
  },
  {
    id: 13,
    totalPackges: 8,
    title: "East Africa",
    image: WT13Image,
    description: "",
    path: "/eastafirca-tour",
  },
  {
    id: 14,
    totalPackges: 2,
    title: "Croatia",
    image: WT14Image,
    description: "",
    path: "/croatia-tour",
  },
  {
    id: 15,
    totalPackges: 4,
    title: "Russia",
    image: WT15Image,
    description: "",
    path: "/russia-tour",
  },
  {
    id: 16,
    totalPackges: 6,
    title: "Kazakhstan",
    image: WT16Image,
    description: "",
    path: "/kazakhstan-tour",
  },
  {
    id: 17,
    totalPackges: 8,
    title: "Jordan",
    image: WT17Image,
    description: "",
    path: "/jordan-tour",
  },
  {
    id: 18,
    totalPackges: 2,
    title: "Israel",
    image: WT18Image,
    description: "",
    path: "/israel-tour",
  },

];

const WorldPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Container>
          <Grid>
            <TourPackages
              mainTitle={"World"}
              details={
                "Planning an international vacation? Look into international tour packages that provide well-rounded itineraries at reasonable prices as you finalize your travel arrangements to a foreign country. Create your own bucket list and give wings to your favourite destinations. Get ready for delightful international travel destinations. Give yourself a little break from the Robotic life, explore the breathtaking beauty through international tourism with RADHA TOURS. There's more to life than the robotic lifestyle we all live these days. From a never-ending list to choose from, you can select your tour packages and customize it according to your preferences. While wanting to go on a holiday is a desire shared by all, where to go and for what varies substantially. Now that we know you are looking for a good international tour package, let us take you through the details"
              }
              heroImg={WORLD_HERO}
              // title={"World Tour Packages"}
              tourCardContent={tourCardContent}
            />
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default WorldPackages;
