import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const HERITAGE_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR =
  "/assets/img/domesticTour/rajasthan/Heritage_Pink_City.png";


const heroTextData = [
  {
    title: "Heritage Pink City",
    duration: "02 Nights / 03 Days",
    typoOfTrip: "Road Trip",
    startCity: "Jaipur ",
    endCity: "Jaipur ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Jaipur Arrival",
    details:
      "Welcome and Arrive Jaipur Airport / Railway Station and meet our local representative who will transfer to the hotel, proceed to Amber fort, built in 16th century, enjoy an Elephant ride (By Own) at Amber, the Observatory Visit the Sheesh Mahal or the Hall of Victory glittering with mirrors. Photo stop at Jal mahal. Drive past the Hawa Mahal. Overnight at hotel in Jaipur.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Jaipur",
    details:
      "Early in the morning leave for Pahalgam the 'valley of shepherds' which is situated at the confluence of the streams flowing from river Lidder and Sheeshnag Lake. En route visit the Awantipura ruins, an 1100-year-old temple dedicated to Lord Vishnu and built by King Awantivarman. On arrival into Pahalgam enjoy the surrounding beauty or walk to Baisaran, which is about 2 km from Pahalgam. A small village set in pine and fir forest is pleasing and offers good view and u will proceed for Aru valley, and chandanwari by car (at your own cost). Aru is small village, 11 kms upstream the Lidder River, is a beautiful locality. The route is picturesque with ample campsites. This village is the starting point for treks to the Kolahoi glacier. The most beautiful of these is the huge, undulating meadow of Baisaran, surrounded by thickly wooded forests of pine. Hajan and Beetab valley on the way to chandanwari. In the evening return to Srinagar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Jaipur Drop",
    details:
      "After Breakfast, check out from Hotel and Proceed to Drop at Jaipur Airport.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Transportation - Entire round-trip journey by Private Vehicle Etios, /Dezire/Tavera/Innova",
  "Sightseeing in private vehicle as per the itinerary including Airport Pick up and drop.",
  "Meal Plan: MAPI (Room + Breakfast + Dinner)",
  "Hotel Taxes and Hotel Service Taxes",
  "Toll, Parking, Driver Allowance and all taxes  ",
];

const exclusionsData = [
  "Any other item not specified in cost includes",
  "Air fare / train fare. ",
  "Guide & Monuments Entrance fees during sightseeing",
  "Camera Charges / Jeep Safari / Camel Safari / Elephant Ride Charges.",
  "Expenses of personal nature such as tips, telephone calls, laundry, liquor, Insurance etc. ",
  "Any other item not specified in the Package Inclusions. ",
  "Any increase in Government taxes. ",
  "Supplement will be applicable for Single occupancy.",
];

const HeritagePinkCity = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={HERITAGE_PINK_CITY_RAJASTHAN_TOUR_RAJASTHAN_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default HeritagePinkCity;
