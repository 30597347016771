import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const SPLENDID_HIMACHAL_TOUR =
  "/assets/img/domesticTour/himachal/Splendid_Himachal.png";

const heroTextData = [
  {
    title:"Splendid Himachal",
    duration: "09 Nights / 10 Day",
    typoOfTrip: "Road Trip",
    startCity: "Chandigarh",
    endCity: "Chandigarh ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: " Chandigarh – Shimla ",
    details:
      "Arrival at Chandigarh railway station pickup & Drive to Shimla in the evening reach at Shimla. On the way some mountain view point’s enjoy your mountain Hill station Road travel Journey to Shimla. Check in hotel & overnight stay at hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "- Shimla - Kufri - Shimla",
    details:
      "This day after breakfast proceed for in and around sightseeing of SHIMLA, visit KUFRI, India's one of the best holiday paradises Kufri in Shimla is beautifully tucked on an altitude of 2,622 meter. The place is a tourist hotspot for its awesome scenic and sports activities. Evening free to stroll in the famous shopping place of Shimla town – “THE MALL & THE RIDGE” This large open space in the heart of town presents excellent views of the mountain ranges. Shimla's landmarks - the neo- gothic structure of Christ Church and the neo - Tudor library building- are worth seeing. Overnight stay at the Hotel in Shimla. Sightseeing for the day: -  Kufri, Green Valley, Chini Bungalow, Mini Zoo, Fun word Mall Road, Ridge, Christ Church, Gaiety Theatre, Scandal Point, Kali Bari Temple, Wood Market, Jansi Park ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Shimla - Kullu - Manali",
    details:
      "Meals: - Break Fast and Dinner After breakfast leave for Manali, on the way sight-seeing or viewpoints like Sunder Nagar Lake, Pandoh dam, Vaishno Devi Temple Aut Tunnel and overnight stay in Manali Sightseeing for the Day: - Sunder Nagar Lake, Hanogi devi temple, Pandoh Dam, Vaishno Devi temple, Kullu Valley, Aut Tunnel (Famous for Kullu Paragliding River Rafting) ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Manali – Local - Manali",
    details:
      "After taking early morning breakfast, procced for city tour of Manali sightseeing (Hadimba temple, Vashist Temple hot spring water, van Vihar, the mall road and Tibetan Monastery) Overnight stay at hotel.Sightseeing for the day: - Hadimba Devi Temple, Club House, Vashist Temple, Tibetan Monastery, Van Vihar, Local Market, Mall Road, Nature Park ",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Manali - Solang Valley - Manali",
    details:
    "After taking early morning breakfast, ready for full day sightseeing of Solang valley Overnight stay at hotel. About Solang Valley Solang Valley in Manali is located at a distance of 13 kms in a side valley at the top of the Kullu valley is a splendid valley between Solang village and Beas Kund. Solang valley offers the view of glaciers and snowcapped mountains and peaks. Solang Valley has fine ski slopes. The Mountaineering Institute of Manali has installed a ski lift for training purposes. Located here is a hut and guest house of the Mountaineering and Allied sports Institute, Manali. Now a few hotels have also come up. The winter skiing festival is organized in Solang Valley. Skiing and Paragliding are two main activities organized here (Charges 1500- 3500 approx.). Apart from this many other adventure sports are available like Zorbing, Horse riding & Snow Motorbikes riding etc. At your own expenses. g etc. At your own expenses.Activities in Solang Valley By your own: -Paragliding, Climbing, River Crossing, Rope Way, Zorbing, Zip ling, Ski Village, Snow Motorbikes riding  Note: - Rohtang pass and Atul tunnel pay an extra charge to visit as per cab expenses and Govt pass for Rohtang pass. Rohtang pass Closed Every Tuesday.Bhagsu Nath Temple, Bhagsu Waterfall, Dalai lama Temple, McLeod Ganj Dal lake, HPCAS (Himachal Pradesh Cricket Academy Stadium)",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: " Manali to Dharamshala",
    details:
      "Manali to Dharamshala: - Post an early morning breakfast, checkout from the hotel and drive to Dharamshala via Kullu. Reach Dharamshala by evening and check in into the hotel and have dinner along with overnight stay.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Dharamshala - Dalhousie",
    details:
      "After breakfast leave for Dalhousie. Overnight stay in a hotel at Dalhousie.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Dalhousie – Khajjiar - Dalhousie",
    details:
      "Today, we proceed to Khajjiar. Khajjiar is part of the notified Kala top – Khajjiar sanctuary. The Monal Pheasant, a bird which was fairly common till recent times has become relatively difficult to spot nowadays but this species can be spotted here. Check-in at the hotel in Dalhousie for overnight stay.About KHAJJIAR Often called India's Switzerland, the exquisite glade of Khajjiar (1960m) has a circumference of about 5 km. Along its fringes, thick forests of deodar climb the slopes, the snow-line rests above these woods. At Khajjiar there is a 12th century temple dedicated to KhajjI Nag. Within the temple are life size wooden images of the five Pandavas",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Dalhousie to Amritsar",
    details:
      "This day after breakfast leave for Amritsar and local sightseeing of Amritsar like, Golden Temple, Jallianwala Bagh, Wagha border overnight stay at hotel.Wagha Border, Jallianwala Bagh, Golden Temple",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Amritsar to Chandigarh",
    details:
      "Wake up in the morning and after having delicious breakfast Check out from the Hotel. Finally say goodbye to Amritsar and drive to Chandigarh carrying beautiful memories of the trip.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Shimla Sightseeing Tour",
"Manali Sightseeing Tour",
"Dalhousie Sightseeing Tour",
"Dharmshala Sightseeing Tour",
"Amritsar – Wagha Border, Golden Temple, Jallianwala Bagh",
 "Airport to Hotel Transfer on Private basis",
 " Hotel to Airport Transfer on Private basis",
 " Daily Breakfast & Dinner",
];

const exclusionsData = [
  "All personal expenses, optional tours and extra meals.",
  "Surcharges as applicable due to peak season, surcharge dates, trade fair dates.",
  "Camera fees, alcoholic/non-alcoholic beverages and starters.",
  "Vehicle service on leisure days for sightseeing not included in the itinerary.",
  "Mandatory Christmas/New Year Eve dinner cost will be applicable as advised by the hotels.",
  "Any services not mentioned in the inclusions list.",
  "Services of Tour Manager are not included in this package.",
  "Tips and porter-age.",
  "Tourism tax is excluded wherever applicable.",
  "Tips are excluded wherever applicable.",
];

const SplendidHimachal = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SPLENDID_HIMACHAL_TOUR}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SplendidHimachal;

