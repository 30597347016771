import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { RU4Image } from "../image";

const heroTextData = [
  {
    title: "Enchanting Russia - Moscow & ST Petersburg",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Moscow",
    endCity: "Saint Petersburg",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Moscow : Arrival",
    details: "Arrival at Moscow Airport. Airport – Hotel transfer in A/c vehicle on private basis. Check-in to Hotel after 14:00hrs. Evening free for leisure activities / For rest. Overnight at Hotel in Moscow. Note: 01st day: A/c vehicle service will be for Airport-Hotel transfers only.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Moscow",
    details: "After buffet breakfast in Hotel, will be met by our English speaking guide in Hotel’s lobby at 10:00 hrs morning to proceed for below ( By Metro ):- Metro tour to explore some beautiful metro stations of Moscow (The Moscow metro is one of the most extensive and heavily traveled subway systems in the world, but it also serves as a sort of museum of Russian history. Opened in 1935, the marble walls, high ceilings, stained glass, mosaics and chandeliers were a testament to the values of Joseph Stalin and his Communist party) Reaching Red Square for walking tour of below attractions :- Red square Kremlin St Basil’s Cathedral Lenin mausoleum Gum Shopping center Cathedral of Christ the savior. Visit to Zaryadye Park Overnight at Hotel in Moscow. Note: 02nd day: English speaking guide service from 10:00 hrs morning till 19:00 hrs evening. No service of A/c vehicle on this day.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Moscow",
    details: " After buffet breakfast in Hotel, will be met by our English speaking guide in Hotel’s lobby at 10:00 hrs morning to proceed for below (By A/c Vehicle):- Panoramic City tour (Sparrow hills / Moscow State University / Stalin Buildings / White House /Victory Park View / Red Square / Kremlin Walls) with English Speaking Guide. Visit to Arbat street with English speaking guide. 5* Radisson Moscow River Cruise Ride (Cruise tickets included in cost ) with English speaking guide, transfers in A/c vehicle. Overnight at Hotel in Moscow. Note: 03rd day: English speaking guide & A/c vehicle service from 10:00 hrs morning till 20:30 hrs evening.",
  },
  {
    id: "Day 4",
    header: "Moscow – Saint Petersburg",
    details: "Check-out from Hotel, transfer from Hotel-Railway station in A/c Vehicle on Private basis. To Take Sapsan High Speed train for Moscow- Saint Petersburg (Economy class Train tickets included in cost). Railway Station-Hotel transfers in A/c Vehicle. Check-in to Hotel in Saint Petersburg after 14:00 hours. Evening free for leisure activities / For rest. Overnight at Hotel in Saint Petersburg. Note: 04th day .A/c vehicle service will be for both side transfers.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Saint Petersburg",
    details: "After buffet breakfast in Hotel, at 10:00 hrs morning will be met by our English speaking guide in Hotel’s lobby to proceed below tour in (A/c vehicle) :- Visit to The Hermitage Museum ( Entrance tickets included in cost ) with English speaking guide. Visit to Palace Square (Main public square which is heart of St Petersburg city with history of revolution) Continue to Panoramic city tour with photo stop :- Peter & Paul Fortress Territory The Bronze Horseman. St.Isaac’s cathedral Church on the Savior of Split blood. Kazan Cathedral The Cruiser Aurora Shopping time at Galleria Shopping centre with English speaking guide. Overnight at Hotel in Saint Petersburg. Note: 05th day English speaking guide and A/c vehicle service will be from 10:00 hour’s morning till 20:00 hours evening.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Saint Petersburg",
    details: "After buffet breakfast in Hotel, at 10:00 hrs morning will be met by our English speaking guide in Hotel’s lobby to proceed below tour in (A/c vehicle) :- Visit to Peterhof Upper & Lower Parks with English speaking guide. Back to city after exploring Peterhof Parks :- Visit to Nevsky prospect with English speaking guide. Shopping time at Galleria Shopping centre with English speaking guide. Overnight at Hotel in Saint Petersburg. Note: 06th day English speaking guide and A/c vehicle service will be from 10:00 hour’s morning till 19:00 hours evening.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Saint Petersburg- India via Moscow",
    details: "After buffet breakfast at hotel & check-out from hotel ,will be picked up by driver from Hotel’s lobby in Saint Petersburg for transfer for Hotel-Airport in A/c Vehicle on Pvt basis. Note: 06th day : A/c Vehicle service will be for Hotel to Airport Transfers only.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Airport - Hotel transfer in A/c Vehicle on private basis.",
"03 Nights accommodation in Hotel in Moscow with Buffet breakfast on Dbl/Twin sharing basis.",
"02 Nights accommodation in Hotel in St. Petersburg with Buffet breakfast on Dbl/Twin sharing basis.",
"Metro tour to explore some beautiful historical metro stations with English Speaking Guide.",
"Metro tickets included in the cost.",
"1 walking tour of Red square / Kremlin area & other surrounding area of famous places of City (To explore the Real heart of the city). Visit to Zaryadye Park with English speaking guide.",
"Panoramic City tour (Sparrow hills / Moscow State University / Stalin Buildings / White House /Victory Park View / Red Square / Kremlin Walls) with English Speaking Guide.",
"Visit to Arbat street with English speaking guide.",
"5* Radisson Moscow River Cruise Ride with Cruise tickets (with English speaking guide), transfers in A/c vehicle.",
"Hotel- Railway Station transfers in A/c vehicle on private basis.",
"Sapsan High Speed train for Moscow – Saint Petersburg (Economy Class tickets included).",
"Railway Station – Hotel transfer in A/c Vehicle in St Petersburg on private basis.",
"Visit to The Hermitage Museum with Entrance tickets with English speaking Guide, transfers in A/c vehicle.",
"Panoramic City tour of Saint Petersburg with English speaking guide in A/c vehicle.",
"Shopping time with English speaking guide.",
"Hotel – Airport transfer in A/c Vehicle on private basis.",
"English speaking guide/Assistant service as per itinerary.",
"A/c vehicle service as per itinerary.",
];

const exclusionsData = [
"Air tickets cost not included for India to Russia to & fro.",
"Lunch not included in cost for any day.",
"Dinner not included in cost for any day.",
"Any type of Portrage Service at Hotel/Airport/Railway Station is not included.",
"Registration Fees at hotel or any type of City tax is not included.",
"Laundry , tipping, mini bar, telephone calls, internet service not included in cost.",
"Russian Tourist Visa Invitation not included in cost.",
"Russian Visa stamping fees not included in cost.",
"No any other services which is not mentioned in travel plan",
"Entrance tickets if anywhere applicable but not mentioned in travel plan.",
"Optional tours cost not included in given cost.",
];

const RU4 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={RU4Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default RU4;


// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
