import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { EU2Image } from "../image";

const heroTextData = [
  {
    title: "Exotic Swiss",
    duration: "08 Nights / 09 Days",
    typoOfTrip: "Road Trip",
    startCity: "Geneva",
    endCity: "Zurich",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
{
  id: "Day 1",
  header: "Arrive Geneva",
  details: "Arrive Switzerland, the heart of Europe. You will be met at the Airport and transferred to your hotel by a private vehicle. Spend the evening at leisure exploring the streets of Geneva. Overnight is at your hotel in Geneva. Welcome to Geneva! Situated along the banks of Lake Geneva at the foot of the Alps, Geneva sparkles as one of Europe's most beautiful cities. Home to the European headquarters of the United Nations, Geneva has a long history of diversity and tolerance dating back to the Protestant Reformation. Today, the city of Geneva is a cultural center second to none featuring world class entertainment, top rated restaurants and unlimited opportunities for recreation.",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 2",
  header: "Geneva – Geneva City Tour and Boat Cruise",
  details: "Buffet breakfast at the hotel. Depart on Geneva City Tour with Boat Cruise. The city tour of Geneva will take you through the international part of Geneva and to the centre of the world's peacemaking organisations. This will be followed by a tour of the main tourist attractions of the city like the Water Fountain (Jet d'eau) and the wonderful Flower Clock. Then get a taste of Geneva's history with the mini-train Tramway Tour and dig even deeper into the Old Town with your guide on a walking tour. To complete this City Tour, continue on the lake with a one hour commented boat cruise, which will let you discover the major attractions of the lake of Geneva: the stone of Niton, the Water Fountain, the Black Harbour, the Diodati House, the UN building, the castle of Bellerive, the Mermaid, and many more interesting sites. Make your own way back to Hotel. Overnight is at your hotel in Geneva.",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 3",
  header: "Geneva – St Moritz",
  details: "Buffet breakfast at the hotel. Check out and proceed to station on own to board your train to St Moritz. Explore St. Moritz a hiking paradise covered with mountain peaks with an altitude of more than 10,000 ft. Hook on many comfortable cable cars for a breath-taking views. Relax and enjoy the magnificence and density of this unique resort. Overnight is at your hotel in St Moritz. Switzerland's original winter wonderland and the cradle of Alpine tourism, St Moritz (San Murezzan in Romansch) has been luring royals, celebrities and moneyed wannabes since 1864. With its shimmering aquamarine lake, emerald forests and aloof mountains, the town looks a million dollars",
},
{
  id: "Day 4",
  header: "St Moritz – Bernina Express",
  details: "Buffet breakfast at the hotel. Experience what is called the most beautiful link between the cool North and the warm South. The Bernina Express passes through 55 tunnels, crosses 196 bridges and overcomes gradients of up to 70 pro mille without cogwheel drive. The train carries travelers from Chur, Switzerland's oldest city, into the stark mountain world above the fog line and past the Morteratsch Glacier. It then travels southward, right into the charming ambience of Veltin. This view was shared by UNESCO in 2008, when it designated the Bernina Express between Thusis and Tirano as a World Heritage Site – the third train route in the world to receive this honor. An imposing viaduct built with roughly hewn stone, framed by the wilderness of the mountain world, with high alpine fir trees and steep rock faces. On the viaduct, the little red train chugs along its track. That's the Bernina Express on its way from Graubünden to Veltlin. Overnight is at your hotel in St Moritz.",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 5",
  header: "St Moritz – Zermatt",
  details: "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station on own to board your Glacier Express to Zermatt. You will cross 291 bridges, 91 tunnels and the 2045 meters-high Oberalp Pass. Ride in the comfort of a modern panorama car and see dense forest, peaks capped with eternal snow, rushing mountain streams and centuries-old villages. Arrive Zermatt, and walk to your hotel on your own. This idyllic mountain village at the foot of the Matterhorn has developed into one of the biggest summer and winter resorts. There are no motorised vehicles in Zermatt. Besides electrically-powered cars and bicycles, the only form of transport allowed is horse-drawn sleighs and carriages. Overnight is at your hotel in Zermatt. Zermatt - Trigonometry at its finest, topographic perfection, a beautiful mountain – call it what you will, the Matterhorn is hypnotic. Like a shark’s fin it rises above the town, like an egotistical celebrity it squeezes into every snapshot, like a diva it has moods swinging from pretty and pink to dark and mysterious",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 6",
  header: "Zermatt - Matterhorn Glacier Paradise",
  details: "Buffet breakfast at the hotel. Today proceed to the Matterhorn Glacier Paradise. 17 tough kilometres of piste: deep black sections, glaciers and breathtaking heights. At 3,883 metres above sea level, the going is comfortably flat at first which is just as well because the air is thin. After the first large bend, the skier is taken by surprise by a really steep slope. Definitely not for beginners! Then: pleasant carving as far as Trockener Steg. Time for a rest at Europe's highest pizzeria before continuing on past the glacier chairlift. Carve to Furgg, and then brace yourself! It becomes black, steep and narrow. After 4 km, a reward in a delightful mountain bistro offering Valais specialities. Snow is absolutely guaranteed on this piste (glacier or snowfall). A real professional races down in 10 to 15 minutes! Overnight is at your hotel in Zermatt.",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 7",
  header: "Zermatt – Interlaken",
  details: "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station on own to board your train to Interlaken. Arrive Interlaken, the gateway to the Top of Europe is known for its remarkable location between the two beautiful lakes of Thun and Brienz. Spend the evening at leisure. Enjoy some adventure sports on your own. Overnight is at your hotel in Interlaken.",
},
{
  id: "Day 8",
  header: "Interlaken",
  details: "Buffet breakfast at the hotel. Proceed to Glacier 3000 today. A hiking paradise in summer and fun in the snow from October thru May! (Alpine coaster is currently closed for renovation). The beauty of nature also lays waiting to be discovered in the variety of fauna and flora. Overnight is at your hotel in Interlaken.",
  labelData: ["label1", "label2", "label3", "label4"],
},
{
  id: "Day 9",
  header: "Interlaken – Zurich - Depart",
  details: "Buffet breakfast at the hotel. Check out of your hotel and proceed to the station on own to board your train to Zurich airport and connect to board your flight back home. * The above itinerary is tentative and subjected to change without prior notice",
  labelData: ["label1", "label2", "label3", "label4"],
},
];

const inclusionData = [
  "Accommodation on Bed & Breakfast Basis: (Daily breakfast except on the day of arrival)",
  "Paris City Tour and Cruise by Coach",
  "Entry Tickets to Eiffel Tower / Montparnasse Tower, without transfers (Any one. Eiffel Tower tickets are subject to availability. Incase of Non – Availability; Montparnasse Tower will be included",
  "Mt. Jungfraujoch (In Conjunction with Swiss Pass)",
  "Mt. Titlis (In Conjunction with Swiss Pass)",
  "The Zurich TROLLEY Experience - Zurich City Tour",
  "Swiss Travel Pass (Regular) for 8 days in Second Class",
  "Second Class train ticket: Paris to Basel",
];

const exclusionsData = [
  "Airfare, Airport Taxes, Porterage",
  "Visas & Overseas Travel Insurance",
  "Meals other than mentioned in the itinerary",
  "High Season, Trade Fair & Special Event surcharge",
  "Anything not specifically mentioned in the Price Includes section.",
];

const EU2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={EU2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default EU2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
