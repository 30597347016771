export const VC1Image = "/assets/img/worldTour/VietnamCombodia/VC1.png";
export const VC2Image = "/assets/img/worldTour/VietnamCombodia/VC2.png";
export const VC3Image = "/assets/img/worldTour/VietnamCombodia/VC3.png";
export const VC4Image = "/assets/img/worldTour/VietnamCombodia/VC4.png";
export const VC5Image = "/assets/img/worldTour/VietnamCombodia/VC5.png";
export const VC6Image = "/assets/img/worldTour/VietnamCombodia/VC6.png";
export const VC7Image = "/assets/img/worldTour/VietnamCombodia/VC7.png";
export const VC8Image = "/assets/img/worldTour/VietnamCombodia/VC8.png";
export const VC9Image = "/assets/img/worldTour/VietnamCombodia/VC9.png";
export const VC10Image = "/assets/img/worldTour/VietnamCombodia/VC10.png";
export const VC11Image = "/assets/img/worldTour/VietnamCombodia/VC11.png";
export const VC12Image = "/assets/img/worldTour/VietnamCombodia/VC12.png";
export const VC13Image = "/assets/img/worldTour/VietnamCombodia/VC13.png";
export const VC14Image = "/assets/img/worldTour/VietnamCombodia/VC14.png";