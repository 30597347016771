import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import {
  SR1Image,
  SR2Image,
  SR3Image,
  SR4Image,
  SR5Image,
  SR6Image,
  SR7Image,
  SR8Image,
} from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const SCANDIREADY_TOUR = "/assets/img/worldTour/wt11.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Bergen & Fjord Excursion",
    image: SR1Image,
    description: "",
    path: "/sr1",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "Discover the Fjords of Norway",
    image: SR2Image,
    description: "",
    path: "/sr2",
  },
  {
    id: 3,
    fromToDays: "05 Nights / 06 Days",
    title: "Fjords & Pulpit Rock Adventure",
    image: SR3Image,
    description: "",
    path: "/sr3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Fjord & Glacier Adventure",
    image: SR4Image,
    description: "",
    path: "/sr4",
  },
  {
    id: 5,
    fromToDays: "07 Nights / 08 Days",
    title: "Bergen & Fjord Excursion",
    image: SR5Image,
    description: "",
    path: "/sr5",
  },
  {
    id: 6,
    fromToDays: "07 Nights / 08 Days",
    title: "Journey Around Iceland",
    image: SR6Image,
    description: "",
    path: "/sr6",
  },
  {
    id: 7,
    fromToDays: "10 Nights / 11 Days",
    title: "Scandinavian Fjords, Villages and Capital Tour",
    image: SR7Image,
    description: "",
    path: "/sr7",
  },
  {
    id: 8,
    fromToDays: "11 Nights / 12 Days",
    title:
      "The Vibrant Nordic Capitals of Four Countries and the Norwegian Fjords Combination",
    image: SR8Image,
    description: "",
    path: "/sr8",
  },
];

// const rulesData = [
//   ""
// ];

const Uzbekistan = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"ScandiReady"}
            subTitle={"Amazing Holidays to ScandiReady"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={SCANDIREADY_TOUR}
            title={"Popular ScandiReady Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Uzbekistan;
