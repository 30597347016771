import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { G2Image } from "../image";

const heroTextData = [
  {
    title: " Divine Shakti Trails",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Ahmedabad ",
    endCity: "Ahmedabad ",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "By Air / By Train – Arrive Ahmedabad",
    details:
      "Arrive Ahmedabad, check in at hotel & later we go for a sightseeing tour of Ahmedabad visiting Gandhi Ashram, Hutheesingh Jain temple, Adalaj stepwell & Vaishnodevi temple.Gandhi Ashram: On a quiet peaceful stretch of the Sabarmati River, Mahatma Gandhi set up a simple retreat in 1915. This was his Satyagrah Ashram and for many years it was the nerve centre of India’s freedom movement. It was from here, in 1930, that Mahatma began his famous Dandi March to the sea to protest the Salt Tax imposed by the British. Hridaya Kunj, the simple cottage where he lived, is now a national monument and preserved as it was during the Mahatma’s lifetime. Must pick gifts and souvenirs from the Ashram shop. Spend a little time here by the river watching the parrots and the squirrels. Peace and tranquility prevail here.Hutheesingh Jain Temple: Built outside the Delhi Gate in 1850 by a rich Jain merchant, the Hutheesingh Temple is the best known of Ahmedabad’s many ornate Jain temples. Adalaj Step-well: Step wells were built in olden times to serve the man purposes of - holding rainwater for later use, a resting place for the travellers, a watering hole for the travellers who had caravans and animals with them. Best examples of rainwater harvesting! The Adalaj step is situated 17kms north of Ahmedabad. The step well at the village of Adalaj is another fine example of this magnificent architectural form. Adalaj Vav is richly carved, every pillar and wall surface covered with leaves and flowers, birds and fishes and friezes of ornamental designs.Vaishno Devi Temple: A popular temple of goddess Vaishno Devi located on S.G. Road (Sarkhej-Gandhinagar highway), it is modelled based on Vaishno Devi temple situated in Jammu & Kashmir. The only difference between the two is that one at Ahmedabad can be visited within a couple of hours. It can be seen crowded every day, and on weekends devotees from all over India visit this temple.Overnight at hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "By Road – 180 Kms / 04 Hrs (One Way) – Ahmedabad – Ambaji - Ahmedabad   ",
    details:
      "After breakfast proceed for day excursion to Ambaji temple.Ambaji – Ambaji Temple contains no idol, but an inscribed Yantra in the niche. Ambaji is essentially a temple town-with a temple dedicated to Goddess Ambaji at the centre. The Ambaji Temple and the front court ‘Chachar no Chowk’ are aligned in the same axis of the cave at Gabbar hill-the original abode of Goddess Ambaji. Twenty-two kms from Danta is one of the major Shakti-piths of India, situated in the Arasur hills. The present temple was constructed a few years ago, maintaining the original architectural style. Millions of pilgrims visit the temple every year-especially during Navratri to seek the blessing of Goddess Ambaji.Later drive back to Ahmedabad.Overnight at hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: " By Road – 155 Kms / 03 Hrs (One Way) – Ahmedabad – Pavagadh - Ahmedabad  ",
    details:
      "After breakfast proceed for a day excursion to Pavagadh.PAVAGADH: Most of the Hindus in India have faith in goddess Mahakali, particularly from the western region covering the states of Gujarat, Rajasthan & Maharashtra. The famous and the oldest temple of Mahakali is situated at Pavagadh, near Vadodara, one of the main cities of Gujarat. The temple has become the place of the pilgrimage for almost all Hindus in India. This temple is among the three main Shaktipeeths in Gujarat. It has been said in Hindu Shastras that after the famous Tandava dance of Lord Shiva, the various parts of the body of Sati fell at many places. These places are called Shaktipeeths. It is said that the left breast of the Sati had fallen here at Pavagadh. As the name suggests, the temple is located on a mountain, near to Champaner town.Later drive back to Ahmedabad.Overnight at hotel",
  },
  {
    id: "Day 4",
    header: "By Road – 160 Kms / 03 Hrs – Ahmedabad To Chotila Followed By Road – 62 Kms / 01 Hr – Chotila To Rajkot",
    details:
      "After breakfast drive to Chotila hills. Chotila: On Ahmedabad-Rajkot Main Highway, Chotila town is situated at the foot of the Chamunda hill which is surmounted by the temple of the Goddess Chamunda at a height of 1173 feet. Originally a holding of the Sodha Parmar clan, Chotila was conquered from Jagasio Parmar by the KhacharKathis in 1566 AD. Most of the KhacharKathis trace their origin to the Chotila house. According to a legend, Jagasio Parmar of nearby town Muli was murdered by the Kathis of KhacharKuman and Vala clans. These Kathis took possession of Chotila and then divided it into four parts (char tilas), the village thereafter came to be known as Chotila.Later drive to Rajkot; arrive in Rajkot and transfer to your hotel.Rajkot is a very pleasant provincial town. Mahatma Gandhi spent a few years of his life in this town. His father was the Dewan (Chief Minister) of Rajkot, once a small princely State. In the evening arrive Rajkot and visit Kaba Gandhi No Delo (literally Kaba Gandhis house). Mahatma Gandhi spent the early days of his life here. You can see the exhibition of Gandhis items here.This will be followed by a visit to Rajkumar College.Mahatma Gandhi completed his secondary education from this school.Overnight at hotel",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: " By Road – 100 Kms / 02 Hrs – Rajkot To Junagadh",
    details:
      "After breakfast drive to Junagadh enroute visiting Girnar hills.Mount Girnar: Girnar Mountain is about 1100m (3630 ft.) high and is an extinct volcano. It is said that Mount Girnar was once a flying mountain. It is 4km east of Junagadh and is the main tourist attraction in the area.On the plateau about 180m (600 ft.) from the top of the hill, is a group of beautifully carved Jain temples built between 1128 and 1500. Neminath, the 22nd tirthankara, is said to have died on Mount Girnar after living here for 700 years. There is a special marble temple dedicated to him here, and a black marble statue of him. The Mallinath Temple (1177) is impressive. On top of the hill is a small Vishnu temple dedicated to Dattatreya. There is also a temple dedicated to the goddess Amba Mata dating back to the 12th century. It is said that a visit to this temple guarantees a happy marriage. There are good views from the top of the mountain.The steps go down and up again to Gorakhnath Peak at 1100m (3666 ft.), where there are footprints of the rishi Gorakhnath. There is another peak where there are footprints of Neminath. Further on is a goddess Kalika shrine. Visitors must climb 5,500 steps to reach the top of the hill, so it is best to start early in the morning (before 7 am). It is a difficult two-hour climb. Hundreds of people climb this hill every day. There are many stalls selling cold drinks along the way, but none selling bottled water, so bring your own.Later drive to Junagadh; arrive Junagadh and transfer to your hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "By Road – 325 Kms / 06 Hrs – Junagadh To Ahmedabad",
    details:
      "After breakfast drive back to Ahmedabad. Arrive Ahmedabad and in time transfer to Airport / Railway Station to board your flight / train for onward journey to your next destination.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "05 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
  "Meal Plan – As Mentioned above.",
  "All currently applicable taxes.",
  "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
  "Road taxes, parking fee, fuel charges, interstate taxes.",
];

const exclusionsData = [
  "Monument Entrance Fee.",
  "Services of English-Speaking Local Guide.",
  "Game Drive at Sasangir National Park or any other national park.",
  "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
  "International or Domestic airfare / Train Fare.",
  "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
];

const G2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={G2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default G2;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
