import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const ANDAMAN_TOUR = "/assets/img/domesticTour/Andaman.png";

const A1 =
  "/assets/img/domesticTour/andaman/A1.png";
const A2 =
  "/assets/img/domesticTour/andaman/A2.png";
const A3 =
  "/assets/img/domesticTour/andaman/A3.png";
const A4 =
  "/assets/img/domesticTour/andaman/A4.png";
const A5 =
  "/assets/img/domesticTour/andaman/A5.png";
const A6 =
  "/assets/img/domesticTour/andaman/A6.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "3 Nights 4 Days Family Holiday Tour Package ",
    image: A1,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "4 Nights 5 Days Family Holiday Tour Package",
    image: A2,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a2",
  },
  {
    id: 2,
    fromToDays: "05 Nights / 06 Days",
    title: "5 Nights 6 Days Family Holiday Tour Package",
    image: A3,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a3",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "6 Nights 7 Days Family Holiday Tour Package",
    image: A4,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a4",
  },
  {
    id: 4,
    fromToDays: "07 Nights / 08 Days",
    title: "7 Nights 8 Days Family Holiday Tour Package",
    image: A5,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a5",
  },
  {
    id: 5,
    fromToDays: "08 Nights / 09 Days",
    title: "8 Nights 9 Days Family Holiday Tour Package",
    image: A6,
    description:
      "Fall in love with Jammu & Kashmir with our affordable Kashmir tour packages. Jammu & Kashmir is a world-famous tourist destination known for its captivating landscapes, ...",
    path: "/a6",
  },
];

// const rulesData = [
//   "Pre-Paid cellular connections do not work in Jammu & Kashmir region. Only Jio, Airtel & Bsnl Post-Paid will work here.",
//   "BAISERAN is the best place to visit in Pahalgam though it is not a snow-covered area but the place has its own natural beauty. Either you go by walk or by Horse Riding.",
//   "Sonamarg: After Sonamarg, there is a Point called Zero point away from Sonamarg, Thajiwas Glacier and activities like Fish Point (Under Water Balloon Ride). There is only option Union Cab, Charges for Union Cab: 3500/-to 5000/- (Negotiable)",
//   "Gondola, you will find Snow and other activities related to snow. Tickets Are fixed as per Govt. fare i.e., Rs. 780 for 1st phase & 920 for 2nd phase",
//   "For Kashmiri Wazwan Food: - Aadhoos at Lal Chowk Srinagar is the best place and Mutton Kebab at Gee Enn Bakers - Residency Road.",
// ];

const AndamanPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Andaman Tour Packages"}
            subTitle={"Amazing Holidays to Andaman"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={ANDAMAN_TOUR}
            title={"Popular Andaman Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Andaman"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default AndamanPackages;
