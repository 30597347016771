import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR5Image } from "../image";

const heroTextData = [
  {
    title: "Bergen & Fjord Excursion",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Bergen",
    endCity: "Oslo",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Bergen, the Gateway to the Fjords",
    details: "Arrival in Bergen according to your own itinerary, private transfer to your hotel. Bergen is Norway’s second largest city and the world’s only city surrounded by 7 mountains and 7 fjords. Bergen is often referred to as the Gateway to the Fjords, due to its close proximity to the world famous Norwegian fjords. This makes Bergen the perfect base for day trips into the fjord region. Accommodation in Bergen - Clarion Admiral or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "City Sightseeing Bergen",
    details: "Explore Bergen’s most famous attractions at your own pace with a hop on, hop off sightseeing bus. Guiding in eight languages. Departures every half hour. The “hop on, hop off” sightseeing bus takes you to well-known attractions like Bryggen, the Fish Market, Bergen Aquarium, Bergen Museum, The Grieg Hall, The Fløibanen Funicular, St. Mary’s Church, Old Bergen Museum etc. This is an excellent opportunity to explore the city at your own pace, choosing yourself where you want to get on and off. The bus departs every half hour and you can choose between eight different languages for the guiding on the bus. Accommodation in Bergen - Clarion Admiral or similar.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "The Hanseatic City of Bergen",
    details: "Breakfast at the hotel. This day is at your own leisure in Bergen. We recommend that you start off by exploring the Hanseatic Bryggen Wharf. The very first buildings in Bergen were alongside the harbour called Bryggen. Bryggen has been the nerve centre of the city for hundreds of years and the silhouette of its ancient gables is perhaps the most familiar image in all of Norway. In 1360 the Hansas - a German guild of merchants - set up one of their import/export offices on Bryggen and dominated trade for the next 400 years. The wooden buildings at Bryggen have been devastated by fire several times, and the Great Fire of 1702 reduced the whole city to ashes. But Bryggen was quickly re-built on top of the foundations from the 11th century. Bryggen is now on UNESCO's World Heritage List. Other sights of interest include the Fløibanen Funicular, Edvard Grieg's home Troldhaugen, the Fish and Flower market, Bergen Aquarium and Gamle Bergen (Old Bergen Open Air Museum). Accommodation in Bergen",
  },
  {
    id: "Day 4",
    header: "Fjord Excursion: Fjord Cruise & Flåm Railway",
    details: "Breakfast at the hotel. Departure by train from Bergen to Voss, before continuing by bus to Gudvangen. A 2-hour fjord cruise will bring you along the Nærøyfjord to Flåm. The Nærøyfjord is the narrowest fjord channel in Europe, and has recently been added to UNESCO’s World Heritage List. At 2 pm you will arrive in the small fjord village of Flåm, where you will spend the night so that you can experience the Flåm Railway in day-light the next day. Accommodation in Flåm Fretheim hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Flåm- & Bergen Railway to Oslo",
    details: "Breakfast at the hotel. Just before noon your Norwegian adventure continues on board the Flåm Railway, which passes through wild and beautiful mountain scenery with waterfalls and steep mountain sides from the Sognefjord reaching up to a height of about 900 metres. There is no other adhesion railway in the world running on normal tracks that is so steep over a long distance. From Myrdal your journey continues on board the Bergen Railway, taking you across the Hardangervidda Mountain Plateau, with Finse as the highest train station at 1222 meters above sea level. After a 5 hour train journey you will arrive in Norway’s capital Oslo. The city is beautifully situated at the head of the Oslo Fjord and surrounded by forested ridges. Accommodation in Oslo – Thon hotel Opera",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Oslo Grand Tour",
    details: "Breakfast at the hotel. This tour is a perfect combination of all the “must see” attractions in Oslo (the peaceful city center, the modern Holmenkollen ski jump, the impressive Vigeland Sculpture Park, a visit of your choice to either the Fram Museum or Kon-Tiki Museum, an informative tour of the Viking Ship Museum) with a delightful and relaxing 2-hour cruise on Oslofjord. The best choice to explore all the city’s highlights and to admire the natural splendor of Oslo from the sea! A complete and unforgettable experience!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Leisure day in Oslo",
    details: "The fast-growing Norwegian capital is beautifully situated at the head of the Oslo Fjord. With it's new neighbourhoods and eye-catching architecture it is one of the most modern capitals in Europe and offers a wide range of activites and sights. Sights of interest include Vigeland Sculpture Park, the Viking Ship Museum, the Munch Museum, Aker Brygge and Oslo's opera house. We recommend one of the may sightseeing tours in Oslo (please see add-ons for further details). Accomodation in Oslo.",
  },
  {
    id: "Day 8",
    header: "Departure from Oslo",
    details: "Breakfast at the hotel. Departure from Oslo according to your own itinerary – Private transfer",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"A panoramic city sightseeing by a modern double-decker coach",
"Holmenkollen Ski Jump (approx 30 min break; the entrance to the Ski Jump Tower is not included)",
"A guided walk through Vigeland Sculpture Park (approx 45 min)",
"Entrance fee to either Fram Museum or Kon-Tiki Museum (free choice)",
"Entrance fee to the Viking Ship Museum",
"Authorized Oslo guide",
"A 2-hour sightseeing cruise on Oslofjord",
"nights hotel accommodation in a standard room with private shower/WC, including breakfast. Hotels - Bergen - Clarion Admiral, FLÅM - Fretheim Hotel, Oslo - Thon Hotel Opera",
"Private transfer in Bergen from airport – your hotel",
"Private transfer in Bergen from your hotel – railway station",
"Private transfer hotel in Oslo – airport",
"Train from Bergen to Voss",
"Bus from Voss to Gudvangen",
"Boat from Gudvangen to Flåm – new ferry Vision of fjords",
"Train from Flåm via Myrdal to Bergen",
"City Sightseeing Bergen hop on hop of",
"Grand tour Oslo",
"accommodation in standard rooms",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
  ];

const SR5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR5;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
