import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U6Image } from "../image";

const heroTextData = [
  {
    title: "Western Fiesta",
    duration: "07 Nights / 08 Days",
    typoOfTrip: "Road Trip",
    startCity: "Las Vegas",
    endCity: "Francisco",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Los Angeles",
    details: "On arrival Los Angeles Airport get transferred to hotel. Rest of the day is free at leisure. Explore the city on your own. Overnight stay at Los Angeles.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Los Angeles - Universal Studios Hollywood",
    details: "Join us today for a tour of Universal Studios which has always been known for its innovative rides and attractions, and is designed to let visitors experience the movies like never before. A trip to Universal is like a trip into the heart of Hollywood. With films and TV shows always in production, one never knows who they might see wandering the sidewalks of the park.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Los Angeles – Las Vegas Helicopter City lights Tour",
    details: "Check out from hotel and we transfer you to coach station for onward coach to Las Vegas. Upon arrival get transferred to hotel. Evening proceed Helicopter Tour of the city. Join Vegas Nights Helicopter Tour and soar through the night’s sky. Seize this once in a lifetime opportunity to see the City of Lights from most spectacular view possible. Vegas Nights is an amazing helicopter tour flying over the resorts of the Las Vegas Strip from the New York-New York skyline to the never fading light of the Luxor pyramid.",
  },
  {
    id: "Day 4",
    header: "Las Vegas Double Decker Bus Tour",
    details: "Day is free at leisure. Evening proceed for Night tour of Vegas by Double Decker Bus. Let the neon night colours of Las Vegas dazzle you on this night bus tour of the entertainment capital of the world. This panoramic Night Tour takes you to all of the most significant landmarks of this wild city on a comprehensive bus route topped off with a stop at Fremont Street, which you will be able to explore by foot – the only way to truly experience the thriving life on Fremont Street!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Las Vegas",
    details: "Spend the day at leisure OR take the Grand Canyon Tour BUS OR AIR (OPTIONAL). Don’t Forget the New Wonder of the West the SKYWALK, In the evening, take the Las Vegas Night Flight (OPTIONAL), a Helicopter Ride over dazzling night time Vegas, take in a fabulous Show or visit any of the free shows such as the Sirens of Treasure Island and much more.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Las Vegas – San Francisco",
    details: "Fly to San Francisco, which is one of the few North American cities to achieve the legendary status of such places as Paris or London. The city's well-known hills offer stunning views of the Pacific Ocean and San Francisco Bay, and feature a wide array of shops, restaurants, and cosmopolitan nightlife. In addition to its diversity and charm, San Francisco is a gateway to Silicon Valley, America's premier high-technology centre. Upon Arrival, check in to the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "San Francisco City Tour",
    details: "Today we proceed on a sightseeing tour of San Francisco. You will visit the famous Golden Gate Bridge, with the world’s highest bridge towers. Continue on to Twin Peaks, Lombard Street, Cable Car, Pier 39, Nob Hill, Civic Center, Union Square, Fisherman’s Wharf, Palace of Fine Arts & other highlights.",
  },
  {
    id: "Day 8",
    header: "Departure San Francisco",
    details: "Check out from hotel and get transferred to San Francisco airport for onward journey. End of tour",
  },
];

const inclusionData = [
" 07 Night’s Accommodation with breakfast as per mentioned or similar (No Breakfast in Las Vegas)",
" 1 Day Universal Studios Hollywood base ticket with return transfers on Seat in Coach basis",
" Helicopter Night tour of Vegas with Limousine transfers from Hotel",
" Double Decker Bus tour of Vegas",
" San Francisco city tour with Bay Cruise on Seat in Coach basis",
" Los Angeles Airport / Hotel transfers on Seat in Coach basis",
" Los Angeles Hotel / Coach Station transfers on Private basis",
" One way coach ticket from Los Angeles to Las Vegas",
" Las Vegas Coach station / Hotel transfers on Private basis",
" Las Vegas Hotel / Airport transfers on Seat in Coach basis",
" San Francisco Airport / Hotel round trip transfers on Seat in Coach basis",
];
const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U6 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U6Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U6;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];


