import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { C3Image } from "../image";

const heroTextData = [
  {
    title: "Enchanting Eastern Canada",
    duration: "06 Nights / 07 Days",
    typoOfTrip: "Road Trip",
    startCity: "Toronto",
    endCity: "Toronto",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Toronto – Niagara - Niagara Falls by night",
    details:
      "Arrive into Toronto’s Pearson International airport. After clearing the immigration procedures proceed towards Niagara on SIC coach/Van. Arrive in Niagara and check in to your hotel. Evening is free at leisure. You may enjoy Illumination show of Niagara Falls by Night (On your Own).Overnight at the hotel. brightness_3Night Stay: Niagara Falls local_diningMeals: Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Full day walking tour of Niagara Falls – Toronto",
    details:
      "After Breakfast, Check out of the hotel and proceed for Full day walking tour of Niagara Falls. Enjoy the Horn blower Niagara Cruise. Also visit The Niagara’s Fury – a 3D movie experience which takes you through the ancient story of Niagara Falls. Later take the Journey behind the falls. Enjoy the White Water Walk - A walk like no other, along a boardwalk at the very edge of one of the world's wildest stretch of whitewater! Later on, proceed to Toronto on SIC basic and check in to your hotel in Toronto. Overnight at the hotel. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Toronto – City Tour of Toronto – CN Tower",
    details:
      "After breakfast, proceed for the City tour of Toronto on Hop on Hop off basis. Visit the world famous CN Tower. Take a ride to the Observatory Deck atop the majestic and monumental CN tower. Later on visit Harbor front, Dundas square, Casa Loma, Queen’s park, Chinatown, Roger’s Center etc. Evening is free at leisure to explorer downtown Toronto. Return back to you hotel and relax. Overnight in the hotel. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
  },
  {
    id: "Day 4",
    header: "Toronto – 1000 Island - Ottawa- Montreal",
    details:
      "After breakfast, board your coach for Montreal. Enroute visit 1000 Islands. It consists of 1864 Islands between Canada and US border in the Saint Lawrence River. Take 1 hr cruise of 1000 Island. After lunch, proceed towards Ottawa, the Capitol of Canada. Arrive in Ottawa and proceed for the orientation tour of Ottawa. You will explore the Parliament Buildings, Embassies, Universities and Museums. You will see the Canadian Mint National Gallery of Canada, the Museum of Civilization, the Residence of the Prime Minister and the National Arts Centre. You will drive through scenic gardens and parklands and along the Rideau Canal, one of Ottawa's picturesque waterways. Later on, proceed towards Montreal. Arrive in Montreal and check into your hotel and relax.",
  },
  {
    id: "Day 5",
    header: "Montreal city tour",
    details:
      "After breakfast, proceed for the city tour of Montreal. Highlights of this tour includes: visit to the beautiful Notre Dame basilica and St Joseph’s Oratory. Take a photo stop at the top of Mt Royal, the observation point, for the panoramic view of the city. Continue your orientation tour with Bio Dome, the Residential and Financial districts, Historic Old Montreal, Bio Dome and the Olympic stadium. In the evening, enjoy a walk in downtown Montreal and the underground city. Overnight in Montreal.",
  },
  {
    id: "Day 6",
    header: "Montreal- Day trip to Quebec City",
    details:
      "After breakfast, leave for day trip to Québec city. Discover the historical treasures a wonderful “joie de vivre” atmosphere of Quebec City. Your expert guide provides Narration as you tour through the only Walled City in North America. Highlights include the Upper and Lower Towns, the Parliament Buildings, Plains of Abraham, the Citadel, Chateau Frontenac, Place d’Armes, Quebec National Assembly and the Notre Dame Basilica. Later in the evening, return back to Montreal. Enjoy the dinner prepared by our chefs and later on go and enjoy the night life of Montreal city. Overnight in the hotel.",
  },
  {
    id: "Day 7",
    header: "Toronto Airport",
    details:
      "After breakfast, check out of the hotel and transfer to the airport on SIC basis to board your flight towards your next destination. brightness_3Night Stay: Toronto local_diningMeals: Breakfast & Dinner",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"Accommodation as per given details with breakfast.",
"Sightseeing as per given details.",
"Service of Vehicle as per given details.",
"Arrival day airport transfer will be by the hotel’s Courtesy Shuttle.",
"Service of Driver Cum Guide.",
"Tips to the Vehicle driver.",
"All Taxes as applicable.",
];

const exclusionsData = [
"Any Airfares or Visa Fees.",
"Meals – Lunch & Dinner.",
"Water Bottle in vehicle or Room.",
"Porterage, Telephone calls, Mini Bar, Laundry, etc.",
"Travel Insurance.",
"City tax or Resort Fees or Environment Tax that hotel may collect only upon arrival.",
"Deposit that guest may need to pay at the time of Check-in for incidental expenses.",
"Any entrances or sightseeing not specifically mentioned in the above inclusion.",
];

const C3 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={C3Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default C3;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
