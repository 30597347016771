import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { KT5Image } from "../image";

const heroTextData = [
  {
    duration: "04 Nights / 05 Days",
    title:  "Bangalore / Mysore / Coorg / Bangalore",
    typoOfTrip: "Road Trip",
    startCity: "Bangalore",
    endCity: "Bangalore",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive Bangalore + Drive to Coorg (290 Kms / 5 – 6 Hrs)",
    details: "On arrival at Bangalore, meet our chauffeur waiting for you and proceed towards Coorg. En route Coorg visit the famous Buddhist Monastery and the Golden Buddha Temple located in Bylekuppe. Bylekuppe - One of the largest Tibetan settlements in South India, it is known for its monasteries, handicrafts, carpet factories, and incense factory. Arrive Coorg in the evening and check into the hotel. Rest of the day free at leisure. Evening free for relaxation. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Coorg",
    details: "After breakfast, enjoy sightseeing of Coorg visiting Bhagamandala, Omkareshwara Temple, Madikeri Fort, Abbey Falls and Raja’s Seat. Evening free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Drive Coorg – Mysore (140 Kms / 3 – 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Mysore. Arrive Mysore in the noon and check into the hotel. Later proceed for sightseeing of Mysore visiting Maharaja’s Palace. Later in the evening proceed to KRS Dam and Brindavan Gardens. One can also enjoy Sound & Light show in the Gardens. Evening free at leisure. Overnight in the hotel",
  },
  {
    id: "Day 4",
    header: "Drive Mysore – Bangalore (140 Kms / 3 – 4 Hrs)",
    details: "After breakfast, check out of the hotel and drive to Bangalore. Arrive Bangalore in the noon and check into the hotel. Later proceed for sightseeing of Bangalore City visiting Vidhan Soudha, Lalbagh Botanical garden, Tipu Sultan’s palace and Bull temple – one of the oldest temple built in 16th century. Rest of the time free at leisure. Overnight in the hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Depart Bangalore",
    details: "After breakfast, time free until noon. Later check out of the hotel and proceed to the airport in time for your flight to your onward destination.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

// const inclusionData = [
// "10 Nights accommodation at the hotel mentioned above or similar category of alternate hotels. ",
// "Meal Plan – As Mentioned above.",
// "All currently applicable taxes.",
// "All transfers, city tours and transport services by air-conditioned All India Tourist permit Vehicle as per the above itinerary.",
// "Road taxes, parking fee, fuel charges, interstate taxes.",

// ];

// const exclusionsData = [
// "Monument Entrance Fee.",
// "Services of English-Speaking Local Guide.",
// "Game Drive at Sasangir National Park or any other national park.",
// "Expense of personal nature like tipping, laundry, telephone / fax calls, alcoholic beverages, camera/video camera fee at monuments, medical expenses, airport departure tax etc. Any other services not mentioned in the inclusions.",
// "International or Domestic airfare / Train Fare.",
// "Any expenses caused by reasons beyond our control such as roadblocks, accidents & any medical evacuations. Any train delays, or re-scheduling etc. Any Visa fees, insurance fees, etc. Any tips to hotel staff, local guides, drivers etc. Any meals apart from the ones mentioned above. Any expenses apart from the ones above.",
// ];

const KT5 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={KT5Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          // inclusionsData={inclusionData}
          // exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default KT5;



