import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./Shadows";
import { light, dark } from "./Palette";
import { typography as overrideTypography } from "./Typography";

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      shadows: shadows(mode),
      typography: overrideTypography,
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary: mode === "light",
          },
        },
        MuiIcon: {
          styleOverrides: {
            root: {
              color: "#212121",
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: "#212121",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
              
            },
          },
        },
        MuiInputLabel:{
          styleOverrides:{
            root:{
              color: "#000000"
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
              ".MuiInputLabel-root":{
                color: "#333333",
              },           
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 16,
              boxShadow: 4,
              bgcolor: "#101010",
              "	.MuiCardHeader-title": {
                fontSize: 22,
                fontWeight: "300",
              },
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              color: "#333333",
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            "*::-webkit-scrollbar": {
              width: "5px",
            },
            "*::-webkit-scrollbar-track": {
              background: "#ffffff",
            },
            "*::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "3px",
            },
          },
        },
      },
      themeToggler,
    })
  );

export default getTheme;
