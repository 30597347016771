import React from "react";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  Card,
  Container,
} from "@mui/material";

import PropTypes from 'prop-types';

import ItineraryTimeline from "./ItineraryTimeline";

const Itinerary = ({intineraryTimelineData}) => {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  sx={{
                    textAlign: "start",
                    color: "text.primary",
                    //   textShadow: "1px 1px 2px black",
                  }}
                >
                  Overview & Itinerary
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Grid item xs={12}>
            <ItineraryTimeline intineraryTimelineData={intineraryTimelineData} />
          </Grid>
        </Card>
      </Box>
    </Container>
  );
};

Itinerary.propTypes = {
  intineraryTimelineData: PropTypes.func,
};


export default Itinerary;
