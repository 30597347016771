import React from "react";
import { Box, Container, Grid } from "@mui/material";
import Main from "../../Components/layouts/Main";
import HeroSection from "./HeroSection";
import ContentSection from "./ContentSection";


const ContactUs = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeroSection />
          </Grid>
          <Grid item xs={12}>
            <ContentSection/>
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
};

export default ContactUs;
