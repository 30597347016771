import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { U12Image } from "../image";

const heroTextData = [
  {
    title: "Splendors of the East",
    duration: "09 Nights / 10 Days",
    typoOfTrip: "Road Trip",
    startCity: "New York",
    endCity: "Orlando",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive in New York",
    details:
      "Welcome to the Big Apple, the City That Never Sleeps. On arrival you will be transferred to the Hotel. The day is free at leisure to explore the city on your own. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "New York City Tour",
    details:
      "Breakfast at hotel and proceed for New York City tour with entrance to Empire State Building & Statue of Liberty Ferry Ride on Seat in Coach Basis. Highlights of the tour: Statue of Liberty express One hour, Non-stop Cruise (Ferry Ticket Only), Empire State Building’s 86th floor Observatory (Admission Included), Rockefeller Center/St. Patrick’s Cathedral, Ground Zero/WTC/911 Memorial, etc. Overnight stay in New York.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "New York - Washington",
    details:
      "Check out from hotel and we drop you to coach station for onward journey to Washington DC, capital of the United States of America and a Guide to the Historic Neighborhoods and Monuments. Evening proceed for Night tour of Washington on Seat in coach basis. When the sun goes down, Washington turns into a different city, and Old Town Trolley Tours knows just the right way to show off the Capitol City at night! See the Capitol Building, the White House, the Jefferson Memorial and the Washington Monument. The trolley will also make stops at the Franklin D. Roosevelt Memorial, the Lincoln Memorial, the Vietnam Veteran's Memorial, the Korean War Veteran's Memorial and the Iwo Jima Memorial. Enjoy of the most emblematic monuments of the city, crossing them in the light of the moon. ",
  },
  {
    id: "Day 4",
    header: "Washington - Buffalo – USA side Niagara Falls",
    details:
      "Take a flight to buffalo and upon arrival we transfer you to Niagara where the world famous falls is located. It is one of the natural wonders of the World. Rest of the day is Free at leisure.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "USA side Niagara Falls",
    details:
      "On your own proceed to experience the famous boat ride “Maid of the mist” which takes us close to the thundering falls and around the American and Horseshoe Falls (seasonal). Overnight stay at Niagara.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Buffalo - Orlando",
    details:
      "Today we depart to Orlando by flight. Orlando is the sixth largest city of Florida and is also known to be its largest inland city. The presence of Disney World theme park has made this city a well-known vacation spot. This city is said to welcome more than 52 million tourists every year. Upon arrival transfer to your hotel.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Orlando – Walt Disney Magic Kingdom",
    details:
      "Breakfast at the Hotel. Proceed to Magic Kingdom. This covers 107 acres, opened on October 1, 1971. It’s been dubbed The Most Magical Place on Earth! and is home to seven themed lands, and dozens of attractions that appeal to all ages. It is the place where storybook fantasy is everyday reality, and Disney classics are brought to life from the moment you step onto Main Street USA, you are transported to a place where the cares of the outside world seem to magically melt away. Visit Magic Kingdom make your childhood fantasies come true as you step into fairytale land of themed rides, shows and parades inspired by Disney’s Stories. Hop on to the happiest land, the Magic Kingdom, where the legend of Mickey Mouse begins. The magic will bring you the classical vehicles Western Carriage, Pirates Treasure Island and Cartoon stars. Overnight in Orlando",
  },
  {
    id: "Day 8",
    header: "Orlando - Epcot Center",
    details:
      "Begin your day at the Epcot center where you can touch the future and travel the world with an amazing array of attractions and live performances. This is an area of 260 acres devoted to past achievements and the future of technology. World showcase offers a kind of permanent World Fair, with pavilions devoted to eleven countries.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Orlando Sea World",
    details:
      "Today you will visit the worlds largest Marine Park Sea World - Here you shall discover many attractions. We dare you to take a one-of-a kind water coaster thrill ride through the mysterious lost city of Atlantis. Soar through danger on a jet copter ride to the Wild Arctic. At Sea World, touch, feed or get face to face with awesome and amazing wild animals, and get in on all the action that draws you back again and again!",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Departure from Orlando",
    details:
      "After breakfast, check out from hotel and we transfer you to airport for onward journey. End of tour and services",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  " 09 Night’s Accommodation with breakfast as per mentioned or similar",
  " All Airport – Hotel – Airport Transfers on Seat in Coach basis ( In Niagara & Orlando Private Return Airport)",
  " Bus Intercity from New York – Washington on Seat in coach basis",
  " Bus Station to Hotel to Bus Station transfer in New York and Washington on Private",
  " City Tour’s : New York & Washington by Night in Seat in Coach basis",
  " Entrance Tickets with Transfers : Statue of Liberty, Empire State Building, Maid of the Mist, Sea World, Epcot Center, Magic Kingdom.( In Orlando Transfers to Sightseeing via Hotel Shuttle )",
  " All Taxes",
];

const exclusionsData = [
  "Airfare, Visa’s and Taxes, Baggage Charges",
  "Resort fee / City Tax / Utility Charges wherever applicable has to be paid directly",
  "Meals, Lunch, Dinner unless specified",
  "Any item not mentioned in Package inclusions",
  "Tips to drivers and Gratuities",
  "Overseas Mediclaim insurance",
  "Bank Remittance charge of USD 30",
  "Any items not mentioned in includes column",
];

const U12 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={U12Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default U12;

// const intineraryTimelineData = [
//   {
//     id: "Day 1",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 2",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 3",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 4",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 5",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 6",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
//   {
//     id: "Day 7",
//     header: "",
//     details: "",
//   },
//   {
//     id: "Day 8",
//     header: "",
//     details: "",
//     labelData: ["label1", "label2", "label3", "label4"],
//   },
// ];
