import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";
import { CR1Image, CR2Image } from "./image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";

const CROATIA_TOUR = "/assets/img/worldTour/wt14.png";

const tourCardContent = [
  {
    id: 1,
    fromToDays: "07 Nights / 08 Days",
    title: "Best of Croatia FIT Tour",
    image: CR1Image,
    description: "",
    path: "/cr1",
  },
  {
    id: 2,
    fromToDays: "07 Nights / 08 Days",
    title: "Best of Croatia Tour",
    image: CR2Image,
    description: "",
    path: "/cr2",
  },
];

// const rulesData = [
//   ""
// ];

const Croatia = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Croatia"}
            subTitle={"Amazing Holidays to Croatia"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={CROATIA_TOUR}
            title={"Popular Croatia Holiday Croatia"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default Croatia;
