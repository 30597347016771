import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

import {RST1Image,RST2Image} from "./image";
import { ST1Image } from "../image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";




const tourCardContent = [
  {
    id: 1,
    fromToDays: "03 Nights / 04 Days",
    title: "Historical Rajasthan",
    image: RST1Image,
    description: "",
    path: "/rst1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Shades of Culture",
    image: RST2Image,
    description: "",
    path: "/rst2",
  },
];

// const rulesData = [
//   ""
// ];

const RajasthanStudent = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
    <Main>
      <Grid>
        <TourSubPackage
          mainTitle={"Rajasthan Student Tour"}
          subTitle={"Amazing Holidays to Rajasthan Student Tour"}
          // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
          heroImg={ST1Image}
          title={"Popular Rajasthan Student Holiday Packages"}
          tourCardContent={tourCardContent}
          // ruleTitle={"Egypt"}
          // rules={rulesData}
        />
      </Grid>
    </Main>
  </Box>
  );
};

export default RajasthanStudent;
