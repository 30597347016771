import React from "react";
import { Box, CardMedia, Container, Grid, Fab } from "@mui/material";
import Main from "../../../Components/layouts/Main";

import FloatingIcons from "../../../Components/FloatingIcons";

import TourSubPackage from "../../../Components/TourSubPackage";

import {RH1Image,RH2Image,RH3Image,RH4Image} from "./image";
import { HO1Image } from "../image";

const DOMESTIC_HERO = "/assets/img/domesticTour/domestic_Hero.png";




const tourCardContent = [
  {
    id: 1,
    fromToDays: "04 Nights / 05 Days",
    title: "Lovely Heritage Pink City",
    image: RH1Image,
    description: "",
    path: "/rh1",
  },
  {
    id: 2,
    fromToDays: "04 Nights / 05 Days",
    title: "Royal Lakend",
    image: RH2Image,
    description: "",
    path: "/rh2",
  },
  {
    id: 3,
    fromToDays: "06 Nights / 07 Days",
    title: "Romantic Marwar",
    image: RH3Image,
    description: "",
    path: "/rh3",
  },
  {
    id: 4,
    fromToDays: "06 Nights / 07 Days",
    title: "Romantic Rajasthan",
    image: RH4Image,
    description: "",
    path: "/rh4",
  },
];

// const rulesData = [
//   ""
// ];

const RajasthanHoneymoon = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <Grid>
          <TourSubPackage
            mainTitle={"Rajasthan Honeymoon Tour"}
            subTitle={"Amazing Holidays to Rajasthan Honeymoon Tour"}
            // details={"Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities. Domestic packages in India can be customized as per the needs and requirements of the traveler.Domestic tours in India lets you explore the beautiful states and cities of India like Goa, Himachal Pradesh, Rajasthan, Uttarakhand, Kerala, Tamil Nadu, Kashmir, Sikkim, Maharashtra, and many such cities."}
            heroImg={HO1Image}
            title={"Popular Rajasthan Honeymoon Holiday Packages"}
            tourCardContent={tourCardContent}
            // ruleTitle={"Egypt"}
            // rules={rulesData}
          />
        </Grid>
      </Main>
    </Box>
  );
};

export default RajasthanHoneymoon;
