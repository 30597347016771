import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { CA1Image } from "../image";

const heroTextData = [
  {
    title: "Cambodia",
    duration: "03 Nights / 04 Days",
    typoOfTrip: "Road Trip",
    startCity: "Siem Reap", 
    endCity: "Siem Reap",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Siem Reap Arrival",
    details:
      "Upon arrival at Siem Reap airport, welcomed by our team then transfer to hotel for check in Dinner at Indian restaurant Overnight in Siem Reap",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Siem Reap",
    details:
      "Breakfast at Hotel. Visit the antique capital of Angkor Thom (12 century): the South Gate with its huge statues depicting the churning ofthe ocean of milk, the Bayon Temple, unique for its 54 towers decorated with over 200 smiling faces ofAvolokitesvara, the Phimeanakas, the Royal Enclosure, the Elephants Terrace and the Terrace of the Leper King. Lunch at local restaurant Visit to Ta Prohm, one of the area’s most beautiful temples. Ta Prohm has been relatively untouched since it wasdiscovered and retains much of its mystery, and the most famous of all the temples on the plain of Angkor: AngkorWat. The temple complex covers 81 hectares and is comparable in size to the Imperial Palace in Beijing. Itsdistinctive five towers are emblazoned on the Cambodian flag and the 12th century masterpiece is considered by arthistorians to be the prime example of classical Khmer art and architecture. Enjoy wonderful sunset from the top ofPre Rup Temple. Dinner Indian Restaurant Overnight in Siem Reap",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Siem Reap",
    details:
      "Breakfast at Hotel. Visit to Banteay Srey, known as Citadel of Women or a Pink Temple, and Banteay Samre Temples Lunch at local restaurant In the afternoon, we will enjoy a boat ride on the Tonle Sap lake at KAMPONG PHLUK VILLAGE. This is the largestpermanent fresh-water lake in South East Asia and flows into Tonle Sap River, joining the Mekong in Phnom Penh.We will see a fishermen's floating village with floating schools, floating police station, etc. It is same as a big villagefloating on the lake, and the village move from place to place following water levels and current. Dinner Indian Restaurant Overnight in Siem Reap ",
  },
  {
    id: "Day 4",
    header: "Siem Reap – Departure",
    details:
      "Breakfast at hotel and checkout Transfer to Siem Reap airport for board flight to India. ",
  },
];

const inclusionData = [
"English speaking tour guide",
"Private air-conditioned transportation",
"Accommodation as mentioned or similar",
"Boat trips",
"Meals as mentioned on tour dating: B=Breakfast, L=Lunch, D=Dinner",
"All entrance fees",
"Two way airport transfers",
"Water & tissues",

];

const exclusionsData = [
"International/Domestic flights costs",
"Personal expenses, meals and drinks unless otherwise specified",
"Travel insurance",
"Visa fees to Vietnam",
"Compulsory Tipping of 3$/pax/day",
"Surcharge for peak season, New year, Holiday… ",

];

const CA1 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={CA1Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default CA1;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];
