import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

const BANGALORE = "/assets/img/domesticTour/kerala/BANGALORE.png";

const heroTextData = [
  {
    title: "Bangalore Mysore Coorg Ooty Kodaikanal Madurai",
    duration: "09 Nights / 10 Day",
    typoOfTrip: "Road Trip",
    startCity: "Bangalore",
    endCity: "Madurai",
  },
];

const overview = [
  "Welcome to the Srinagar, the ke City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrive at Bangalore",
    details:
      "Upon landing in the Bangalore Airport our executive will pick you and drop you at the hotel. Evening, continue for a nearby touring voyage through Bangalore City. Bangalore, the capital of Karnataka, is referred to prominently as the city of Gardens. The city is today the Silicon Valley of India separated from being the chief regulatory, business and modern center point of Karnataka State. It lies at a height of 920 m above MSL. The present day Bangalore City was established by Kempe Gowda, a chieftain under the Vijayanagara Empire, in 1537 A.D. With the help from lord Achutaraya, he fabricated a mud post here and set up petite towns inside the stronghold. Spots of vacationer enthusiasm here incorporate the Vidhana Soudha, Lalbagh Gardens, Cubban Park and Museum, Cariappa Memorial Park, The Govt. Exhibition hall, Visvesvaraya Industrial and Technological Museum, The Fort, Tippu’s Palace, Bull Temple, Venkatappa Art Gallery.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Destination Mysore ",
    details:
      "After breakfast, drive to Mysore. Evening, continue for a neighborhood touring voyage through Mysore. Mysore, a quiet and peaceful city, lies at a separation of 139 km from Bangalore. The city was the majestic capital of the recent Mysore kingdom managed by the Wodeyar line. Spots of visitor intrigue incorporate the stupendous Mysore Palace, Chamarajendra Art Gallery, Mysore Zoo Gardens, St. Philomena’s Church, Temples, Chamundi Hills, Brindavan Gardens and Bylakuppa. Medium-term at the lodging.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Destination Coorg",
    details:
      "Following a filling breakfast drive to Coorg; on entry register into inn. Rest of the day is available to you to appreciate at relaxation. Medium-term at the lodging.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 4",
    header: "Across Coorg",
    details:
      "After breakfast continue for an energizing touring voyage through Coorg. Kodagu or Coorg, known as the ‘Scotland of India’, is the most pleasant hill station of Karnataka. Situated on the Western Ghats at an elevation of 4000 ft, Kodagu has thick woodlands, espresso and tea ranches, orange plantations and lavish paddy fields spotting its territory. Most of the populace here has a place with the Kodava people group; an antiquated military race with particular culture and customs.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Destination Ooty ",
    details:
      "After a reviving breakfast drive to Ooty; on entry register into inn. Rest of the day is available to you to appreciate at relaxation.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Across Ooty",
    details:
      "After breakfast continue for a neighbourhood touring voyage through Ooty. The lovely hill station of Ooty is situated on the Blue Mountains (Nilgiris) at an elevation of 2240 m above MSL. Ooty, known as the ‘Ruler of Hill Stations’, was at one time a most loved summer retreat for the Govt. of Madras. The scene here is specked with tremendous territories of tea gardens, thick backwoods, rambling meadows and grandiose mountains. Spots of visitor enthusiasm here incorporate the Botanical Gardens, Centenary Rose Park, Rose Garden, Lake, Government Museum, Deer Park and Doddabetta.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 7",
    header: "Destination Kodaikanal",
    details:
      "Withdraw for Kodaikanal after an early breakfast at the inn. Evening, leave on an exciting touring voyage through Kodaikanal. Kodaikanal, at a separation of 120 km from Madurai, lies on the southern hull of the Palani Hills. The town is revolved around the star molded Kodai Lake spread over a zone of sixty sections of land. Kodaikanal has thickly lush inclines, well spread out walk ways through pleasant scenes, dazzling cascades and soak rough drops. Spots of visitor intrigue incorporate the Lake, Bryant Park, Coaker’s Walk, Solar Physical Observatory, Kurunji Andavar Temple, Pillar Rocks, Green Valley View and Shembaganur Natural History Museum.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 8",
    header: "Destination Madurai",
    details:
      "After a reviving breakfast continue by road to Madurai. On landing register into inn. Rest of the day is available to you to exhaust at recreation. Medium-term at the lodging.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 9",
    header: "Voyage through Madurai",
    details:
      "Following breakfast, continue for a neighborhood touring voyage through Madurai. The sanctuary town of Madurai, arranged on the banks of stream Vaigai, is a standout amongst the most antiquated urban areas in India with a history going back to the pre-Christian occasions. It was the supreme capital of the Pandyan Empire in the fourteenth Century AD. Madurai was the seat of Tamil learning and the remainder of the Tamil Sangams (Academies) was held here almost 2000 years back. Spots of traveler intrigue incorporate the forcing Thirumalai Nayakar Mahal, Gandhi Museum, Vaigai Dam, Meenakshi Temple, Athisayam Water Theme Park, Thirupparankundrum, Azhagar Koil and the Vandiyur Mariamman Teppakulam.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 10",
    header: "Departure",
    details:
      "Your tour comes to an end today. After breakfast, our executives will pick you up and drop you to the airport or railway station.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
  "Pickup and drop from Cochin Airport/Railway station/Bus station.",
  "All sightseeing, land transfer & inter hotel transfer.",
  "Driver’s batá, toll, parking, road tax and permit charges, fuel charges.",
  "Transportation as mentioned with an English/Hindi speaking friendly driver cum guide throughout your trip.",
  "Hotel Accommodation as mentioned with Complimentary breakfast (Except on the arrival day)",
  "Houseboat Booking with All Meals Welcome drink, Lunch, Tea and snacks, Dinner, Mineral water etc",
  "Complimentary Honeymoon inclusions like Candle light dinner Only for Honeymoon Couples in houseboat.",
  "Childs below 5 yrs will be complimentary.",
];

const exclusionsData = [
  "All Entrance fees for sightseeings spots and Amusement parks & Optional Activities charges",
  "Any portage at airports and hotels, tips, insurance, wine, mineral water, telephone charges and all items of personal nature.",
  "Sightseeings points which are not mentioned in the itinerary Lunch and dinner are not included in CP plans.",
  "Compulsory Gala Dinner on X'mas & New year Eve if applicable.",
  "Any other services not specifically mentioned in the inclusions.",
];

const BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackages = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={BANGALORE}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default BangaloreMysoreCoorgOotyKodaikanalMaduraiTourPackages;
