import React from "react";
import { Box, Grid } from "@mui/material";
import Main from "../../../../Components/layouts/Main";
import FloatingIcons from "../../../../Components/FloatingIcons";
import TourDetails from "../../../../Components/TourDetails";

import { SR2Image } from "../image";

const heroTextData = [
  {
    title: "Discover the Fjords of Norway",
    duration: "05 Nights / 06 Days",
    typoOfTrip: "Road Trip",
    startCity: "Oslo",
    endCity: "Bergen",
  },
];

const overview = [
  "Welcome to the Srinagar, the Lake City. On arrival at Srinagar Airport, being your tour of Splendid Kashmir'. You will be transferred to hotel. Proceed on a city tour visiting the Shankaracharya temple situated on the highest hill of Srinagar, the famous Mughal Gardens-Chashmashahi, Nishat & Shalimar. These gardens were Mughal Emperors concept of Paradise and today these are popular picnic spots. Overnight stay at the Hotel.",
];

const intineraryTimelineData = [
  {
    id: "Day 1",
    header: "Arrival in Oslo",
    details: "Arrival in Oslo according to your own itinerary. The Norwegian capital is beautifully situated at the head of the Oslo Fjord and surrounded by forested ridges. Arable fields, vast forests and the untouched nature areas. The city’s surroundings provide recreational opportunities that are frequently taken advantage of by the city’s inhabitants, as well as increasingly by visitors. Accommodation in Oslo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 2",
    header: "Explore the Norwegian capital",
    details: "Breakfast at the hotel. This day is at your own leisure to explore Oslo. Oslo offers many well know sights such as the Vigeland Sculpture Park, the Viking Ship Museum, the Munch Museum and Oslo Opera House. With the hop-on/hop-off Oslo cruise during summer you can easily go from the city of Oslo to Bygdøy peninsula where the Kon-tiki and Fram Polar Museum is located (please see add-ons for further details). Accommodation in Oslo.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 3",
    header: "Travel on the Bergen Railway to Myrdal and Vatnahalsen",
    details: "Breakfast at the hotel. Morning departure from Oslo by train. The 5 hour journey from Oslo to Myrdal is part of the Bergen Railway, classed as one of the finest train journeys in the world. This railway connects the two largest cities in Norway and shows the diversity of the Norwegian landscape. In Myrdal you will change to the Flåm Railway which, only one stop later, will arrive at Vatnahalsen Mountain Hotel. The family owned hotel offers a relaxed and welcoming atmosphere, as well as a spectacular location 820 metres above sea level. Please note that Vatnahalsen Hotel is a tourist class hotel, but included in both of our hotel categories due to it's breathtaking location. Here you can breath in the fresh mountain air, relax or go for walks in a completely car free environment. Dinner and accommodation at Vatnahalsen Mountain Hotel.",
  },
  {
    id: "Day 4",
    header: "Vatnahalsen - Flåm Railway & Fjord Cruise - Bergen",
    details: "Breakfast at the hotel, before continuing down the Flåm Railway to Flåm. The Flåm Railway passes through wild and beautiful mountain scenery with waterfalls and steep mountain sides reaching a height of up to 900 metres and down to the Sognefjord. There is no other adhesion railway in the world running on normal tracks that is so steep over a long distance. Time at leisure in Flåm before continuing by boat on the Nærøyfjord to Gudvangen. The Nærøyfjord is the narrowest fjord channel in Europe, and has been added to UNESCO’s World Heritage List. From Gudvangen the journey continues by bus through the Nærøyfjord Valley and up Stalheimskleiva Road, with its 13 steep hairpin bends (Stalheimskleiva is only open during the summer). The last part of today's journey will be by train from Voss to Bergen, Norway’s second largest city and the world’s only city surrounded by 7 mountains and 7 fjords. Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 5",
    header: "Day at leisure in Bergen",
    details: "Breakfast at the hotel. This day is at your own leisure in Bergen. Even though Bergen is the second largest city in Norway, it is rather a town with the charm and atmosphere of a small town; you can walk practically everywhere in only 20 minutes and instead of tall buildings you will find wooden houses and narrow streets. Take a stroll along Bryggen Wharf before heading to Mount Fløyen with Fløibanen funicular for a spectacular view of the city. Other sights of interest include Edvard Grieg's home Troldhaugen, the Fish and Flower market, Bergen Aquarium and Gamle Bergen (Old Bergen Open Air Museum). Accommodation in Bergen.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
  {
    id: "Day 6",
    header: "Departure from Bergen",
    details: "Breakfast in the hotel. Departure from Bergen according to your own itinerary.",
    labelData: ["label1", "label2", "label3", "label4"],
  },
];

const inclusionData = [
"5 nights hotel accommodation in a standard room with private shower/WC, including breakfast.",
"1 x buffet or 3 course dinner at Vatnahalsen Mountain Hotel",
"Train from Oslo to Myrdal",
"Train from Myrdal to Vatnahalsen",
"Train from Vatnahalsen to Flåm",
"Boat from Flåm to Gudvangen",
"Bus from Gudvangen to Voss",
"Train from Voss to Bergen",
];

const exclusionsData = [
  "Meals and Beverages other than those mentioned in the itinerary.",
  "All extra activities and admission fees",
  "Porterage services",
  "Coach service for 10 Hrs. The tour and route will be planned accordingly",
  "Personal Travel Insurance",
  "Air tickets",
  "Tips to driver (Normal standard Eur 2 per person per day).",
  ];

const SR2 = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Main>
        <TourDetails
          heroImg={SR2Image}
          heroTextData={heroTextData}
          overview={overview}
          intineraryTimelineData={intineraryTimelineData}
          inclusionsData={inclusionData}
          exclusionsData={exclusionsData}
        />
      </Main>
    </Box>
  );
};

export default SR2;

// const intineraryTimelineData = [
// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// ];

// {
//   id: "Day 1",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 2",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 3",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 4",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 5",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 6",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 7",
//   header: "",
//   details: "",
// },
// {
//   id: "Day 8",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
// {
//   id: "Day 9",
//   header: "",
//   details: "",
//   labelData: ["label1", "label2", "label3", "label4"],
// },
