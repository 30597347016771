import React from "react";
import { Box, Typography } from "@mui/material";

const ContentSection = () => {
  return (
    <Box>
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        While information is the cornerstone of our ability to provide superior
        service, our most important asset is our clients’ trust. Keeping client
        information secure, and using it only as our clients would want us to,
        is a top priority for all of us at Journey Travel & Tours. Here then, is
        our promise to our individual customers:
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will safeguard, according to strict standards of security and
        confidentiality, any information our customers share with us.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will limit the collection and use of customer information to the
        minimum we require to deliver superior service to our customers, which
        includes advising our customers about our products, services and other
        opportunities, and to administer our business.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will permit only authorized employees, who are trained in the proper
        handling of customer information, to have access to that information.
        Employees who violate our Privacy Promise will be subject to our normal
        disciplinary process.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will not reveal customer information to any external organization
        unless we have previously informed the customer in disclosures or
        agreements, or are required by law.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will always maintain control over the confidentiality of our customer
        information. We may, however, share customer information with reputable
        companies when a customer has expressed an interest in their service or
        product. Please note that this Privacy Policy does not apply to these
        other companys use of customer information.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        Whenever we hire other organizations to provide support services, we
        will require them to conform to our privacy standards and to allow us to
        audit them for compliance.
      </Typography>
      <br />
      <Typography variant="h5" color={"text.secondary"} textAlign={"start"}>
        We will attempt to keep customer files complete, up-to-date, and
        accurate. We will tell our customers how and where to conveniently
        access their information (except when we’re prohibited by law) and how
        to notify us about errors which we will promptly correct
      </Typography>
      <br />
    </Box>
  );
};

export default ContentSection;
