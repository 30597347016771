import React from "react";
import TourHeroSection from "./TourHeroSection";
import { Grid, Box, Container } from "@mui/material";
import TourCard from "./TourCard";

import PropTypes from "prop-types";
import Rules from "./Rules";

const TourSubPackage = ({
  title,
  heroImg,
  mainTitle,
  tourCardContent,
  subTitle,
  rules,
  ruleTitle,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Container>
            <TourHeroSection
              mainTitle={mainTitle}
              subTitle={subTitle}
              heroImg={heroImg}
            />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <TourCard title={title} tourCardContent={tourCardContent} />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <Rules rules={rules} ruleTitle={ruleTitle} />
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

TourSubPackage.propTypes = {
  title: PropTypes.string,
  heroImg: PropTypes.string,
  subTitle: PropTypes.string,
  mainTitle: PropTypes.string,
  tourCardContent: PropTypes.object,
  rules: PropTypes.object,
  ruleTitle: PropTypes.string,
};

export default TourSubPackage;
